import React from 'react'
import './Document.scss'
import { FileStatus, ProjectDocument } from '../../../model/document'
import { DocumentUploader } from './DocumentUploader'

import { ReactComponent as Valid } from '../../../assets/images/valid.svg'
import { ReactComponent as Pending } from '../../../assets/images/pending.svg'
import { DocumentUpload } from './DocumentUpload'
import { observer } from 'mobx-react-lite'
import { getLibelleConsigne } from '../../projects/project/parts/ProjectDetails'

export interface DocumentProps {
  document: ProjectDocument
  documentUpload: (upload: DocumentUpload) => any
  methodLog?: () => any
}

const indicator = (status: FileStatus) => (
  <div
    title={status === 'VALID' ? 'Document validé' : 'Document en attente de validation'}
    className="StatusIndicator"
    style={{ backgroundColor: status === 'VALID' ? 'var(--main-green)' : 'var(--main-yellow)' }}
  >
    {status === 'VALID' ? <Valid /> : <Pending />}
  </div>
)

export const Document = observer<DocumentProps>(({ document, documentUpload, methodLog }) => (
  <div className="Document">
    <div className="Document-header serif">{document.description}</div>
    {document.consigne && (
      <>
        <div className="Document-hearder">{getLibelleConsigne(document.consigne)}</div>
        <div className="FilesContainer" style={{ display: 'row' }}>
          <a
            className="File"
            href={document.url}
            target={'_blank'}
            rel="noopener noreferrer"
            onClick={() => methodLog && methodLog()}
          >
            <span>Télécharger le document</span>
          </a>
          {document.files.map(file => (
            <a
              className="File"
              key={file.idGED}
              title={file.name}
              href={file.downloadUrl}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              <span>{file.name}</span>
              {file.status !== 'VALID' && indicator(file.status)}
            </a>
          ))}
          {document.consigne !== 'LIRE' && document.status !== 'VALID' && (
            <DocumentUploader doc={document} documentUpdated={upload => documentUpload(upload)} />
          )}
        </div>
      </>
    )}

    {!document.consigne && (
      <div className="FilesContainer" style={{ display: 'flex' }}>
        {document.files.map(file => (
          <a
            className="File"
            key={file.idGED}
            title={file.name}
            href={file.downloadUrl}
            target={'_blank'}
            rel="noopener noreferrer"
          >
            <span>{file.name}</span>
            {file.status !== 'VALID' && indicator(file.status)}
          </a>
        ))}
        {document.status !== 'VALID' && (
          <DocumentUploader doc={document} documentUpdated={upload => documentUpload(upload)} />
        )}
      </div>
    )}
  </div>
))

import React, { FC, memo, useEffect, useState } from 'react'
import { DisplayableResource, MetadataUrlResponse } from '../../../../model/resource'
import { Card, CardBody, CardImg, CardText, CardTitle } from 'reactstrap'
import './ResourceLink.scss'
import { useAppContext } from '../../../../context'
import { from } from 'rxjs'
import { tap } from 'rxjs/operators'

export interface ResourceLinkProps {
  resource: DisplayableResource
}

export const ResourceLink: FC<ResourceLinkProps> = memo(({ resource }) => {
  const { metadataService } = useAppContext()
  const [metadata, setMetadata] = useState<MetadataUrlResponse>({})

  useEffect(() => {
    const obs = from(metadataService.metadata(resource.link))
      .pipe(tap(metadata => setMetadata(metadata)))
      .subscribe()
    return function() {
      obs.unsubscribe()
    }
  }, [resource.link, metadataService])

  return (
    <Card className="ResourceLink">
      <a href={resource.link} target="_blank" rel="noopener noreferrer">
        {metadata.image && (
          <CardImg top className="ResourceLink-Image" src={metadata.image} alt={resource.name} />
        )}

        <CardBody className="d-flex flex-column">
          <CardTitle className="ResourceLink-Title">{resource.name}</CardTitle>
          <CardText className="ResourceLink-Description text-muted">
            {metadata.description}
          </CardText>
        </CardBody>
      </a>
    </Card>
  )
})

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleRight,
  faArrowUp,
  faArrowDown,
  faBalanceScale,
  faBell,
  faBellSlash,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faCircle,
  faDownload,
  faEllipsisH,
  faEnvelope,
  faFileAlt,
  faInfoCircle,
  faLightbulb,
  faMapMarked,
  faMapMarkerAlt,
  faMinus,
  faPaperclip,
  faPaperPlane,
  faPhone,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faSpinner,
  faStar,
  faSyncAlt,
  faTachometerAlt,
  faTimesCircle,
  faUpload,
  faUser,
  faUserCircle,
  faUsers,
  faWifi,
  faExclamationCircle,
  faChevronLeft,
  faTimes,
  faPlay,
  faUniversity,
  faEye,
  faAngleDoubleLeft,
  faThumbtack,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'

import {
  faCheckCircle as faCheckCircleR,
  faComment,
  faTimesCircle as faTimesCircleR,
  faCalendar,
  faCalendarAlt,
  faTrashAlt,
} from '@fortawesome/free-regular-svg-icons'

import { faFacebookF, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'

library.add(
  faBell,
  faBellSlash,
  faEnvelope,
  faCheck,
  faPlus,
  faPlusCircle,
  faUserCircle,
  faUser,
  faFileAlt,
  faStar,
  faUsers,
  faCircle,
  faDownload,
  faLightbulb,
  faTachometerAlt,
  faBalanceScale,
  faWifi,
  faMapMarked,
  faSyncAlt,
  faCheckCircle,
  faTimes,
  faTimesCircle,
  faTimesCircleR,
  faCheckCircleR,
  faUpload,
  faEllipsisH,
  faAngleRight,
  faChevronRight,
  faChevronLeft,
  faPaperPlane,
  faPaperclip,
  faMapMarkerAlt,
  faMinus,
  faSpinner,
  faComment,
  faPhone,
  faFacebookF,
  faTwitter,
  faYoutube,
  faLinkedinIn,
  faExclamationCircle,
  faInfoCircle,
  faArrowUp,
  faArrowDown,
  faCalendar,
  faCalendarAlt,
  faPlay,
  faUniversity,
  faEye,
  faAngleDoubleLeft,
  faThumbtack,
  faQuestionCircle,
  faExternalLinkAlt,
  faTrashAlt
)

import { ProjectDocument } from './document'
import { FinancialPlan } from './financial-plan'
import { ProjectLivrableDetails } from './livrable-entrepreneur'
import { PrediagInfo } from '../components/prediag/PrediagModel'

export type UserId = string
export type AdvisorId = string
export type ProjectId = string
export type StructureId = string
export type MeetingId = string

export interface Project extends LightProject {
  owners: User[]
  presentation: string
  goal?: string
  structure?: Structure
  advisor?: Advisor
  association?: Association
  financialPlan?: FinancialPlan
  livrable?: ProjectLivrableDetails
  memento: MementoEntrepreneur
  operations: Operation[]
  createdDate: string
  globalTimeLine?: GlobalTimeLine
  prediagAllowed: boolean
  active: boolean
}

export interface MementoEntrepreneur {
  noteId?: string
  pdf: boolean
}

export interface MementoEntrepreneur {
  pdf: boolean
}

export interface LightProject {
  id: ProjectId
  name: string
}

export interface DetailedProject extends Project {
  documents: ProjectDocument[]
  meetings: Meeting[]
  newMeetings: Meeting[]
  timeline?: History
  financement: FinancementOperation[]
  prediags: PrediagInfo[]
  displayPrediagAlert: boolean
}

export interface Structure {
  id: StructureId
  name: string
  address?: Address
  siret?: string
  legalStatus?: string
}

export interface Address {
  street: string
  city: string
  zipCode: string
}

export interface Advisor {
  id: AdvisorId
  firstName: string
  lastName: string
  email: string
  phone?: string
}

export interface User {
  id: UserId
  firstName: string
  lastName: string
  email: string
}

export interface Association {
  id: string
  name: string
  address?: Address
  phone?: string
}

export interface Meeting {
  id: MeetingId
  projectId: ProjectId

  reason: string
  date: string
  duration: number

  advisors: Advisor[]
  entrepreneurs: User[]

  type: MeetingType
  address?: Address
  tel?: string
}

export interface MeetingCancel {
  id: MeetingId
  cancelReason?: string
  onlyForCurrentUser: boolean
}

export interface DocumentConsultationRequest {
  documentId: string
  url: string
  projectId: string
}

export type MeetingType = 'IN_PERSON' | 'PHONE' | 'COMMITTEE'

export type ProjectStage = 'INIT' | 'DECISION' | 'IN_CONTRACT' | 'ACTIVATED'

export type ProjectFinancementStage =
  | 'INIT'
  | 'DECISION'
  | 'IN_CONTRACT'
  | 'ACTIVATED'
  | 'ABANDONNE'
  | 'AJOURNE'
  | 'REFUSE'

export interface AnalysisNote {
  id: string
  projectId: string
  analysisItems: Bloc[]
  createdDate: string
}

export interface SingleBloc {
  type: 'simple'
  blockType: BlockType
  metadata: Field[]
  values: YearlyResults[]
}

export interface SplitBloc {
  type: 'split'
  blockType: BlockType
  metadata: Field[]
  subBlocks: SubBlock[]
}

export type Bloc = SingleBloc | SplitBloc

export interface SubBlock {
  variant: BlockTypeVariant
  values: YearlyResults[]
}

export interface Field {
  id: FieldId
  label: string
  computed: boolean
}

export interface YearlyResults {
  date: string
  results: CategorySummary[]
}

export interface CategorySummary {
  fieldId: FieldId
  value: number
}

export type AnalysisCategory = string
export type FieldId = string

export type BlockType = 'ACTIF' | 'PASSIF' | 'NET_PROFIT' | 'WORKING_CAPITAL' | 'DEBTS'
export type BlockTypeVariant = 'PAST' | 'ESTIMATED'

export type ProjectEventId = string

export interface ProjectEvent {
  id: ProjectEventId
  projectId: ProjectId
  createdAt: string
  eventType: ProjectEventType
  metadata: any
}

export type ProjectEventType =
  | 'DOCUMENT_REQUIRED'
  | 'DOCUMENT_VALIDATION'
  | 'MEETING_NEW'
  | 'MEETING_UPDATE'
  | 'MEETING_CANCELLED'
  | 'DELIVERABLE_UPDATE'
  | 'MEMENTO_PUBLISH'
  | 'MEMENTO_UNPUBLISH'
  | 'MEMENTO_UPDATE'
  | 'COMMITTEE_STATUS'
  | 'NOTIFY_PREDIAG_NEEDED'
  | 'NOTIFY_PREDIAG_UPDATE'
  | 'NOTIFY_PREDIAG_VALIDATION'

export const ProjectEventTypes: ProjectEventType[] = [
  'DOCUMENT_REQUIRED',
  'DOCUMENT_VALIDATION',
  'MEETING_NEW',
  'MEETING_UPDATE',
  'MEETING_CANCELLED',
  'DELIVERABLE_UPDATE',
  'MEMENTO_PUBLISH',
  'MEMENTO_UNPUBLISH',
  'MEMENTO_UPDATE',
  'COMMITTEE_STATUS',
  'NOTIFY_PREDIAG_NEEDED',
  'NOTIFY_PREDIAG_UPDATE',
  'NOTIFY_PREDIAG_VALIDATION',
]

export interface History {
  projectId: ProjectId
  events: ProjectEvent[]
}

export interface Pact {
  id: string
  name: string
  link: string
}

export interface Operation {
  id: string
  creationDate: string
  type: OperationType
  timelineStatus: OperationStatus
  abandonment?: Abandonment
  loan?: Loan
  rate: number
  amount: number
  askedAmount: number
  askedLength: number
  delay: number
  lengthWithDelay: number
  comitteeDate?: string
  notificationDate?: string
  decaissementDate?: string
  signatureContractDate?: string
  chargedOfMissionDecision?: ChargedOfMissionDecision
  accountingCode?: string
  amortissementType?: AmortissementType
  paymentMean?: PaymentMean
  firstDeadlineDate?: string
  firstDeadLineAmount: number
  otherDeadlinesAmount: number
  quantiemePrelevement?: string
  nouba?: Nouba
}

export interface FinancementOperation {
  id: string
  creationDate: string
  type: OperationType
  timelineStatus: FinancementOperationStatus
  status: FinancementStatus
  abandonment?: Abandonment
  loan?: Loan
  rate: number
  amount: number
  askedAmount: number
  askedLength: number
  delay: number
  lengthWithDelay: number
  comitteeDate?: string
  nextComitteeDate?: string
  notificationDate?: string
  decaissementDate?: string
  signatureContractDate?: string
  chargedOfMissionDecision?: ChargedOfMissionDecision
  accountingCode?: string
  amortissementType?: AmortissementType
  paymentMean?: PaymentMean
  firstDeadlineDate?: string
  firstDeadLineAmount: number
  otherDeadlinesAmount: number
  quantiemePrelevement?: string
  nouba?: Nouba
}

export interface OperationType {
  id: string
  description: string
  funding: string
  network: string
}

export type OperationStatus = 'INIT' | 'DECISION' | 'IN_CONTRACT' | 'ACTIVATED'

export type FinancementOperationStatus =
  | 'INIT'
  | 'DECISION'
  | 'IN_CONTRACT'
  | 'ACTIVATED'
  | 'ABANDONNE'
  | 'REFUSE'
  | 'AJOURNE'

export const FinancementOperationsSuiviesStatusArray: FinancementOperationStatus[] = [
  'INIT',
  'DECISION',
  'IN_CONTRACT',
  'ACTIVATED',
  'AJOURNE',
]

export const FinancementOperationsArchiveesStatusArray: FinancementOperationStatus[] = [
  'ABANDONNE',
  'REFUSE',
]

export type FinancementStatus =
  | 'INSTRUCTION'
  | 'ABANDONNE'
  | 'AJOURNE'
  | 'REFUSE'
  | 'ACCEPTE'
  | 'DECAISSE'
  | 'FACTURE'
  | 'ENCAISSE'
  | 'CLOS'
  | 'EXTERNE'

export interface Abandonment {
  date: string
  reason: AbandonmentReason
  amount: number
}

export type AbandonmentReason =
  | 'PERSO_BEARER'
  | 'PERSO_FUNDING'
  | 'PERSO_OUT_OF_DATE'
  | 'PROJECT_LOST'
  | 'PROJECT_CE'
  | 'PROJECT_ASSOCIATE'
  | 'PROJECT_TECHNICAL'
  | 'FUNDING_CB'
  | 'FUNDING_REFUSED'
  | 'FUNDING_OTHER'
  | 'FUNDING_DEPOSIT'
  | 'FUNDING_FINANCE'

export interface Loan {
  id: string
  type: OperationType
  status: OperationStatus
  rate: number
  askedAmount: number
  amount: number
  askedLength: number
  delay: number
  lengthWithDelay: number
}

export type ChargedOfMissionDecision = 'FAVORABLE' | 'UNFAVORABLE' | 'RESERVE'

export type AmortissementType = 'MONTHLY' | 'QUARTERLY' | 'BIANNUAL' | 'ANNUAL' | 'FINAL'

export type PaymentMean = 'CHECK' | 'BANK_TRANSFER' | 'DEBIT'

export interface Nouba {
  id: string
  versionId: string
  synchronizationDate: string
  interventionLine: string
  baseId: string
  baseVersionId: string
  baseSynchronizationDate: string
  baseInterventionLine: string
}

export interface GlobalTimeLine {
  init: TimeLineProgress
  decision: TimeLineProgress
  inContract: TimeLineProgress
  activated: TimeLineProgress
}

export type TimeLineProgress = 'NONE' | 'IN_PROGRESS' | 'DONE'

export interface CommitteeOperation {
  notificationType: CommitteeNotificationType
  operation: CommitteeEventOperation
}

export interface CommitteeEventOperation {
  id: string
  type: OperationType
}

export type CommitteeNotificationType = 'ADD' | 'REPORT' | 'REMOVE'

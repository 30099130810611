import React, { FC, useLayoutEffect, useState } from 'react'
import { H2 } from '../shared/H/H'
import styled from 'styled-components'
import { useAppContext } from '../../context'
import { AnsweredQuestion, FAQuizz, Quizz } from '../../model/quizz'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { QuizzForm } from './QuizzForm'
import { Project } from '../../model/project'
import { FATitleCard } from '../shared/CustomStyledComponents/Card'
import { OnBoardingQuizz } from './onboarding/OnBoardingQuizz'
import { BrowserView } from 'react-device-detect'

const Body = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

const QuizzDiv = styled.div`
  width: 285px;
  height: 385px;
  flex-grow: 0;
  display: flex;
  min-width: 285px;
  min-height: 385px;
  position: relative;
  background-color: white;
  padding: 20px;
`

const QuizContainer = styled.div`
  display: flex;
  flex-direction: row;
  .space-between + .space-between {
    margin-left: 3%;
  }
`

const Column = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  z-index: 2;
  text-align: center;
  padding-bottom: 20%;
`

const Description = styled.span`
  font-size: 15px;
  line-height: 17px;
  font-weight: 600;
  &.result {
    color: var(--main-red);
  }
  &.no-result {
    color: var(--main-white);
  }
`
const QuizzTitle = styled.span`
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  &.result {
    color: var(--main-red);
  }
  &.no-result {
    color: var(--main-white);
  }
`

const QuizzLaunch = styled.div`
  color: var(--main-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5%;
  width: 40%;
  text-align: center;
  :hover {
    cursor: pointer;
  }
`

const LaunchButton = styled.div`
  text-align: center;
  padding: 12px 0;
  width: 48px;
  border-radius: 25px;
  background-color: var(--main-red);
`

const SeeAnswers = styled.div`
  text-align: center;
  width: 80px;
  height: 40px;
  border-radius: 5px;
  background-color: var(--main-gray-2);
  font-size: 32px;
  margin-bottom: 8px;
  :hover {
    background-color: var(--main-red);
  }
`

const AbsoluteDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
`
const BackgroundIcon = styled(FontAwesomeIcon)`
  position: absolute;
  color: var(--main-gray-3);
  font-size: 18em;
`
const QuizzImageMask = styled(AbsoluteDiv as any)`
  opacity: 0.8;
  background-color: black;
  z-index: 1;
`

export const QuizzContext = React.createContext<{
  goBackToQuizzes: () => any
  answerQuestion: (answeredQuestion: AnsweredQuestion) => any
  project?: Project
  forceSeeResult: boolean
  quizzCompleted: boolean
  quizzId: FAQuizz
}>({
  goBackToQuizzes: () => {},
  answerQuestion: () => {},
  project: undefined,
  forceSeeResult: false,
  quizzCompleted: false,
  quizzId: 'BANK',
})

const QuizzOverview: FC<{ quizz: Quizz; onClick: () => any }> = ({ quizz, onClick }) => {
  const hasResult = quizz.profiles.length > 0
  const { accountService } = useAppContext()
  const [onBoardingReady, setonBoardingReady] = useState(false)

  useLayoutEffect(() => setonBoardingReady(true), [accountService.accountOnBoarding])

  return (
    <QuizzDiv className={'space-between'}>
      {!hasResult && <QuizzImageMask />}
      <AbsoluteDiv className={'d-flex flex-column justify-content-center'}>
        <BackgroundIcon icon={'university'} />
      </AbsoluteDiv>
      <Column>
        <div className={'px-3'}>
          <QuizzTitle className={hasResult ? 'result' : 'no-result'}>{quizz.title}</QuizzTitle>
        </div>
        <div className={'px-3'}>
          {!hasResult && <Description className={'no-result'}>{quizz.description}</Description>}
          {hasResult && (
            <>
              <Description className={'result'}>
                {quizz.profiles.length > 1 ? 'Vos profils' : 'Votre profil'} :{' '}
              </Description>
              <br />
              <QuizzTitle className={'result'}>
                {quizz.profiles.map(p => (
                  <div key={p.label} style={quizz.profiles.length > 1 ? { fontSize: '12px' } : {}}>
                    {p.label}
                  </div>
                ))}
              </QuizzTitle>
            </>
          )}
        </div>
        <div />
        <div
          style={{
            position: 'absolute',
            bottom: '-10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {!hasResult && (
            <QuizzLaunch onClick={onClick}>
              <LaunchButton>
                <FontAwesomeIcon icon={'play'} size={'lg'} />
              </LaunchButton>
              <span
                style={{
                  lineHeight: '20px',
                  fontSize: '16px',
                  fontWeight: 600,
                  color: hasResult ? 'var(--main-gray)' : 'white',
                }}
              >
                Commencer le quiz
              </span>
            </QuizzLaunch>
          )}
          {hasResult && (
            <QuizzLaunch onClick={onClick} style={{ lineHeight: '10px' }}>
              <SeeAnswers>
                <FontAwesomeIcon
                  icon={'eye'}
                  size={'lg'}
                  style={{ paddingBottom: '3px', lineHeight: '10px' }}
                />
              </SeeAnswers>
              <span
                style={{
                  lineHeight: '18px',
                  fontSize: '16px',
                  fontWeight: 600,
                  color: hasResult ? 'var(--main-gray)' : 'white',
                }}
              >
                Revoir mes réponses
              </span>
            </QuizzLaunch>
          )}
        </div>
      </Column>
      <BrowserView>{onBoardingReady && <OnBoardingQuizz />}</BrowserView>
    </QuizzDiv>
  )
}

export const QuizzComponent: FC = () => {
  const { quizzService, projectsStore } = useAppContext()
  const [quizzes, setQuizzes] = useState<Quizz[]>([])
  const [quizz, setQuizz] = useState<Quizz>()

  const [forceSeeResult] = useState(false)

  if (quizzes.length === 0) {
    quizzService.getQuizzes().then(resultQuizzes => {
      if (quizzes) {
        setQuizzes(resultQuizzes)
      }
    })
  }

  return (
    <>
      <FATitleCard body>
        <H2 arrowColor={'red'} style={{ marginBottom: 0 }}>
          Quiz
        </H2>
      </FATitleCard>
      <Body>
        {!quizz && quizzes && (
          <QuizContainer id={'onBoardingQuizz'}>
            {quizzes.map(quizz => (
              <QuizzOverview
                key={`${quizz.quizzId}`}
                quizz={quizz}
                onClick={() => setQuizz(quizz)}
              />
            ))}
          </QuizContainer>
        )}
        {quizz && (
          <QuizzContext.Provider
            value={{
              forceSeeResult,
              goBackToQuizzes: () => {
                setQuizz(undefined)
                setQuizzes([])
              },
              quizzCompleted: quizz && quizz.profiles.length > 0,
              answerQuestion: answeredQuestion =>
                quizzService
                  .answer(answeredQuestion.overrideQuizzId ?? quizz!!.quizzId, answeredQuestion)
                  .then(res => setQuizz(res)),
              project: projectsStore.projects[0],
              quizzId: quizz.quizzId,
            }}
          >
            <QuizzForm quizz={quizz} />
          </QuizzContext.Provider>
        )}
      </Body>
    </>
  )
}

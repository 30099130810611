import React, { FC, useEffect } from 'react'
import { Card, Col, Row } from 'reactstrap'
import { ProjectMessenger } from './ProjectMessenger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './MessengerPage.scss'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Route, RouteComponentProps, Switch } from 'react-router'
import { Project } from '../../model/project'
import { ProjectMessengerData } from './store/ProjectMessengerData'
import { H4 } from '../shared/H/H'
import { useAppContext } from '../../context'
import { useEffectOnce } from 'react-use'
import { BrowserView, MobileView } from 'react-device-detect'

export const MessengerPageRedirector: FC<RouteComponentProps<{ id: string }>> = observer(
  ({ match }) => {
    const { projectsStore, messengerStore } = useAppContext()
    useEffectOnce(() =>
      projectsStore.onProjectsLoaded(projects => {
        messengerStore.setMainProject(projects.find(p => p.id === match.params.id) || projects[0])
      })
    )
    return <></>
  }
)

const ProjectPill = observer<{ project: ProjectMessengerData }>(({ project }) => {
  const { routingStore, messengerStore } = useAppContext()
  const { currentProject } = messengerStore!
  const isCurrent = currentProject === project.project

  useEffect(() => {
    if (isCurrent) routingStore.replace(`/m/${project.id}`)
  })
  return (
    <Card
      key={project.id}
      body
      className={classNames('Messenger-ProjectSwitch', {
        'Messenger-ProjectSwitch-Selected': isCurrent,
      })}
      onClick={() => messengerStore.setMainProject(project.project)}
    >
      <div className="d-flex justify-content-between align-items-center">
        <span className="text">{project.project.name}</span>
        {!!project.newMessagesCount && (
          <div className={`Messenger-NewMessageCounter`}>{project.newMessagesCount}</div>
        )}
      </div>
    </Card>
  )
})

const ProjectMessengerSwitcher = observer(() => {
  const { messengerStore } = useAppContext()
  const projectWithMessenger = messengerStore.projectsWithMessages.filter(p => p.messengerEnabled)
  if (projectWithMessenger.length > 1)
    return (
      <Card body outline className="mb-3">
        <H4 arrowColor={'blue'} text={'Messagerie'} />
        <div className="d-flex flex-column serif">
          {projectWithMessenger.map(p => (
            <ProjectPill project={p} key={p.id} />
          ))}
        </div>
      </Card>
    )
  else return <></>
})

const AdvisorContactCard = observer<{ project: Project }>(({ project }) => (
  <Card body outline className="AdvisorContact">
    <div className="m-2">
      <span
        className="fa-layers fa-fw align-items-start"
        style={{ height: '75px', marginLeft: '-20px' }}
      >
        <FontAwesomeIcon icon="user" size={'3x'} />
        <FontAwesomeIcon icon={['far', 'comment']} size={'2x'} transform="up-10 right-20" />
      </span>
    </div>
    <div className="AdvisorContact-contact">Contacter mon conseiller</div>
    <div className="divider" />

    {project.advisor && (
      <>
        <div className="AdvisorContact-name serif">
          {project.advisor.firstName} {project.advisor.lastName}
        </div>
        <div className="AdvisorContact-info">{project.advisor.email}</div>
        <div className="AdvisorContact-info">{project.advisor.phone}</div>
      </>
    )}
  </Card>
))

export const MessengerPage = observer(() => {
  const { messengerStore } = useAppContext()
  const projectMessengerData = messengerStore.forCurrentProject
  const currentProject = messengerStore.currentProject
  return (
    <div className="Messenger">
      <Switch>
        <Route path="/m/:id" component={MessengerPageRedirector} />
        <Route component={MessengerPageRedirector} />
      </Switch>
      {currentProject && (
        <>
          <BrowserView>
            <Row className="d-flex">
              <Col md={5} lg={4} xl={3} className="mb-3">
                <ProjectMessengerSwitcher />
                <AdvisorContactCard project={currentProject} />
              </Col>
              <Col md={7} lg={8} xl={9} className="d-flex">
                {projectMessengerData && (
                  <div className="flex-grow-1">
                    <ProjectMessenger projectMessageData={projectMessengerData} />
                  </div>
                )}
              </Col>
            </Row>
          </BrowserView>
          <MobileView>
            <ProjectMessengerSwitcher />
            {projectMessengerData && (
              <div className="flex-grow-1">
                <ProjectMessenger projectMessageData={projectMessengerData} />
              </div>
            )}
          </MobileView>
        </>
      )}
    </div>
  )
})

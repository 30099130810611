import React, { FC, useContext, useState } from 'react'
import {
  PrediagCategory,
  PrediagHeader,
  PrediagQuestionPart,
  PrediagSubCategory,
} from './PrediagModel'
import { CategoryTitle, Indent } from './PrediagCategoryComponent'
import { camelize } from 'tslint/lib/utils'
import styled from 'styled-components'
import { FAInput } from '../shared/CustomStyledComponents/Inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PrediagContext } from './PrediagComponent'

const FAIconUrlPrediag = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
  color: var(--main-violet);
`

const FACheckboxPrediag = styled(FAInput as any)`
  -ms-transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -o-transform: scale(2);
  color: var(--main-violet);
  background-color: var(--main-violet-2);
`

const LinkPrediag = styled.a`
  color: var(--main-violet);
`

const Table = styled.table`
  width: calc(100vw - 400px);
  border: none;
`

const SubTitle = styled.tr`
  display: block;
  color: var(--main-violet);
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
`

const Line = styled.tr`
  border: none;
`

const Title = styled.td`
  border: none;
  background-color: var(--main-gray-3);
  margin-left: 35px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
`

const TitleLine = styled(Line as any)`
  margin-left: -35px;
  background-color: var(--main-gray-3);
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  width: 100%;
  ${Title}:nth-child(1) {
    width: 20%;
  }
  ${Title}:nth-child(2) {
    width: 10%;
  }
  ${Title}:nth-child(3) {
    width: 10%;
  }
  ${Title}:nth-child(4) {
    width: 30%;
  }
  ${Title}:nth-child(5) {
    width: 15%;
  }
  ${Title}:nth-child(6) {
    width: 15%;
  }
`

const ItemTd = styled.td`
  border: none;
`

interface QuestionListProps {
  questionPart: PrediagQuestionPart
}

const PrediagQuestionCheckBox: FC<QuestionListProps> = ({ questionPart }) => {
  const { answer } = useContext(PrediagContext)
  const [value, setValue] = useState(questionPart.answer || '0')

  const onChange = (changedValue: string) => {
    setValue(changedValue)
    answer({ questionId: questionPart.id, value: changedValue })
  }

  return (
    <>
      <FACheckboxPrediag
        type="checkbox"
        checked={value === '1'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.checked ? '1' : '0')
        }}
      />
    </>
  )
}

const PrediagQuestionTextArea: FC<QuestionListProps> = ({ questionPart }) => {
  const { answer } = useContext(PrediagContext)
  const [value, setValue] = useState(questionPart.answer || '')

  const answerQuestion = (answerValue: string) => {
    answer({ questionId: questionPart.id, value: answerValue })
  }

  return (
    <>
      <FAInput
        inputType={'textarea'}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value)
          answerQuestion(e.target.value)
        }}
      />
    </>
  )
}

function renderItem(type: string, item: PrediagQuestionPart) {
  switch (type) {
    case 'LABEL':
      return <ItemTd>{item.text}</ItemTd>
    case 'BOOLEAN':
      return (
        <ItemTd>
          <PrediagQuestionCheckBox questionPart={item} />
        </ItemTd>
      )
    case 'TEXTAREA':
      return (
        <ItemTd>
          <PrediagQuestionTextArea questionPart={item} />
        </ItemTd>
      )
    case 'URL':
      return (
        <ItemTd>
          <LinkPrediag href={item.url} target="_blank">
            <FAIconUrlPrediag icon="external-link-alt" /> {item.text}
          </LinkPrediag>
        </ItemTd>
      )
    default:
      return <ItemTd>{}</ItemTd>
  }
}

const ItemLine: FC<{
  item: PrediagQuestionPart
  header: PrediagHeader
}> = ({ item, header }) => {
  return <>{header && renderItem(header.type, item)}</>
}

const Header: FC<{
  subCategory: PrediagSubCategory
}> = ({ subCategory }) => {
  return (
    <>
      <Table>
        <TitleLine>
          {subCategory.headers.map(value => (
            <>
              <Title>{value.title}</Title>
            </>
          ))}
        </TitleLine>
        <SubTitle>
          <ItemTd colSpan={6}>{subCategory.title}</ItemTd>
        </SubTitle>
        {subCategory.questions.map(value => (
          <>
            <Line>
              {value.parts.map(it => (
                <>
                  <ItemLine
                    item={it}
                    header={subCategory.headers.find(h => h.id === it.headerId)!!}
                  />
                </>
              ))}
            </Line>
          </>
        ))}
      </Table>
    </>
  )
}

export const PrediagCategoryComponentListQuestions: FC<{
  prediagCategory: PrediagCategory
  categoryIndex: number
}> = ({ prediagCategory, categoryIndex }) => {
  return (
    <>
      <CategoryTitle>
        {categoryIndex + 1} / {camelize(prediagCategory.title)}
      </CategoryTitle>

      <Indent>
        {prediagCategory.sousCategories &&
          prediagCategory.sousCategories.map(value => (
            <Header subCategory={value} key={value.id} />
          ))}
      </Indent>
    </>
  )
}

import React, { FC, useContext } from 'react'
import { Prediag } from './PrediagModel'
import { PrediagContext } from './PrediagComponent'
import styled from 'styled-components'
import { GreyText } from '../calendar/FACalendar'
import { PrimaryButton, SecondaryButton } from '../shared/CustomStyledComponents/Buttons'
import { FATextBold } from '../shared/CustomStyledComponents/Text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppContext } from '../../context'

const BigTitle = styled.span`
  font-weight: 600;
  color: var(--main-violet);
  font-size: 50px;
`

export const PrediagEndingPage: FC<{ prediag: Prediag; goBackToPrediagHome: () => any }> = ({
  prediag,
  goBackToPrediagHome,
}) => {
  const { prediagStore } = useAppContext()

  return (
    <>
      <BigTitle>Merci</BigTitle>
      <div className={'py-2 d-flex flex-column'}>
        <GreyText>{prediag.footer}</GreyText>
      </div>

      <div className={'mt-3 d-flex flex-column'}>
        {
          <PrimaryButton
            color={'--main-violet'}
            onClick={() => prediagStore.downloadPrediag(prediag.id)}
          >
            <FontAwesomeIcon icon={'download'} /> Télécharger le questionnaire complet
          </PrimaryButton>
        }
        <SecondaryButton className={'my-3'} color={'--main-violet'} onClick={goBackToPrediagHome}>
          Retour à la page d'accueil
        </SecondaryButton>
      </div>
    </>
  )
}

export const PrediagValidationStatusPage: FC<{ prediag: Prediag }> = ({ prediag }) => {
  const { project, home } = useContext(PrediagContext)

  const { prediagStore } = useAppContext()

  return (
    <>
      <FATextBold color={'--main-violet'}>Statut</FATextBold>
      <FATextBold>{prediag.validated ? 'Validé' : 'En cours de validation'}</FATextBold>
      {
        <PrimaryButton
          className={'my-3'}
          color={'--main-violet'}
          onClick={() => prediagStore.downloadPrediag(prediag.id)}
        >
          <FontAwesomeIcon icon={'download'} /> Télécharger le questionnaire complet
        </PrimaryButton>
      }
      {prediag.provenance === 'MUFFIN' && (
        <div className={'d-flex flex-column my-3'}>
          <FATextBold color={'--main-violet'}>Evaluation demandée par votre conseiller:</FATextBold>
          <GreyText>
            {project &&
              project.advisor &&
              `${project.advisor.firstName} ${project.advisor.lastName.toUpperCase()}`}
          </GreyText>
        </div>
      )}
      <div className={'d-flex flex-column my-3'} style={{ maxWidth: '50%' }}>
        <FATextBold color={'--main-violet'}>Objectifs:</FATextBold>
        <span>
          <GreyText>{prediag.description}</GreyText>
        </span>
      </div>
      <div>
        <SecondaryButton color={'--main-violet'} onClick={home}>
          Retour à l'accueil
        </SecondaryButton>
      </div>
    </>
  )
}

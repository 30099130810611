import React, { useState } from 'react'
import { TabDefinition, Tabs } from '../shared/Tabs/Tabs'
import './Profile.scss'
import { Notifications } from './notifications/Notifications'
import { Personal } from './personal/Personal'
import { H4 } from '../shared/H/H'
import { Card } from 'reactstrap'
import { observer } from 'mobx-react-lite'
import { useAppContext } from '../../context'

export type PROFILE_TABS_KIND = 'PERSONAL' | 'NOTIFICATIONS'

const tabs: TabDefinition<PROFILE_TABS_KIND>[] = [
  {
    label: 'Mes informations personnelles',
    id: 'PERSONAL',
  },
  {
    label: 'Paramétrer mes notifications',
    id: 'NOTIFICATIONS',
  },
]

const Profile = observer(() => {
  const { authStore } = useAppContext()
  const [selectedTab, setTab] = useState<PROFILE_TABS_KIND>('PERSONAL')

  return (
    <div className="Profile">
      <Card body className="Profile-Header">
        <H4 arrowColor={'green'} text={'Mon Profil'} />
        <Tabs
          tabs={tabs}
          selected={selectedTab}
          onSelect={t => {
            setTab(t)
          }}
        />
      </Card>
      {selectedTab === 'PERSONAL' ? <Personal user={authStore.user!} /> : <Notifications />}
    </div>
  )
})

export default Profile

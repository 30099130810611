import React, { FC, useState } from 'react'
import { Button, Card, PopoverBody } from 'reactstrap'

import './AvancementProjet.scss'
import { observer } from 'mobx-react-lite'
import { TimeLine, TimelineSkeleton } from '../../projects/timeline/Timeline'
import { H4 } from '../../shared/H/H'
import { useAppContext } from '../../../context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  BoldText,
  ButtonPopover,
  Carre,
  CustomPopover,
  GreenBoldText,
  IconHelp,
  InnerLinePopover,
  LinePopover,
  TextPopover,
  TitrePopOver,
} from '../../shared/CustomStyledComponents/Popover'

const AvancementProjet: FC<{}> = observer(() => {
  const { projectsStore } = useAppContext()
  const [popoverHelpOpen, setPopoverHelpOpen] = useState(false)
  const [popoverHelpNextOpen, setPopoverHelpNextOpen] = useState(false)

  return (
    <div className="AvancementProjet">
      <Card body>
        <H4 style={{ display: 'flex' }} arrowColor={'green'}>
          Les étapes de mon projet
          {projectsStore.projects.length > 0 ? (
            <>
              <button
                style={{ marginLeft: 'auto' }}
                className="fa-layers fa-fw fa-lg btn-naked"
                onClick={() => setPopoverHelpOpen(true)}
              >
                <IconHelp icon={['fas', 'question-circle']} color={'var(--main-gray-2)'} />
              </button>
            </>
          ) : (
            <></>
          )}
        </H4>
        <div className="Timelines">
          {projectsStore.projects.map((project, index) => {
            return (
              <>
                <TimeLine
                  id={index === 0 ? 'helpTimeLine' : ''}
                  project={project}
                  key={project.id}
                  projectLink={true}
                />
                {project.active && (
                  <>
                    <HelpPopover
                      target={'helpTimeLine'}
                      isHelpOpen={popoverHelpOpen}
                      toggleHelp={() => setPopoverHelpOpen(!popoverHelpOpen)}
                      setPopoverHelpNextOpen={setPopoverHelpNextOpen}
                    />
                    <HelpPopoverNext
                      target={'helpTimeLine'}
                      isHelpOpen={popoverHelpNextOpen}
                      setPopoverHelpOpen={setPopoverHelpOpen}
                      toggleHelp={() => setPopoverHelpNextOpen(!popoverHelpNextOpen)}
                    />
                  </>
                )}
              </>
            )
          })}
          {!projectsStore.projectsFetched && <TimelineSkeleton />}
          {projectsStore.projectsFetched && !projectsStore.projects.length && (
            <div>Vous n'avez pas de projet en cours</div>
          )}
        </div>
      </Card>
    </div>
  )
})

const HelpPopover: FC<{
  target: string
  isHelpOpen: boolean
  toggleHelp: () => any
  setPopoverHelpNextOpen: any
}> = ({ target, isHelpOpen, toggleHelp, setPopoverHelpNextOpen }) => {
  return (
    <CustomPopover target={target} isOpen={isHelpOpen} trigger="legacy" placement={'bottom'}>
      <PopoverBody>
        <div>
          <TextPopover style={{ display: 'flex' }}>
            1 / 2
            <button
              style={{ marginLeft: 'auto' }}
              className="fa-layers fa-fw fa-lg btn-naked"
              onClick={toggleHelp}
            >
              <FontAwesomeIcon icon="times" color={'var(--main-gray-2)'} />
            </button>
          </TextPopover>
        </div>
        <TitrePopOver>Les étapes de votre projet</TitrePopOver>
        <div style={{ marginTop: '15px' }}>
          <LinePopover style={{ marginBottom: '10px' }}>
            <TextPopover>
              Le cycle de vie de votre projet est divisé en <GreenBoldText>4 étapes</GreenBoldText>{' '}
              :
            </TextPopover>
          </LinePopover>
          <div>
            <LinePopover>
              <CustomNumber number={'1'} />
              <InnerLinePopover>
                <TextPopover>
                  <GreenBoldText>En accompagnement</GreenBoldText> : je suis accompagné(e) pour
                  trouver la meilleure solution qui répond à mon besoin
                </TextPopover>
              </InnerLinePopover>
            </LinePopover>
            <LinePopover>
              <CustomNumber number={'2'} />
              <InnerLinePopover>
                <TextPopover>
                  <GreenBoldText>En décision</GreenBoldText> : le comité s'apprête à prendre une
                  décision sur ma demande de financement
                </TextPopover>
              </InnerLinePopover>
            </LinePopover>
            <LinePopover>
              <CustomNumber number={'3'} />
              <InnerLinePopover>
                <TextPopover>
                  <GreenBoldText>En contractualisation</GreenBoldText> : j'ai eu un accord en
                  comité, je réunis tous les éléments nécessaires pour le mettre en place
                </TextPopover>
              </InnerLinePopover>
            </LinePopover>
            <LinePopover>
              <CustomNumber number={'4'} />
              <InnerLinePopover>
                <TextPopover>
                  <GreenBoldText>Activé</GreenBoldText> : le(s) solution(s) de financement ont été
                  mise(s) en place, à moi de jouer !
                </TextPopover>
              </InnerLinePopover>
            </LinePopover>
          </div>
        </div>
        <LinePopover>
          <ButtonPopover
            style={{ marginLeft: 'auto' }}
            onClick={() => {
              toggleHelp()
              setPopoverHelpNextOpen(true)
            }}
          >
            Suivant
          </ButtonPopover>
        </LinePopover>
      </PopoverBody>
    </CustomPopover>
  )
}

const HelpPopoverNext: FC<{
  target: string
  isHelpOpen: boolean
  toggleHelp: () => any
  setPopoverHelpOpen: any
}> = ({ target, isHelpOpen, toggleHelp, setPopoverHelpOpen }) => {
  return (
    <CustomPopover target={target} isOpen={isHelpOpen} trigger="legacy" placement={'bottom'}>
      <PopoverBody>
        <div>
          <TextPopover style={{ display: 'flex' }}>
            2 / 2
            <button
              style={{ marginLeft: 'auto' }}
              className="fa-layers fa-fw fa-lg btn-naked"
              onClick={toggleHelp}
            >
              <FontAwesomeIcon icon="times" color={'var(--main-gray-2)'} />
            </button>
          </TextPopover>
        </div>
        <TitrePopOver>Légende</TitrePopOver>
        <div>
          <LinePopover>
            <Carre style={{ backgroundColor: 'var(--main-light-gray)' }} />
            <InnerLinePopover>
              <GreenBoldText>Blanc : </GreenBoldText>
              <BoldText>l'étape n'est pas commencée</BoldText>
            </InnerLinePopover>
          </LinePopover>
          <LinePopover>
            <Carre style={{ backgroundColor: 'var(--main-yellow-2)' }} />
            <InnerLinePopover>
              <GreenBoldText>Orange : </GreenBoldText>
              <BoldText>l'étape est en cours</BoldText>
            </InnerLinePopover>
          </LinePopover>
          <LinePopover>
            <Carre style={{ backgroundColor: 'var(--main-green)' }} />
            <InnerLinePopover>
              <GreenBoldText>Vert : </GreenBoldText>
              <BoldText>l'étape est terminée</BoldText>
            </InnerLinePopover>
          </LinePopover>
        </div>
        <LinePopover>
          <div style={{ marginLeft: 'auto' }}>
            <Button
              color=""
              onClick={() => {
                toggleHelp()
                setPopoverHelpOpen(true)
              }}
            >
              <TextPopover>Précédent</TextPopover>
            </Button>
            <ButtonPopover
              onClick={() => {
                toggleHelp()
              }}
            >
              Terminer
            </ButtonPopover>
          </div>
        </LinePopover>
      </PopoverBody>
    </CustomPopover>
  )
}

const CustomNumber: FC<{
  number: string
}> = ({ number }) => {
  return (
    <span className="fa-layers fa-fw" style={{ marginRight: '10px', color: 'var(--main-gray)' }}>
      <FontAwesomeIcon icon={'circle'} size={'lg'} style={{ marginTop: '13px' }} />
      <BoldText style={{ color: 'var(--main-white)', marginTop: '10px' }} className="fa-layers">
        {number}
      </BoldText>
    </span>
  )
}

export default AvancementProjet

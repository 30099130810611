import React, { FC, ReactNode } from 'react'
import { Button, PopoverBody, UncontrolledPopover } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { GreyText } from './FACalendar'
import { useAppContext } from '../../context'
import { ProjectDocument } from '../../model/document'

interface DocumentPopoverProps {
  popoverOpen: boolean
  toggle: () => any
  target: string
  documents: ProjectDocument[]
}

const LeftElement = styled.div`
  margin: 5px;
  align-self: flex-start;
  min-width: 20px;
`

const StyledUncontrolledPopover = styled(UncontrolledPopover)`
  .popover {
    max-width: 20% !important;
  }

  * .arrow {
    opacity: 0;
  }
`

const Title = styled.span`
  font-size: 18px;
  line-height: 26px;
  color: var(--main-black-1);
  font-weight: 600;
`

const Line: FC<{ leftElement?: ReactNode }> = ({ leftElement, children }) => {
  return (
    <div style={{ display: 'flex', lineHeight: '26px' }}>
      <LeftElement>{leftElement}</LeftElement>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export const DocumentPopover: FC<DocumentPopoverProps> = ({
  popoverOpen,
  toggle,
  target,
  documents,
}) => {
  const { projectsStore } = useAppContext()

  return (
    <>
      <StyledUncontrolledPopover
        placement="auto"
        isOpen={popoverOpen}
        target={target}
        toggle={toggle}
        trigger="legacy"
      >
        <PopoverBody>
          <Line>
            <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <Button color={'link'} onClick={toggle}>
                <FontAwesomeIcon icon={'times'} color={'var(--main-gray)'} size={'lg'} />
              </Button>
            </div>
          </Line>
          <Line
            leftElement={
              <FontAwesomeIcon
                icon={'file-alt'}
                style={{ color: projectsStore.getProjectColor(documents[0].projectId) }}
                size={'lg'}
              />
            }
          >
            <Title>Document(s) à fournir :</Title>
          </Line>
          {documents.map(doc => {
            return (
              <Line
                leftElement={
                  <FontAwesomeIcon icon={'thumbtack'} color={'var(--main-gray-2)'} size={'lg'} />
                }
              >
                <GreyText>{doc.description}</GreyText>
              </Line>
            )
          })}
        </PopoverBody>
      </StyledUncontrolledPopover>
    </>
  )
}

import React, { FC, useContext, useState } from 'react'
import { PrediagQuestion } from './PrediagModel'
import { FATextBold, InvalidText } from '../shared/CustomStyledComponents/Text'
import { FAInput, FANumberInput, FASelect } from '../shared/CustomStyledComponents/Inputs'
import { Label } from 'reactstrap'
import styled from 'styled-components'
import { PrediagContext } from './PrediagComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAlert } from 'react-alert'
import { ProjectDocument } from '../../model/document'
import { useAppContext } from '../../context'
import { UploadModal } from '../shared/document/UploadModal'
import { PrimaryButton } from '../shared/CustomStyledComponents/Buttons'
import { PrediagQuestionArray } from './PrediagQuestionArray'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'

export const DATE_FORMAT = 'YYYY-MM-DD'

export interface QuestionProps {
  question: PrediagQuestion
  valid: boolean
}

const BooleanRadioButtonGroup = styled.div`
  &.invalid {
    border-radius: 5px;
    border: var(--main-red) 2px solid;
  }
`

const PrediagQuestionBoolean: FC<QuestionProps> = ({ question, valid }) => {
  const { answer } = useContext(PrediagContext)
  const [value, setValue] = useState(question.answer || '')

  const onChange = (changedValue: string) => {
    setValue(changedValue)
    answer({ questionId: question.id, value: changedValue })
  }

  return (
    <>
      <div className={'d-flex flex-row my-3 w-75'}>
        <BooleanRadioButtonGroup className={`d-flex flex-column ${valid ? '' : 'invalid'} p-1`}>
          <Label>
            <FAInput type="radio" checked={value === 'oui'} onChange={() => onChange('oui')} /> Oui
          </Label>
          <Label>
            <FAInput type="radio" checked={value === 'non'} onChange={() => onChange('non')} /> Non
          </Label>
        </BooleanRadioButtonGroup>
        {!valid && (
          <div className={'align-self-center px-2'}>
            <FontAwesomeIcon icon={'exclamation-circle'} size={'lg'} color={'var(--main-red)'} />
          </div>
        )}
      </div>
      {!valid && <InvalidText className={'pt-2'}>Vous devez sélectionner une réponse</InvalidText>}
    </>
  )
}

const PrediagQuestionSelect: FC<QuestionProps> = ({ question, valid }) => {
  const { answer } = useContext(PrediagContext)
  const [value, setValue] = useState(question.answer || '')
  return (
    <>
      <div className={'d-flex flex-row w-75'}>
        <FASelect
          className={`form-control ${valid ? '' : 'invalid'}`}
          onChange={e => {
            setValue(e.target.value)
            answer({ questionId: question.id, value: e.target.value })
          }}
          value={value}
        >
          {question.options &&
            question.options.map(option => (
              <option key={option.id} value={option.id}>
                {option.text}
              </option>
            ))}
        </FASelect>
        {!valid && (
          <div className={'align-self-center px-2'}>
            <FontAwesomeIcon icon={'exclamation-circle'} size={'lg'} color={'var(--main-red)'} />
          </div>
        )}
      </div>
      {!valid && <InvalidText className={'pt-2'}>Vous devez sélectionner une réponse</InvalidText>}
    </>
  )
}

export const PrediagQuestionInput: FC<QuestionProps & {
  inputType: 'number' | 'text' | 'textarea'
}> = ({ question, valid, inputType }) => {
  const { answers, answer } = useContext(PrediagContext)
  const [value, setValue] = useState(question.answer || '')

  const answerQuestion = (answerValue: string) => {
    answer({ questionId: question.id, value: answerValue })
  }

  if (
    !question.required &&
    !question.answer &&
    !answers.find(ans => ans.questionId === question.id)
  ) {
    answerQuestion('')
  }

  return (
    <>
      <div className={'d-flex flex-row w-75'}>
        {inputType === 'number' && (
          <FANumberInput
            className={`${valid ? '' : 'invalid'}`}
            inputValue={value}
            onChangeValue={(e: string) => {
              setValue(e)
              answerQuestion(e)
            }}
            suffix={question.suffix}
          />
        )}
        {inputType !== 'number' && (
          <FAInput
            className={`${inputType !== 'textarea' ? 'w-25' : ''} ${valid ? '' : 'invalid'}`}
            value={value}
            type={inputType}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValue(e.target.value)
              answerQuestion(e.target.value)
            }}
          />
        )}
        {!valid && (
          <div className={'align-self-center px-2'}>
            <FontAwesomeIcon icon={'exclamation-circle'} size={'lg'} color={'var(--main-red)'} />
          </div>
        )}
      </div>
      {!valid && <InvalidText className={'pt-2'}>Cette question est obligatoire</InvalidText>}
    </>
  )
}

export const PrediagQuestionDatetime: FC<QuestionProps> = ({ question, valid }) => {
  const { answers, answer } = useContext(PrediagContext)
  const [value, setValue] = useState(
    question.answer ? dayjs(question.answer, DATE_FORMAT) : dayjs()
  )

  const date = value.toDate()

  const answerQuestion = (answerValue: string) => {
    answer({ questionId: question.id, value: answerValue })
  }

  if (
    !question.required &&
    !question.answer &&
    !answers.find(ans => ans.questionId === question.id)
  ) {
    answerQuestion('')
  }

  return (
    <>
      <div className={'d-flex flex-row w-75'}>
        <DatePicker
          selected={date}
          onChange={(d: Date | null) => {
            const newValue = dayjs(d || undefined)
            setValue(newValue)
            answerQuestion(newValue.format(DATE_FORMAT))
          }}
        />
        {!valid && (
          <div className={'align-self-center px-2'}>
            <FontAwesomeIcon icon={'exclamation-circle'} size={'lg'} color={'var(--main-red)'} />
          </div>
        )}
      </div>
      {!valid && <InvalidText className={'pt-2'}>Cette question est obligatoire</InvalidText>}
    </>
  )
}

const PrediagQuestionDocument: FC<{
  question: PrediagQuestion
  projectId: string
  prediagId: string
}> = ({ question, projectId, prediagId }) => {
  const [document, setDocument] = useState(question.document || undefined)
  const [modalOpened, toggleModal] = useState(false)
  const [errorOnUpload, setError] = useState(false)
  const [sending, setSending] = useState(false)
  const alert = useAlert()
  const { apiProxy, projectsStore, prediagStore } = useAppContext()

  const sendFiles = async (files: File[], docType: string, description?: string) => {
    setSending(true)
    if (errorOnUpload) {
      setError(false)
    }
    const fd = new FormData()
    files.forEach(f => fd.append('file', f, f.name))
    fd.append('documentType', docType)
    fd.append('documentDescription', description!)
    let doc
    try {
      if (document !== undefined && document !== null) {
        doc = await apiProxy.formData<ProjectDocument>(
          `/project/${projectId}/documents/${document.id}`,
          fd
        )
        projectsStore.updateDocument(doc)
      } else {
        doc = await apiProxy.formData<ProjectDocument>(`/project/${projectId}/documents/`, fd)
        projectsStore.addDocument(projectId, doc)
      }
    } catch (e) {
      setError(true)
    } finally {
      setSending(false)
      if (errorOnUpload || doc === undefined) {
        alert.error("Une erreur est survenue lors de l'envoi de vos documents")
      } else {
        alert.success('Document(s) envoyé(s)', { timeout: 5000 })
        setDocument(doc)
        await prediagStore.answerPrediag({
          prediagId,
          projectId,
          ended: false,
          answers: [{ questionId: question.id, value: doc.id }],
        })
      }
      toggleModal(false)
    }
  }

  return (
    <div className={'d-flex flex-row w-75'}>
      <div>
        <PrimaryButton color={'--main-violet'} onClick={() => toggleModal(!modalOpened)}>
          Ajouter une pièce jointe
        </PrimaryButton>
      </div>
      <div style={{ marginLeft: '30px', marginTop: '10px' }}>
        {document?.files.map(file => (
          <div key={file.idGED} style={{ marginBottom: '8px' }}>
            <a
              style={{ color: 'var(--main-gray-1)', fontWeight: 'normal' }}
              className="File"
              key={file.idGED}
              title={file.name}
              href={file.downloadUrl}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              <span>{file.name}</span>
            </a>
          </div>
        ))}
      </div>
      <UploadModal
        activatePrediagChoice={true}
        open={modalOpened}
        toggle={() => toggleModal(!modalOpened)}
        sendFiles={sendFiles}
        sending={sending}
        origin={'prediag'}
        doc={document}
        docType={
          question.docType !== null && question.docType !== undefined && question.docType !== ''
            ? question.docType
            : 'PREDIAG'
        }
      />
    </div>
  )
}

export const PrediagQuestionComponent: FC<{
  prediagQuestion: PrediagQuestion
  prediagQuestionIndex: number
  supposedToBeValid: boolean
  description: string
  projectId: string
  prediagId: string
}> = ({
  prediagQuestion,
  prediagQuestionIndex,
  supposedToBeValid,
  projectId,
  prediagId,
  description,
}) => {
  const { answers } = useContext(PrediagContext)

  const hasAnswer: () => boolean = () => {
    const pendingAnswer = answers.find(ans => ans.questionId === prediagQuestion.id)
    if (pendingAnswer !== undefined) {
      return pendingAnswer.value !== ''
    }
    return !!prediagQuestion.answer
  }

  const required =
    prediagQuestion.required || prediagQuestion.conditions.filter(c => c.required).length > 0

  const isValid = () => {
    if (prediagQuestion.type === 'ARRAY') {
      return supposedToBeValid
    }
    return supposedToBeValid || !prediagQuestion.required || hasAnswer()
  }

  const valid = isValid()

  return (
    <PrediagQuestionComponentStyled>
      <div className={'d-flex flex-column'}>
        {prediagQuestion.title && (
          <FATextBold>
            {prediagQuestionIndex + 1}. {prediagQuestion.title} {required && '*'}
          </FATextBold>
        )}
        {prediagQuestion.description && (
          <i dangerouslySetInnerHTML={{ __html: prediagQuestion.description }} />
        )}
        {prediagQuestion.type === 'SELECT' && (
          <PrediagQuestionSelect
            question={prediagQuestion}
            valid={valid}
            key={`question_${prediagQuestion.id}`}
          />
        )}
        {prediagQuestion.type === 'BOOLEAN' && (
          <PrediagQuestionBoolean
            question={prediagQuestion}
            valid={valid}
            key={`question_${prediagQuestion.id}`}
          />
        )}
        {prediagQuestion.type === 'TEXTAREA' && (
          <PrediagQuestionInput
            question={prediagQuestion}
            valid={valid}
            inputType={'textarea'}
            key={`question_${prediagQuestion.id}`}
          />
        )}
        {prediagQuestion.type === 'FLOAT' && (
          <PrediagQuestionInput
            question={prediagQuestion}
            valid={valid}
            inputType={'number'}
            key={`question_${prediagQuestion.id}`}
          />
        )}
        {prediagQuestion.type === 'DOCUMENT' && (
          <PrediagQuestionDocument
            question={prediagQuestion}
            projectId={projectId}
            prediagId={prediagId}
            key={`question_${prediagQuestion.id}`}
          />
        )}
        {prediagQuestion.type === 'ARRAY' && (
          <PrediagQuestionArray
            question={prediagQuestion}
            valid={valid}
            description={description}
          />
        )}
        {prediagQuestion.type === 'DATETIME' && (
          <PrediagQuestionDatetime
            question={prediagQuestion}
            valid={valid}
            key={`question_${prediagQuestion.id}`}
          />
        )}
      </div>
    </PrediagQuestionComponentStyled>
  )
}

const PrediagQuestionComponentStyled = styled.div`
  margin-bottom: 40px !important;
`

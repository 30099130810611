import { AnsweredQuestion, FAQuizz, Quizz } from '../model/quizz'
import { ApiProxy } from './APIProxy'
import { AuthStore } from '../store/auth'
import { ProjectsStore } from '../components/projects/store/projects.store'

export class QuizzService {
  constructor(
    private apiProxy: ApiProxy,
    private authStore: AuthStore,
    private projectsStore: ProjectsStore
  ) {}

  getQuizzes = async () => {
    const entrepreneurId = this.authStore.id
    return await this.apiProxy.get<Quizz[]>(`/quizz/${entrepreneurId}/all`)
  }

  answer = async (quizzId: FAQuizz, answeredQuestion: AnsweredQuestion) => {
    const entrepreneurId = this.authStore.id
    return await this.apiProxy.post<Quizz>('/quizz/answer', {
      quizzId,
      entrepreneurId,
      answeredQuestion,
    })
  }

  result = async (quizzId: FAQuizz, userMessage?: string) => {
    const projectId = this.projectsStore.projects[0].id
    const entrepreneurId = this.authStore.id
    return await this.apiProxy.post('/quizz/result', {
      entrepreneurId,
      quizzId,
      projectId,
      userMessage,
    })
  }
}

export type Mime =
  | 'doc'
  | 'spreadsheet'
  | 'presentation'
  | 'pdf'
  | 'archive'
  | 'image'
  | 'audio'
  | 'video'
  | 'other'

type MimePredicate = [Mime, (s: string) => boolean]

const DOCUMENT_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text',
]
const SPREADSHEETS_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet',
]
const PRESENTATIONS_TYPES = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.presentation',
]
const ARCHIVE_TYPES = [
  'application/x-bzip',
  'application/x-bzip2',
  'application/x-tar',
  'application/zip',
  'application/x-7z-compressed',
]

const mimeTypePredicates: MimePredicate[] = [
  ['pdf', str => str === 'application/pdf'],
  ['doc', str => DOCUMENT_TYPES.includes(str)],
  ['spreadsheet', str => SPREADSHEETS_TYPES.includes(str)],
  ['presentation', str => PRESENTATIONS_TYPES.includes(str)],
  ['archive', str => ARCHIVE_TYPES.includes(str)],
  ['image', str => str.indexOf('image/') === 0],
  ['audio', str => str.indexOf('audio/') === 0],
  ['video', str => str.indexOf('video/') === 0],
]

export class MimeTypesService {
  getKnownTypeForMimeType(contentType: string): Mime {
    for (const entry of mimeTypePredicates) {
      if (entry[1](contentType)) {
        return entry[0]
      }
    }
    return 'other'
  }
}

export const mimeTypeService = new MimeTypesService()

import { action, computed, observable } from 'mobx'
import { NotificationId, UserNotification } from '../../model/notifications'
import { AuthStore } from '../../store/auth'
import { StompService } from '../../service/StompService'
import { HistoriesStore } from '../projects/store/histories.store'
import { ProjectEvent, ProjectId } from '../../model/project'
import { groupBy } from '../../utils/groupby'
import { mapValue } from '../../utils/map'
import { ProjectsStore } from '../projects/store/projects.store'
import { PrediagStore } from '../projects/store/prediag.store'

interface ProjectUpdate {
  project: boolean
  timeline: boolean
  documents: boolean
  meetings: boolean
  prediags: boolean
}

export class NotificationStore {
  constructor(
    private authStore: AuthStore,
    private stompService: StompService,
    private historiesStore: HistoriesStore,
    private projectsStore: ProjectsStore,
    private prediagStore: PrediagStore
  ) {
    stompService.watchNotifications(this.addNotifications.bind(this))
  }

  @observable notifications: UserNotification[] = []
  private firstFetch = true

  @action
  addNotifications = (notifs: UserNotification[]) => {
    if (!this.firstFetch) {
      const updates = this.computeUpdates(notifs)
      updates.forEach(({ timeline, documents, meetings, project, prediags }, key) => {
        if (timeline) this.historiesStore.refresh(key)
        if (project) this.projectsStore.refreshProject(key)
        if (meetings) this.projectsStore.refreshMeetings(key)
        if (documents) this.projectsStore.refreshDocuments(key)
        if (prediags) this.prediagStore.refreshPrediagsForProject(key)
      })
    }

    this.notifications = [
      ...this.notifications.filter(({ id }) => !notifs.find(it => it.id === id)),
      ...notifs,
    ].sort((a, b) => a.created.localeCompare(b.created))
    this.firstFetch = false
  }

  @computed get unreadCount(): number {
    return this.unreadNotifications.length
  }

  @computed get unreadNotifications(): UserNotification[] {
    return this.notifications.filter(n => !n.read)
  }

  @action
  markRead(id: NotificationId) {
    this.notifications
      .filter(n => n.id === id)
      .forEach(n => {
        this.stompService.client.publish({
          destination: `/app/notifications/${n.id}`,
        })
        n.read = true
      })
  }

  @action
  markAllRead() {
    this.notifications.forEach(n => {
      this.stompService.client.publish({
        destination: `/app/notifications/${n.id}`,
      })
      n.read = true
    })
  }

  private computeUpdates = (ns: UserNotification[]): Map<ProjectId, ProjectUpdate> => {
    const partials: [ProjectId, Partial<ProjectUpdate>][] = ns
      .map(n => n.projectEvent)
      .map(
        ev => [ev.projectId, this.computePartialUpdate(ev)] as [ProjectId, Partial<ProjectUpdate>]
      )

    const merged: Map<ProjectId, Partial<ProjectUpdate>[]> = mapValue(
      groupBy(partials, it => it[0]),
      ps => ps.map(it => it[1])
    )

    const combinePartials: (ps: Partial<ProjectUpdate>[]) => ProjectUpdate = ps =>
      ps.reduce(
        (acc: ProjectUpdate, val: Partial<ProjectUpdate>) => ({
          project: acc.project || !!val.project,
          timeline: acc.timeline || !!val.timeline,
          documents: acc.documents || !!val.documents,
          meetings: acc.meetings || !!val.meetings,
          prediags: acc.prediags || !!val.prediags,
        }),
        {
          project: false,
          timeline: true,
          documents: false,
          meetings: false,
          prediags: false,
        }
      )
    return mapValue(merged, it => combinePartials(it))
  }

  private computePartialUpdate = (ev: ProjectEvent): Partial<ProjectUpdate> => {
    switch (ev.eventType) {
      case 'DOCUMENT_REQUIRED':
      case 'DOCUMENT_VALIDATION':
        return { timeline: true, documents: true }
      case 'MEETING_NEW':
      case 'MEETING_UPDATE':
      case 'MEETING_CANCELLED':
        return { timeline: true, meetings: true }
      case 'DELIVERABLE_UPDATE':
      case 'COMMITTEE_STATUS':
        return { timeline: true, project: true }
      case 'NOTIFY_PREDIAG_NEEDED':
      case 'NOTIFY_PREDIAG_UPDATE':
      case 'NOTIFY_PREDIAG_VALIDATION':
        return { prediags: true }
      default:
        return { timeline: false }
    }
  }
}

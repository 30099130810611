import { default as React, FC, HTMLAttributes, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { omit } from '../../../utils/omit'

interface MaterialLikeInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean
  replaceBorder?: React.ReactNode
}

export const MaterialLikeInput: FC<MaterialLikeInputProps> = props => {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    props.autoFocus && inputRef.current && inputRef.current.focus()
  }, [inputRef, props.autoFocus])

  return (
    <label
      htmlFor={props.id}
      className={classNames('inp', {
        'has-error': !!props.hasError,
        'no-border': !!props.replaceBorder,
      })}
    >
      <input
        {...omit(
          props,
          'tabIndex',
          'placeholder',
          'children',
          'replaceBorder',
          'hasError',
          'autoFocus'
        )}
        tabIndex={2}
        placeholder="&nbsp;"
        ref={inputRef}
      />
      <span className="label">{props.placeholder}</span>
      {props.replaceBorder || <></>}
    </label>
  )
}

export const MaterialLikeErrors: FC<HTMLAttributes<HTMLDivElement>> = props => (
  <div {...omit(props, 'className', 'children')} className={`${props.className || ''} inp-errors`}>
    {props.children}
  </div>
)

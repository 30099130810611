import React, { Component } from 'react'
import './Footer.scss'
import logoBpi from '../assets/images/logo_bpifrance.png'
import logoBanqueTerritoire from '../assets/images/BANQUE_TERRITOIRES.png'
import logoFSE from '../assets/images/FSE2015_HD.png'
import logoFA from '../assets/images/logo-fa-footer.png'
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

@observer
class Footer extends Component {
  render() {
    return (
      <div className={'Footer row'}>
        <div className={'d-flex align-items-center col-12 col-md-6 col-lg-3'}>
          <div className={'Footer-logo'}>
            <a href="https://www.franceactive.org" target="_blank" rel="noopener noreferrer">
              <img src={logoFA} alt="logo France Active" />
            </a>
          </div>
          <div className={'Footer-social-networks ml-4'}>
            <a
              href={'https://fr-fr.facebook.com/France-Active-1718435931731729/'}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={['fab', 'facebook-f']} color="white" />
            </a>
            <a href={'https://twitter.com/franceactive'} rel="noopener noreferrer" target="_blank">
              <FontAwesomeIcon icon={['fab', 'twitter']} color="white" />
            </a>
            <a
              href={'https://www.youtube.com/channel/UCoUtTyPQPW37JJ4HDfmLGEA'}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={['fab', 'youtube']} color="white" />
            </a>
            <a
              href={'https://fr.linkedin.com/company/france-active'}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={['fab', 'linkedin-in']} color="white" />
            </a>
          </div>
        </div>
        <div className={'Footer-links col-12 col-lg-5'}>
          <div>
            <Link to={'/legal'}>Mentions légales</Link>·
            <Link to={'/cgu'}>Conditions générales d'utilisation</Link>·
            <a
              href={'https://www.franceactive.org/donnees-personnelles/'}
              rel="noopener noreferrer"
              target="_blank"
            >
              Gestion des données personnelles
            </a>
          </div>
        </div>
        <div className="Footer-partners col-12 col-md-6 col-lg-4">
          <div style={{ userSelect: 'none' }}>Partenaires&nbsp;:</div>
          <a href={'https://www.caissedesdepots.fr/'} rel="noopener noreferrer" target="_blank">
            <img height="36px" src={logoBanqueTerritoire} alt="logo Caisse des dépots" />
          </a>
          <a
            href={'http://www.europe-en-france.gouv.fr/'}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={logoFSE} alt="logo feder" />
          </a>
          <a href={'https://www.bpifrance.fr/'} rel="noopener noreferrer" target="_blank">
            <img src={logoBpi} alt="logo BPI France" />
          </a>
        </div>
      </div>
    )
  }
}

export default Footer

import React, { FC, memo } from 'react'
import { DisplayableResource } from '../../../model/resource'
import { ResourceLink } from './linkCard/ResourceLink'
import { ResourceDocument } from './documentCard/ResourceDocument'

export const ResourceC: FC<{ resource: DisplayableResource }> = memo(
  ({ resource }) => {
    switch (resource.type) {
      case 'LINK':
        return <ResourceLink resource={resource} />
      case 'DOCUMENT':
        return <ResourceDocument resource={resource} />
      case 'CONTACT':
        return <></>
    }
  },
  (prevProps, nextProps) => prevProps.resource.id === nextProps.resource.id
)

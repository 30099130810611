import { ApiProxy } from './APIProxy'
import { Advisor, AdvisorId } from '../model/project'
import { action, observable, ObservableMap } from 'mobx'

export class AdvisorService {
  @observable advisors: Map<AdvisorId, Advisor> = new ObservableMap()
  advisorIds: Array<String> = []

  constructor(private apiProxy: ApiProxy) {}

  getAdvisor(advisorId: AdvisorId): Advisor | undefined {
    if (!this.advisorIds.includes(advisorId)) {
      this.refresh(advisorId)
    }
    return this.advisors.get(advisorId)
  }

  @action
  async refresh(advisorId: AdvisorId) {
    if (!this.advisorIds.includes(advisorId)) {
      this.advisorIds.push(advisorId)
    }
    this.advisors.set(advisorId, await this.apiProxy.get<Advisor>(`/advisor/${advisorId}`))
  }
}

import { action, computed, observable, reaction } from 'mobx'
import { Project, ProjectId } from '../../../model/project'
import {
  ChannelMember,
  LightUser,
  Message,
  MessengerPayloadType,
  NewMessage,
  PageDetails,
  PayloadType,
  UserType,
} from '../../../model/message'
import dayjs, { Dayjs } from 'dayjs'
import { uniqBy } from '../../../utils/uniqBy'

export interface MessageGroup {
  messages: Message[]
  sender: ChannelMember
  unread: boolean
  oldestUnread: boolean
  date?: Dayjs
}

export class ProjectMessengerData {
  @observable project: Project
  @observable channelMembers: ChannelMember[] = []
  @observable messages: Message[] = []
  @observable sync: boolean = false
  @observable noMoreToLoad: boolean = false
  @observable requestPending: boolean = false
  @observable lastAction?: PayloadType
  @observable firstMessage: boolean = true

  @computed get noMessagesAtAll(): boolean {
    return this.messages.length === 0 && !this.firstMessage
  }

  @computed get newMessagesCount(): number {
    const currentUser = this.channelMembers.find(m => m.account.id === this.currentUserId)
    if (currentUser == null) {
      return 0
    }
    const lastMessageSeenAt = dayjs(currentUser.lastMessageSeenAt)
    return this.messages
      .filter(m => m.author.userId !== currentUser.account.id)
      .filter(m => dayjs(m.createdDate).isAfter(lastMessageSeenAt)).length
  }

  @computed get lastMessageSeenAt(): dayjs.Dayjs {
    const currentUser = this.channelMembers.find(m => m.account.id === this.currentUserId)
    if (currentUser == null) {
      return dayjs()
    }
    return dayjs(currentUser.lastMessageSeenAt)
  }

  @computed get otherMembersInChannel(): ChannelMember[] {
    return this.channelMembers
      .filter(m => m.account.id !== this.currentUserId)
      .filter(m => m.account.firstName !== 'Anonyme')
  }

  @computed get id(): ProjectId {
    return this.project.id
  }

  @action enableSync = () => {
    this.sync = true
  }
  @action disableSync = () => {
    this.sync = false
  }

  constructor(
    project: Project,
    private currentUserId: string,
    public readonly messengerEnabled: boolean,
    private readonly synchronize: () => void,
    private readonly fetchMoreMessages: (options: PageDetails) => void,
    readonly sendMessage: (options: NewMessage) => void
  ) {
    this.project = project
    setInterval(() => this.shouldSendSeenEvent() && this.synchronize(), 20000)
  }

  private shouldSendSeenEvent = () =>
    this.sync &&
    this.messages.length &&
    dayjs(this.messages[this.messages.length - 1].createdDate).isAfter(this.lastMessageSeenAt)

  @computed get groupedMessages(): MessageGroup[] {
    const newGroup = (message: Message) => ({
      messages: [message],
      sender: this.channelMember(message.author),
      unread: this.lastMessageSeenAt.isBefore(dayjs(message.createdDate)),
      oldestUnread: false,
    })

    const last = (groups: MessageGroup[]) => {
      const { messages } = groups[groups.length - 1]
      return messages[0]
    }

    return this.messages
      .reduce((groups: MessageGroup[], message: Message) => {
        if (!groups.length) {
          return [newGroup(message)]
        }
        if (shouldGroup(last(groups), message, this.lastMessageSeenAt)) {
          groups[groups.length - 1].messages.push(message)
          return groups
        }
        return [...groups, newGroup(message)]
      }, [])
      .map((value, index, array) =>
        value === array.find(g => g.unread && g.sender.account.id !== this.currentUserId)
          ? { ...value, oldestUnread: true }
          : value
      )
      .map((value, index, array) => {
        const date = dayjs(value.messages[0].createdDate).startOf('day')
        const daysDiff =
          index !== 0 && dayjs(array[index - 1].messages[0].createdDate).day() !== date.day()
        const displayDate = index === 0 || daysDiff
        return !displayDate ? value : { ...value, date }
      })
  }

  channelMember(usr: LightUser): ChannelMember {
    if (usr.type === 'SYSTEM') {
      return this.emptyUserForType('SYSTEM')
    }
    return (
      this.channelMembers.find(it => it.account.id === usr.userId) ||
      this.emptyUserForType(usr.type)
    )
  }

  private emptyUserForType(type: UserType): ChannelMember {
    return {
      type,
      account: {
        id: '',
        lastName: '',
        firstName: '',
        email: '',
      },
      lastMessageSeenAt: dayjs().toISOString(),
    }
  }

  @action
  processWsMessage(payload: MessengerPayloadType) {
    switch (payload.payloadType) {
      case 'Message':
        this.firstMessage = false
        this.messages = uniqBy([...this.messages, { ...payload }].sort(compareFn), it => it.id)
        this.lastAction = payload.payloadType
        break
      case 'MessageList':
        this.firstMessage = false
        this.messages = uniqBy([...payload.messages, ...this.messages].sort(compareFn), it => it.id)
        this.noMoreToLoad = payload.messages.length < 20
        this.requestPending = false
        this.lastAction = payload.payloadType
        break
      case 'ChannelInfo':
        this.channelMembers = payload.channelMembers
        this.lastAction = payload.payloadType
        break
      case 'SeenUpdate':
        this.channelMembers
          .filter(it => it.type === payload.userType && it.account.id === payload.userId)
          .forEach(member => (member.lastMessageSeenAt = payload.date))
        break
      case 'SomeoneJoined':
        const newUser = payload.user
        if (!this.channelMembers.find(it => it.account.id === newUser.account.id)) {
          return (this.channelMembers = [
            ...this.channelMembers,
            {
              account: newUser.account,
              type: newUser.type,
              lastMessageSeenAt: new Date().toISOString(),
            },
          ])
        }
        break
      case 'UserConnected':
        this.channelMembers = this.channelMembers.map(it =>
          it.account.id !== payload.id
            ? it
            : {
                ...it,
                online: true,
              }
        )
        break
      case 'UserDisconnected':
        this.channelMembers = this.channelMembers.map(it =>
          it.account.id !== payload.id
            ? it
            : {
                ...it,
                online: false,
              }
        )
        break
      default:
        return
    }
  }

  @action
  loadMore(): Promise<any> {
    this.fetchMoreMessages({
      offset: this.messages.length,
      pageSize: 20,
    })
    this.requestPending = true
    return new Promise(resolve => {
      const iReactionDisposer = reaction(
        () => !this.requestPending,
        () => {
          resolve()
          iReactionDisposer()
        },
        { fireImmediately: true }
      )
    })
  }
}

const sameSender = (one: Message, other: Message): boolean =>
  one.author.type === other.author.type && one.author.userId === other.author.userId

const closeInTime = (one: Message, other: Message): boolean =>
  Math.abs(dayjs(one.createdDate).diff(dayjs(other.createdDate), 'minute')) < 5

const bothReadOrBothUnread = (cutoff: dayjs.Dayjs, one: Message, other: Message): boolean => {
  const d1 = dayjs(one.createdDate)
  const d2 = dayjs(other.createdDate)
  return (cutoff.isAfter(d1) && cutoff.isAfter(d2)) || (cutoff.isBefore(d1) && cutoff.isBefore(d2))
}

const shouldGroup = (one: Message, other: Message, cutoff: dayjs.Dayjs): boolean =>
  sameSender(one, other) && closeInTime(one, other) && bothReadOrBothUnread(cutoff, one, other)

const compareFn = (a: { createdDate: string }, b: { createdDate: string }) =>
  dayjs(a.createdDate).diff(dayjs(b.createdDate), 'second')

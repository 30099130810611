import React, { Component } from 'react'
import { Card } from 'reactstrap'
import './Notifications.scss'
import ReactSwitch from 'react-switch'
import { inject, observer } from 'mobx-react'
import { AccountService } from '../../../service/account.service'
import { H4 } from '../../shared/H/H'

@inject('accountService')
@observer
export class Notifications extends Component<{ accountService?: AccountService }> {
  private updateNotifParam(paramToUpdate: string, checked: boolean) {
    this.props.accountService!.updatePersonalization({ checked, param: paramToUpdate })
  }

  private uncheckAll(checked: boolean) {
    const params = [
      'MAIL_PROJECT_STATE_CHANGE',
      'MAIL_MEMENTO_PUBLICATION',
      'MAIL_MEETING',
      'MAIL_DOCUMENT',
      'MAIL_MESSAGE',
    ]
    this.props.accountService!.updateAllPersonalization(params, !checked)
  }

  renderNotifSwitch = (title: string, checked: boolean, func: Function) => {
    return (
      <div className={'Notifications-content-switch'}>
        <div>{title}</div>
        <ReactSwitch
          checked={checked}
          onChange={e => func(e)}
          onColor={'#00b195'}
          offColor={'#e3e3e3'}
          uncheckedIcon={false}
          checkedIcon={false}
          height={18}
          width={34}
        />
      </div>
    )
  }

  renderParameters = () => {
    const notifsParam = this.props.accountService!.accountPersonalization
    if (notifsParam) {
      const allUnchecked =
        !notifsParam.MAIL_PROJECT_STATE_CHANGE &&
        !notifsParam.MAIL_MEMENTO_PUBLICATION &&
        !notifsParam.MAIL_MEETING &&
        !notifsParam.MAIL_DOCUMENT &&
        !notifsParam.MAIL_MESSAGE
      return (
        <>
          <b>Projet</b>
          {this.renderNotifSwitch(
            "Changement d'état de la timeline",
            notifsParam.MAIL_PROJECT_STATE_CHANGE,
            (checked: boolean) => this.updateNotifParam('MAIL_PROJECT_STATE_CHANGE', checked)
          )}
          {this.renderNotifSwitch(
            'Mémento',
            notifsParam.MAIL_MEMENTO_PUBLICATION,
            (checked: boolean) => this.updateNotifParam('MAIL_MEMENTO_PUBLICATION', checked)
          )}
          {this.renderNotifSwitch(
            'Planification rendez-vous',
            notifsParam.MAIL_MEETING,
            (checked: boolean) => this.updateNotifParam('MAIL_MEETING', checked)
          )}
          {this.renderNotifSwitch(
            'Documents à fournir',
            notifsParam.MAIL_DOCUMENT,
            (checked: boolean) => this.updateNotifParam('MAIL_DOCUMENT', checked)
          )}
          <div className={'mt-3'}>
            <b>Messagerie</b>
          </div>
          {this.renderNotifSwitch('Nouveau message', notifsParam.MAIL_MESSAGE, (checked: boolean) =>
            this.updateNotifParam('MAIL_MESSAGE', checked)
          )}
          <div className="divider" />
          {this.renderNotifSwitch('Tout désactiver', allUnchecked, (checked: boolean) =>
            this.uncheckAll(checked)
          )}
        </>
      )
    }
    return ''
  }

  render() {
    return (
      <div className="row Notifications">
        <div className="col-12 col-md-8 col-xl-6">
          <Card body>
            <H4 arrowColor={'green'} text={'Paramétrer vos notifications mail'} />
            <div className={'Notifications-content'}>{this.renderParameters()}</div>
          </Card>
        </div>
      </div>
    )
  }
}

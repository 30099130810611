import React, { Component } from 'react'
import './DocumentUploader.scss'

import { ProjectDocument } from '../../../model/document'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ApiProxy } from '../../../service/APIProxy'
import { DocumentUpload } from './DocumentUpload'
import { UploadModal } from './UploadModal'

interface DocumentUploaderProps {
  doc: ProjectDocument
  documentUpdated: (upload: DocumentUpload) => void
  apiProxy?: ApiProxy
}

@inject('apiProxy')
@observer
export class DocumentUploader extends Component<DocumentUploaderProps> {
  @observable upload: DocumentUpload = new DocumentUpload(this.props.doc, this.props.apiProxy!)
  @observable dropzoneOpened: boolean = false

  sendFiles = (files: File[]) => {
    this.toggleDropzone()
    this.props.documentUpdated(this.upload)
    this.upload.send(files)
  }

  toggleDropzone = () => {
    this.dropzoneOpened = !this.dropzoneOpened
  }

  render() {
    const sending = this.upload.sendingState === 'sending'

    return (
      <div className={sending ? 'breakDocumentUploader DocumentUploader' : 'DocumentUploader'}>
        {!sending ? (
          <div className="dropzone dashb" onClick={() => this.toggleDropzone()}>
            Ajouter un fichier
            <FontAwesomeIcon icon="plus-circle" size="2x" />
          </div>
        ) : (
          <div className="uploading">
            <div
              className="uploading-progress"
              style={{ width: `${this.upload.progress.toFixed(0)}%` }}
            />
            <span className="uploading-text"> {this.upload.progress.toFixed(0)} %</span>
          </div>
        )}
        <UploadModal
          open={this.dropzoneOpened}
          toggle={this.toggleDropzone}
          doc={this.props.doc}
          sendFiles={this.sendFiles}
        />
      </div>
    )
  }
}

import React, { Component } from 'react'
import { Category, DisplayableResource, Theme } from '../../model/resource'
import InfiniteScroll from 'react-infinite-scroller'
import { action, IObservableArray, observable, reaction } from 'mobx'
import './InfiniteResources.scss'
import { disposeOnUnmount, inject, observer } from 'mobx-react'
import { ResourceService } from '../../service/resourceService'
import { Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ResourceC } from './cards/resource'
import { AnalyticsService } from '../../service/analytics.service'

@inject('resourceService', 'analyticsService')
@observer
export class InfiniteRessources extends Component<{
  category: Category
  theme: Theme
  resourceService?: ResourceService
  analyticsService?: AnalyticsService
}> {
  @observable resources: IObservableArray<DisplayableResource> = observable.array([])
  @observable hasMore: boolean = true

  @disposeOnUnmount
  private reloadOnThemeChange = reaction(
    () => this.props.theme.id,
    () => {
      this.resources.clear()
      this.hasMore = true
      // this.fetchResources()
    }
  )

  render() {
    const resources = this.resources

    const addEventClicResource = (resourceId: String, origin: String) => {
      this.props.analyticsService!.addEventClicResources(resourceId, origin)
    }

    return (
      <>
        <InfiniteScroll
          className="InfiniteRessources"
          pageStart={0}
          loadMore={async () => this.fetchResources()}
          hasMore={this.hasMore}
          loader={
            <div className="loader" key={0}>
              <FontAwesomeIcon icon="spinner" spin />
            </div>
          }
        >
          {resources.map(r => (
            <Col
              lg="3"
              md="4"
              sm="6"
              xs="12"
              key={r.id}
              className="px-1 Resource"
              onClick={() => addEventClicResource(r.id, 'RESOURCE')}
            >
              <ResourceC resource={r} />
            </Col>
          ))}
        </InfiniteScroll>
      </>
    )
  }

  @action
  private async fetchResources() {
    const { resourceService, theme, category } = this.props
    const newResources = await resourceService!.findResourceByTheme(
      theme.id,
      category.id,
      this.resources.length
    )
    const alreadyLoadedRIds = this.resources.map(r => r.id)
    const items = newResources.filter(r => !alreadyLoadedRIds.includes(r.id))
    if (!items.length) {
      this.hasMore = false
    }
    this.resources.push(...items)
  }
}

import { action, observable } from 'mobx'
import { Prediag, PrediagUpdate } from '../../prediag/PrediagModel'
import { ApiProxy } from '../../../service/APIProxy'
import { AuthStore } from '../../../store/auth'
import { ProjectsStore } from './projects.store'
import { DetailedProject, ProjectId } from '../../../model/project'
import dayjs from 'dayjs'
import { DATE_FORMAT } from '../../prediag/PrediagQuestion'

interface PrediagHolder {
  prediag: Prediag
  prediagCreationDate: string
  prediagModificationDate: string
  prediagStatus: string
}

export interface ProjectPrediags {
  projectId: string
  projectName: string
  holders: PrediagHolder[]
}

export class PrediagStore {
  @observable projectPrediags: ProjectPrediags[] = []
  @observable prediagsFetched: boolean = false

  constructor(
    private authStore: AuthStore,
    private apiProxy: ApiProxy,
    private projectStore: ProjectsStore
  ) {
    this.projectStore.onProjectsLoaded(projects => this.fetchPrediags(projects))
  }

  @action
  async refreshPrediagsForProject(projectId: ProjectId) {
    const projects = this.projectStore.projects.filter(p => p.id === projectId)
    this.fetchPrediags(projects)
  }

  @action fetchPrediags = (projects: DetailedProject[]) => {
    this.projectPrediags = this.projectPrediags.filter(
      pre => projects.map(p => p.id).indexOf(pre.projectId) === -1
    )
    projects.forEach(project => {
      const projectPrediags: ProjectPrediags | undefined = this.projectPrediags.find(
        pre => pre.projectId === project.id
      )
      if (!projectPrediags) {
        const newHolder = {
          holders: [],
          projectId: project.id,
          projectName: project.name,
        }
        this.projectPrediags.push(newHolder)
      }

      project.prediags.forEach(prediagInfo => {
        this.retrievePrediag(project.id, prediagInfo.prediagId).then(prediag => {
          const projectPrediags: ProjectPrediags | undefined = this.projectPrediags.find(
            pre => pre.projectId === project.id
          )
          const prediagHolder = {
            prediag,
            prediagCreationDate: prediagInfo.creationDate,
            prediagModificationDate: prediagInfo.modificationDate,
            prediagStatus: prediagInfo.status,
          }
          if (projectPrediags) {
            projectPrediags.holders.push(prediagHolder)
          }
        })
      })
    })
  }

  @action createPrediag = (projectId: string) => {
    return this.apiProxy
      .post<Prediag>(`/prediag/createPrediag`, { projectId })
      .then(prediag => this.updateProjectPrediags(prediag))
  }

  @action answerPrediag = (answerPrediag: PrediagUpdate) => {
    return this.apiProxy.post<string>(`/prediag/updatePrediag`, answerPrediag, false).then(() => {
      return this.retrievePrediag(answerPrediag.projectId, answerPrediag.prediagId).then(prediag =>
        this.updateProjectPrediags(prediag)
      )
    })
  }

  retrievePrediag(projectId: string, prediagId?: string) {
    if (prediagId) {
      return this.apiProxy.get<Prediag>(`/prediag/project/${projectId}/getPrediag/${prediagId}`)
    }
    return this.apiProxy.get<Prediag>(`/prediag/project/${projectId}/getPrediag`)
  }

  @action deletePrediag = (projectId: string, prediagId: string) => {
    return this.apiProxy
      .post<boolean>('/prediag/deletePrediag', { projectId, prediagId })
      .then(res => {
        this.projectStore.refreshProject(projectId).then(() => {
          if (res) {
            this.projectPrediags = []
            this.fetchPrediags(this.projectStore.projects)
          }
        })
      })
  }

  @action getPdfPrediag = (projectId: string) => {
    return this.apiProxy.get<any>(`/prediag/getPrediagPdf/${projectId}`)
  }

  downloadPrediag = (prediagId: string) => {
    this.getPdfPrediag(prediagId).then(value => {
      const downloadLink = document.createElement('a')
      downloadLink.href = `${value.data.datas}`
      downloadLink.download = 'prediag.pdf'
      downloadLink.click()
    })
  }

  retrieveProjectPrediagsSortedForProject(projectId?: string): ProjectPrediags | undefined {
    const projectPrediags = this.projectPrediags.find(p => p.projectId === projectId)

    if (!projectPrediags) {
      return undefined
    }

    const sortedHolders = projectPrediags.holders.sort((h1, h2) => {
      const isBefore = dayjs(h1.prediagCreationDate).isAfter(dayjs(h2.prediagCreationDate))
      return isBefore ? 1 : -1
    })
    return { ...projectPrediags, holders: sortedHolders }
  }

  updateProjectPrediags = (prediag: Prediag) => {
    const projectPrediag = this.projectPrediags.find(p => p.projectId === prediag.projectId)!!
    const holder =
      projectPrediag.holders.find(pre => pre.prediag.id === prediag.id) ??
      projectPrediag.holders.find(pre => pre.prediag.id !== '')!!
    const updatedHolder = {
      ...holder,
      prediagCreationDate: dayjs().format(DATE_FORMAT),
      prediagModificationDate: dayjs().format(DATE_FORMAT),
    }
    projectPrediag.holders = projectPrediag.holders
      .filter(pre => pre.prediag.id !== prediag.id && pre.prediag.id !== '')
      .concat([{ ...updatedHolder, prediag }])
    return prediag
  }
}

import { ProjectDocument } from '../../model/document'

type QuestionType =
  | 'SELECT'
  | 'TEXTAREA'
  | 'TEXT'
  | 'NUMBER'
  | 'FLOAT'
  | 'ARRAY'
  | 'LISTPART'
  | 'BOOLEAN'
  | 'DOCUMENT'
  | 'DATETIME'

type PrediagSubCategoryHeaderType = 'boolean' | 'url' | 'label' | 'textarea' | 'text'
type PrediagQuestionHeaderType = 'FLOAT' | 'TEXT' | 'NUMBER' | 'DATETIME'
type PrediagStatus = 'DONE' | 'INPROGRESS' | 'TODO'
type PrediagProvenance = 'MUFFIN' | 'APPLI'
type PrediagCategorieType = 'LIST'

export interface Prediag {
  id: string
  projectId: string
  version: string
  title: string
  preRequis: string
  description: string
  durationDescription: string
  footer: string
  status: PrediagStatus
  categories: PrediagCategory[]
  provenance: PrediagProvenance
  validated: boolean
  completedStep: number
}

export interface PrediagCategory {
  id: string
  title: string
  description: string
  sousCategories: PrediagSubCategory[]
  questions: PrediagQuestion[]
  answered: boolean
  type?: PrediagCategorieType
}

export interface PrediagSubCategory {
  id: string
  title: string
  headers: PrediagHeader[]
  questions: PrediagQuestion[]
  description?: string
}

export interface PrediagInfo {
  prediagId: string
  creationDate: string
  modificationDate: string
  status: string
}

export interface PrediagQuestion {
  id: string
  title: string
  required: boolean
  hidden: boolean
  type: QuestionType
  options: PrediagOption[]
  conditions: PrediagQuestionCondition[]
  suffix: string
  headers: PrediagQuestionHeader[]
  parts: PrediagQuestionPart[]
  answer: string
  answers: Record<string, string>[]
  document: ProjectDocument
  description: string
  docType: string
}

export interface PrediagHeader {
  id: string
  title: string
  type: PrediagSubCategoryHeaderType
}

interface PrediagOption {
  id: string
  text: string
}

export interface PrediagQuestionCondition {
  required: boolean
  hidden: boolean
  questionId: string
  questionValue: string
}

export interface PrediagQuestionHeader {
  id: string
  title: string
  required: boolean
  type: PrediagQuestionHeaderType
  suffix: string
}

export interface PrediagQuestionPart {
  id: string
  headerId: string
  text: string
  url: string
  answer: string
}

export interface PrediagUpdate {
  prediagId: string
  projectId: string
  answers: PrediagAnswer[]
  ended: boolean
  lastStepId?: string
}

export interface PrediagAnswer {
  questionId: string
  value?: string
  values?: Record<string, string>[]
}

export const areRequiredFieldsFilled = (
  category: PrediagCategory,
  answers: PrediagAnswer[],
  filteredQuestions: string[]
) => {
  const subCategoriesAreValid =
    category.sousCategories
      .map(sc => validateSubCategory(sc, answers, filteredQuestions))
      .filter(valid => !valid).length === 0
  const questionsAreValid =
    category.questions
      .map(q => validateQuestion(q, answers, filteredQuestions))
      .filter(valid => !valid).length === 0
  return subCategoriesAreValid && questionsAreValid
}

const validateSubCategory = (
  subCategory: PrediagSubCategory,
  answers: PrediagAnswer[],
  filteredQuestions: string[]
) => {
  return (
    subCategory.questions
      .map(q => validateQuestion(q, answers, filteredQuestions))
      .filter(valid => !valid).length === 0
  )
}

const validateQuestion = (
  question: PrediagQuestion,
  answers: PrediagAnswer[],
  filteredQuestions: string[]
) => {
  if (question.type === 'ARRAY') {
    return validateQuestionArray(question, answers)
  }
  const isConditionnallyRequired =
    question.conditions.filter(c => c.required).length > 0 &&
    filteredQuestions.indexOf(question.id) === -1

  const alreadyHadAnAnswer = !!question.answer && question.answer !== ''
  const isNotRequired = !(question.required || isConditionnallyRequired)
  const hasAPendingAnswer = answers.find(a => a.questionId === question.id)

  if (!!hasAPendingAnswer) {
    return hasAPendingAnswer.value !== '' || isNotRequired
  }

  return alreadyHadAnAnswer || isNotRequired
}

const validateQuestionArray = (question: PrediagQuestion, answers: PrediagAnswer[]) => {
  const values = answers.find(a => a.questionId === question.id)?.values ?? []
  const headers = question.headers
  return values.filter(v => !validateRecord(v, headers)).length === 0
}

const validateRecord = (value: Record<string, string>, headers: PrediagQuestionHeader[]) => {
  return (
    headers.filter(h => (value[h.id] === undefined || value[h.id] === '') && h.required).length ===
    0
  )
}

import { Button, Form, Input, InputGroup } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useEffect, useState } from 'react'
import './ProjectMessengerInput.scss'
import { useAppContext } from '../../context'
import { ProjectDocument } from '../../model/document'
import { ProjectId } from '../../model/project'
import { UploadModal } from '../shared/document/UploadModal'
import { AlertComponentProps, useAlert } from 'react-alert'

type ProjectMessengerInputProps = {
  disabled: boolean
  onNewMessage: (m: string) => void
  projectId: ProjectId
}

export const ProjectMessengerInput: FC<ProjectMessengerInputProps> = ({
  disabled,
  onNewMessage,
  projectId,
}) => {
  const { apiProxy, projectsStore } = useAppContext()

  const [newMessage, setMessage] = useState('')
  const [modalOpened, toggleModal] = useState(false)
  const [errorOnUpload, setError] = useState(false)
  const [sending, setSending] = useState(false)
  const [myAlert, setMyAlert] = useState<AlertComponentProps>()
  const alert = useAlert()

  useEffect(() => {
    return () => {
      myAlert && alert.remove(myAlert)
    }
    // eslint-disable-next-line
  }, [myAlert])

  const sendFiles = async (files: File[], docType?: string, description?: string) => {
    setSending(true)
    if (errorOnUpload) {
      setError(false)
    }

    const fd = new FormData()
    files.forEach(f => fd.append('file', f, f.name))
    fd.append('documentType', docType!)
    fd.append('documentDescription', description!)

    try {
      const doc = await apiProxy.formData<ProjectDocument>(`/project/${projectId}/documents/`, fd)
      projectsStore.addDocument(projectId, doc)
    } catch (e) {
      setError(true)
    } finally {
      setSending(false)
      toggleModal(false)
      setMyAlert(
        errorOnUpload
          ? alert.error("Une erreur est survenue lors de l'envoi de vos documents")
          : alert.success('Document(s) envoyé(s)')
      )
    }
  }

  return (
    <Form
      className="ProjectMessengerInput"
      onSubmit={e => {
        e.preventDefault()
        onNewMessage(newMessage.trim())
        setMessage('')
      }}
    >
      <InputGroup size="lg">
        <Input
          className="inputZone"
          onChange={ev => setMessage(ev.target.value || '')}
          type="textarea"
          value={newMessage}
          onKeyUp={e => e.which}
          disabled={disabled}
          placeholder={
            disabled ? 'La messagerie est désactivée sur ce projet' : 'Ecrivez quelque chose...'
          }
        />
        <div className="actions">
          <button
            className="btn btn-naked attachDocumentButton"
            type="button"
            disabled={disabled}
            onClick={() => toggleModal(!modalOpened)}
          >
            <FontAwesomeIcon icon="paperclip" size={'lg'} color="var(--main-green)" />
          </button>

          <Button type="submit" className="sendButton" disabled={!newMessage.trim().length}>
            <FontAwesomeIcon icon="paper-plane" size={'lg'} />
          </Button>
        </div>
      </InputGroup>
      <UploadModal
        open={modalOpened}
        toggle={() => toggleModal(!modalOpened)}
        sendFiles={sendFiles}
        sending={sending}
      />
    </Form>
  )
}

import { MetadataUrlResponse } from '../model/resource'
import { ApiProxy } from './APIProxy'

export class MetadataService {
  private cache: Map<string, MetadataUrlResponse> = new Map()

  constructor(private apiProxy: ApiProxy) {}

  metadata = (url: string): Promise<MetadataUrlResponse> => {
    if (this.cache.has(url)) {
      return Promise.resolve(this.cache.get(url)!)
    }

    return this.apiProxy
      .post<{ url: string }, MetadataUrlResponse>('/metadata', {
        url,
      })
      .then(response => {
        this.cache.set(url, response)
        return response
      })
  }
}

import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(Timezone)

const FORMAT = 'ddd DD MMMM - HH:mm'

export function makeDateDaysAndHours(createdAt: string) {
  return dayjs(createdAt).format(FORMAT)
}

export function format(date: Dayjs) {
  return date.format(FORMAT)
}

// Muffin register all meetings with France timezone, so we must parse meeting date in France Timezone even for DOM-TOM
export const unzonedDayjs = (date: string) => {
  return dayjs.utc(date).tz('Europe/Paris')
}

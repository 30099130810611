import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.css'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Router } from 'react-router-dom'
import { Provider } from 'mobx-react'
import { configure } from 'mobx'
import 'dayjs/locale/fr'
import AppContext, { createContext } from './context'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { positions, Provider as AlertProvider, transitions } from 'react-alert'
// @ts-ignore
import AlertTemplate from 'react-alert-template-basic'

dayjs.extend(relativeTime).locale('fr')

configure({
  enforceActions: 'never',
})

const serviceAndStore = createContext()

const options = {
  position: positions.TOP_RIGHT,
  offset: '110px',
  transition: transitions.SCALE,
}

ReactDOM.render(
  <AppContext.Provider value={serviceAndStore}>
    <Provider {...serviceAndStore}>
      <Router history={serviceAndStore.routingStore.history}>
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
        </AlertProvider>
      </Router>
    </Provider>
  </AppContext.Provider>,
  document.getElementById('root') || document.createElement('div')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

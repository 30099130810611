import React, { FC, useState } from 'react'
import Header from './Header'
import LeftMenu from './leftMenu/LeftMenu'
import './Home.scss'
import { RouteDefinition, RouteWithSubRoutes } from './RouteWithSubRoutes'
import Dashboard from '../components/dashboard/Dashboard'
import Projects from '../components/projects/Projects'
import { Route, Switch } from 'react-router'
import { MessengerPage } from '../components/messenger/MessengerPage'
import Titled from './Titled'
import Footer from './Footer'
import Profile from '../components/profile/Profile'
import Cgu from './static/cgu'
import Legal from './static/legal'
import Resources from '../components/resources/Resources'
import Dpd from './static/dpd'
import { useAppContext } from '../context'
import { observer } from 'mobx-react-lite'
import UnknownPage from './UnknownPage'
import { browserVersion, isBrowser, isEdge } from 'react-device-detect'
import { Button, UncontrolledAlert } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FACalendar from '../components/calendar/FACalendar'
import { QuizzComponent } from '../components/quizz/Quizz'
import { PrediagPage } from '../components/prediag/PrediagPage'
import { Link } from 'react-router-dom'

const Home: FC = observer(() => {
  const { messengerStore, authStore, projectsStore } = useAppContext()
  const { messengerEnabled, newMessagesCount } = messengerStore
  const routes: RouteDefinition[] = [
    {
      path: '/d',
      component: Dashboard,
      title: 'Tableau de bord',
      secured: true,
    },
    {
      path: '/p',
      component: Projects,
      secured: true,
      title: 'Mon projet',
    },
    {
      path: '/m',
      component: MessengerPage,
      title: 'Messagerie',
      secured: true,
      renderCondition: () => messengerEnabled === true,
      waitCondition: () => messengerEnabled === 'pending',
    },
    {
      path: '/r',
      component: Resources,
      title: 'Resources et Formations',
      secured: true,
    },
    {
      path: '/profil',
      component: Profile,
      title: 'Profil',
      secured: true,
    },
    {
      path: '/cgu',
      component: Cgu,
      title: 'CGU',
      secured: false,
    },
    {
      path: '/dpd',
      component: Dpd,
      title: 'DPD',
      secured: false,
    },
    {
      path: '/legal',
      component: Legal,
      title: 'Mentions Légales',
      secured: false,
    },
    {
      path: '/calendar',
      component: FACalendar,
      title: 'Calendrier',
      secured: true,
    },
    {
      path: '/quiz',
      component: QuizzComponent,
      title: 'Quiz',
      secured: true,
      exact: false,
    },
    {
      path: '/prediag',
      component: PrediagPage,
      title: 'Prediag',
      secured: true,
    },
  ]
  const [leftMenuCollapsed, setLeftMenuCollapsed] = useState<boolean>(isBrowser)

  const showMenu = (shown: boolean) => {
    setLeftMenuCollapsed(shown)
  }

  const displayPrediagAlert = projectsStore.isPrediagRequired

  return (
    <div className="Home d-flex flex-column">
      <Titled
        title={() => `${newMessagesCount ? `(${newMessagesCount})` : ''} L'Appli France Active`}
      >
        <Header
          toggleLeftMenu={() => setLeftMenuCollapsed(!leftMenuCollapsed)}
          onClick={() => showMenu(false)}
        />
        {authStore.reallyAuthenticated === 'AUTHENTICATED' ? (
          <>
            {isEdge && +browserVersion < 80 && (
              <UncontrolledAlert color={'info'}>
                <FontAwesomeIcon icon={'info-circle'} />
                &nbsp; La messagerie n'est pas supportée sur le navigateur Edge. Merci d'utiliser un
                autre navigateur pour profiter de cette fonctionnalité.
              </UncontrolledAlert>
            )}

            {displayPrediagAlert && (
              <UncontrolledAlert color={'info'}>
                <FontAwesomeIcon icon={'info-circle'} />
                &nbsp;Covid-19 : Evaluer la situation économique de mon entreprise &nbsp;
                <Link to={`/prediag/`}>
                  {' '}
                  <Button>Saisir le questionnaire</Button>
                </Link>
              </UncontrolledAlert>
            )}

            <div className="d-flex flex-grow-1" style={{ marginTop: '24px' }}>
              <LeftMenu collapsed={leftMenuCollapsed} showMenu={showMenu} />
              <div className="Home-Content">
                <Switch>
                  {routes.map(route => (
                    <RouteWithSubRoutes key={route.path} {...route} />
                  ))}
                  <Route component={UnknownPage} />
                </Switch>
              </div>
            </div>
          </>
        ) : (
          <div className="d-flex flex-grow-1 mt-3">
            <div className="ml-2 mr-2" style={{ width: '100%' }}>
              <Switch>
                {routes
                  .filter(r => !r.secured)
                  .map(route => (
                    <RouteWithSubRoutes key={route.path} {...route} />
                  ))}
                <Route
                  render={() => {
                    if (authStore.reallyAuthenticated === 'NOT_AUTHENTICATED') {
                      authStore.login()
                    }
                    return <div />
                  }}
                />
              </Switch>
            </div>
          </div>
        )}
        <Footer />
      </Titled>
    </div>
  )
})
export default Home

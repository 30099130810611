import { action, computed, observable, ObservableMap } from 'mobx'
import { Project } from '../../../model/project'
import { ProjectMessengerData } from './ProjectMessengerData'
import { ProjectsStore } from '../../projects/store/projects.store'
import { AuthStore } from '../../../store/auth'
import { StompService } from '../../../service/StompService'
import { FeaturesStore } from '../../../store/featuresStore'
import { PayloadType } from '../../../model/message'

type MessagesMap = ObservableMap<string, ProjectMessengerData>

export class MessengerStore {
  @observable currentProject: Project | null = null
  @observable projectsMessages: MessagesMap = new ObservableMap()
  @observable messengerEnabled: true | false | 'pending' = 'pending'

  constructor(
    projectsStore: ProjectsStore,
    private authStore: AuthStore,
    private stompService: StompService,
    private featuresStore: FeaturesStore
  ) {
    projectsStore.onProjectsLoaded(projects => {
      this.featuresStore.onFeaturesLoaded(() => {
        projects.forEach(p => {
          const pmd = this.createMessengerData(p)
          if (pmd.messengerEnabled) {
            stompService.register(p.id, m => pmd.processWsMessage(m))
          }
        })
        this.messengerEnabled = [...this.projectsMessages.values()]
          .map(it => it.messengerEnabled)
          .reduce((acc, val) => acc || val, false)
      })
    })
  }

  @computed get newMessagesCount(): number {
    return [...this.projectsMessages.values()]
      .map(it => it.newMessagesCount)
      .reduce((acc, val) => acc + val, 0)
  }

  @computed get projectsWithMessages() {
    return [...this.projectsMessages.values()]
  }

  forProject(projectId: string) {
    return this.projectsMessages.get(projectId)
  }

  @computed get forCurrentProject(): ProjectMessengerData | undefined {
    if (this.currentProject) {
      return this.forProject(this.currentProject.id)
    }
    return undefined
  }

  @computed get lastAction(): PayloadType | undefined {
    const project = this.forCurrentProject
    if (project) {
      return project!.lastAction
    }
    return undefined
  }

  @action setMainProject(p: Project) {
    this.currentProject = p
  }

  @action createMessengerData(p: Project): ProjectMessengerData {
    const projectMessengerData = observable(
      new ProjectMessengerData(
        p,
        this.authStore.id,
        this.featuresStore.messengerActivation(p),
        () =>
          this.stompService.client.publish({
            destination: `/app/projectChannel/${p.id}/updateSeen`,
          }),
        options =>
          this.stompService.client.publish({
            destination: `/app/projectChannel/${p.id}/getMessages`,
            body: JSON.stringify(options),
          }),
        ({ text }) =>
          this.stompService.client.publish({
            destination: `/app/projectChannel/${p.id}/sendMessage`,
            body: JSON.stringify({ text }),
          })
      )
    )
    this.projectsMessages.set(p.id, projectMessengerData)
    return projectMessengerData
  }
}

import { action, autorun, computed, observable, reaction } from 'mobx'
import environment from '../config/environment'
import Keycloak, { KeycloakProfile } from 'keycloak-js'
import { RouterStore } from 'mobx-react-router'

export interface UserModel {
  username?: string
  email?: string
  id?: string
  firstName?: string
  lastName?: string
  associationId?: AssociationId
}
export type AssociationId = string

const keycloak = new Keycloak({
  url: environment.keycloakUrl,
  realm: 'france-active',
  clientId: 'front-appli-by-fa',
})

type AuthState = 'AUTHENTICATED' | 'NOT_AUTHENTICATED' | 'PENDING'

export class AuthStore {
  constructor(private routingStore: RouterStore) {
    autorun(() => this.initKeycloak())
  }

  @observable user?: UserModel

  @observable reallyAuthenticated: AuthState = 'PENDING'

  @computed get isLoggedIn(): boolean {
    return !!this.user
  }

  @computed get authHeader(): string {
    return `Bearer ${keycloak.token}`
  }
  @computed get token(): string {
    return keycloak.token || ''
  }
  @computed get id(): string {
    return (this.user && this.user.id) || ''
  }

  @action private initKeycloak = () => {
    keycloak
      .init({
        onLoad: 'check-sso',
        checkLoginIframeInterval: 1,
      })
      .then(async authenticated => {
        if (authenticated) {
          keycloak.loadUserProfile().then(resp => {
            this.updateUser(resp)
            if (this.routingStore.location.pathname === '/') {
              this.routingStore.push('/d')
            }
            this.reallyAuthenticated = 'AUTHENTICATED'
          })
          // todo essayer de coller le refresh quand on demande un token
          setInterval(() => {
            keycloak.updateToken(5).catch(() => keycloak.logout())
          }, 300000)
        } else {
          this.reallyAuthenticated = 'NOT_AUTHENTICATED'
        }
      })
  }

  @action private updateUser(resp: KeycloakProfile) {
    // @ts-ignore
    const attributes = resp['attributes']
    let keycloakAssociationId
    if (attributes !== undefined && attributes.associationId !== undefined) {
      keycloakAssociationId = attributes.associationId[0]
    }

    this.user = {
      ...resp,
      id: keycloak.subject,
      associationId: keycloakAssociationId,
    }
  }

  @action
  logout(redirectUri?: string) {
    keycloak.logout({
      redirectUri,
    })
  }

  @action
  login() {
    keycloak.login()
  }

  onLoggedIn = (onLoggedInCallback: () => any) =>
    reaction(
      () => this.isLoggedIn,
      loggedIn => loggedIn && onLoggedInCallback(),
      {
        fireImmediately: true,
      }
    )
}

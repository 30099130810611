import React, { FC, useState } from 'react'
import {
  CommitteeOperation,
  ProjectEvent,
  ProjectEventType,
  ProjectId,
} from '../../../../model/project'
import { toJS } from 'mobx'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './History.scss'
import { DocumentValidationEventStatus } from '../../../../model/document'
import { useAppContext } from '../../../../context'
import { observer } from 'mobx-react-lite'
import { makeDateDaysAndHours } from '../../../../utils/date'
import { Card, Col, Input } from 'reactstrap'
import { H4 } from '../../../shared/H/H'
import styled from 'styled-components'

type HistoryCProps = { projectId: ProjectId }

const EventDictionnary: Record<HistoryFilterType, string> = {
  COMMITTEE_STATUS: 'Comité',
  MEMENTO: 'Memento',
  DOCUMENT: 'Documents',
  PREDIAG: 'Prédiag',
}

const MementoFilterDictionnary: ProjectEventType[] = [
  'MEMENTO_PUBLISH',
  'MEMENTO_UNPUBLISH',
  'MEMENTO_UPDATE',
  'DELIVERABLE_UPDATE',
]

const DocumentFilterDictionnary: ProjectEventType[] = ['DOCUMENT_REQUIRED', 'DOCUMENT_VALIDATION']
const PrediagFilterDictionnary: ProjectEventType[] = [
  'NOTIFY_PREDIAG_NEEDED',
  'NOTIFY_PREDIAG_UPDATE',
  'NOTIFY_PREDIAG_VALIDATION',
]

export type HistoryFilterType = 'COMMITTEE_STATUS' | 'DOCUMENT' | 'PREDIAG' | 'MEMENTO'

export const HistoryFiltersTypes: HistoryFilterType[] = [
  'COMMITTEE_STATUS',
  'MEMENTO',
  'DOCUMENT',
  'PREDIAG',
]

const HistoryFilters = styled.div`
  color: var(--main-gray);
`

// @ts-ignore
const Event: FC<{ ev: ProjectEvent }> = ({ ev }) => {
  switch (ev.eventType) {
    case 'DOCUMENT_REQUIRED':
      return (
        <VerticalTimelineElement
          key={ev.id}
          id={ev.id}
          date={makeDateDaysAndHours(ev.createdAt)}
          className="vertical-timeline-element--document-required"
          iconStyle={{ backgroundColor: '#1474aa', color: '#fff' }}
          icon={<FontAwesomeIcon icon={'file-alt'} size={'lg'} fixedWidth />}
        >
          <p>
            {ev.metadata.count} document
            {ev.metadata.count > 1
              ? ev.metadata.onlyDynamicDocs === true
                ? 's ont été transmis'
                : 's ont été demandés'
              : ev.metadata.onlyDynamicDocs === true
              ? ' a été transmis'
              : ' a été demandé'}
          </p>
        </VerticalTimelineElement>
      )
    case 'DOCUMENT_VALIDATION':
      const status = ev.metadata.status as DocumentValidationEventStatus

      switch (status) {
        case 'VALIDATED':
          return (
            <VerticalTimelineElement
              key={ev.id}
              id={ev.id}
              date={makeDateDaysAndHours(ev.createdAt)}
              className="vertical-timeline-element--document-validated"
              iconStyle={{ background: 'lightgreen', color: '#fff' }}
              icon={<FontAwesomeIcon icon={'check'} size={'lg'} fixedWidth />}
            >
              <p>{ev.metadata.docName ? ev.metadata.docName : 'Un document'} a été validé</p>
            </VerticalTimelineElement>
          )

        case 'INCOMPLETE':
          return (
            <VerticalTimelineElement
              key={ev.id}
              id={ev.id}
              date={makeDateDaysAndHours(ev.createdAt)}
              className="vertical-timeline-element--document-incomplete"
              iconStyle={{ background: 'purple', color: '#fff' }}
              icon={<FontAwesomeIcon icon={'plus'} size={'lg'} fixedWidth />}
            >
              <p>
                {ev.metadata.docName ? ev.metadata.docName : 'Un document'} a été marqué incomplet
              </p>
            </VerticalTimelineElement>
          )
        case 'REFUSED':
          return (
            <VerticalTimelineElement
              key={ev.id}
              id={ev.id}
              date={makeDateDaysAndHours(ev.createdAt)}
              className="vertical-timeline-element--document-refused"
              iconStyle={{ background: 'red', color: '#fff' }}
              icon={<FontAwesomeIcon icon={'times-circle'} size={'lg'} fixedWidth />}
            >
              <p>{ev.metadata.docName ? ev.metadata.docName : 'Un document'} a été refusé</p>
            </VerticalTimelineElement>
          )
      }

      return (
        <VerticalTimelineElement key={ev.id} id={ev.id}>
          {JSON.stringify(toJS(ev), null, 4)}
        </VerticalTimelineElement>
      )
    case 'MEETING_NEW':
      return <></>
    case 'MEETING_UPDATE':
      return <></>
    case 'MEETING_CANCELLED':
      return <></>
    case 'DELIVERABLE_UPDATE':
    case 'MEMENTO_UPDATE':
      return (
        <VerticalTimelineElement
          key={ev.id}
          id={ev.id}
          date={makeDateDaysAndHours(ev.createdAt)}
          className="vertical-timeline-element--document-required"
          iconStyle={{ background: '#1474aa', color: '#fff' }}
          icon={<FontAwesomeIcon icon={'file-alt'} size={'lg'} fixedWidth />}
        >
          <p>Mémento mis à jour</p>
        </VerticalTimelineElement>
      )
    case 'MEMENTO_PUBLISH':
    case 'MEMENTO_UNPUBLISH':
      return (
        <VerticalTimelineElement
          key={ev.id}
          id={ev.id}
          date={makeDateDaysAndHours(ev.createdAt)}
          className="vertical-timeline-element--document-required"
          iconStyle={{ background: '#1474aa', color: '#fff' }}
          icon={<FontAwesomeIcon icon={'file-alt'} size={'lg'} fixedWidth />}
        >
          {ev.eventType === 'MEMENTO_PUBLISH' ? (
            <p>Mémento publié</p>
          ) : ev.eventType === 'MEMENTO_UNPUBLISH' ? (
            <p>Mémento dépublié</p>
          ) : (
            ''
          )}
        </VerticalTimelineElement>
      )
    case 'COMMITTEE_STATUS':
      return (
        <>
          {ev.metadata.operations && (
            <VerticalTimelineElement
              id={ev.id}
              date={makeDateDaysAndHours(ev.createdAt)}
              className="vertical-timeline-element--meeting-new"
              iconStyle={{ background: 'green', color: '#fff' }}
              icon={<FontAwesomeIcon icon={'users'} size={'lg'} fixedWidth />}
            >
              <p>
                Comité du {makeDateDaysAndHours(ev.metadata.committeeDate)} :
                <br />
                {ev.metadata.operations
                  .sort((a: CommitteeOperation) => (a.notificationType === 'ADD' ? -1 : 1))
                  .map((op: CommitteeOperation) => (
                    <>
                      <span key={op.operation.id}>
                        {op.notificationType === 'ADD'
                          ? ` - Le produit "${op.operation.type.funding}" sera étudié (${op.operation.type.description})`
                          : ` - Le produit "${op.operation.type.funding}" ne sera pas étudié lors de ce comité (${op.operation.type.description})`}
                      </span>
                      <br />
                    </>
                  ))}
              </p>
            </VerticalTimelineElement>
          )}
        </>
      )
    case 'NOTIFY_PREDIAG_NEEDED':
      return (
        <VerticalTimelineElement
          key={ev.id}
          id={ev.id}
          date={makeDateDaysAndHours(ev.createdAt)}
          className="vertical-timeline-element--document-required"
          iconStyle={{ backgroundColor: 'var(--main-violet)', color: '#fff' }}
          icon={<FontAwesomeIcon icon={'file-alt'} size={'lg'} fixedWidth />}
        >
          <p>Demande d'évaluation de la santé de ma structure</p>
        </VerticalTimelineElement>
      )
    case 'NOTIFY_PREDIAG_UPDATE':
      return (
        <VerticalTimelineElement
          key={ev.id}
          id={ev.id}
          date={makeDateDaysAndHours(ev.createdAt)}
          className="vertical-timeline-element--document-required"
          iconStyle={{ backgroundColor: 'var(--main-violet)', color: '#fff' }}
          icon={<FontAwesomeIcon icon={'file-alt'} size={'lg'} fixedWidth />}
        >
          <p>Une évaluation de votre structure a été mise à jour</p>
        </VerticalTimelineElement>
      )
    case 'NOTIFY_PREDIAG_VALIDATION':
      return (
        <VerticalTimelineElement
          key={ev.id}
          id={ev.id}
          date={makeDateDaysAndHours(ev.createdAt)}
          className="vertical-timeline-element--document-required"
          iconStyle={{ backgroundColor: 'var(--main-violet)', color: '#fff' }}
          icon={<FontAwesomeIcon icon={'file-alt'} size={'lg'} fixedWidth />}
        >
          <p>Une évaluation de votre structure a été validée</p>
        </VerticalTimelineElement>
      )
  }
}

const LiFilter = styled.li`
  input {
    cursor: pointer;
  }
  label {
    cursor: pointer;
  }
`

const FilterButton: FC<{
  eventType: HistoryFilterType
  activeFilters: HistoryFilterType[]
  onClick: (elementType: HistoryFilterType) => any
}> = ({ eventType, onClick, activeFilters }) => {
  return (
    <LiFilter onClick={() => onClick(eventType)}>
      <Input
        type="checkbox"
        id={`${eventType}Filter`}
        defaultChecked={activeFilters.indexOf(eventType) !== -1}
      />
      <label htmlFor={`${eventType}Filter`}>{EventDictionnary[eventType]}</label>
    </LiFilter>
  )
}

const SortDetail = styled.div`
  display: flex;
  justify-content: space-between;
  color: black;
`

const SortButton: FC<{
  label: string
  sortingDirection: number
  onClick: () => any
}> = ({ label, onClick, sortingDirection }) => {
  const icon = sortingDirection < 1 ? 'arrow-up' : 'arrow-down'
  return (
    <div
      onClick={onClick}
      style={{
        cursor: 'pointer',
      }}
    >
      <SortDetail>
        <div>
          <b>{label}</b>
        </div>
        <div
          style={{
            display: 'inline',
            color: 'black',
          }}
        >
          <FontAwesomeIcon icon={icon} style={{ fontSize: '1.25em' }} />
        </div>
      </SortDetail>
    </div>
  )
}

export const HistoryC: FC<HistoryCProps> = observer(({ projectId }) => {
  const { historiesStore } = useAppContext()
  const tl = historiesStore.getHistory(projectId)

  const [activeFilters, setActiveFilters] = useState(HistoryFiltersTypes)

  const [sortingDesc, setSortingDesc] = useState(-1)

  const manageFilterChange = (eventType: HistoryFilterType) => {
    if (activeFilters.indexOf(eventType) !== -1) {
      setActiveFilters(activeFilters.filter(type => type !== eventType))
    } else {
      setActiveFilters(activeFilters.concat(eventType))
    }
  }

  const isEventTypePresent = (eventType: HistoryFilterType, events: ProjectEvent[] | undefined) => {
    return events?.some(
      event =>
        event.eventType === eventType ||
        DocumentFilterDictionnary.includes(event.eventType) ||
        PrediagFilterDictionnary.includes(event.eventType) ||
        MementoFilterDictionnary.includes(event.eventType)
    )
  }

  const isEventActive = (ev: ProjectEvent, activeFilters: HistoryFilterType[]) => {
    const isCommitteeStatus =
      activeFilters.includes('COMMITTEE_STATUS') && ev.eventType === 'COMMITTEE_STATUS'
    const isDocument =
      activeFilters.includes('DOCUMENT') && DocumentFilterDictionnary.includes(ev.eventType)
    const isPrediag =
      activeFilters.includes('PREDIAG') && PrediagFilterDictionnary.includes(ev.eventType)
    const isMemento =
      activeFilters.includes('MEMENTO') && MementoFilterDictionnary.includes(ev.eventType)
    return isCommitteeStatus || isDocument || isPrediag || isMemento
  }

  const sortEvents = (event1: ProjectEvent, event2: ProjectEvent) => {
    return event1.createdAt > event2.createdAt ? sortingDesc : -1 * sortingDesc
  }

  return tl == null ? (
    <div>chargement...</div>
  ) : (
    <div className="row">
      <Col xs={12} sm={8} md={6} lg={3}>
        <Card body>
          <H4 arrowColor={'yellow'} text={'Historique'} />
          <HistoryFilters>
            <div style={{ fontSize: '18px', marginBottom: '-10px', color: 'var(--main-gray)' }}>
              Trier par :
            </div>
            <hr />
            <div>
              <SortButton
                label={'Date de création'}
                sortingDirection={sortingDesc}
                onClick={() => setSortingDesc(sortingDesc * -1)}
              />
            </div>
            <div style={{ fontSize: '18px', marginBottom: '-10px' }}>Filtrer par :</div>
            <hr />
            <ul style={{ listStyle: 'none' }}>
              {Object.values(HistoryFiltersTypes).map(
                type =>
                  isEventTypePresent(type, tl.events) && (
                    <FilterButton
                      activeFilters={activeFilters}
                      key={`${type}:${activeFilters.indexOf(type) !== -1}`}
                      eventType={type}
                      onClick={manageFilterChange}
                    />
                  )
              )}
            </ul>
          </HistoryFilters>
        </Card>
      </Col>
      <Col xs={12} sm={8} md={8} lg={8}>
        <div className="History">
          <VerticalTimeline layout="1-column" animate={false}>
            {tl.events
              .filter(ev => isEventActive(ev, activeFilters))
              .sort(sortEvents)
              .map(ev => {
                return <Event key={ev.id} ev={ev} />
              })}
            <VerticalTimelineElement
              className="vertical-timeline-element--start"
              iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
              icon={<FontAwesomeIcon icon="star" size={'lg'} fixedWidth />}
            />
          </VerticalTimeline>
        </div>
      </Col>
    </div>
  )
})

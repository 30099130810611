import {
  AbandonmentReason,
  AmortissementType,
  ChargedOfMissionDecision, FinancementOperationStatus,
  OperationStatus,
  PaymentMean,
} from '../model/project'

export const FinancementOperationStatusDictionary: Record<FinancementOperationStatus, string> = {
  ACTIVATED: 'Activé',
  DECISION: 'En décision',
  IN_CONTRACT: 'En contractualisation',
  INIT: 'En accompagnement',
  AJOURNE: 'Ajourné',
  REFUSE: 'Refusé',
  ABANDONNE: 'Abandonné'
}


export const ChargedOfMissionDecisionDictionary: Record<ChargedOfMissionDecision, string> = {
  FAVORABLE: 'Favorable',
  UNFAVORABLE: 'Défavorable',
  RESERVE: 'Reservé',
}

export const AmortissementTypeDictionary: Record<AmortissementType, string> = {
  MONTHLY: 'Mensuel',
  QUARTERLY: 'Trimestriel',
  BIANNUAL: 'Semestriel',
  ANNUAL: 'Annuel',
  FINAL: 'In fine',
}

export const PaymentMeanDictionary: Record<PaymentMean, string> = {
  BANK_TRANSFER: 'Virement',
  CHECK: 'Chèque',
  DEBIT: 'Prélèvement automatique',
}

export const AbandonmentReasonDictionary: Record<AbandonmentReason, string> = {
  PERSO_BEARER: 'Abandon de la part du porteur',
  PERSO_FUNDING: 'Autofinancement',
  PERSO_OUT_OF_DATE: 'DLU dépassée, pas de retour',
  PROJECT_LOST: 'Perte de FDF/droit au bail...',
  PROJECT_CE: 'Représentation du dossier en CE',
  PROJECT_ASSOCIATE: 'Abandon associé',
  PROJECT_TECHNICAL: 'Abandon technique',
  FUNDING_CB: 'Crédit bail (recours au)',
  FUNDING_REFUSED: 'Refus bancaire',
  FUNDING_OTHER: 'Autre garantie prise par la banque',
  FUNDING_DEPOSIT: 'Caution personnelle',
  FUNDING_FINANCE: 'Refus autre financeur',
}

export function getOperationStatusWeight(operationStatus: OperationStatus): 0 | 1 | 2 | 3 {
  switch (operationStatus) {
    case 'INIT':
      return 0
    case 'DECISION':
      return 1
    case 'IN_CONTRACT':
      return 2
    case 'ACTIVATED':
      return 3
  }
}

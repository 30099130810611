import React from 'react'
import './Projects.scss'
import { observer } from 'mobx-react-lite'
import { Redirect, Route, Switch } from 'react-router'
import Project from './project/Project'
import { useAppContext } from '../../context'
import { Card } from 'reactstrap'
import styled from 'styled-components'

const NoProject = styled(Card)`
  display: flex;
  min-height: 200px;
  align-items: center;
  justify-content: center;
`

export const Projects = observer(() => {
  const { projectsStore } = useAppContext()
  return (
    <div className="Projects">
      <Switch>
        <Route exact path="/p/:id" component={Project} />
        <Route
          component={observer(() => (
            <div>
              {!!projectsStore.projects.length && (
                <Redirect to={`/p/${projectsStore.projects[0].id}`} />
              )}
              {projectsStore.projectsFetched && !projectsStore.projects.length && (
                <NoProject>
                  <span style={{ textAlign: 'center' }}>Vous n'avez pas encore de projets</span>
                </NoProject>
              )}
            </div>
          ))}
        />
      </Switch>
    </div>
  )
})

export default Projects

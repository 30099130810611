import { useState } from 'react'
import * as Cookies from 'js-cookie'

export const useCookie = (key: string, initialValue: string | null = null) => {
  const [item, setInnerValue] = useState(() => Cookies.get(key) || initialValue)

  const setValue = (value: string, options: Cookies.CookieAttributes) => {
    setInnerValue(value)
    Cookies.set(key, value, options)
  }

  return [item, setValue]
}

export default useCookie

import React, { FC, useState } from 'react'
import { Button, Card } from 'reactstrap'
import { H2 } from '../shared/H/H'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CalendarMonth } from './CalendarMonth'
import dayjs from 'dayjs'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { CalendarYear } from './CalendarYear'
import { useAppContext } from '../../context'
import { DetailedProject } from '../../model/project'
import Meetings from '../dashboard/meetings/Meetings'
import { observer } from 'mobx-react-lite'

const TitleCard = styled(Card)`
  margin-bottom: 20px;
`

export const GreyText = styled.span`
  color: var(--main-gray);
`

const Body = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const CalendarContent = styled(Card)`
  width: 80%;
  margin-right: 20px;
`

const Header = styled.div`
  padding: 1% 1% 0 1%;
  * {
    line-height: 13px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const RoundedButton = styled(Button)`
  border-radius: 0.25rem;
`

const TimeTypeSwitch = styled.div`
  .time-type-checked {
    background-color: var(--main-green);
    color: white;
  }
  .time-type-checked:hover {
    background-color: var(--main-green) !important;
    color: white !important;
  }
  .btn:hover {
    background-color: white;
    color: var(--main-gray);
  }
  .btn:focus,
  .btn:active {
    outline: none !important;
    box-shadow: none;
  }
`

// @ts-ignore
const SwitchButton = styled(RoundedButton)`
  background-color: white;
  color: var(--main-gray);
  border: none;
`

type TimeType = 'year' | 'month'

const FACalendar: FC = observer(() => {
  const { projectsStore } = useAppContext()
  const today = dayjs()
  const [day, setDay] = useState(today)
  const [timeType, setTimeType] = useState<TimeType>('month')
  const [checkedProjects, setCheckedProjects] = useState(projectsStore.projects)
  const [projectsFetched, setProjectsFetched] = useState(projectsStore.projectsFetched)

  if (projectsStore.projectsFetched && !projectsFetched) {
    setProjectsFetched(true)
    setCheckedProjects(projectsStore.projects)
  }

  const meetings = checkedProjects.flatMap(p => p.meetings)
  const documents = checkedProjects
    .flatMap(p => p.documents)
    .filter(doc => doc.dueDate !== null && doc.status !== 'VALID')

  const toggleCheckProject = (project: DetailedProject) => {
    const removeCheckProject = (projectToRemove: DetailedProject) => {
      setCheckedProjects(checkedProjects.filter(p => p !== projectToRemove))
    }

    const addCheckProject = (projectToAdd: DetailedProject) => {
      setCheckedProjects(checkedProjects.concat([projectToAdd]))
    }

    if (checkedProjects.indexOf(project) === -1) {
      addCheckProject(project)
    } else {
      removeCheckProject(project)
    }
  }

  const isCurrentMonth = day.isSame(today, 'month')

  return (
    <>
      <TitleCard body>
        <H2 arrowColor={'green'} style={{ marginBottom: 0 }}>
          Calendrier
        </H2>
      </TitleCard>
      <Body>
        <CalendarContent>
          <Header>
            <div style={{ width: '30%' }}>
              <RoundedButton
                outline
                style={{ fontWeight: 'bold' }}
                onClick={() => setDay(dayjs())}
                disabled={isCurrentMonth}
              >
                Aujourd'hui
              </RoundedButton>
              <Button color={'link'} onClick={() => setDay(day.add(-1, timeType))}>
                <FontAwesomeIcon icon="chevron-left" color={'var(--main-gray)'} />
              </Button>
              <Button color={'link'} onClick={() => setDay(day.add(1, timeType))}>
                <FontAwesomeIcon icon="chevron-right" color={'var(--main-gray)'} />
              </Button>
              <span style={{ fontWeight: 'bold' }}>
                {day.format(timeType === 'year' ? 'YYYY' : 'MMMM YYYY')}
              </span>
            </div>
            <div>
              {projectsStore.projects.map(p => (
                <FormControlLabel
                  key={`toggle-${p.id}`}
                  control={
                    <Checkbox
                      checked={checkedProjects.indexOf(p) !== -1}
                      onChange={() => toggleCheckProject(p)}
                      value="checkedG"
                      style={{ color: projectsStore.getProjectColor(p.id) }}
                    />
                  }
                  label={<GreyText>{p.name}</GreyText>}
                />
              ))}
            </div>
            <TimeTypeSwitch>
              <SwitchButton
                className={timeType === 'year' ? 'time-type-checked' : ''}
                onClick={() => {
                  setTimeType('year')
                }}
              >
                Année
              </SwitchButton>
              <SwitchButton
                className={timeType === 'month' ? 'time-type-checked' : ''}
                onClick={() => setTimeType('month')}
              >
                Mois
              </SwitchButton>
            </TimeTypeSwitch>
          </Header>
          <hr />
          {timeType === 'month' && (
            <CalendarMonth date={day} meetings={meetings} documents={documents} />
          )}
          {timeType === 'year' && <CalendarYear date={day} meetings={meetings} />}
        </CalendarContent>
        <div style={{ flexGrow: 0 }}>
          <Meetings title={'Mes RDV'} meetings={projectsStore.meetings} />
        </div>
      </Body>
    </>
  )
})

export default FACalendar

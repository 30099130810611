export interface Environment {
  production: boolean
  keycloakUrl: string
  API_URL: string
  SESAM_URL: string
  APP_VERSION: string
}

const getEnv = (): Environment => {
  if (process.env.NODE_ENV !== 'production') {
    return {
      production: false,
      keycloakUrl: `${process.env.REACT_APP_AUTH_URL}`,
      API_URL: `${process.env.REACT_APP_API_URL}`,
      SESAM_URL: `${process.env.REACT_APP_SESAM_URL}`,
      APP_VERSION: `${process.env.REACT_APP_VERSION}`,
    }
  }
  // @ts-ignore
  const env = window.__env
  return {
    production: true,
    keycloakUrl: env.AUTH_URL,
    API_URL: env.API_URL,
    SESAM_URL: env.SESAM_URL,
    APP_VERSION: env.APP_VERSION,
  }
}

export default getEnv()

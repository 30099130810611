import { computed, observable } from 'mobx'
import { DocumentTypes } from '../model/document'
import { AuthStore } from './auth'
import { TypesService } from '../service/typesService'
import { Pact } from '../model/project'

export class DictionaryStore {
  @observable documentTypes: DocumentTypes = []
  @observable pacts: Pact[] = []

  constructor(private typesService: TypesService, private authStore: AuthStore) {
    authStore.onLoggedIn(async () => (this.documentTypes = await typesService.getDocumentTypes()))
    authStore.onLoggedIn(async () => (this.pacts = await typesService.getPacts()))
  }

  @computed get administrativeDocuments(): string[] {
    return this.documentTypes.filter(it => it.category === 'ADMINISTRATIVE').map(it => it.type)
  }
  @computed get projectDocuments(): string[] {
    return this.documentTypes.filter(it => it.category === 'PROJECT').map(it => it.type)
  }
  @computed get prediagDocuments(): string[] {
    return this.documentTypes.filter(it => it.category === 'PREDIAG').map(it => it.type)
  }
  @computed get dynamicDocuments(): string[] {
    return this.documentTypes.filter(it => it.category === 'DYNAMICDOCUMENT').map(it => it.type)
  }
}

import { useState } from 'react'
import produce, { createDraft } from 'immer'

export interface IndexedData<T> {
  index: number
  data: T
}

interface IndexedDataProps<T> {
  nextIndex: number
  indexedData: IndexedData<T>[]
}

export function useIndexedData<T>(
  data: T[],
  createNewData: () => T
): [
  IndexedData<T>[],
  (index: number, data: T) => IndexedData<T>[],
  (index: number, callbackFn?: () => any) => IndexedData<T>[]
] {
  const initialIndexedData = data.map((d, index) => ({ index, data: d }))
  const initialData: IndexedDataProps<T> = {
    indexedData: initialIndexedData,
    nextIndex: initialIndexedData.length,
  }
  const [indexedDataProps, setIndexedDataProps] = useState(initialData)

  const updateData = (index: number, d: T): IndexedData<T>[] => {
    const updatedData = produce(indexedDataProps, draft => {
      const dataIndex = draft.indexedData.findIndex(a => a.index === index)
      draft.indexedData[dataIndex].data = createDraft(d)
      if (dataIndex === draft.indexedData.length - 1) {
        draft.indexedData.push({
          index: indexedDataProps.nextIndex,
          data: createDraft(createNewData()),
        })
        draft.nextIndex = indexedDataProps.nextIndex + 1
      }
    })
    setIndexedDataProps(updatedData)
    return updatedData.indexedData
  }

  const deleteData = (index: number): IndexedData<T>[] => {
    const updatedData = produce(indexedDataProps, draft => {
      draft.indexedData = draft.indexedData.filter(a => a.index !== index)
    })
    setIndexedDataProps(updatedData)
    return updatedData.indexedData
  }

  return [indexedDataProps.indexedData, updateData, deleteData]
}

import React, { FC, useContext, useState } from 'react'
import styled from 'styled-components'
import { Profile } from '../../model/quizz'
import { Checkbox } from '@material-ui/core'
import { Input } from 'reactstrap'
import { Link } from 'react-router-dom'
import { QuizzContext } from './Quizz'
import { useAppContext } from '../../context'
import { useAlert } from 'react-alert'

const ProfileTitle = styled.span`
  font-size: 50px;
  line-height: 50px;
  color: var(--main-red);
  font-weight: 600;
`

const ProfileDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: var(--main-gray);
  font-weight: 400;
  margin-top: 20px;
`
const ButtonSendResult = styled(Link)`
  background-color: var(--main-red);
  color: white;
  border-radius: 4px;

  :hover {
    background-color: var(--main-black-2);
    color: white;
  }
`

const ButtonBackToQuiz = styled(Link)`
  background-color: var(--main-gray-3);
  color: var(--main-gray);
  border-radius: 4px;

  :hover {
    background-color: var(--main-red);
    color: white;
  }
`

const GreyText = styled.span`
  color: var(--main-gray);
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
`

const Bold = styled.span`
  color: var(--main-black);
  font-weight: 600;
`

export const QuizzResult: FC<{ profiles: Profile[] }> = ({ profiles }) => {
  const { quizzService } = useAppContext()
  const { goBackToQuizzes, project, quizzId } = useContext(QuizzContext)
  const alert = useAlert()

  const [sendToAdvisor, setSendToAdvisor] = useState(false)
  const [messageToAdvisor, setMessageToAdvisor] = useState<string | undefined>(undefined)

  const sendResult = () => {
    if (sendToAdvisor) {
      quizzService.result(quizzId, messageToAdvisor).then(
        success => {
          const advisorName =
            project && project.advisor
              ? `${project.advisor.firstName} ${project.advisor.lastName}`
              : 'votre conseiller'
          alert.success(
            `Merci d’avoir répondu à ces questions ! Vos résultats ont bien été transmis à ${advisorName}`
          )
        },
        error => {
          alert.error("Une erreur s'est produite lors de l'envoi des résultats.")
        }
      )
    }
  }

  return (
    <div style={{ width: '60%' }}>
      {profiles.map(p => (
        <>
          <ProfileTitle>{p.label}</ProfileTitle>
          <ProfileDescription>{p.description}</ProfileDescription>
        </>
      ))}

      <div className={'my-3'}>
        <Checkbox
          checked={sendToAdvisor}
          onChange={() => setSendToAdvisor(!sendToAdvisor)}
          value="checkedG"
          style={{ color: 'var(--main-red)' }}
        />
        <GreyText>
          Je souhaite communiquer les résultats de ce quiz à{' '}
          {project && project.advisor
            ? `${project.advisor.firstName} ${project.advisor.lastName}`
            : 'mon conseiller'}
          .
        </GreyText>
      </div>

      {sendToAdvisor && (
        <div className={'my-3'}>
          <Bold>
            Informations complémentaires que vous souhaiteriez porter à la connaissance de{' '}
            {project && project.advisor
              ? `${project.advisor.firstName} ${project.advisor.lastName}`
              : 'votre conseiller'}
            .
          </Bold>
          <Input type="textarea" onChange={ev => setMessageToAdvisor(ev.target.value || '')} />
        </div>
      )}
      <div className={'d-flex flex-row'}>
        <div>
          <ButtonSendResult to="/d" className={'btn'} onClick={sendResult}>
            Terminer
          </ButtonSendResult>
        </div>
        <div className={'mx-3'}>
          <ButtonBackToQuiz className={'btn'} to="/quiz" onClick={goBackToQuizzes}>
            Retour aux quiz
          </ButtonBackToQuiz>
        </div>
      </div>
    </div>
  )
}

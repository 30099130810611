import { ApiProxy } from './APIProxy'
import { DocumentTypes } from '../model/document'
import { Pact } from '../model/project'

export class TypesService {
  constructor(private apiProxy: ApiProxy) {}

  getDocumentTypes: () => Promise<DocumentTypes> = () =>
    this.apiProxy.get<DocumentTypes>('/types/documents/types')

  getPacts: () => Promise<Pact[]> = () => this.apiProxy.get<Pact[]>('/pacts')
}

import { ApiProxy } from './APIProxy'
import {
  Category,
  CategoryId,
  Module,
  Resource,
  ResourceId,
  ThemeId,
  WeightedResource,
} from '../model/resource'

export class ResourceService {
  constructor(private apiProxy: ApiProxy) {}

  getCategories = () => this.apiProxy.get<Category[]>('/resources/categories')
  getDefaultModule = () => this.apiProxy.get<Module | undefined>('/resources/modules/defaultModule')
  getResource = (rid: ResourceId) => this.apiProxy.get<Resource>(`/resources/${rid}`)
  findResourceByTheme = (themeId: ThemeId, categoryId: CategoryId, offset: number) =>
    this.apiProxy.get<WeightedResource[]>(`/resources/findByTheme`, true, {
      themeId,
      categoryId,
      offset,
    })
}

import React, { useCallback, useContext } from 'react'
import 'react-vertical-timeline-component/style.min.css'
import './App.scss'
import Home from './home/Home'
import './config/fontAwesome'
import { observer } from 'mobx-react-lite'
import CookieConsent from 'react-cookie-consent'
import useCookie from './utils/useCookie'
import AppContext from './context'

const App = observer(() => {
  const loadHJ = useCallback(() => {
    // @ts-ignore
    window.loadHotjar()
  }, [])

  const [consent] = useCookie('fa-cookie-consent', null)
  const { profilerService } = useContext(AppContext)

  if (profilerService) {
    profilerService.start()
  }

  if (consent === 'true') {
    loadHJ()
  }

  return (
    <div className="App">
      <CookieConsent
        location={'top'}
        cookieName={'fa-cookie-consent'}
        enableDeclineButton={true}
        declineButtonText={'Je refuse'}
        buttonText={"J'ai compris"}
        containerClasses={'consent-container'}
        buttonClasses={'btn btn-fa consent-button consent-button-accept'}
        declineButtonClasses={'btn btn-fa consent-button consent-button-decline'}
        disableButtonStyles={true}
        onAccept={loadHJ}
      >
        En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de Cookies pour
        réaliser des statistiques de visite. Pour en savoir plus, consultez la page de
        <a
          href={'https://www.franceactive.org/donnees-personnelles/'}
          rel="noopener noreferrer"
          target="_blank"
        >
          {' '}
          Gestion des données personnelles
        </a>
      </CookieConsent>
      <Home />
    </div>
  )
})

export default App

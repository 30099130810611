import React, { FC, useState } from 'react'
import { MementoEntrepreneur, Project } from '../../../../model/project'
import { Button, Card, Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Memento.scss'
import { isEmpty } from '../../../../utils/isEmpty'
import { useAppContext } from '../../../../context'
import { Feature, useHealthCheck } from '../../../../store/healthcheckStore'
import noMemento from '../../../../assets/images/no-memento.png'
import mementoFeature1 from '../../../../assets/images/memento_feature_1.png'
import mementoFeature2 from '../../../../assets/images/memento_feature_2.png'
import mementoIconPdf from '../../../../assets/images/memento_icon_pdf.png'
import { ProjectLivrableDetails } from '../../../../model/livrable-entrepreneur'
import { useAlert } from 'react-alert'
import { Dialog } from '@material-ui/core'

const MementoContent: FC<{
  project: Project
  livrable?: ProjectLivrableDetails
  memento: MementoEntrepreneur
}> = ({ project, livrable, memento }) => {
  const { pdfService } = useAppContext()
  const [generationPending, setGenerationPending] = useState(false)
  const [pdfSuccess, setPdfSuccess] = useState(false)
  const [linkPdf, setLinkPdf] = useState<string>('')
  const [mementoIsUp, setMementoIsUp] = useState<boolean | undefined>(undefined)
  const alert = useAlert()
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  useHealthCheck(Feature.MEMENTO).then((mementoIsUp: boolean) => {
    setMementoIsUp(mementoIsUp)
  })

  const generateMementoV2 = () => {
    if (!generationPending) {
      setGenerationPending(true)
      const pdfPromise = pdfService!.generateMementoV2(project.id, timeZone)
      pdfPromise
        .then(value => {
          setPdfSuccess(true)
          setLinkPdf(value.link)
          window.open(value.link, '_blank')
          setGenerationPending(false)
        })
        .catch(() => {
          alert.error('Une erreur est survenue pendant la génération du Mémento.')
          setGenerationPending(false)
        })
    }
  }

  return (
    <div>
      {mementoIsUp === undefined ? (
        <FontAwesomeIcon icon="spinner" color="var(--main-green)" className="fa-stack-1x" spin />
      ) : !mementoIsUp ? (
        <Card outline body className="Deliverable-Download MementoIsDown">
          <span style={{ fontWeight: 'bold' }}>Télécharger le mémento</span>
          <span className="pdfButton">En maintenance</span>
        </Card>
      ) : (
        <>
          {memento.pdf ? (
            <Col xs={12} sm={12} md={12} style={{ display: 'flex' }}>
              <Col xs={12} sm={8} md={8} className="flex-fill card card-body">
                <Card className="Deliverable-Download">
                  <span>
                    <h3 style={{ fontWeight: 'bold' }}>Qu'est-ce que le Memento ?</h3>
                  </span>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Col xs={12} sm={6} md={6}>
                      <img alt="Conseil" src={mementoFeature1} className="Image-Feature-1" />
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                      <p>
                        <h4 className="TitreFeature">Conseil</h4>
                        <p className="textFeature">
                          Votre conseiller vous adresse les éléments qui lui semblent utiles pour
                          faire avancer votre projet. Vous trouverez en synthèse les sujets que vous
                          avez abordé lors de votre (ou vos) entretien(s).
                        </p>
                      </p>
                    </Col>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <p>
                      <h4 className="TitreFeature">Analyse du projet</h4>
                      <p className="textFeature">
                        Votre conseiller partage avec vous l’analyse globale de votre projet :
                        économique et financière, ainsi que l’engagement qui est le vôtre ! Vous
                        trouverez également vos principaux éléments financiers accompagnés
                        d’explications pédagogiques pour vous y retrouver.
                      </p>
                    </p>
                    <img
                      alt="Analyse du projet"
                      src={mementoFeature2}
                      className="Image-Feature-2"
                    />
                  </div>
                </Card>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <Row style={{ margin: '5px' }}>
                  <Card outline body className="Deliverable-Download MementoPdf">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <img alt="Icon Memento Pdf" src={mementoIconPdf} className="IconMementoPdf" />
                      <p
                        style={{ textAlign: 'center', cursor: 'pointer' }}
                        onClick={() => generateMementoV2()}
                      >
                        {generationPending ? (
                          <FontAwesomeIcon
                            icon="spinner"
                            color="var(--main-white)"
                            style={{ minHeight: '75px' }}
                            spin
                          />
                        ) : (
                          <FontAwesomeIcon className="IconDownloadPdf" icon={'download'} />
                        )}
                        <h4 className="TextDownloadPdf">Télécharger le Memento</h4>
                      </p>
                      <Dialog open={pdfSuccess}>
                        <PdfSuccessDialogContent
                          closeButtonFct={() => setPdfSuccess(false)}
                          linkPdf={linkPdf}
                        />
                      </Dialog>
                    </div>
                  </Card>
                  {livrable && <OldMemento projectId={project.id} alone={false} />}
                </Row>
              </Col>
            </Col>
          ) : (
            <>{livrable && <OldMemento projectId={project.id} alone={true} />}</>
          )}
        </>
      )}
    </div>
  )
}

export const Memento: FC<{ project: Project }> = ({ project }) => {
  const { livrable, memento } = project
  const hasMemento = () => {
    const { presentation, livrable, memento } = project
    return presentation || (livrable && !isEmpty(livrable)) || memento.pdf
  }

  return (
    <div className="Deliverable row">
      {hasMemento() ? (
        <MementoContent project={project} livrable={livrable} memento={memento} />
      ) : (
        <Col xs={12} sm={12} md={12} className="Deliverable-Content Memento">
          <Card body>
            <div style={{ textAlign: 'center' }}>
              <img alt="Vous n'avez pas encore de Mémento" src={noMemento} width="50%" />
              <h1>Vous n'avez pas encore de Mémento</h1>
            </div>
          </Card>
        </Col>
      )}
    </div>
  )
}

export const OldMemento: FC<{ projectId: string; alone: boolean }> = ({ projectId, alone }) => {
  const { pdfService } = useAppContext()
  const [generationPending, setGenerationPending] = useState(false)

  const generateMementoV1 = () => {
    if (!generationPending) {
      setGenerationPending(true)
      const pdfPromise = pdfService!.generateMementoV1(projectId)
      pdfPromise.then(value => {
        window.open(value.link, '_blank')
        setGenerationPending(false)
      })
    }
  }

  return (
    <>
      {alone && (
        <Card outline body className="Deliverable-Download MementoPdf">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <img
              alt="Icon Memento Pdf"
              style={{ maxHeight: '425px' }}
              src={mementoIconPdf}
              className="IconMementoPdf"
            />
            <p
              style={{ textAlign: 'center', cursor: 'pointer' }}
              onClick={() => generateMementoV1()}
            >
              {generationPending ? (
                <FontAwesomeIcon
                  icon="spinner"
                  color="var(--main-white)"
                  style={{ minHeight: '75px' }}
                  spin
                />
              ) : (
                <FontAwesomeIcon className="IconDownloadPdf" icon={'download'} />
              )}
              <h4 className="TextDownloadPdf">Télécharger le Memento</h4>
            </p>
          </div>
        </Card>
      )}
      {!alone && (
        <Card outline body className="Deliverable-Download" style={{ justifyContent: 'center' }}>
          <span>
            {generationPending ? (
              <FontAwesomeIcon
                icon="spinner"
                color="var(--main-green)"
                style={{ minHeight: '50px' }}
                spin
              />
            ) : (
              <Button onClick={() => generateMementoV1()} className="Deliverable-Button">
                Télécharger l'ancienne version
              </Button>
            )}
          </span>
          <p style={{ textAlign: 'center' }}>
            Le Memento s’est enrichi ! Il compte désormais plus de rubriques et permet de vous
            accompagner de façon plus complète. Votre conseiller avait partagé avec vous un Memento
            avant cette amélioration. Vous pouvez toujours le consulter ici.
          </p>
        </Card>
      )}
    </>
  )
}

const PdfSuccessDialogContent: FC<{
  closeButtonFct: () => any
  linkPdf: string
}> = ({ closeButtonFct, linkPdf }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        justifyContent: 'space-between',
        padding: '25px',
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          fontWeight: 600,
          fontSize: '27px',
          lineHeight: '32px',
          textAlign: 'center',
        }}
      >
        Félicitations !
      </div>
      <div
        style={{
          color: 'var(--main-grey-1)',
          fontSize: '16px',
          lineHeight: '26px',
          textAlign: 'center',
        }}
      >
        Votre mémento a bien été généré.
        <br />
        (Si le fichier pdf ne s'ouvre pas automatiquement, retrouvez le{' '}
        <a href={linkPdf} target={'_blank imply noopener; support opener'}>
          ici
        </a>
        )
      </div>
      <div style={{ textAlign: 'center' }}>
        <Button onClick={() => closeButtonFct()} className="Deliverable-Button">
          Retourner à l'accueil
        </Button>
      </div>
    </div>
  )
}

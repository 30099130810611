import { ApiProxy } from './APIProxy'
import dayjs from 'dayjs'

export class ProfilerService {
  constructor(private apiProxy: ApiProxy) {}

  startTime = dayjs()
  endtime = dayjs()

  start() {
    this.startTime = dayjs()
  }

  endCallOf(service: string) {
    this.endtime = dayjs()
    const durationMs = this.endtime.diff(this.startTime)
    this.apiProxy
      .post<any>(`/profiler/${service}/profiling`, { durationMs }, false)
      .catch(error => console.log(error))
  }
}

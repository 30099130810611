import React, { Component } from 'react'
import { Card } from 'reactstrap'
import { H2, H4, H5 } from '../../components/shared/H/H'

class Cgu extends Component {
  render() {
    return (
      <Card body>
        <H2 arrowColor={'green'}>Conditions générales d'utilisation</H2>
        <div style={{ margin: '1rem', textAlign: 'justify' }}>
          <H4 arrowColor={'yellow'}>1. Définitions</H4>
          <ul>
            <li>
              <b>Administrateur : </b>désigne toute personne chargée de procéder à l’analyse et au
              contrôle de l’utilisation des Ressources Informatiques mises à la disposition des
              Utilisateurs sur l’Intranet. L’Administrateur est le service Marketing et Innovation
              de France Active.
            </li>
            <li>
              <b>Compte : </b>moyen de connexion permettant à un Utilisateur d’avoir accès à L’Appli
              by France Active, et sécurisé par un identifiant et un mot de passe.
            </li>
            <li>
              <b>L’Appli by France Active : </b>désigne l’outil en ligne de France Active proposant
              à l’Utilisateur de charger des documents, de retrouver ses rendez-vous avec son
              conseiller, de retrouver un diagnostic de son projet et d’échanger par messagerie avec
              son conseiller.
            </li>
            <li>
              <b>Ressources Informatiques ou Ressources : </b>désignent l’ensemble des fichiers et
              documents, quel qu’en soit l’auteur, ainsi que les services et fonctionnalités mis à
              disposition des Utilisateurs sur L’Appli by France Active.
            </li>
            <li>
              <b>Utilisateur : </b>désigne toute personne physique autorisée à accéder à L’Appli by
              France Active.
            </li>
          </ul>

          <H4 arrowColor={'yellow'} className={'mt-5'}>
            2. Objet
          </H4>
          <p>
            Les présentes conditions générales d'utilisation ont pour objet de préciser les
            conditions d’utilisation de L’Appli by France Active et les droits et obligations des
            Utilisateurs, afin de garantir un usage conforme à la règlementation en vigueur, aux
            objectifs de L’Appli by France Active et aux bonnes pratiques en matière d’accès,
            d’utilisation, de partage et de diffusion des Ressources présentes sur celui-ci.
          </p>

          <H4 arrowColor={'yellow'} className={'mt-5'}>
            3. Autorisation d’accès à L’Appli By France Active
          </H4>
          <p>
            L’accès à L’Appli by France Active par l'Utilisateur ne peut être effectif qu’après
            validation de son Compte et acceptation des présentes conditions générales
            d’utilisation.
          </p>

          <H4 arrowColor={'yellow'} className={'mt-5'}>
            4. Règles d'utilisation et de sécurité
          </H4>
          <H5 arrowColor={'blue'}>Confidentialité</H5>
          <p>L’appli by France Active n’a pas vocation à être publique.</p>
          <p>
            Chaque Utilisateur de L’Appli by France Active dispose d’un Compte Appli by France
            Active qui lui permet d’avoir accès aux Ressources.
          </p>
          <p>
            L’Utilisateur s’engage à se connecter uniquement via son Compte Appli by France Active.
            En conséquence, il s’interdit de s’approprier ou de déchiffrer le mot de passe d’un
            autre Utilisateur.
          </p>
          <p>
            L’Utilisateur s’interdit également de divulguer à quiconque ses codes d’accès à L’Appli
            by France Active (identifiant et mot de passe).
          </p>
          <p>
            Tout constat de violation, tentative de violation ou soupçon de violation de son compte
            personnel doit être signalé à l’Administrateur.
          </p>
          <p>
            L’Utilisateur s’interdit de communiquer à d’autres utilisateurs non autorisés toute
            Ressource identifiée par son auteur comme confidentielle.
          </p>

          <H5 arrowColor={'blue'}>Preservation de l’integrité des ressources informatiques</H5>
          <p>
            L’Utilisateur s’engage à ne pas apporter volontairement des perturbations mettant
            sciemment en péril la sécurité ou le bon fonctionnement de L’Appli by France Active et
            s’engage en particulier à :
          </p>
          <ul>
            <li>ne pas interrompre le fonctionnement normal du serveur,</li>
            <li>
              ne pas développer, installer ou copier des programmes ou logiciels destinés à
              contourner la sécurité, saturer les Ressources et, d’une manière générale, les
              services,
            </li>
            <li>
              ne pas introduire de programmes nuisibles (virus, cheval de Troie, ver,
              logiciels-espions…).
            </li>
          </ul>

          <H5 arrowColor={'blue'}>Contenus et espaces de discussion</H5>
          <p>
            Les espaces de discussion de L’Appli by France Active sont des lieux d’échange et de
            partage d’idées, d’informations et d’expérience, de coopération. Chacun peut s’y
            exprimer librement à condition de respecter certaines règles de bonne conduite. La plus
            grande courtoisie est attendue dans les échanges.
          </p>
          <ul>
            <li style={{ color: 'var(--main-green)' }}>
              <b>Respect des personnes et de l’ordre public</b>
            </li>
            <p>
              L’Utilisateur veille au respect de la personnalité, de l’intimité et de la vie privée
              d’autrui. En conséquence, l’Utilisateur s’engage à ne pas publier de message
              injurieux, provocateur ou diffamatoire ; à respecter les autres Utilisateurs et ne pas
              porter atteinte à leur image ou leur réputation.
            </p>
            <p>
              L’Intranet ne saurait être un vecteur de la provocation et à ce titre, l’Utilisateur
              agit dans le respect de l’ordre public et s’interdit notamment tout encouragement à
              des actes malveillants de quelque nature que ce soit ou contraire à la loi (trouble à
              l’ordre public, incitation au racisme, incitation au terrorisme, incitation au
              suicide…) ou toute diffusion de message à caractère violent de nature à porter
              atteinte à la dignité humaine.
            </p>
            <li style={{ color: 'var(--main-green)' }}>
              <b>Respect de la propriété intellectuelle</b>
            </li>
            <p>
              L’Utilisateur s’engage à ne pas poster dans L’Appli by France Active des fichiers,
              documents, images, photographies ou autres créations protégées par des droits de
              propriété intellectuelle, sans avoir obtenu préalablement l’autorisation écrite des
              titulaires de ces droits.
            </p>
            <p>
              Le manquement aux règles dictées par les présentes conditions générales d’utilisation
              est susceptible d’engager la responsabilité de l’Utilisateur et d’entraîner des
              sanctions disciplinaires, proportionnées à la gravité des faits concernés.
            </p>
          </ul>

          <H4 arrowColor={'yellow'} className={'mt-5'}>
            5. Intervention de l’Administrateur
          </H4>
          <p>
            L’Administrateur de L’Appli By France Active se réserve la possibilité de supprimer tout
            ou partie d’un contenu posté par un Utilisateur et qui ne respecterait pas les
            dispositions des présentes conditions générales d’utilisation.
          </p>
          <p>
            Outre les sanctions pénales prévues par les lois en vigueur, l’Administrateur aura
            également la possibilité, s’il l’estime nécessaire, de clôturer un Compte afin de
            refuser l’accès à L’Appli By France Active à un Utilisateur qui n’aurait pas respecté
            les dispositions des conditions générales d’utilisation.
          </p>
          <p>
            Pour toute question relative aux présentes conditions générales d’utilisation ou
            signalement d’un contenu inapproprié sur L’Appli By France Active vous pouvez contacter
            l’Administrateur par mail à l’adresse suivante jeanlt@franceactive.org
          </p>

          <H4 arrowColor={'yellow'} className={'mt-5'}>
            6. Information des Utilisateurs
          </H4>
          <p>
            Les présentes conditions générales d’utilisation sont mises à la disposition des
            Utilisateurs sur L’Appli By France Active.
          </p>

          <H4 arrowColor={'yellow'} className={'mt-5'}>
            7. Entrée en vigueur
          </H4>
          <p>
            Les présentes conditions générales d’utilisation s’imposent à chaque Utilisateur à
            compter de sa première connexion à L’Appli By France Active.
          </p>
        </div>
      </Card>
    )
  }
}

export default Cgu

import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Card, Row } from 'reactstrap'
import { ContactCard } from './ContactCard'
import './ProjectMessenger.scss'
import { ProjectMessengerData } from './store/ProjectMessengerData'
import { MessageBox } from './Message'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import InfiniteScroll from 'react-infinite-scroller'
import { ProjectMessengerInput } from './ProjectMessengerInput'
import { useAppContext } from '../../context'
import { observer } from 'mobx-react-lite'
import { useIdle } from 'react-use'
import { Redirect } from 'react-router'
import { Feature, useHealthCheck } from '../../store/healthcheckStore'

const sayWhen = (date: Dayjs) => {
  const diff = dayjs()
    .startOf('day')
    .diff(date.startOf('day'), 'day')
  switch (diff) {
    case 0:
      return "Aujourd'hui"
    case 1:
      return 'Hier'
    default:
      return date.format('dddd D MMMM YYYY')
  }
}

export const ProjectMessenger: FC<{ projectMessageData: ProjectMessengerData }> = observer(
  ({ projectMessageData }) => {
    const { authStore, messengerStore } = useAppContext()
    const currentUserId = authStore!.id
    const [messengerIsUp, setMessengerIsUp] = useState<boolean | undefined>(undefined)

    useHealthCheck(Feature.MESSAGE).then(isUp => {
      setMessengerIsUp(isUp)
    })

    const messageContainer = useRef<HTMLDivElement>(null)
    const messageContainerElement = messageContainer.current

    const scrollToBottom = (timeout: number = 0) => {
      setTimeout(() => {
        const element = messageContainer.current
        if (element) {
          element!.scrollTop = element!.scrollHeight
        }
      }, timeout)
    }

    const isIdle = useIdle(30e3)

    useEffect(() => {
      isIdle ? projectMessageData.disableSync() : projectMessageData.enableSync()
      return () => projectMessageData.disableSync()
    }, [projectMessageData, isIdle])

    // Switch de projet
    useLayoutEffect(() => scrollToBottom(), [messengerStore.forCurrentProject])

    // déplacement initial vers la fin
    useLayoutEffect(() => {
      if (messengerStore.forCurrentProject!.firstMessage) {
        return scrollToBottom(200)
      }
    }, [messengerStore.forCurrentProject, messageContainerElement])

    // reception de nouveaux messages quand on est deja en bas du div
    useEffect(() => {
      if (projectMessageData.lastAction === 'Message' && messageContainerElement) {
        const endOfScroll: boolean =
          Math.abs(
            messageContainerElement.offsetHeight +
              messageContainerElement.scrollTop -
              messageContainerElement.scrollHeight
          ) < 110
        if (endOfScroll) {
          scrollToBottom()
        }
      }
    }, [projectMessageData.lastAction, messageContainerElement, projectMessageData.messages.length])

    return (
      <div className="ProjectMessenger">
        {messengerIsUp === undefined ? (
          <FontAwesomeIcon icon="spinner" color="var(--main-green)" className="fa-stack-1x" spin />
        ) : !messengerIsUp ? (
          <Redirect to={'/d'} />
        ) : (
          <>
            <Card body outline className="mb-3 Members">
              <h4>Contacts présents dans cette discussion</h4>
              <Row className="ContactCards">
                <div className="">
                  <Card body outline className="ContactCard">
                    <span className="fa-layers fa-3x fa-fw">
                      <FontAwesomeIcon icon="user-circle" />
                      {/* (US 581) Désactivation du token online */}
                      {/*<FontAwesomeIcon*/}
                      {/*  icon={'circle'}*/}
                      {/*  color={'green'}*/}
                      {/*  transform="shrink-12 right-6 down-6"*/}
                      {/*/>*/}
                    </span>
                    <div className="ContactCard-ContactInfo">
                      <span className="ContactCard-ContactName serif">Vous</span>
                    </div>
                  </Card>
                </div>
                {projectMessageData.otherMembersInChannel.map((m, i) => (
                  <div className="" key={i}>
                    <ContactCard user={m} />
                  </div>
                ))}
              </Row>
            </Card>
            <Card body outline className="flex-grow-2 d-flex flex-column MessengerSection">
              <div
                className="ProjectMessenger-Messages-Container"
                id="messageContainer"
                ref={messageContainer}
              >
                <InfiniteScroll
                  className="InfiniteMessages"
                  loadMore={() => projectMessageData.loadMore()}
                  initialLoad={false}
                  hasMore={!projectMessageData.noMoreToLoad}
                  isReverse={true}
                  loader={
                    <div className="loader" key={0}>
                      <FontAwesomeIcon icon="spinner" spin />
                    </div>
                  }
                  useWindow={false}
                >
                  {projectMessageData.noMessagesAtAll ? (
                    <i className="d-flex justify-content-center">
                      Personne n'a encore envoyé de message
                    </i>
                  ) : (
                    <>
                      {projectMessageData.groupedMessages.map((g, i) => (
                        <div key={i} className={classNames({ oldestUnread: g.oldestUnread })}>
                          {g.oldestUnread && <div className="UnreadDivider">Nouveaux Messages</div>}
                          {g.date && <div className="DayDivider">{sayWhen(g.date)}</div>}
                          <MessageBox {...g} self={g.sender.account.id === currentUserId} />
                        </div>
                      ))}
                    </>
                  )}
                </InfiniteScroll>
              </div>

              <div className="ProjectMessenger-Input">
                <ProjectMessengerInput
                  disabled={!projectMessageData.messengerEnabled}
                  projectId={projectMessageData.id}
                  onNewMessage={text => {
                    projectMessageData.sendMessage({ text })
                    scrollToBottom(100)
                  }}
                />
              </div>
            </Card>
          </>
        )}
      </div>
    )
  }
)

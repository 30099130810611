export interface FeatureFlagLight {
  [key: string]: boolean
}

export enum FeatureKey {
  PROJECT_STATUS_FEATURE = 'project:status:display',
  QUIZZ_FEATURE = 'checkup:client:display',
  MESSENGER_FEATURE = 'messenger:global:enable',
  ACCOUNT_DELETE_FEATURE = 'account:delete:display',
}

import styled from 'styled-components'
import { Input } from 'reactstrap'
import React, { FC, useState } from 'react'

export const FASelect = styled.select`
  max-width: 320px;

  &.invalid {
    border: var(--main-red) 2px solid;
  }
`

export const FAInput = styled(Input)`
  &.invalid {
    border: var(--main-red) 2px solid;
  }
  &.form-check-input {
    position: inherit;
    margin-left: 0;
  }
  &.suffix {
    padding-right: 24px;
  }
`

const RelativeDiv = styled.div`
  position: relative;
`

const AbsoluteSpan = styled.span`
  position: absolute;
  top: 8px;
  right: 10px;
`

export const FANumberInput: FC<{
  inputValue?: string
  suffix?: string
  onChangeValue?: (value: string) => any
  className?: string
  valid?: boolean
  hidden?: boolean
}> = ({ inputValue, suffix, onChangeValue, className, valid = true, hidden = false }) => {
  const format = (input: string) => {
    const formatNonEmptyString = (value: string): string => {
      if (value.endsWith('.')) {
        return `${parseFloat(value.substr(0, value.length - 1)).toLocaleString('fr-FR')},`
      }
      if (value === '-') {
        return value
      }
      return parseFloat(value)
        .toLocaleString('fr-FR')
        .replace('.', ',')
    }

    return input === '' ? '' : formatNonEmptyString(input)
  }

  const sanitize = (input: string) => {
    return input.replace(/[^-^0-9]+/g, '').replace(',', '.')
  }

  const initValue = format(sanitize((inputValue || '') as string))

  const [value, setValue] = useState(initValue)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeValue && onChangeValue(sanitize(e.target.value))
    setValue(format(sanitize(e.target.value)))
  }

  if (!suffix) {
    return (
      <FAInput
        className={`${className} ${valid ? '' : 'invalid'}`}
        value={value}
        onChange={onChange}
        type={'text'}
        hidden={hidden}
      />
    )
  }
  return (
    <RelativeDiv hidden={hidden}>
      <FAInput
        value={value}
        className={`${className} suffix ${valid ? '' : 'invalid'}`}
        onChange={onChange}
        type={'text'}
      />
      <AbsoluteSpan>{suffix}</AbsoluteSpan>
    </RelativeDiv>
  )
}

import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import './DocumentUploader.scss'
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ProjectDocument } from '../../../model/document'
import { action, computed, observable, reaction } from 'mobx'
import { disposeOnUnmount, inject, observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactComponent as Upload } from '../../../assets/images/download.svg'
import { ReactComponent as Trash } from '../../../assets/images/trash.svg'
import { DictionaryStore } from '../../../store/dictionaryStore'
import { MaterialLikeErrors, MaterialLikeInput } from '../MaterialLikeInput/MaterialLikeInput'

export type UMProps = {
  open: boolean
  doc?: ProjectDocument
  toggle: () => void
  sendFiles: (files: File[], docType: string, description?: string) => void
  dictionaryStore?: DictionaryStore
  sending?: boolean
  origin?: string
  docType?: string
  activatePrediagChoice?: boolean
}

type UploadModalErrors = 'DESCRIPTION_REQUIRED' | 'FILES_REQUIRED'

@inject('dictionaryStore')
@observer
export class UploadModal extends Component<UMProps> {
  @observable files: File[] = []
  @observable rejectedFiles: File[] = []
  @observable filesTooHeavy: boolean = false
  @observable tooManyFiles: boolean = false
  @observable dropzoneOpened: boolean = false
  @observable docType: string = 'OTHER'
  @observable description: string = ''
  @observable disableUpload: boolean = false
  @observable errors: UploadModalErrors[] = []

  @disposeOnUnmount
  empty = reaction(
    () => !this.props.open,
    () => {
      this.files = []
      this.disableUpload = false
      this.rejectedFiles = []
      this.filesTooHeavy = false
      this.tooManyFiles = false
      this.dropzoneOpened = false
      this.docType =
        this.props.docType !== null && this.props.docType !== undefined
          ? this.props.docType
          : 'OTHER'
      this.description = ''
    }
  )

  @computed
  private get filesCount() {
    return !!this.files.length
  }

  private alreadyIn = (files: File[], file: File): boolean =>
    !!files.find(f => f.name === file.name && f.size === file.size)

  @action
  private removeFile = (file: File) => {
    this.files = this.files.filter(f => f !== file)
    this.filesTooHeavy = false
    this.tooManyFiles = false
  }

  getFileSizePercent = (fileSize: number) => fileSize / 200000

  private getFilesSize = (files = this.files) =>
    files.map(f => f.size).reduce((acc, s) => acc + s, 0)

  @action
  private onDrop = (acceptedFiles: File[], rejectedFiles: File[]) => {
    let filesTooHeavy = false
    let tooManyFiles = false

    const newFiles: File[] = acceptedFiles.reduce(
      (acc, f) => {
        const files = [...acc, f]
        if (files.length > (this.props.doc ? Math.max(10 - this.props.doc.files.length, 1) : 10)) {
          tooManyFiles = true
        } else if (this.getFilesSize(files) > 20000000) {
          filesTooHeavy = true
        } else if (this.alreadyIn(acc, f)) {
          rejectedFiles.push(f)
        } else {
          return files
        }
        return acc
      },
      [...this.files]
    )

    this.filesTooHeavy = filesTooHeavy
    this.tooManyFiles = tooManyFiles
    this.rejectedFiles = rejectedFiles || []
    this.files = newFiles
  }

  private dropZone = () => (
    <>
      <div className="NewFilesContainer">
        <Dropzone
          disabled={this.tooManyFiles}
          onDrop={(a, r) => this.onDrop(a, r)}
          accept="image/*,.txt,.xls,.xlsx,.ppt,.pptx,.doc,.docx,.xml,.csv,.pdf,.zip,.odt,.ott,.ods,.ots,.odp"
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames('dropzone', {
                  isActive: isDragActive,
                  disabled: this.tooManyFiles,
                })}
              >
                <input {...getInputProps()} />

                <div className="dropzone-content">
                  <span>Glissez vos fichiers ici ou cliquez</span>
                  <FontAwesomeIcon icon="plus-circle" size="2x" />
                </div>
              </div>
            )
          }}
        </Dropzone>
        {this.files.map((f, i) => (
          <div className="File" key={i} title={f.name}>
            <span>{f.name}</span>
            <div className="StatusIndicator" style={{ backgroundColor: 'var(--main-red)' }}>
              <Upload style={{ color: 'white' }} />
            </div>
            <Trash onClick={() => this.removeFile(f)} className="removeFileButton" />
          </div>
        ))}
      </div>
      <div className="size-limit">
        <div className="size-limit-progress-container">
          <div
            className="size-limit-progress"
            style={{ width: `${this.getFileSizePercent(this.getFilesSize())}%` }}
          />
        </div>
        {this.filesCount && <div>{(this.getFilesSize() / 1000000).toFixed(2)} Mo / 20 Mo</div>}
      </div>
      <div className="Alerts">
        {this.tooManyFiles && (
          <Alert color="warning">
            Des fichiers ont été rejetés car il y a trop de fichiers pour ce document (maximum 10)
          </Alert>
        )}
        {this.filesTooHeavy && (
          <Alert color="warning">
            Des fichiers ont été rejetés car le poids total est trop élevé (maximum 20 Mo)
          </Alert>
        )}
        {!!this.rejectedFiles.length && (
          <Alert color="warning">
            Des fichiers ont été rejetés (type non conforme, doublon, ...)
          </Alert>
        )}
      </div>
    </>
  )

  render() {
    return (
      <Modal isOpen={this.props.open} toggle={this.props.toggle} className={'DocumentUploader'}>
        <ModalHeader toggle={this.props.toggle}>
          Envoyer des fichiers{' '}
          {this.props.doc && (
            <>
              pour le document <b>{this.props.doc.description}</b>
            </>
          )}
        </ModalHeader>
        <ModalBody>
          {!this.props.doc && (
            <>
              <div className="d-flex justify-content-between mb-3">
                <div>Type de document :</div>
                <select
                  value={this.docType}
                  style={{ border: 'none', background: 'none' }}
                  onChange={e => (this.docType = e.target.value)}
                  disabled={this.props.origin === 'prediag'}
                >
                  {this.props.dictionaryStore!.documentTypes.map(
                    i =>
                      i.category !== 'DYNAMICDOCUMENT' &&
                      (i.category !== 'PREDIAG' || this.props.activatePrediagChoice === true) && (
                        <option key={i.label} value={i.type}>
                          {i.label}
                        </option>
                      )
                  )}
                </select>
              </div>
              <div className="mb-3">
                <MaterialLikeInput
                  autoFocus
                  hasError={this.errors.includes('DESCRIPTION_REQUIRED')}
                  onBlur={e =>
                    !this.description
                      ? this.errors.push('DESCRIPTION_REQUIRED')
                      : (this.errors = this.errors.filter(e => e !== 'DESCRIPTION_REQUIRED'))
                  }
                  placeholder={'Description'}
                  onChange={e => (this.description = e.target.value)}
                />
                {this.errors.includes('DESCRIPTION_REQUIRED') && (
                  <MaterialLikeErrors>
                    La description du document est obligatoire
                  </MaterialLikeErrors>
                )}
              </div>
            </>
          )}
          {this.dropZone()}
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ backgroundColor: 'var(--main-blue)', border: 0 }}
            disabled={
              !this.files.length ||
              (!this.description.trim() && !this.props.doc) ||
              this.disableUpload
            }
            onClick={() => {
              this.disableUpload = true
              this.props.sendFiles(this.files, this.docType, this.description.trim())
            }}
          >
            {this.props.sending ? (
              <FontAwesomeIcon icon="spinner" spin />
            ) : (
              <>Envoyer les fichiers</>
            )}
          </Button>{' '}
          <Button disabled={this.props.sending} color="secondary" onClick={this.props.toggle}>
            Annuler
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

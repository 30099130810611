import { ProjectId } from '../model/project'
import { ApiProxy } from './APIProxy'

export class PdfService {
  constructor(private apiProxy: ApiProxy) {}

  generateMementoV1 = async (projectId: ProjectId): Promise<{ link: string }> => {
    return this.apiProxy.get(`/project/${projectId}/export`)
  }

  generateMementoV2 = async (projectId: ProjectId, timeZone: string): Promise<{ link: string }> => {
    return this.apiProxy.get(`/project/${projectId}/mementoPdf`, true, { timeZone: `${timeZone}` })
  }
}

import React, { FC, useEffect, useRef, useState } from 'react'
import './Resources.scss'
import { Card } from 'reactstrap'
import { H2 } from '../shared/H/H'
import { Category, Theme } from '../../model/resource'
import { InfiniteRessources } from './InfiniteResources'
import { observer } from 'mobx-react-lite'
import { useAppContext } from '../../context'
import { Redirect } from 'react-router'
import { Feature, useHealthCheck } from '../../store/healthcheckStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OnBoardingResources } from './onboarding/OnBoardingResources'
import { BrowserView } from 'react-device-detect'

const Resources: FC = observer(() => {
  const [oneLine, setOneLine] = useState<boolean>(false)
  const [onBoardingReady, setonBoardingReady] = useState(false)
  const [category, setCategory] = useState<Category>()
  const [theme, setTheme] = useState<Theme>()
  const { resourcesStore } = useAppContext()

  const updateCategory = (c: Category) => {
    setCategory(c)
    setTheme(c.themes[0])
  }

  useEffect(() => {
    const disposer = resourcesStore.onCategoriesLoaded(() =>
      updateCategory(resourcesStore.categories[0])
    )
    return () => disposer()
  }, [resourcesStore])

  const mutableRefObject = useRef<HTMLDivElement>(null)
  const ref = mutableRefObject.current
  useEffect(() => {
    if (ref) {
      setOneLine(
        ![...ref!.children]
          .map(it => it as HTMLDivElement)
          .map(it => it.offsetTop)
          .filter(it => it > 0).length
      )
    }
  }, [ref])

  const [resourceIsUp, setResourceIsUp] = useState<boolean | undefined>(undefined)

  useHealthCheck(Feature.RESSOURCES).then((resourceIsUp: boolean) => {
    setResourceIsUp(resourceIsUp)
    setonBoardingReady(true)
  })

  return (
    <div className="Resources">
      {resourceIsUp === undefined ? (
        <FontAwesomeIcon icon="spinner" color="var(--main-green)" className="fa-stack-1x" spin />
      ) : !resourceIsUp ? (
        <Redirect to={'/d'} />
      ) : (
        <>
          <Card body>
            <div id={'onboardingResources'}>
              <H2 arrowColor={'blue'}>Mon espace ressources</H2>
              <div className={`Categories ${oneLine ? 'oneLine' : ''}`} ref={mutableRefObject}>
                {resourcesStore.categories.map(c => (
                  <div
                    className={`Category ${category === c ? 'active' : ''}`}
                    key={c.id}
                    onClick={() => updateCategory(c)}
                  >
                    {c.name}
                  </div>
                ))}
              </div>
            </div>
            {category && (
              <div className="Themes mt-3">
                {category.themes.map(t => (
                  <div
                    className={`Theme ${theme === t ? 'active' : ''}`}
                    key={t.id}
                    onClick={() => setTheme(t)}
                  >
                    {t.name}
                  </div>
                ))}
              </div>
            )}

            {theme && category && (
              <div className="mt-3">
                <InfiniteRessources theme={theme} category={category} />
              </div>
            )}
          </Card>
          <BrowserView>{onBoardingReady && <OnBoardingResources />}</BrowserView>
        </>
      )}
    </div>
  )
})

export default Resources

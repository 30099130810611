import React, { FC, useContext, useState } from 'react'
import { FACard } from '../shared/CustomStyledComponents/Card'
import styled from 'styled-components'
import { FATextBold, GreyText } from '../shared/CustomStyledComponents/Text'
import {
  areRequiredFieldsFilled,
  Prediag,
  PrediagAnswer,
  PrediagQuestionCondition,
  PrediagUpdate,
} from './PrediagModel'
import { Progress } from 'reactstrap'
import {
  ButtonRoundChevron,
  PrimaryButton,
  SecondaryButton,
} from '../shared/CustomStyledComponents/Buttons'
import { PrediagCategoryComponent } from './PrediagCategoryComponent'
import { PrediagCategoryComponentListQuestions } from './PrediagCategoryComponentListQuestions'
import { useAppContext } from '../../context'
import { PrediagEndingPage, PrediagValidationStatusPage } from './PrediagEndingPage'
import { DetailedProject } from '../../model/project'
import { useAlert } from 'react-alert'
import { Dialog } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'

const Italique = styled(GreyText as any)`
  font-style: italic;
`

export const PrediagProgress = styled(Progress)`
  .progress-bar {
    background: var(--main-violet);
  }
  width: 600px;
  height: 10px;
  line-height: 20px;
`

export const PrediagContext = React.createContext<{
  answer: (prediagAnswer: PrediagAnswer) => any
  answers: PrediagAnswer[]
  filteredQuestions: string[]
  project?: DetailedProject
  home: () => any
}>({
  answer: () => {},
  answers: [],
  filteredQuestions: [],
  home: () => {},
})

export const PrediagDescription: FC<{ prediag: Prediag }> = ({ prediag }) => {
  const { project } = useContext(PrediagContext)

  const preRequis = prediag.preRequis.split(':')
  const preRequisTitle = preRequis[0]
  const preRequisValue = preRequis[1]

  return (
    <>
      {prediag.provenance === 'MUFFIN' && (
        <div className={'d-flex flex-column m-3'}>
          <FATextBold color={'--main-violet'}>Evaluation demandée par votre conseiller</FATextBold>
          <GreyText>
            {project &&
              project.advisor &&
              `${project.advisor.firstName} ${project.advisor.lastName.toUpperCase()}`}
          </GreyText>
        </div>
      )}

      <div className={'d-flex flex-column my-3'}>
        <FATextBold color={'--main-violet'}>Durée approximative</FATextBold>
        <GreyText>{prediag.durationDescription}</GreyText>
      </div>

      <div className={'d-flex flex-column my-3'}>
        <FATextBold color={'--main-violet'}>{preRequisTitle}</FATextBold>
        <GreyText>{preRequisValue}</GreyText>
      </div>

      <div className={'d-flex flex-column my-3'} style={{ maxWidth: '50%' }}>
        <FATextBold color={'--main-violet'}>Objectifs</FATextBold>
        <span>
          <GreyText>{prediag.description}</GreyText>
        </span>
      </div>

      <div className={'d-flex flex-column my-3'}>
        <FATextBold color={'--main-violet'}>Bon à savoir :</FATextBold>
        <GreyText>Vous avez la possibilité de compléter l'évaluation en plusieurs fois</GreyText>
      </div>
    </>
  )
}

export const PrediagComponent: FC<{
  prediag: Prediag
  projectId: string
  answerPrediag: (ans: PrediagUpdate) => any
  deletePrediag: (projectId: string, prediagId: string) => any
  home: () => any
}> = ({ prediag, projectId, answerPrediag, deletePrediag, home }) => {
  const { prediagStore, projectsStore } = useAppContext()

  const project = projectsStore.projects.find(p => p.id === projectId)

  const status = prediag.status

  const categoriesNumber = prediag.categories.length

  const completedStep = prediag.completedStep
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(-1)
  const [supposedToBeValid, setSupposedToBeValid] = useState(true)
  const currentCategory = prediag.categories[currentCategoryIndex]
  const displayEndPage = prediag.status === 'DONE'
  const alert = useAlert()
  const [showPopup, setShowPopup] = useState(false)

  const completedStepPercentage = (completedStep + 1) / prediag.categories.length

  const emptyAnswers: () => PrediagUpdate = () => {
    return {
      prediagId: prediag.id,
      projectId: prediag.projectId,
      answers: [],
      ended: false,
    }
  }
  const [currentAnswers, setCurrentAnswers] = useState(emptyAnswers())
  if (prediag.id && !currentAnswers.prediagId) {
    setCurrentAnswers(emptyAnswers())
  }

  const saveAnswers = () => {
    answerPrediag({ ...currentAnswers })
    alert.success('Données sauvegardées.', { timeout: 2000 })
  }

  const cancelPrediag = () => {
    deletePrediag(projectId, prediag.id)
  }

  const cleanAndGoToNextCategory = () => {
    if (currentCategoryIndex === prediag.categories.length - 1) {
      projectsStore.isPrediagRequired = false
    }
    setCurrentAnswers(emptyAnswers())
    setCurrentCategoryIndex(currentCategoryIndex + 1)
    setSupposedToBeValid(true)
  }

  const validateAndPushAnswersAndGoToNextCategory = (ended: boolean = false) => {
    const valid = areRequiredFieldsFilled(
      currentCategory,
      currentAnswers.answers,
      filteredQuestions
    )
    answerPrediag({ ...currentAnswers, ended, lastStepId: currentCategory.id })
    if (valid) {
      cleanAndGoToNextCategory()
    } else {
      setSupposedToBeValid(false)
    }
  }

  const checkCondition = (condition: PrediagQuestionCondition, answers: PrediagAnswer[]) => {
    const questions = prediag.categories.flatMap(c => {
      return c.questions.concat(c.sousCategories.flatMap(sub => sub.questions))
    })

    const questionId = condition.questionId
    const wantedValue = condition.questionValue

    const waitingAnswer = answers.find(ans => ans.questionId === questionId)
    const question = questions.find(q => q.id === questionId)

    if (waitingAnswer) {
      return waitingAnswer.value === wantedValue
    }
    return question?.answer === wantedValue
  }

  const isHidden = (conditions: PrediagQuestionCondition[], answers: PrediagAnswer[]) => {
    return conditions.filter(c => !checkCondition(c, answers)).length > 0
  }

  const filterConditionnalQuestions = (answers: PrediagAnswer[] = currentAnswers.answers) => {
    return prediag.categories
      .flatMap(c => {
        const fromRootQuestions = c.questions.filter(q => isHidden(q.conditions, answers))
        const fromSubQuestions = c.sousCategories.flatMap(sub =>
          sub.questions.filter(q => isHidden(q.conditions, answers))
        )
        return fromRootQuestions.concat(fromSubQuestions)
      })
      .map(q => q.id)
  }
  const [filteredQuestions, setFilteredQuestions] = useState<string[]>(
    filterConditionnalQuestions()
  )

  const answer = (answer: PrediagAnswer) => {
    let newAnswers = [...currentAnswers.answers].filter(ans => ans.questionId !== answer.questionId)
    newAnswers.push(answer)

    const updatedFilteredQuestions = filterConditionnalQuestions(newAnswers)
    newAnswers = newAnswers.filter(ans => updatedFilteredQuestions.indexOf(ans.questionId) === -1)

    setCurrentAnswers({ ...currentAnswers, answers: newAnswers })
    setFilteredQuestions(updatedFilteredQuestions)
  }

  const start = () => {
    if (!prediag.id) {
      prediagStore.createPrediag(projectId).then(() => setCurrentCategoryIndex(0))
    }
    setCurrentCategoryIndex(completedStep + 1)
  }

  const toggleCancelPrediag = () => {
    setShowPopup(!showPopup)
  }

  return (
    <FACard>
      {currentCategoryIndex === -1 && !displayEndPage && (
        <>
          <FATextBold className={'mb-3'}>{prediag.title}</FATextBold>
          <FATextBold color={'--main-violet'}>Statut</FATextBold>
          <FATextBold>
            {status === 'TODO' && 'A commencer'}
            {status === 'INPROGRESS' && 'A finaliser'}
          </FATextBold>
        </>
      )}
      {currentCategoryIndex > -1 && currentCategoryIndex < categoriesNumber && !displayEndPage && (
        <div className={'d-flex flex-row justify-content-between pr-3'}>
          <FATextBold color={'--main-violet'}>{prediag.title}</FATextBold>
          <Italique>les questions avec un * sont obligatoires à la saisie</Italique>
        </div>
      )}
      {!displayEndPage && (
        <div className={'d-flex flex-row py-2'}>
          <PrediagProgress
            className={'align-self-center'}
            value={prediag.id ? completedStepPercentage * 100 : 0}
          />
          <GreyText className={'mx-3'}>
            {prediag.id ? completedStep + 1 : 0} / {prediag.categories.length} étapes
          </GreyText>
          {currentCategoryIndex !== undefined && currentCategoryIndex > 1 && (
            <ButtonRoundChevron
              type={'angle-double-left'}
              onClick={() => setCurrentCategoryIndex(0)}
            />
          )}
          {currentCategoryIndex !== undefined && currentCategoryIndex > 0 && (
            <ButtonRoundChevron
              type={'chevron-left'}
              onClick={() => setCurrentCategoryIndex(currentCategoryIndex - 1)}
            />
          )}
          {currentCategoryIndex !== undefined &&
            currentCategoryIndex + 1 < prediag.categories.length &&
            currentCategory &&
            currentCategory.answered && (
              <ButtonRoundChevron
                type={'chevron-right'}
                onClick={() => setCurrentCategoryIndex(currentCategoryIndex + 1)}
              />
            )}
        </div>
      )}
      {status === 'INPROGRESS' && currentCategoryIndex === -1 && !displayEndPage && (
        <div className={'d-flex flex-row'}>
          <PrimaryButton color={'--main-violet'} onClick={() => start()}>
            Continuer l'évaluation
          </PrimaryButton>
          &nbsp; &nbsp;
          <SecondaryButton color={'--main-red'} onClick={toggleCancelPrediag}>
            Abandonner
          </SecondaryButton>
        </div>
      )}
      {}

      <PrediagContext.Provider
        value={{
          answer,
          project,
          filteredQuestions,
          home,
          answers: currentAnswers.answers,
        }}
      >
        {currentCategoryIndex === -1 && !displayEndPage && (
          <>
            <PrediagDescription prediag={prediag} />
            <div className={'d-flex>'}>
              {status === 'TODO' && (
                <PrimaryButton color={'--main-violet'} onClick={start} className={'mr-3'}>
                  Commencer l'évaluation
                </PrimaryButton>
              )}
              <SecondaryButton color={'--main-violet'} onClick={home}>
                Retour à l'accueil
              </SecondaryButton>
            </div>
          </>
        )}

        {currentCategoryIndex > -1 && currentCategoryIndex < categoriesNumber && !displayEndPage && (
          <div className={'d-flex flex-column'}>
            {currentCategory.type && currentCategory.type === 'LIST' && (
              <PrediagCategoryComponentListQuestions
                prediagCategory={currentCategory}
                categoryIndex={currentCategoryIndex}
              />
            )}
            {currentCategory.type === undefined ||
              (currentCategory.type !== 'LIST' && (
                <PrediagCategoryComponent
                  prediagCategory={currentCategory}
                  categoryIndex={currentCategoryIndex}
                  supposedToBeValid={supposedToBeValid}
                  projectId={prediag.projectId}
                  prediagId={prediag.id}
                />
              ))}
            <div className={'d-flex justify-content-between'} style={{ width: '80%' }}>
              <SecondaryButton color={'--main-violet'} onClick={saveAnswers}>
                Sauvegarder
              </SecondaryButton>
              <SecondaryButton color={'--main-red'} onClick={toggleCancelPrediag}>
                Abandonner
              </SecondaryButton>

              {currentCategoryIndex + 1 < prediag.categories.length && (
                <PrimaryButton
                  color={'--main-violet'}
                  onClick={() => validateAndPushAnswersAndGoToNextCategory()}
                >
                  Passer à l'étape {currentCategoryIndex + 2}
                </PrimaryButton>
              )}
              {currentCategoryIndex + 1 === prediag.categories.length && (
                <PrimaryButton
                  color={'--main-violet'}
                  onClick={() => validateAndPushAnswersAndGoToNextCategory(true)}
                >
                  Terminer ma déclaration
                </PrimaryButton>
              )}
            </div>
          </div>
        )}
        <Dialog open={showPopup}>
          <DialogTitle id="alert-dialog-title">{'Attention'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div>Souhaitez-vous abandonner votre évaluation ?</div>
              <div>Vous ne pourrez pas revenir en arrière.</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <SecondaryButton
              color={'--main-gray'}
              onClick={() => {
                setShowPopup(false)
              }}
            >
              Annuler
            </SecondaryButton>
            <PrimaryButton color={'--main-violet'} onClick={() => cancelPrediag()}>
              Valider
            </PrimaryButton>
          </DialogActions>
        </Dialog>

        {displayEndPage && currentCategoryIndex === prediag.categories.length && (
          <PrediagEndingPage prediag={prediag} goBackToPrediagHome={home} />
        )}
        {displayEndPage && currentCategoryIndex === -1 && (
          <PrediagValidationStatusPage prediag={prediag} />
        )}
      </PrediagContext.Provider>
    </FACard>
  )
}

import { RxStomp, RxStompState } from '@stomp/rx-stomp'
import { observable, runInAction } from 'mobx'
import environment from '../config/environment'
import { AuthStore } from '../store/auth'
import { UserNotification } from '../model/notifications'
import { ProjectId } from '../model/project'
import { MessengerPayloadType } from '../model/message'

export class StompService {
  client: RxStomp = new RxStomp()

  @observable state: RxStompState = RxStompState.CLOSED

  constructor(private authStore: AuthStore) {
    authStore.onLoggedIn(() => this.init())
  }

  init() {
    this.authStore.onLoggedIn(() => {
      this.configureClient()

      this.client.connectionState$.subscribe(s => runInAction(() => (this.state = s)))

      this.client.activate()
    })
  }

  register(projectId: ProjectId, handler: (payload: MessengerPayloadType) => any) {
    this.client.watch(`/topic/messages.${projectId}`).subscribe(m => handler(JSON.parse(m.body)))
    this.client
      .watch(`/user/topic/messages.${projectId}`)
      .subscribe(m => handler(JSON.parse(m.body)))
  }

  watchNotifications = (handler: (ns: UserNotification[]) => void) => {
    this.client.watch('/user/topic/notifications').subscribe(m => handler(JSON.parse(m.body)))
  }

  private configureClient() {
    this.client.configure({
      brokerURL: this.getBrokerURL(),
      heartbeatIncoming: 0, // Typical value 0 - disabled
      heartbeatOutgoing: 10000, // Typical value 20000 - every 20 seconds
      reconnectDelay: 5000,
      beforeConnect: () => {
        if (this.client) {
          this.client.stompClient.brokerURL = this.getBrokerURL()
        }
      },
      debug: environment.production
        ? () => {}
        : msg => {
            console.log(msg)
          },
    })
  }

  private getBrokerURL = () => `${this.wsURI()}?access_token=${this.authStore.token}`

  private wsURI = () => `${environment.SESAM_URL}/ws/v1.0`
}

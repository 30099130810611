import React, { FC, useState } from 'react'
import './Formation.scss'
import { Card, Col, Row } from 'reactstrap'
import { H4 } from '../../shared/H/H'
import { ResourceC } from '../../resources/cards/resource'
import { observer } from 'mobx-react-lite'
import { useAppContext } from '../../../context'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import { Feature, useHealthCheck } from '../../../store/healthcheckStore'
import { DisplayableResource } from '../../../model/resource'

const SkeletonDescription = styled.div`
  padding: 5px;
`
const SkeletonImage = styled.div`
  margin-top: -4px;
`

const Formation: FC = observer(() => {
  const { resourcesStore } = useAppContext()
  const { defaultModule, defaultThemeResources } = resourcesStore
  const [resourceIsUp, setResourceIsUp] = useState<boolean | undefined>(undefined)

  useHealthCheck(Feature.RESSOURCES).then((resourceIsUp: boolean) => {
    setResourceIsUp(resourceIsUp)
  })

  return (
    <div className="Formation">
      <Card body style={{ height: '100%' }}>
        <H4 arrowColor={'green'}>
          A la une :&nbsp;
          <span
            style={{ color: 'var(--main-green)', fontWeight: 'normal', fontSize: 'smaller' }}
            className="sans"
          >
            {defaultModule && defaultModule!.name}
          </span>
        </H4>
        <Row noGutters>
          {resourceIsUp === undefined ? (
            <>
              {Array.from(Array(4).keys()).map(i => (
                <Col xl="3" lg="4" md="4" sm="12" xs="12" key={i} className="px-1 mb-1">
                  <Card style={{ border: '1px solid #c6c6c6' }}>
                    <SkeletonImage>
                      <Skeleton width={'100%'} height={'100px'} />
                    </SkeletonImage>
                    <SkeletonDescription>
                      <Skeleton width={'100%'} height={'20px'} count={2} />
                      <Skeleton width={'90%'} height={'12px'} count={3} />
                    </SkeletonDescription>
                  </Card>
                </Col>
              ))}
            </>
          ) : !resourceIsUp ? (
            'Service en maintenance'
          ) : defaultModule ? (
            defaultThemeResources.length === defaultModule.preSelectedResources.length ? (
              <>
                {defaultThemeResources.slice(0, 4).map(r => (
                  <ResourceComponent key={r.id} resource={r} />
                ))}
                {defaultThemeResources.slice(4, 8).map(r => (
                  <ResourceComponent key={r.id} resource={r} />
                ))}
              </>
            ) : (
              <>
                {Array.from(Array(4).keys()).map(i => (
                  <Col xl="3" lg="4" md="4" sm="12" xs="12" key={i} className="px-1 mb-1">
                    <Card style={{ border: '1px solid #c6c6c6' }}>
                      <SkeletonImage>
                        <Skeleton width={'100%'} height={'100px'} />
                      </SkeletonImage>
                      <SkeletonDescription>
                        <Skeleton width={'100%'} height={'20px'} count={2} />
                        <Skeleton width={'90%'} height={'12px'} count={3} />
                      </SkeletonDescription>
                    </Card>
                  </Col>
                ))}
              </>
            )
          ) : (
            'Pas encore de ressources'
          )}
        </Row>
      </Card>
    </div>
  )
})

const ResourceComponent: FC<{ resource: DisplayableResource }> = ({ resource }) => {
  const { analyticsService } = useAppContext()
  const addEventClicResource = (resourceId: String, origin: String) => {
    analyticsService.addEventClicResources(resourceId, origin)
  }

  return (
    <Col
      xl="3"
      lg="4"
      md="4"
      sm="12"
      xs="12"
      key={resource.id}
      className="px-1 mb-1"
      onClick={() => addEventClicResource(resource.id, 'HOMEPAGE')}
    >
      <ResourceC resource={resource} />
    </Col>
  )
}

export default Formation

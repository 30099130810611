import React, { FC, useEffect, useRef, useState } from 'react'
import './ChangePassword.scss'
import { ZXCVBNResult, ZXCVBNScore } from 'zxcvbn'
import {
  MaterialLikeErrors,
  MaterialLikeInput,
} from '../../shared/MaterialLikeInput/MaterialLikeInput'
import ZxcvbnTranslations from './ZxcvbnTranslations'
import { notEmpty } from '../../../utils/nullSafe'
import { useAppContext } from '../../../context'
import { useAsync, useAsyncFn, useDebounce } from 'react-use'

type ZxcvbnFn = (password: string) => ZXCVBNResult

type ChangePasswordErrors =
  | 'OLD_REQUIRED'
  | 'NEW_REQUIRED'
  | 'CONFIRM_IS_NOT_SAME'
  | 'NEW_IS_NOT_STRONG_ENOUGH'

export const ChangePassword: FC = () => {
  const { value: zxcvbn } = useAsync<ZxcvbnFn>(
    async () => await import('zxcvbn').then(mod => mod.default),
    []
  )
  const { accountService } = useAppContext()
  const form = useRef<HTMLFormElement>(null)

  const [oldPassword, setOldPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [newPasswordTmp, setNewPasswordTmp] = useState<string>('')

  useDebounce(() => setNewPassword(newPasswordTmp), 200, [newPasswordTmp])

  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [passwordStrength, setPasswordStrength] = useState<ZXCVBNScore>(0)
  const [touched, setTouched] = useState<boolean>(false)

  const [feedback, setFeedback] = useState<string>('')
  const [warnings, setWarnings] = useState<string>('')

  const [errors, setErrors] = useState<ChangePasswordErrors[]>([])

  const isValid = touched && !errors.length

  useEffect(() => {
    if (newPassword != null && zxcvbn) {
      const { score, feedback } = zxcvbn(newPassword)
      setPasswordStrength(score)
      setFeedback(
        feedback.suggestions
          .map(s => ZxcvbnTranslations.find(tr => tr.msgid === s))
          .filter(notEmpty)
          .map(tr => tr.msgstr)
          .join('. ')
      )
      const find = ZxcvbnTranslations.find(tr => tr.msgid === feedback.warning)
      setWarnings(find ? find.msgstr : '')
    }
  }, [newPassword, zxcvbn])

  useEffect(() => {
    const errors: ChangePasswordErrors[] = []
    if (!touched) return
    if (!oldPassword) errors.push('OLD_REQUIRED')
    if (confirmPassword !== newPassword) errors.push('CONFIRM_IS_NOT_SAME')
    if (passwordStrength <= 2) errors.push('NEW_IS_NOT_STRONG_ENOUGH')
    setErrors(errors)
  }, [newPassword, confirmPassword, oldPassword, passwordStrength, touched])

  const [changePwdRequestState, changePwdFn] = useAsyncFn(async () => {
    const res = await accountService.changePwd(oldPassword, newPassword)
    setTouched(false)
    setNewPassword('')
    setOldPassword('')
    setConfirmPassword('')
    form.current && form.current.reset()
    return res
  }, [accountService, oldPassword, newPassword])

  return (
    <div className="ChangePassword">
      <form
        name="change-pwd"
        id="change-pwd"
        className="mb-2 mt-4"
        ref={form}
        onSubmit={e => {
          e.preventDefault()
          isValid && changePwdFn()
        }}
      >
        <MaterialLikeInput
          hasError={errors.includes('OLD_REQUIRED')}
          tabIndex={2}
          id="password"
          placeholder="Mot de passe actuel"
          name="current_password"
          type="password"
          onChange={c => setOldPassword(c.target.value)}
          onFocus={() => setTouched(true)}
          autoComplete="off"
        />
        {errors.includes('OLD_REQUIRED') && (
          <MaterialLikeErrors>Entrez votre mot de passe courant</MaterialLikeErrors>
        )}

        <MaterialLikeInput
          hasError={errors.includes('NEW_IS_NOT_STRONG_ENOUGH')}
          id="new-password"
          placeholder="Nouveau mot de passe"
          disabled={!zxcvbn}
          name="password"
          type="password"
          onFocus={() => setTouched(true)}
          onChange={c => setNewPasswordTmp(c.target.value)}
          replaceBorder={
            !!zxcvbn &&
            touched && (
              <div className={`PasswordStrength is-strength-${passwordStrength}`}>
                <div role="progressbar" className={`PasswordStrength-strength-bar`} />
              </div>
            )
          }
        />
        {errors.includes('NEW_IS_NOT_STRONG_ENOUGH') && (
          <MaterialLikeErrors>
            <p>{feedback}</p>
            <p>{warnings}</p>
          </MaterialLikeErrors>
        )}
        <MaterialLikeInput
          hasError={errors.includes('CONFIRM_IS_NOT_SAME')}
          id="password_confirm"
          placeholder="Confirmez le nouveau mot de passe"
          disabled={!zxcvbn}
          onFocus={() => setTouched(true)}
          name="password_confirm"
          type="password"
          onChange={c => setConfirmPassword(c.target.value)}
        />
        {errors.includes('CONFIRM_IS_NOT_SAME') && (
          <MaterialLikeErrors>Les mots de passe ne sont pas identiques</MaterialLikeErrors>
        )}
        {changePwdRequestState.error ? (
          <div className="error-msg">Echec lors de la modification du mot de passe</div>
        ) : (
          changePwdRequestState.value && (
            <div className="success-msg">Mot de passe modifié avec succès</div>
          )
        )}
        <div className={'btn-container mt-2'}>
          <button
            type="submit"
            className="btn btn-fa"
            disabled={!isValid || changePwdRequestState.loading}
          >
            Changer de mot de passe
          </button>
        </div>
      </form>
    </div>
  )
}

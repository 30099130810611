import React from 'react'
import { GlobalTimeLine, Project, ProjectStage } from '../../../model/project'
import { observer } from 'mobx-react-lite'
import './TimelineProgress.scss'

import styled from 'styled-components'

export const TimelineProgress = observer<{ project: Project; id?: string }>(({ project, id }) => {
  const projectStatus = project.globalTimeLine

  return (
    <div className={'timeline-table'} id={id}>
      <div className={'step'}>
        <div className={'title'}>En accompagnement</div>
        <Progress colorFilling={getFillingColor('INIT', projectStatus)} className={'fill'} />
      </div>

      <div className={'step'}>
        <div className={'title'}>En décision</div>
        <Progress colorFilling={getFillingColor('DECISION', projectStatus)} className={'fill'} />
      </div>

      <div className={'step'}>
        <div className={'title'}>En contractualisation</div>
        <Progress colorFilling={getFillingColor('IN_CONTRACT', projectStatus)} className={'fill'} />
      </div>

      <div className={'step'}>
        <div className={'title'}>Activé</div>
        <Progress colorFilling={getFillingColor('ACTIVATED', projectStatus)} className={'fill'} />
      </div>
    </div>
  )
})

const Progress = styled.div<{ colorFilling: string }>`
  background-color: ${({ colorFilling }) => `${colorFilling} !important`};
`

function getFillingColor(currentStatus: ProjectStage, projectGlobalStatus?: GlobalTimeLine) {
  if (projectGlobalStatus === undefined) {
    return 'Red'
  }

  let state
  if (currentStatus === 'INIT') {
    state = projectGlobalStatus.init
  } else if (currentStatus === 'DECISION') {
    state = projectGlobalStatus.decision
  } else if (currentStatus === 'IN_CONTRACT') {
    state = projectGlobalStatus.inContract
  } else {
    state = projectGlobalStatus.activated
  }

  let color = 'White'
  switch (state) {
    case 'NONE':
      color = 'White'
      break
    case 'IN_PROGRESS':
      color = 'var(--main-yellow-2)'
      break
    case 'DONE':
      color = 'var(--main-green)'
      break
  }
  return color
}

export const TimelineStatic = observer<{ id?: string }>(({ id }) => {
  return (
    <div className={'timeline-table'} id={id}>
      <div className={'step'}>
        <div className={'title'}>Accompagnement</div>
        <Progress colorFilling={'var(--main-green)'} className={'fill'} />
      </div>

      <div className={'step'}>
        <div className={'title'}>Décision</div>
        <Progress colorFilling={'var(--main-green)'} className={'fill'} />
      </div>

      <div className={'step'}>
        <div className={'title'}>Contractualisation</div>
        <Progress colorFilling={'var(--main-green)'} className={'fill'} />
      </div>

      <div className={'step'}>
        <div className={'title'}>Activation</div>
        <Progress colorFilling={'var(--main-green)'} className={'fill'} />
      </div>
    </div>
  )
})

import React, { FC } from 'react'
import './UnknownPage.scss'
import { Link } from 'react-router-dom'

const UnknownPage: FC = () => (
  <div className="UnknownPage">
    <p>Page Introuvable</p>
    <Link to={'/d'}>Retourner sur votre tableau de bord</Link>
  </div>
)

export default UnknownPage

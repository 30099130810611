import { action, computed, observable, reaction, runInAction } from 'mobx'
import { Category, DisplayableResource, Module, ResourceId } from '../../../model/resource'
import { AuthStore } from '../../../store/auth'
import { ResourceService } from '../../../service/resourceService'
import { notEmpty } from '../../../utils/nullSafe'

export class ResourcesStore {
  @observable categories: Category[] = []
  @observable resources: DisplayableResource[] = []
  @observable defaultModule: Module | undefined

  constructor(authStore: AuthStore, private resourceService: ResourceService) {
    authStore.onLoggedIn(() => this.fetchCategories())
    authStore.onLoggedIn(() => this.fetchDefaultTheme())
  }

  onCategoriesLoaded = (onCategoriesLoadedCallback: () => any) =>
    reaction(() => this.categories.length, l => l && onCategoriesLoadedCallback(), {
      fireImmediately: true,
    })

  @action
  async fetchCategories() {
    const categories = await this.resourceService.getCategories()
    runInAction(() => (this.categories = categories))
  }

  @action
  async fetchDefaultTheme() {
    const principalTheme = await this.resourceService.getDefaultModule()
    if (principalTheme) {
      runInAction(() => {
        this.defaultModule = principalTheme
        this.fetchResources(principalTheme.preSelectedResources)
      })
    }
  }

  @computed get defaultThemeResources(): DisplayableResource[] {
    return (
      (this.defaultModule &&
        this.defaultModule.preSelectedResources
          .map(id => this.resources.find(r => r.id === id))
          .filter(notEmpty)) ||
      []
    )
  }

  @action
  async fetchResource(id: ResourceId): Promise<DisplayableResource> {
    const cached: DisplayableResource | undefined = this.resources.find(r => r.id === id)
    if (cached) {
      return cached
    }
    const res = await this.resourceService.getResource(id)
    runInAction(() => this.resources.push(res))
    return res
  }

  fetchResources(rids: ResourceId[]) {
    Promise.all(rids.map(rid => this.fetchResource(rid)))
  }
}

import * as React from 'react'
import './Message.scss'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import { MessageGroup } from './store/ProjectMessengerData'
import { observer } from 'mobx-react-lite'
import { makeDateDaysAndHours } from '../../utils/date'

export interface MessageProps extends MessageGroup {
  self: boolean
}

export const MessageBox = observer<MessageProps>(({ messages, self, sender, unread }) => (
  <div
    className={classNames('MessageBloc', {
      'MessageBloc-Own': self,
      'MessageBloc-Unread': unread && !self,
      'MessageBloc-System': sender.type === 'SYSTEM',
    })}
  >
    {sender.type !== 'SYSTEM' && (
      <div className="d-flex align-items-center align-self-start">
        <FontAwesomeIcon icon="user-circle" size="3x" />
      </div>
    )}
    <div className="messages">
      {!self && (
        <div className="senderName serif">
          {sender.account.firstName} {sender.account.lastName}
        </div>
      )}
      {messages.map(m => (
        <div
          key={m.id}
          className="MessageAndTime"
          style={{ flexDirection: self ? 'row-reverse' : 'row' }}
        >
          <div
            className={classNames('Message', {
              'Message-Own': self,
              'Message-Other': !self && sender.type !== 'SYSTEM',
              'Message-Advisor': sender.type === 'ADVISOR',
              'Message-System': sender.type === 'SYSTEM',
            })}
          >
            {m.text}
          </div>
          <div
            className="Message-Time align-self-center"
            title={makeDateDaysAndHours(m.createdDate)}
          >
            {dayjs(m.createdDate).format('HH:mm')}
          </div>
        </div>
      ))}
    </div>
  </div>
))

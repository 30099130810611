import styled from 'styled-components'
import React, { FC } from 'react'
import { Color } from './Colors'

interface CustomStyleAttributes {
  color?: Color
}

type FAStyleAttributes<T> = CustomStyleAttributes & React.HTMLAttributes<T>

export const FATextDefault: FC<FAStyleAttributes<HTMLSpanElement>> = ({
  color = '--main-gray',
  children,
}) => {
  const FATextDefaultStyle = styled.span`
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: var(${color});
    font-weight: 400;
  `
  return <FATextDefaultStyle>{children}</FATextDefaultStyle>
}

export const FAParagraph = styled.p`
  margin-bottom: 30px;
`

export const FATextMini = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: var(--main-gray);
  font-weight: 400;
`

export const FATextBold: FC<FAStyleAttributes<HTMLSpanElement>> = ({
  color = '--main-black-2',
  children,
}) => {
  const FATextBoldStyle = styled.span`
    font-family: 'Montserrat', sans-serif;
    color: var(${color});
    font-weight: 600;
  `
  return <FATextBoldStyle>{children}</FATextBoldStyle>
}

export const GreyText = styled.span`
  color: var(--main-gray);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`

export const InvalidText = styled.span`
  color: var(--main-red);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`

export const FATextH3: FC<FAStyleAttributes<HTMLSpanElement>> = ({
  color = '--main-black-2',
  children,
}) => {
  const Style = styled.span`
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    line-height: 30px;
    color: var(${color});
    font-weight: 600;
  `
  return <Style>{children}</Style>
}

import React, { FC } from 'react'
import './Dashboard.scss'
import AvancementProjet from './avancementProjet/AvancementProjet'
import Meetings from './meetings/Meetings'
import Formation from './formation/Formation'
import Documents from './documents/Documents'
import { Col } from 'reactstrap'
import { observer } from 'mobx-react-lite'
import { BrowserView, MobileView } from 'react-device-detect'
import AvancementProjetMobile from './avancementProjet/mobile/AvancementProjetMobile'
import { useAppContext } from '../../context'
import { OnboardingDashboard } from './onboarding/OnboardingDashboard'

const Dashboard: FC = observer(() => {
  const { projectsStore } = useAppContext()
  const { accountService } = useAppContext()
  return (
    <div>
      <BrowserView>
        <div className="subDashboard row no-gutters" id="dashboard">
          <Col lg={8} id={'onboardingDashboardTwo'}>
            <AvancementProjet />
          </Col>
          <Col lg={4} id={'onboardingDashboardFour'}>
            <Meetings
              title={'Mes rendez-vous à venir'}
              meetings={projectsStore.newMeetings.slice(0, 4)}
              linkToCalendar={true}
            />
          </Col>
        </div>
        <div className="subDashboard row no-gutters">
          <Col lg={8} id={'onboardingDashboardFive'}>
            <Formation />
          </Col>
          <Col lg={4} id={'onboardingDashboardSix'}>
            <Documents />
          </Col>
        </div>
        <BrowserView>
          {accountService.accountOnBoarding.DASHBOARD !== undefined && (
            <OnboardingDashboard />
          )}
        </BrowserView>
      </BrowserView>
      <MobileView>
        <div className="subDashboard row no-gutters">
          <Col lg={6}>
            <Meetings
              title={'Mes rendez-vous à venir'}
              meetings={projectsStore.newMeetings.slice(0, 4)}
            />
          </Col>
          <Col lg={6}>
            <AvancementProjetMobile />
          </Col>
        </div>
        <div className="subDashboard row no-gutters">
          <Col lg={6}>
            <Documents />
          </Col>
          <Col lg={6}>
            <Formation />
          </Col>
        </div>
      </MobileView>
    </div>
  )
})
export default Dashboard

import React from 'react'
import { Card } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChannelMember, UserType } from '../../model/message'
import './ContactCard.scss'
import { observer } from 'mobx-react-lite'

export interface ContactCardProps {
  user: ChannelMember
}

const renderType = (type: UserType) => {
  switch (type) {
    case 'ENTREPRENEUR':
      return 'Membre'
    case 'ADVISOR':
      return 'France Active'
    case 'SYSTEM':
      return ''
  }
}

export const ContactCard = observer<ContactCardProps>(({ user }) => (
  <Card body outline className="ContactCard">
    <span className="fa-layers fa-3x fa-fw">
      <FontAwesomeIcon icon="user-circle" />
      {/* (US 581) Désactivation du token online */}
      {/*<FontAwesomeIcon*/}
      {/*  icon={'circle'}*/}
      {/*  color={user.online ? 'green' : 'red'}*/}
      {/*  transform="shrink-12 right-6 down-6"*/}
      {/*/>*/}
    </span>

    <div className="ContactCard-ContactInfo">
      <span className="ContactCard-ContactName serif">
        {user.account.firstName} {user.account.lastName}
      </span>
      <span className="ContactCard-ContactType"> {renderType(user.type)} </span>
    </div>
  </Card>
))

// Vu que la version TS de Lodash renvoie un type qui n'est pas exporté (Dictionnary), on redéfini ça ici...
// et on renvoie une Map bien typée
export function groupBy<T, U>(
  array: T[],
  extractor: (t: T) => U,
  base?: Map<U, T[]>
): Map<U, T[]> {
  return array.reduce((map, val) => {
    const key = extractor(val)
    map.set(key, (map.get(key) || []).concat([val]))
    return map
  }, base || new Map<U, T[]>())
}

import React from 'react'
import { UserNotification } from '../../model/notifications'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Notification.scss'
import dayjs from 'dayjs'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ProjectEvent } from '../../model/project'
import { observer } from 'mobx-react-lite'
import { useAppContext } from '../../context'
import { makeDateDaysAndHours } from '../../utils/date'

const getLink = (ev: ProjectEvent) => {
  switch (ev.eventType) {
    case 'DOCUMENT_REQUIRED':
      return `/p/${ev.projectId}#project`
    case 'DOCUMENT_VALIDATION':
      return `/p/${ev.projectId}#project`
    case 'MEMENTO_PUBLISH':
    case 'MEMENTO_UNPUBLISH':
    case 'MEMENTO_UPDATE':
      return `/p/${ev.projectId}#memento`
    case 'MEETING_NEW':
    case 'MEETING_UPDATE':
    case 'MEETING_CANCELLED':
      return `/calendar`
    case 'COMMITTEE_STATUS':
      return `/p/${ev.projectId}#history`
    case 'DELIVERABLE_UPDATE':
      return `/p/${ev.projectId}#memento`
    case 'NOTIFY_PREDIAG_UPDATE':
    case 'NOTIFY_PREDIAG_VALIDATION':
    case 'NOTIFY_PREDIAG_NEEDED':
      return `/prediag/${ev.projectId}`
    default:
      return ''
  }
}

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
const makeDate = (date: string) => capitalize(dayjs(date).fromNow())

const getIcon = (ev: ProjectEvent): IconProp => {
  switch (ev.eventType) {
    case 'DOCUMENT_REQUIRED':
      return 'file-alt'
    case 'DOCUMENT_VALIDATION':
      return 'check'
    case 'MEETING_NEW':
    case 'MEETING_UPDATE':
    case 'MEETING_CANCELLED':
      return 'users'
    case 'MEMENTO_PUBLISH':
    case 'MEMENTO_UNPUBLISH':
    case 'MEMENTO_UPDATE':
      return 'file-alt'
    case 'DELIVERABLE_UPDATE':
      return 'file-alt'
    default:
      return 'circle'
  }
}

export interface NotificationProps {
  notification: UserNotification
  clicked?: () => void
}

const getDescription = (projectEvent: ProjectEvent) => {
  switch (projectEvent.eventType) {
    case 'DOCUMENT_REQUIRED':
      return projectEvent.metadata.onlyDynamicDocs
        ? 'Nouveaux documents transmis'
        : 'Nouvelle demande de documents'
    case 'DOCUMENT_VALIDATION':
      switch (projectEvent.metadata.status) {
        case 'VALIDATED':
          return projectEvent.metadata.docName
            ? `${projectEvent.metadata.docName} a été validé`
            : 'Un document a été validé'
        case 'REFUSED':
          return projectEvent.metadata.docName
            ? `${projectEvent.metadata.docName} a été refusé`
            : 'Un document a été refusé'
        case 'INCOMPLETE':
          return projectEvent.metadata.docName
            ? `${projectEvent.metadata.docName} a été marqué incomplet`
            : 'Un document a été marqué incomplet'
      }
      return 'Des documents ont été validés'
    case 'MEMENTO_PUBLISH':
      return 'Mémento publié'
    case 'MEMENTO_UNPUBLISH':
      return 'Mémento dépublié'
    case 'MEMENTO_UPDATE':
      return 'Mémento mise à jour'
    case 'MEETING_NEW':
      return 'Nouveau rendez-vous'
    case 'MEETING_UPDATE':
      return 'Rendez-vous mis à jour'
    case 'MEETING_CANCELLED':
      return 'Rendez-vous annulé'
    case 'DELIVERABLE_UPDATE':
      return 'Mise à jour du mémento'
    case 'COMMITTEE_STATUS':
      return `Mise à jour du comité du ${makeDateDaysAndHours(projectEvent.metadata.committeeDate)}`
    case 'NOTIFY_PREDIAG_NEEDED':
      return "Demande d'évaluation de la santé de ma structure"
    case 'NOTIFY_PREDIAG_UPDATE':
      return 'Une évaluation de votre structure a été mise à jour'
    case 'NOTIFY_PREDIAG_VALIDATION':
      return 'Une évaluation de votre structure a été validée'
    default:
      return projectEvent.eventType
  }
}

export const Notification = observer<NotificationProps>(({ notification, clicked }) => {
  const { routingStore } = useAppContext()
  return (
    <div
      className="UserNotification"
      onClick={() => {
        routingStore!.push(getLink(notification.projectEvent))
        clicked && clicked()
      }}
    >
      <div className="date">{makeDate(notification.created)}</div>
      <div className="UserNotificationContent">
        <div className="UserNotificationIcon">
          <FontAwesomeIcon icon={getIcon(notification.projectEvent)} size={'lg'} />
        </div>
        <div className="description">{getDescription(notification.projectEvent)}</div>
      </div>
    </div>
  )
})

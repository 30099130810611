import { ApiProxy } from './APIProxy'
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils'
import { action, observable } from 'mobx'
import { AuthStore } from '../store/auth'

export class AccountService {
  constructor(private authStore: AuthStore, private apiProxy: ApiProxy) {
    authStore.onLoggedIn(() => {
      this.fetchPersonalization()
      this.fetchOnBoarding()
    })
  }

  @observable accountPersonalization: { [key: string]: boolean } = {}
  @observable accountOnBoarding: { [key: string]: boolean } = {}

  changePassword(oldPassword: string, newPassword: string): IPromiseBasedObservable<void> {
    return fromPromise(this.changePwd(oldPassword, newPassword))
  }

  changePwd(oldPassword: string, newPassword: string) {
    return this.apiProxy.post<void>('/account/changePassword', { oldPassword, newPassword }, false)
  }

  @action async fetchOnBoarding() {
    const res = await this.apiProxy.get<any>(`/account/onboarding`)
    this.accountOnBoarding = res.onBoardingPages
  }

  @action updateOnBoarding(paramToUpdate: { param: string; checked: boolean }) {
    this.accountOnBoarding[paramToUpdate.param] = paramToUpdate.checked
    return fromPromise(
      this.apiProxy.put<boolean>(
        `/account/onboarding`,
        { onBoardingPages: { [paramToUpdate.param]: paramToUpdate.checked } },
        true
      )
    )
  }

  @action async fetchPersonalization() {
    const res = await this.apiProxy.get<any>(`/account/personalization`)
    this.accountPersonalization = res.notifications
  }

  @action updatePersonalization(paramToUpdate: { param: string; checked: boolean }) {
    this.accountPersonalization[paramToUpdate.param] = paramToUpdate.checked
    return fromPromise(
      this.apiProxy.put<any>(
        `/account/personalization`,
        {
          notifications: { [paramToUpdate.param]: paramToUpdate.checked },
        },
        false
      )
    )
  }

  @action updateAllPersonalization(paramsToUpdate: string[], checked: boolean) {
    paramsToUpdate.forEach(param => (this.accountPersonalization[param] = checked))
    return fromPromise(
      this.apiProxy.put<any>(
        `/account/personalization`,
        {
          notifications: this.accountPersonalization,
        },
        false
      )
    )
  }

  @action async deleteAccount() {
    await this.apiProxy.delete<any>(`/account`)
    this.authStore.logout()
  }
}

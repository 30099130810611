import './H.scss'
import { ReactComponent as Arrow } from '../../../assets/images/arrow.svg'
import React, { HTMLAttributes } from 'react'

export interface HProps extends HTMLAttributes<HTMLHeadElement> {
  arrowColor: 'green' | 'yellow' | 'red' | 'blue' | 'violet'
  text?: string
}

export const H1 = (props: HProps) => (
  <h1 className={`arrowed-h ${props.className || ''}`}>
    <Arrow style={{ color: `var(--main-${props.arrowColor})` }} /> {props.text || props.children}
  </h1>
)
export const H2 = (props: HProps) => (
  <h2 className={`arrowed-h ${props.className || ''}`} style={props.style}>
    <Arrow style={{ color: `var(--main-${props.arrowColor})` }} /> {props.text || props.children}
  </h2>
)
export const H3 = (props: HProps) => (
  <h3 className={`arrowed-h ${props.className || ''}`}>
    <Arrow style={{ color: `var(--main-${props.arrowColor})` }} /> {props.text || props.children}
  </h3>
)
export const H4 = (props: HProps) => (
  <h4 className={`arrowed-h ${props.className || ''}`}>
    <Arrow style={{ color: `var(--main-${props.arrowColor})` }} />
    <div style={{ color: `var(--main-${props.color || 'black-1'}` }}>
      {' '}
      {props.text || props.children}
    </div>
  </h4>
)
export const H5 = (props: HProps) => (
  <h5 className={`arrowed-h ${props.className || ''}`}>
    <Arrow style={{ color: `var(--main-${props.arrowColor})` }} /> {props.text || props.children}
  </h5>
)
export const H6 = (props: HProps) => (
  <h6 className={`arrowed-h ${props.className || ''}`}>
    <Arrow style={{ color: `var(--main-${props.arrowColor})` }} /> {props.text || props.children}
  </h6>
)

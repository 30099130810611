import React, { FC, useState } from 'react'
import styled from 'styled-components'
import dayjs, { Dayjs } from 'dayjs'
import { Meeting } from '../../model/project'
import { GreyText } from './FACalendar'
import { useAppContext } from '../../context'
import { MeetingPopover } from './MeetingPopover'
import { MEETING_LABELS } from '../dashboard/meetings/MeetingC'
import { ProjectDocument } from '../../model/document'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DocumentPopover } from './DocumentPopover'
import { unzonedDayjs } from '../../utils/date'

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2%;
`

const Square = styled.div`
  height: 150px;
  border: 1px solid var(--main-light-gray);
  width: calc(100% / 7);
  padding: 0.5%;
  * span {
    font-size: 0.8em;
  }
  position: relative;
`

const Chip = styled.div`
  color: white;
  background-color: var(--main-green);
  border-radius: 15px;
  width: 25px;
`

const MeetingLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  :hover {
    cursor: pointer;
  }
`

const DocumentLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  :hover {
    cursor: pointer;
  }
`

const MeetingLine: FC<{ meeting: Meeting }> = ({ meeting }) => {
  const { projectsStore } = useAppContext()
  const [popoverOpen, setPopoverOpen] = useState(false)
  const target = `target-${meeting.id}`

  const isInThePast = unzonedDayjs(meeting.date)
    .add(meeting.duration, 'minute')
    .isBefore(dayjs())

  return (
    <>
      <MeetingLineContainer
        id={target}
        onClick={() => setPopoverOpen(true)}
        style={{ display: 'flex', flexDirection: 'row', opacity: isInThePast ? 0.5 : 1 }}
      >
        <Chip
          style={{
            height: '10px',
            width: '10px',
            alignSelf: 'center',
            backgroundColor: projectsStore.getProjectColor(meeting.projectId),
          }}
        />
        &nbsp;
        <GreyText style={{ alignSelf: 'center' }}>
          {unzonedDayjs(meeting.date).format('HH[h]mm')} &nbsp;
          <span style={{ color: 'black', fontWeight: 'bold' }}>{MEETING_LABELS[meeting.type]}</span>
        </GreyText>
      </MeetingLineContainer>
      <MeetingPopover
        target={target}
        popoverOpen={popoverOpen}
        toggle={() => setPopoverOpen(!popoverOpen)}
        meeting={meeting}
      />
    </>
  )
}

const DocumentLine: FC<{ documents: ProjectDocument[] }> = ({ documents }) => {
  const { projectsStore } = useAppContext()
  const [popoverOpen, setPopoverOpen] = useState(false)
  const target = `target-${documents[0].id}`

  const isInThePast = dayjs(documents[0].dueDate).isBefore(dayjs())

  return (
    <>
      <DocumentLineContainer
        id={target}
        onClick={() => setPopoverOpen(true)}
        style={{ display: 'flex', flexDirection: 'row', opacity: isInThePast ? 0.5 : 1 }}
      >
        <FontAwesomeIcon
          icon="file-alt"
          style={{
            alignSelf: 'center',
            color: projectsStore.getProjectColor(documents[0].projectId),
          }}
        />
        &nbsp;
        <GreyText style={{ alignSelf: 'center' }}>
          <span style={{ color: 'black', fontWeight: 'bold' }}>Document(s)</span>
        </GreyText>
      </DocumentLineContainer>
      <DocumentPopover
        target={target}
        popoverOpen={popoverOpen}
        toggle={() => setPopoverOpen(!popoverOpen)}
        documents={documents}
      />
    </>
  )
}

interface DayProps {
  day: Dayjs
  outOfBounds?: boolean
  showDay?: boolean
  meetings?: Meeting[]
  documents?: ProjectDocument[]
}

const Day: FC<DayProps> = ({ day, outOfBounds = false, showDay = false, meetings, documents }) => {
  const today = dayjs()
  const isToday = day.isSame(today, 'day')

  const todayMeetings =
    (meetings && meetings.filter(m => unzonedDayjs(m.date).isSame(day, 'day'))) || []
  const todayDocuments =
    (documents && documents.filter(d => dayjs(d.dueDate).isSame(day, 'day'))) || []

  const todayDocumentsByProjects = new Map<string, ProjectDocument[]>()
  if (todayDocuments !== undefined && todayDocuments.length > 0) {
    todayDocuments.forEach(d => {
      if (d !== null && d !== undefined) {
        if (
          todayDocumentsByProjects.has(d.projectId) &&
          todayDocumentsByProjects.get(d.projectId) !== undefined &&
          todayDocumentsByProjects.get(d.projectId) !== null
        ) {
          // @ts-ignore
          let oldValue: ProjectDocument[] =
            todayDocumentsByProjects.get(d.projectId) === undefined ||
            todayDocumentsByProjects.get(d.projectId) === null
              ? []
              : todayDocumentsByProjects.get(d.projectId)
          if (oldValue === undefined) {
            oldValue = []
          }
          todayDocumentsByProjects.set(d.projectId, oldValue.concat(d))
        } else {
          todayDocumentsByProjects.set(d.projectId, [d])
        }
      }
    })
  }

  const dateDisplay = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {isToday ? (
        <Chip>
          <span>{day.format('D')}</span>
        </Chip>
      ) : (
        <GreyText>{day.format('D')}</GreyText>
      )}
      &nbsp;
      {day.date() === 1 && <GreyText>{day.format('MMM')}</GreyText>}
    </div>
  )

  return (
    <>
      <Square style={outOfBounds ? { backgroundColor: 'var(--main-blue-2)' } : {}}>
        <div style={{ textAlign: 'center' }}>
          {showDay && (
            <>
              <GreyText>{day.format('ddd').toLocaleUpperCase()}</GreyText>
              <br />
            </>
          )}
          {dateDisplay}
        </div>
        <div>{todayMeetings && todayMeetings.map(m => <MeetingLine key={m.id} meeting={m} />)}</div>
        <div>
          {todayDocumentsByProjects !== undefined &&
            todayDocumentsByProjects.size > 0 &&
            Array.from(todayDocumentsByProjects.keys()).map(key => {
              if (
                todayDocumentsByProjects.get(key) !== undefined &&
                todayDocumentsByProjects.get(key) !== null
              ) {
                const documents = todayDocumentsByProjects.get(key)
                  ? todayDocumentsByProjects.get(key)
                  : []
                // @ts-ignore
                return <DocumentLine key={`p_${key}`} documents={documents} />
              }
              return undefined
            })}
        </div>
      </Square>
    </>
  )
}

export const retrieveAllDaysOfMonthFrom = (month: number, firstDay: Dayjs): Dayjs[] => {
  if (firstDay.month() !== month) {
    return []
  }
  return [firstDay].concat(retrieveAllDaysOfMonthFrom(month, firstDay.add(1, 'day')))
}

const retrieveFirstDaysOfMonth = (date: dayjs.Dayjs): dayjs.Dayjs[] => {
  const addToNextFirstDays = (day: Dayjs): Dayjs[] => {
    if (day.day() === 1) {
      return []
    }
    return [day].concat(addToNextFirstDays(day.add(1, 'day')))
  }
  const firstDay = date.date(1)
  return addToNextFirstDays(firstDay)
}

export const CalendarMonth: FC<{
  date: Dayjs
  meetings: Meeting[]
  documents: ProjectDocument[]
}> = ({ date, meetings, documents }) => {
  const firstDayOfMonth = date.date(1)
  const dayOfWeek = (firstDayOfMonth.day() === 0 ? 7 : firstDayOfMonth.day()) - 1

  const lastDaysOfPreviousMonth: DayProps[] = Array(dayOfWeek)
    .fill(0)
    .map((_, i) => firstDayOfMonth.add(-(dayOfWeek - i), 'day'))
    .map(day => {
      return { day, outOfBounds: true }
    })

  const allDaysOfCurrentMonth: DayProps[] = retrieveAllDaysOfMonthFrom(
    firstDayOfMonth.month(),
    firstDayOfMonth
  ).map(day => {
    return { day }
  })

  const firstDaysOfNextMonth: DayProps[] = retrieveFirstDaysOfMonth(date.add(1, 'month')).map(
    day => {
      return { day, outOfBounds: true }
    }
  )

  const allDays: DayProps[] = lastDaysOfPreviousMonth
    .concat(allDaysOfCurrentMonth)
    .concat(firstDaysOfNextMonth)
    .map(d => {
      return {
        ...d,
        meetings,
        documents,
      }
    })

  return (
    <Grid>
      {allDays.map((prop, i) => (
        <Day
          key={prop.day.toISOString()}
          day={prop.day}
          outOfBounds={prop.outOfBounds}
          showDay={i < 7}
          meetings={prop.meetings}
          documents={prop.documents}
        />
      ))}
    </Grid>
  )
}

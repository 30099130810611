import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popover } from 'reactstrap'

export const IconHelp = styled(FontAwesomeIcon)`
  transform: rotate(0) !important;
`

export const ButtonPopover = styled(Button)`
  background-color: var(--main-green);
  border-radius: 8px;
  border: none;
`

export const CustomPopover = styled(Popover)`
  .popover {
    padding: 25px;
    border: none;
    border-radius: 5px;
    width: 525px !important;
    min-width: min-content;
    box-shadow: 0px 0px 100px 2500px rgba(25, 25, 25, 0.6);
    moz-box-shadow: 0px 0px 100px 2500px rgba(25, 25, 25, 0.6);
    webkit-box-shadow: 0px 0px 100px 2500px rgba(25, 25, 25, 0.6);
    z-index: 10;
  }
  .popover-inner {
    min-width: 24vw;
  }
  .arrow::before {
    opacity: 0;
  }
`

// @ts-ignore
export const CustomPopoverMenu = styled(CustomPopover)`
  .popover {
    min-width: 330px;
    padding: 20px;
  }
`

export const LinePopover = styled.span`
  display: flex;
  margin: 0 0 0 5px;
`

// @ts-ignore
export const InnerLinePopover = styled(LinePopover)`
  margin-bottom: auto;
  margin-top: auto;
`

export const TextPopover = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--main-gray);
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const BoldText = styled(TextPopover)`
  font-weight: 600;
`

export const GreenBoldText = styled(BoldText)`
  color: var(--main-green);
  min-width: fit-content;
`

export const YellowBoldText = styled(BoldText)`
  color: var(--main-yellow);
  min-width: fit-content;
`

export const VioletBoldText = styled(BoldText)`
  color: var(--main-violet);
  min-width: fit-content;
`

export const BlueBoldText = styled(BoldText)`
  color: var(--main-blue);
  min-width: fit-content;
`

export const RedBoldText = styled(BoldText)`
  color: var(--main-red);
  min-width: fit-content;
`

export const TitrePopOver = styled(TextPopover)`
  font-size: 24px;
  line-height: 30px;
  color: var(--main-black-2);
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
`

export const Carre = styled.span`
  margin: 15px;
  width: 35px;
  height: 35px;
`

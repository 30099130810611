import React, { FC, useContext } from 'react'
import { DATE_FORMAT, QuestionProps } from './PrediagQuestion'
import { FATextBold, InvalidText } from '../shared/CustomStyledComponents/Text'
import styled from 'styled-components'
import { Table } from 'reactstrap'
import { PrediagContext } from './PrediagComponent'
import { FAIconButton } from '../shared/CustomStyledComponents/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FAInput, FANumberInput } from '../shared/CustomStyledComponents/Inputs'
import { PrediagQuestionHeader } from './PrediagModel'
import { IndexedData, useIndexedData } from '../../utils/IndexedData'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'
import { fr } from 'date-fns/locale'

registerLocale('fr', fr)

const PrediagTable = styled.div`
  border: 3px solid var(--main-blue-2);
  padding: 20px;
  width: 95%;
  table {
    border: 0;
    th {
      background-color: white;
    }
  }
  * td {
    width: 150px;
  }
`

const isRecordEmpty = (record: Record<string, string>) => {
  const keys = Object.entries({ ...record }).map(e => e[0])
  return keys.filter(key => record[key] && record[key] !== '').length === 0
}

export const PrediagQuestionArray: FC<QuestionProps & { description: string }> = ({
  question,
  valid,
  description,
}) => {
  const { answer } = useContext(PrediagContext)

  const buildEmptyLine = () => {
    const obj: Record<string, string> = {}
    question.headers.forEach(h => {
      obj[h.id] = ''
    })
    return obj
  }

  const [indexedValues, updateValues, deleteValue] = useIndexedData<Record<string, string>>(
    question.answers.concat(buildEmptyLine()),
    () => buildEmptyLine()
  )

  const pushAnswers = (values?: IndexedData<Record<string, string>>[]) => {
    const baseValues = values ? values : indexedValues

    const updatedValues = baseValues.map(indexed => indexed.data).filter(rec => !isRecordEmpty(rec))
    answer({ values: updatedValues, questionId: question.id })
  }

  const answerCell = (headerId: string, index: number, value: string) => {
    const updatedValue = { ...indexedValues.find(i => i.index === index)!!.data }
    updatedValue[headerId] = value
    updateValues(index, updatedValue)
    pushAnswers()
  }

  const removeLine = (indexToRemove: number) => {
    const updatedValues = deleteValue(indexToRemove)
    pushAnswers(updatedValues)
  }

  const datepickerCell = (
    h: PrediagQuestionHeader,
    id: number,
    hidden: boolean,
    rawValue?: string
  ) => {
    const dateValue = rawValue ? dayjs(rawValue, DATE_FORMAT).toDate() : null

    const DatePickerContainer = styled.span`
      &.invalid {
        border: var(--main-red) 2px solid;
      }
      &.form-check-input {
        position: inherit;
        margin-left: 0;
      }
      &.suffix {
        padding-right: 24px;
      }
      input {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        color: #495057;
      }
    `

    return (
      <DatePickerContainer hidden={hidden}>
        <DatePicker
          locale={'fr'}
          selected={dateValue}
          dateFormat={'dd/MM/yyyy'}
          onChange={(d: Date | null) => {
            answerCell(h.id, id, d ? dayjs(d).format(DATE_FORMAT) : '')
          }}
        />
      </DatePickerContainer>
    )
  }

  const generateCell = (h: PrediagQuestionHeader, v: Record<string, string>, id: number) => {
    const cellIsValid = valid || !!v[h.id] || !h.required

    const hidden = h.id !== question.headers[0].id && isRecordEmpty(v)

    return (
      <td key={`${h.id}-${id}`}>
        <div className={'d-flex flex-row'}>
          {(h.type === 'FLOAT' || h.type === 'NUMBER') && (
            <FANumberInput
              suffix={h.suffix}
              inputValue={v[h.id]}
              onChangeValue={(e: string) => answerCell(h.id, id, e)}
              valid={cellIsValid}
              hidden={hidden}
            />
          )}
          {h.type === 'DATETIME' && datepickerCell(h, id, hidden, v[h.id])}
          {h.type === 'TEXT' && (
            <FAInput
              value={v[h.id]}
              type={'text'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                answerCell(h.id, id, e.target.value)
              }
              className={`${cellIsValid ? '' : 'invalid'}`}
              hidden={hidden}
            />
          )}
          {!cellIsValid && (
            <div className={'align-self-center px-2'}>
              <FontAwesomeIcon icon={'exclamation-circle'} size={'lg'} color={'var(--main-red)'} />
            </div>
          )}
        </div>
        {!cellIsValid && <InvalidText className={'pt-2'}>Ce champ est obligatoire</InvalidText>}
      </td>
    )
  }

  return (
    <>
      <FATextBold>1. {description}</FATextBold>
      <PrediagTable>
        <Table bordered={false} borderless={true}>
          <thead>
            <tr>
              {question.headers.map(h => (
                <th key={h.id}>
                  <div className={'d-flex flex-row'}>
                    <span>{h.title}</span>
                    {h.required && <span>*</span>}
                  </div>
                </th>
              ))}
              <th />
            </tr>
          </thead>
          <tbody>
            {indexedValues.map((indexedValue, index) => (
              <tr key={indexedValue.index}>
                {question.headers.map(h => generateCell(h, indexedValue.data, indexedValue.index))}
                <td>
                  {index !== indexedValues.length - 1 && (
                    <FAIconButton
                      onClick={() => removeLine(indexedValue.index)}
                      icon={['far', 'trash-alt']}
                      color={'--main-gray-2'}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </PrediagTable>
    </>
  )
}

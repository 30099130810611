import React, { Component } from 'react'
import './Tabs.scss'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import classNames from 'classnames'

export interface TabDefinition<KEY_TYPE> {
  label: string
  hide?: boolean
  disabled?: boolean
  id: KEY_TYPE
  target?: string
  content?: React.ReactNode
}

export type TabsProps<KEY_TYPE> = {
  tabs: TabDefinition<KEY_TYPE>[]
  onSelect: (t: KEY_TYPE) => void
  selected?: KEY_TYPE
}

@observer
export class Tabs<KEY_TYPE> extends Component<TabsProps<KEY_TYPE>> {
  @observable active: KEY_TYPE =
    this.props.selected || this.props.tabs.filter(def => !def.hide)[0].id

  componentDidUpdate(
    prevProps: Readonly<TabsProps<KEY_TYPE>>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    const { selected, tabs, onSelect } = this.props
    if (prevProps.selected !== selected && selected) {
      const tabDefinition = tabs.find(it => it.id === selected)
      if (!tabDefinition || tabDefinition.disabled || tabDefinition.hide) {
        onSelect(this.props.tabs.filter(def => !def.hide)[0].id)
      } else {
        this.active = selected
      }
    }
  }

  render() {
    return (
      <>
        <div className="Tabs mb-3">
          {this.props.tabs
            .filter(def => !def.hide)
            .map(def => (
              <div
                className={classNames('Tab', {
                  'Tab-active': def.id === this.active,
                  'Tab-disabled': def.disabled,
                })}
                key={def.label}
                id={def.target}
                onClick={() => {
                  if (this.active !== def.id && !def.disabled) {
                    this.active = def.id
                    this.props.onSelect(def.id)
                  }
                }}
              >
                {def.label}
              </div>
            ))}
        </div>
        {this.props.tabs.find(def => def.id === this.active)!.content}
      </>
    )
  }
}

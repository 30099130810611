import React, { FC, useContext, useState } from 'react'
import { Quizz, QuizzAnswer, QuizzQuestion } from '../../model/quizz'
import styled from 'styled-components'
import { Card, Progress } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { QuizzResult } from './QuizzResult'
import { QuizzContext } from './Quizz'
import { PrimaryButton } from '../shared/CustomStyledComponents/Buttons'

const QuizzTitle = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
`

const QuestionTitle = styled.span`
  color: var(--main-red);
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
`

const QuestionNumber = styled.span`
  font-size: 20px;
  line-height: 24px;
  color: var(--main-red);
  font-weight: 400;
`

const Answers = styled.div`
  display: flex;
  flex-direction: column;
  .answer-box + .answer-box {
    margin-top: 2%;
  }
`
const AnswerDiv = styled.div`
  width: 700px;
  padding: 20px 20px 20px 20px;
  border: 1px solid var(--main-gray-2);
  display: flex;
  flex-direction: row;
  color: var(--main-gray);
  &.clickable {
    :hover {
      background-color: var(--main-red);
      color: white !important;
      cursor: pointer;
      border: none;
    }
  }
  &.chosen {
    background-color: var(--main-red);
    color: white !important;
    border: none;
  }
`

const AnswerLetter = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  align-self: center;
  font-size: 20px;
  line-height: 24px;
  color: var(--main-black-2);
  font-weight: 600;
  background-color: var(--main-gray-3);
  display: flex;
  justify-content: center;
  margin-right: 20px;
`

const QuizzProgress = styled(Progress)`
  .progress-bar {
    background: var(--main-red);
  }
  width: 600px;
  height: 10px;
  line-height: 20px;
`

const GreyText = styled.span`
  color: var(--main-gray);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`
const QuestionNavigation = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid var(--main-gray-2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
    background-color: var(--main-gray-3);
  }
`

const AnswerText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`

const Answer: FC<{ answer: QuizzAnswer; clickAnswer: () => any }> = ({ answer, clickAnswer }) => {
  const { quizzCompleted } = useContext(QuizzContext)
  const [chosen, setChosen] = useState(answer.chosen)

  const chosenClass = chosen ? 'chosen' : ''

  const clickable = quizzCompleted ? '' : 'clickable'

  const clickHandler = () => {
    if (!quizzCompleted) {
      clickAnswer()
      setChosen(true)
    }
  }

  return (
    <AnswerDiv className={`answer-box ${chosenClass} ${clickable}`} onClick={clickHandler}>
      <AnswerLetter>
        <div style={{ alignSelf: 'center' }}>
          {String.fromCharCode(answer.answerId + 96).toUpperCase()}
        </div>
      </AnswerLetter>
      <AnswerText>{answer.text}</AnswerText>
    </AnswerDiv>
  )
}

const Question: FC<{ quizzQuestion: QuizzQuestion }> = ({ quizzQuestion }) => {
  const { answerQuestion, quizzCompleted } = useContext(QuizzContext)

  return (
    <>
      <div className={'mb-3'} style={{ maxWidth: '700px' }}>
        {quizzQuestion.dimension !== undefined && quizzQuestion.dimension !== null ? (
          <div style={{ marginBottom: '10px', fontWeight: 600, fontSize: '18px' }}>
            Dimension : {quizzQuestion.dimension}
          </div>
        ) : (
          <></>
        )}
        <QuestionNumber>{quizzQuestion.questionId} / </QuestionNumber>
        <QuestionTitle>{quizzQuestion.text}</QuestionTitle>
      </div>
      <Answers>
        {quizzQuestion.answers.map(a => (
          <Answer
            key={`${quizzQuestion.questionId}-${a.answerId}-${a.chosen}`}
            answer={a}
            clickAnswer={() => {
              if (!quizzCompleted) {
                answerQuestion({
                  overrideQuizzId: quizzQuestion.overrideQuizzId,
                  questionId: quizzQuestion.questionId,
                  answerId: a.answerId,
                })
              }
            }}
          />
        ))}
      </Answers>
    </>
  )
}

export const QuizzForm: FC<{ quizz: Quizz }> = ({ quizz }) => {
  const { forceSeeResult } = useContext(QuizzContext)
  const [questionId, setQuestionId] = useState<number | undefined>()
  const quizzCompleted = quizz.profiles.length > 0
  const [showResult, setShowResult] = useState(forceSeeResult)

  const completedQuestions = quizz.questions.filter(q => q.answered)

  const lastCompletedQuestion = completedQuestions.reduce(
    (currentMaxId, question2) =>
      currentMaxId > question2.questionId ? currentMaxId : question2.questionId,
    1
  )

  if (!quizzCompleted && questionId === undefined) {
    setQuestionId(lastCompletedQuestion)
  }
  if (quizzCompleted && questionId === undefined) {
    setQuestionId(1)
  }

  const percentage = questionId !== undefined ? questionId / quizz.questions.length : 0
  const quizzQuestion = quizz.questions.find(q => q.questionId === questionId)!!

  return (
    <Card style={{ width: '100%' }}>
      <Container>
        <div className={'p-3'} style={{ width: 'fit-content' }}>
          <QuizzTitle>{quizz.title}</QuizzTitle>
          {!showResult && (
            <div className={'d-flex flex-row'} style={{ width: 'fit-content' }}>
              <QuizzProgress className={'align-self-center'} value={percentage * 100} />
              <GreyText className={'mx-3'}>
                {questionId} / {quizz.questions.length}
              </GreyText>
              {questionId !== undefined && questionId > 2 && (
                <QuestionNavigation className={'mr-1'} onClick={() => setQuestionId(1)}>
                  <FontAwesomeIcon
                    icon={'angle-double-left'}
                    size={'xs'}
                    color={'var(--main-gray)'}
                  />
                </QuestionNavigation>
              )}
              {questionId !== undefined && questionId > 1 && (
                <QuestionNavigation
                  className={'mr-1'}
                  onClick={() => setQuestionId(questionId - 1)}
                >
                  <FontAwesomeIcon icon={'chevron-left'} size={'xs'} color={'var(--main-gray)'} />
                </QuestionNavigation>
              )}
              {questionId !== undefined &&
                questionId < quizz.questions.length &&
                quizzQuestion &&
                quizzQuestion.answered && (
                  <QuestionNavigation onClick={() => setQuestionId(questionId + 1)}>
                    <FontAwesomeIcon
                      icon={'chevron-right'}
                      size={'xs'}
                      color={'var(--main-gray)'}
                    />
                  </QuestionNavigation>
                )}
            </div>
          )}
        </div>
        {quizzQuestion && (
          <>
            <div className={'p-3 px-5 mx-3 mb-2'} style={{ width: 'fit-content' }}>
              {!showResult && <Question quizzQuestion={quizzQuestion} />}
              {showResult && <QuizzResult profiles={quizz.profiles} />}
            </div>
            <div className={'align-self-end px-3 mx-5 mb-5'}>
              {!showResult && (
                <PrimaryButton
                  color={'--main-red'}
                  onClick={() => {
                    if (
                      !quizzCompleted &&
                      questionId !== undefined &&
                      questionId < quizz.questions.length
                    ) {
                      setQuestionId(questionId + 1)
                    } else {
                      setShowResult(true)
                    }
                  }}
                  disabled={!quizzQuestion.answered}
                >
                  {(quizzCompleted && 'Voir mes résultats') || 'Continuer'}
                </PrimaryButton>
              )}
            </div>
          </>
        )}
      </Container>
    </Card>
  )
}

import { ApiProxy } from './APIProxy'
import { AuthStore } from '../store/auth'

export class AnalyticsService {
  constructor(private apiProxy: ApiProxy, private authStore: AuthStore) {
    this.authStore.onLoggedIn(() => {
      this.addUserVisit().then()
    })
  }

  addEventClicResources = async (resourceId: String, origin: String) => {
    const associationId =
      this.authStore.user?.associationId !== undefined ? this.authStore.user?.associationId : ''
    await this.apiProxy.post('/analytics/resources/addResourceEvent', {
      resourceId,
      associationId,
      origin,
    })
  }

  addUserVisit = async () => {
    await this.apiProxy.post('/analytics/userVisit', {
      userAgentString: navigator?.userAgent ?? 'unkown user-agent',
    })
  }
}

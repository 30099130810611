import React, { Fragment, useState } from 'react'
import { Operation, OperationStatus } from '../../../model/project'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import './Operations.scss'
import './Timeline.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getOperationStatusWeight } from '../../../service/projectService'
import { Collapse, UncontrolledTooltip } from 'reactstrap'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'

export const Operations = observer<{ operations: Operation[]; projectId: string }>(
  ({ operations, projectId }) => {
    const [opened, setOpened] = useState(false)

    let lienSolutionFinancement
    if (opened) {
      lienSolutionFinancement = <span>Masquer les solutions de financement </span>
    } else {
      lienSolutionFinancement = <span>Voir les solutions de financement </span>
    }

    return (
      <div className="Operations">
        <Collapse className="Operations" isOpen={opened}>
          {operations
            .filter(o => !!o.timelineStatus)
            .map(operation => (
              <Fragment key={operation.id}>
                <div className={'title'}>
                  <Link to={`/p/${projectId}#products`} style={{ color: 'var(--main-gray)' }}>
                    {operation.type.description}
                  </Link>
                </div>

                <div className={'timeline-table'}>
                  <div className={'step'}>
                    <Progress
                      colorFilling={calculeFillingColor(operation.timelineStatus, 'INIT')}
                      className={'fill'}
                    />
                  </div>
                  <div className={'step'}>
                    <Progress
                      colorFilling={calculeFillingColor(operation.timelineStatus, 'DECISION')}
                      className={'fill'}
                    />
                  </div>
                  {isInState(operation.timelineStatus, 'DECISION') &&
                    operation.comitteeDate !== undefined &&
                    !isPastDate(operation.comitteeDate) && (
                      <>
                        <UncontrolledTooltip
                          placement="top"
                          target="committeeIconSpan"
                          innerClassName="toolTipInnerStyle"
                          autohide={false}
                        >
                          Date de comité
                          <br /> {dayjs(operation.comitteeDate).format('DD/MM/YYYY')}
                        </UncontrolledTooltip>
                        <span id="committeeIconSpan">
                          <FontAwesomeIcon
                            icon="exclamation-circle"
                            color="#FFBA5C"
                            className="committeeIcon"
                          />
                        </span>
                      </>
                    )}
                  <div className={'step'}>
                    <Progress
                      colorFilling={calculeFillingColor(operation.timelineStatus, 'IN_CONTRACT')}
                      className={'fill'}
                    />
                  </div>
                  <div className={'step'}>
                    <Progress
                      colorFilling={calculeFillingColor(operation.timelineStatus, 'ACTIVATED')}
                      className={'fill'}
                    />
                  </div>
                </div>
              </Fragment>
            ))}
        </Collapse>

        <div className="show-operations " onClick={() => setOpened(!opened)}>
          {lienSolutionFinancement}
          <FontAwesomeIcon
            icon={'angle-right'}
            className={`show-products-link ${opened ? 'toggled' : ''}`}
          />
        </div>
      </div>
    )
  }
)

const Progress = styled.div<{ colorFilling: string }>`
  background-color: ${({ colorFilling }) => `${colorFilling} !important`};
`

function calculeFillingColor(state: OperationStatus, currentStatus: OperationStatus) {
  return isInState(state, currentStatus) ? '#66ABD5' : '#F4F7FC'
}

function isInState(state: OperationStatus, currentStatus: OperationStatus) {
  const operationStatusWeight = getOperationStatusWeight(state)
  const currentStatusWeight = getOperationStatusWeight(currentStatus)

  return currentStatusWeight <= operationStatusWeight
}

function isPastDate(date: string) {
  const dateToCheck = new Date(date)
  const now = new Date()

  return dateToCheck < now
}

import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useAppContext } from '../../../context'
import {
  ButtonPopover,
  CustomPopover,
  LinePopover,
  TextPopover,
  TitrePopOver,
} from '../../shared/CustomStyledComponents/Popover'
import { PopoverBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const OnBoardingQuizz: FC<{}> = observer(() => {
  const { accountService } = useAppContext()
  const [onBoarding, setonBoarding] = useState(!accountService.accountOnBoarding.QUIZZ)

  useEffect(() => setonBoarding(!accountService.accountOnBoarding.QUIZZ), [
    accountService.accountOnBoarding.QUIZZ,
  ])

  return (
    <>
      {onBoarding &&
        document.getElementById('onBoardingQuizz')?.setAttribute('style', 'z-index: 11;')}

      <CustomPopover
        target={'onBoardingQuizz'}
        isOpen={onBoarding}
        toggle={() => setonBoarding(!onBoarding)}
        trigger="classic"
        placement={'top'}
      >
        <PopoverBody>
          <TextPopover style={{ display: 'flex' }}>
            <button
              style={{ marginLeft: 'auto' }}
              className="fa-layers fa-fw fa-lg btn-naked"
              onClick={() => {
                setonBoarding(false)
                accountService.updateOnBoarding({ param: 'QUIZZ', checked: true })
                document.getElementById('onBoardingQuizz')?.setAttribute('style', 'z-index: 0')
              }}
            >
              <FontAwesomeIcon size="lg" icon="times" color={'var(--main-gray-2)'} />
            </button>
          </TextPopover>
          <TitrePopOver>Evaluez-vous !</TitrePopOver>
          <div>
            <LinePopover>
              <TextPopover>Remplissez des quiz et découvrez votre profil</TextPopover>
            </LinePopover>
          </div>
          <LinePopover>
            <div style={{ marginLeft: 'auto' }}>
              <ButtonPopover
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  setonBoarding(false)
                  accountService.updateOnBoarding({ param: 'QUIZZ', checked: true })
                  document.getElementById('onBoardingQuizz')?.setAttribute('style', 'z-index: 0')
                }}
              >
                Terminer
              </ButtonPopover>
            </div>
          </LinePopover>
        </PopoverBody>
      </CustomPopover>
    </>
  )
})

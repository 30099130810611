export interface ZxcvbnTranslation {
  msgid: string
  msgstr: string
}

const ZxcvbnTranslations: ZxcvbnTranslation[] = [
  {
    msgid: 'Use a few words, avoid common phrases',
    msgstr: 'Utilisez quelques mots, évitez les mots souvent utilisés ensemble',
  },
  {
    msgid: 'No need for symbols, digits, or uppercase letters',
    msgstr: 'Pas besoin de symboles, nombres, ou lettres majuscules',
  },
  {
    msgid: 'Add another word or two. Uncommon words are better.',
    msgstr: 'Ajoutez un ou deux autres mots. Les mots les moins courants sont les meilleurs.',
  },
  {
    msgid: 'Straight rows of keys are easy to guess',
    msgstr: 'Les alignements de touches sont faciles à deviner',
  },
  {
    msgid: 'Short keyboard patterns are easy to guess',
    msgstr: "Les motifs sur le clavier sont faciles à deviner s'ils sont courts",
  },
  {
    msgid: 'Use a longer keyboard pattern with more turns',
    msgstr: 'Utilisez un motif plus long sur le clavier, avec plus de changement de direction',
  },
  {
    msgid: 'Repeats like aaa are easy to guess',
    msgstr: 'Les répétitions du type "aaa" sont faciles à deviner',
  },
  {
    msgid: 'Repeats like "abcabcabc" are only slightly harder to guess than "abc"',
    msgstr: 'Les répétitions du type "abcabcabc" sont à peine plus dures à deviner que "abc"',
  },
  {
    msgid: 'Avoid repeated words and characters',
    msgstr: 'Évitez de répéter des mots ou des caractères',
  },
  {
    msgid: 'Sequences like abc or 6543 are easy to guess',
    msgstr: 'Les séquences du type "abc" ou "6543" sont faciles à deviner',
  },
  {
    msgid: 'Avoid sequences',
    msgstr: 'Évitez les séquences',
  },
  {
    msgid: 'Recent years are easy to guess',
    msgstr: 'Les années récentes sont faciles à deviner',
  },
  {
    msgid: 'Avoid recent years',
    msgstr: 'Évitez les années récentes',
  },
  {
    msgid: 'Avoid years that are associated with you',
    msgstr: 'Évitez les années associés aux événements de votre vie',
  },
  {
    msgid: 'Dates are often easy to guess',
    msgstr: 'Les dates sont souvent faciles à deviner',
  },
  {
    msgid: 'Avoid dates and years that are associated with you',
    msgstr: 'Évitez les dates et les années associées aux événements de votre vie',
  },
  {
    msgid: 'This is a top-10 common password',
    msgstr: "C'est l'un des 10 mot de passe les plus courants",
  },
  {
    msgid: 'This is a top-100 common password',
    msgstr: "C'est l'un des 100 mot de passe les plus courants",
  },
  {
    msgid: 'This is a very common password',
    msgstr: "C'est un mot de passe très courant",
  },
  {
    msgid: 'This is similar to a commonly used password',
    msgstr: "C'est proche d'un mot de passe très courant",
  },
  {
    msgid: 'A word by itself is easy to guess',
    msgstr: 'Un mot isolé est facile à deviner',
  },
  {
    msgid: 'Names and surnames by themselves are easy to guess',
    msgstr: 'Les noms et prénoms, pris de manière isolés, sont faciles à deviner',
  },
  {
    msgid: 'Common names and surnames are easy to guess',
    msgstr: ' Les noms et prénoms courants sont faciles à deviner',
  },
  {
    msgid: "Capitalization doesn't help very much",
    msgstr: "Utiliser des majuscules n'aide pas beaucoup",
  },
  {
    msgid: 'All-uppercase is almost as easy to guess as all-lowercase',
    msgstr: 'Tout-en-majuscule est à peine plus dur à deviner que tout-en-minuscule',
  },
  {
    msgid: "Reversed words aren't much harder to guess",
    msgstr: "Un mot écrit à l'envers n'est pas beaucoup plus dur à deviner",
  },
  {
    msgid: "Predictable substitutions like '@' instead of 'a' don't help very much",
    msgstr: 'Les substitutions prévisibles comme "@" à la place de "a" n\'aident pas beaucoup.',
  },
  {
    msgid: 'less than a second',
    msgstr: "moins d'une seconde",
  },
  {
    msgid: 'centuries',
    msgstr: 'siècles',
  },
  {
    msgid: 'seconds',
    msgstr: 'secondes',
  },
  {
    msgid: 'minutes',
    msgstr: 'minutes',
  },
  {
    msgid: 'hours',
    msgstr: 'heures',
  },
  {
    msgid: 'days',
    msgstr: 'jours',
  },
  {
    msgid: 'months',
    msgstr: 'mois',
  },
  {
    msgid: 'years',
    msgstr: 'années',
  },
  {
    msgid: 'second',
    msgstr: 'seconde',
  },
  {
    msgid: 'minute',
    msgstr: 'minute',
  },
  {
    msgid: 'hour',
    msgstr: 'heure',
  },
  {
    msgid: 'day',
    msgstr: 'jour',
  },
  {
    msgid: 'month',
    msgstr: 'mois',
  },
  {
    msgid: 'year',
    msgstr: 'année',
  },
  {
    msgid: 'Your password is too guessable :',
    msgstr: 'Votre mot de passe est trop facile à deviner :',
  },
  {
    msgid: 'It would take an offline attacker %(time)s to guess it.',
    msgstr: 'Le deviner prendrait %(time)s à un attaquant qui aurait copié notre base de données.',
  },
  {
    msgid: 'Warning',
    msgstr: 'Attention',
  },
  {
    msgid: 'Advice',
    msgstr: 'Conseil',
  },
  {
    msgid: 'We expect',
    msgstr: 'Nous',
  },
  {
    msgid: 'nothing: you can use any password you want.',
    msgstr: "n’exigeons rien : vous pouvez utiliser n'importe quel mot de passe.",
  },
  {
    msgid: 'a password that cannot be guessed',
    msgstr: 'demandons un mot de passe qui ne peut pas être deviné',
  },
  {
    msgid: 'by your familly or friends.',
    msgstr: 'par votre famille ou vos amis.',
  },
  {
    msgid: 'by attackers online.',
    msgstr: 'par des attaquants sur internet.',
  },
  {
    msgid: 'without access to our database.',
    msgstr: "à moins d'avoir accès à notre base de données.",
  },
  {
    msgid: 'without a dedicated team and an access to our database.',
    msgstr: "à moins d'avoir une équipe dédiée et un accès à notre base de données.",
  },
  {
    msgid: 'There is no specific rule for a great password,',
    msgstr: "Il n'y a pas de règle absolue pour un bon mot de passe,",
  },
  {
    msgid: 'however if your password is too easy to guess,',
    msgstr: 'cependant si votre mot de passe est trop facile à deviner,',
  },
  {
    msgid: 'we will tell you how to make a better one.',
    msgstr: "nous vous dirons comment l'améliorer.",
  },
]
export default ZxcvbnTranslations

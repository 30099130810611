import React, { FC, useLayoutEffect, useState } from 'react'
import { H2 } from '../shared/H/H'
import { FACard, FATitleCard } from '../shared/CustomStyledComponents/Card'
import styled from 'styled-components'
import { useAppContext } from '../../context'
import { Tabs } from '../shared/Tabs/Tabs'
import { observer } from 'mobx-react'
import { PrediagComponent } from './PrediagComponent'
import { Prediag, PrediagUpdate } from './PrediagModel'
import { SecondaryButton } from '../shared/CustomStyledComponents/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FATextBold, FATextDefault, FATextH3 } from '../shared/CustomStyledComponents/Text'
import PrediagIcon from '../../assets/images/prediag-icon.svg'
import dayjs from 'dayjs'
import { OnBoardingPrediag } from './onboarding/OnBoardingPrediag'
import { NavLink } from 'react-router-dom'
import { BrowserView } from 'react-device-detect'

const PrediagTabs = styled.div`
  padding-top: 20px;

  .Tabs *.Tab.Tab-active {
    background-color: var(--main-violet) !important;
  }

  .Tabs *.Tab {
    border-bottom: solid 2px var(--main-violet) !important;
  }
`

export const PrediagPage: FC = observer(() => {
  const { prediagStore, routingStore, projectsStore } = useAppContext()

  const projectFromUrl =
    new URLSearchParams(routingStore.location.search).get('project') ?? undefined

  const tabs = prediagStore.projectPrediags.map(holder => {
    return {
      label: holder.projectName,
      id: holder.projectId,
    }
  })
  let selectedProject = projectFromUrl
  if (selectedProject === undefined && tabs.length > 0) {
    selectedProject = prediagStore.projectPrediags[0].projectId
  }
  const projectPrediags = prediagStore.retrieveProjectPrediagsSortedForProject(selectedProject)
  const [selectedPrediag, setSelectedPrediag] = useState<Prediag | undefined>(undefined)

  const noPrediagInProgress =
    projectPrediags?.holders?.every(h => h.prediag.status === 'DONE') ?? false

  const createPrediag = () => {
    prediagStore.createPrediag(projectPrediags!!.projectId).then(setSelectedPrediag)
  }

  const answerPrediag = (prediagUpdate: PrediagUpdate) => {
    prediagStore.answerPrediag(prediagUpdate).then(setSelectedPrediag)
  }

  const deletePrediag = (projectId: string, prediagId: string) => {
    prediagStore.deletePrediag(projectId, prediagId).then(() => setSelectedPrediag(undefined))
  }

  const home = () => {
    setSelectedPrediag(undefined)
  }

  return (
    <>
      <FATitleCard body>
        <H2 arrowColor={'violet'} style={{ marginBottom: 0 }}>
          En activité : ma situation
        </H2>
        {tabs.length > 0 && (
          <PrediagTabs>
            <Tabs
              selected={selectedProject}
              tabs={tabs}
              onSelect={t => routingStore.replace(`?project=${t}`)}
            />
          </PrediagTabs>
        )}
      </FATitleCard>

      {selectedProject && !projectsStore.project(selectedProject)?.structure && (
        <FACard className={'d-flex flex-wrap'}>
          <div style={{ padding: '0 10px', marginBottom: '10px' }}>
            Vous ne pouvez pas saisir le questionnaire car aucune structure n'est rattachée à votre
            projet.
            <br />
            Contactez{' '}
            <NavLink to={'/m'} replace={routingStore!.location.pathname.startsWith('/m')}>
              {projectsStore.project(selectedProject)?.advisor?.firstName}{' '}
              {projectsStore.project(selectedProject)?.advisor?.lastName}
            </NavLink>{' '}
            pour plus d’informations
          </div>
        </FACard>
      )}

      {selectedProject &&
        projectsStore.project(selectedProject)?.structure &&
        projectPrediags &&
        selectedPrediag === undefined &&
        (projectsStore.project(selectedProject)?.prediagAllowed === true ? (
          <div className={'d-flex flex-wrap'} style={{ margin: '0 -10px' }}>
            {projectPrediags?.holders.map((holder, index) => (
              <div
                key={holder.prediag.id}
                style={{ padding: '0 10px', marginBottom: '10px' }}
                id={index === 0 ? 'onBoardingPrediag' : ''}
              >
                <PrediagCard
                  index={index}
                  prediag={holder.prediag}
                  onClick={() => setSelectedPrediag(holder.prediag)}
                  creationDate={holder.prediagCreationDate}
                />
              </div>
            ))}

            {noPrediagInProgress && (
              <div style={{ padding: '0 10px' }}>
                <CreatePrediagCard
                  onClick={createPrediag}
                  first={projectPrediags.holders.length === 0}
                />
              </div>
            )}
          </div>
        ) : (
          <FACard className={'d-flex flex-wrap'}>
            <div style={{ padding: '0 10px', marginBottom: '10px' }}>
              <div style={{ marginBottom: '12px' }}>
                Des travaux d'amélioration nous empêchent actuellement d'afficher le questionnaire
                sur la situation de votre structure. Cette fonctionnalité sera très bientôt à
                nouveau disponible.
              </div>
              <div>Merci de votre patience !</div>
            </div>
          </FACard>
        ))}

      {selectedPrediag &&
        projectPrediags &&
        (projectsStore.project(projectPrediags.projectId)?.prediagAllowed === true ? (
          <PrediagComponent
            prediag={selectedPrediag}
            projectId={projectPrediags.projectId}
            answerPrediag={answerPrediag}
            deletePrediag={deletePrediag}
            home={home}
          />
        ) : (
          <FACard className={'d-flex flex-wrap'}>
            <div style={{ padding: '0 10px', marginBottom: '10px' }}>
              <div style={{ marginBottom: '12px' }}>
                Des travaux d'amélioration nous empêchent actuellement d'afficher le questionnaire
                sur la situation de votre structure. Cette fonctionnalité sera très bientôt à
                nouveau disponible.
              </div>
              <div>Merci de votre patience !</div>
            </div>
          </FACard>
        ))}
    </>
  )
})

const PrediagDiv = styled.div`
  width: 285px;
  height: 385px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-width: 285px;
  min-height: 385px;
  position: relative;
  background-color: white;
  padding: 20px;
  text-align: center;

  * div {
    text-align: center;
  }

  background-image: url(${PrediagIcon}); // TODO en attente du svg
  background-repeat: no-repeat;
  background-size: cover;
`

const SeeAnswers = styled(SecondaryButton as any)`
  font-size: 32px !important;
  color: var(--main-white) !important;
  background-color: var(--main-gray) !important;
`

const ClickableDiv = styled.div`
  :hover {
    cursor: pointer;
  }
`

const PrediagCard: FC<{
  prediag: Prediag
  creationDate: string
  onClick: () => any
  index: number
}> = ({ prediag, creationDate, onClick, index }) => {
  const date = dayjs(creationDate).format('D MMMM YYYY')
  const [onMount, setonMount] = useState(false)
  const { accountService } = useAppContext()

  useLayoutEffect(() => setonMount(true), [accountService.accountOnBoarding])

  return (
    <PrediagDiv>
      <FATextBold color={'--main-violet'}>{prediag.title}</FATextBold>
      <FATextH3 color={'--main-violet'}>{date}</FATextH3>
      <ClickableDiv className={'d-flex flex-column'} onClick={onClick}>
        <div>
          <SeeAnswers color={'--main-violet'}>
            <FontAwesomeIcon
              icon={prediag.status === 'DONE' ? 'eye' : 'play'}
              size={'lg'}
              style={{ paddingBottom: '3px', lineHeight: '10px' }}
            />
          </SeeAnswers>
        </div>
        {prediag.status === 'DONE' && <FATextDefault>Revoir mes réponses</FATextDefault>}
        {prediag.status === 'INPROGRESS' && <FATextDefault>Continuer l'évaluation</FATextDefault>}
        {prediag.status === 'TODO' && <FATextDefault>Commencer l'évaluation</FATextDefault>}
      </ClickableDiv>
      <BrowserView>{onMount && index === 0 && <OnBoardingPrediag />}</BrowserView>
    </PrediagDiv>
  )
}

const NewPrediagDiv = styled.div`
  width: 285px;
  height: 385px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-width: 285px;
  min-height: 385px;
  position: relative;
  padding: 20px;
  text-align: center;

  * div {
    text-align: center;
  }

  border: 4px dashed var(--main-gray-2);

  :hover {
    cursor: pointer;
    background-color: var(--main-violet-2);
  }
`

const CreatePrediagCard: FC<{ onClick: () => any; first: boolean }> = ({ onClick, first }) => {
  const [onMount, setonMount] = useState(false)
  const { accountService } = useAppContext()

  useLayoutEffect(() => setonMount(true), [accountService.accountOnBoarding])

  return (
    <>
      <NewPrediagDiv onClick={onClick} id={first ? 'onBoardingPrediag' : ''}>
        <div>
          <div>
            <FATextH3 color={'--main-violet'}>
              Commencer <br /> un nouveau Questionnaire
            </FATextH3>
          </div>
          <div className={'mt-3'}>
            <FontAwesomeIcon icon={'plus-circle'} size={'3x'} color={'var(--main-violet)'} />
          </div>
        </div>
      </NewPrediagDiv>
      <BrowserView>{onMount && first && <OnBoardingPrediag />}</BrowserView>
    </>
  )
}

import { action, observable, ObservableMap } from 'mobx'
import { History, ProjectId } from '../../../model/project'
import { ApiProxy } from '../../../service/APIProxy'
import { ProjectsStore } from './projects.store'

export class HistoriesStore {
  @observable histories: Map<ProjectId, History> = new ObservableMap()

  constructor(private apiProxy: ApiProxy, projectsStore: ProjectsStore) {
    projectsStore.onProjectsLoaded(ps => ps.forEach(async p => this.refresh(p.id)))
  }

  getHistory(projectId: ProjectId): History | undefined {
    return this.histories.get(projectId)
  }

  @action
  async refresh(pId: ProjectId) {
    this.histories.set(pId, await this.apiProxy.get<History>(`/project/${pId}/timeline`))
  }
}

import React from 'react'
import { Card } from 'reactstrap'
import { H2, H4 } from '../../components/shared/H/H'
import environment from '../../config/environment'

const Legal = () => (
  <Card body>
    <H2 arrowColor={'green'}>Mentions légales</H2>
    <div>
      <H4 arrowColor={'yellow'}>Informations relatives à l’éditeur du site</H4>
      <p style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
        Le présent site est la propriété de :
      </p>
      <p>
        France Active
        <br />
        Association régie par la loi du 1er juillet 1901, créée en 1988
        <br />
        Siège social : Tour Cityscope - 3 rue Franklin, 93100 MONTREUIL
        <br />
        SIRET n° 344 891 668 00065
        <br />
        01 53 24 26 26
        <br />
        contact@franceactive.org
      </p>

      <p>
        Le directeur de la publication est :
        <br />
        Fanny Gérôme
      </p>

      <H4 arrowColor={'yellow'}>Informations relatives à l’hébergeur du site</H4>

      <p style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Le site est hébergé par :</p>
      <p>
        MICROSOFT FRANCE
        <br />
        Société par actions simplifiée
        <br />
        Siège social : 37 Quai du President Roosevelt, 92130 ISSY LES MOULINEAUX
        <br />
        SIREN n° 327 733 184
        <br />
        08 90 10 93 18
        <br />
      </p>

      <H4 arrowColor={'yellow'}>Droits d'auteur</H4>
      <p>
        L'ensemble de ce site relevant de la législation française et internationale sur le droit
        d'auteur et la propriété intellectuelle, tous les droits d’exploitation sont réservés, y
        compris pour les documents téléchargeables et les représentations iconographiques et
        photographiques.
      </p>
      <p>
        La reproduction de tout ou partie de ce site sur un support électronique quel qu'il soit est
        formellement interdite sauf autorisation expresse du directeur de la publication.
      </p>
      <div>
        <p>
          La reproduction des textes de ce site sur un support papier est autorisée, tout
          particulièrement dans le cadre pédagogique, sous réserve du respect des trois conditions
          suivantes :
        </p>
        <ul>
          <li>gratuité de la diffusion,</li>
          <li>
            respect de l'intégrité des documents reproduits : pas de modification ni altération
            d'aucune sorte,
          </li>
          <li>
            citation claire et lisible de la source sous la forme suivante - par exemple - : "Ce
            document provient du site internet : appli.franceactive.org. Les droits de reproduction
            sont réservés et strictement limités". L'adresse internet du site appli.franceactive.org
            doit impérativement figurer dans la référence.
          </li>
        </ul>
      </div>

      <H4 arrowColor={'yellow'}>Crédits photographiques et icônes</H4>
      <p>
        Les photographies figurant sur ce site sont la propriété de France Active.
        <br />
        Les icônes figurant sur ce site proviennent du site www.flaticon.com.
      </p>

      <div className="float-right">version {environment.APP_VERSION}</div>
    </div>
  </Card>
)

export default Legal

import React, { FC, ReactNode, useState } from 'react'
import { Button, PopoverBody, UncontrolledPopover } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Address, Meeting } from '../../model/project'
import dayjs from 'dayjs'
import { GreyText } from './FACalendar'
import { MEETING_LABELS } from '../dashboard/meetings/MeetingC'
import { useAppContext } from '../../context'
import { CancelMeetingModal } from '../dashboard/meetings/Meetings'
import { unzonedDayjs } from '../../utils/date'

interface MeetingPopoverProps {
  popoverOpen: boolean
  toggle: () => any
  target: string
  meeting: Meeting
}

const hourFormat = 'HH[h]mm'
const dayFormat = `dddd D MMMM [/] ${hourFormat}`

const toUrlEncoded = (address: Address) =>
  [address.street, address.city, address.zipCode].join(' ').replace(/\s/g, '+')

const Chip = styled.div`
  color: white;
  background-color: var(--main-green);
  border-radius: 3px;
  height: 13px;
  width: 13px;
  align-self: center;
`

const LeftElement = styled.div`
  margin: 5px;
  align-self: flex-start;
  min-width: 20px;
`

const StyledUncontrolledPopover = styled(UncontrolledPopover)`
  .popover {
    max-width: 20% !important;
  }

  * .arrow {
    opacity: 0;
  }
`

const CancelSpan = styled.span`
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
`

const Title = styled.span`
  font-size: 18px;
  line-height: 26px;
  color: var(--main-black-1);
  font-weight: 600;
`

const Line: FC<{ leftElement?: ReactNode }> = ({ leftElement, children }) => {
  return (
    <div style={{ display: 'flex', lineHeight: '26px' }}>
      <LeftElement>{leftElement}</LeftElement>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export const MeetingPopover: FC<MeetingPopoverProps> = ({
  popoverOpen,
  toggle,
  target,
  meeting,
}) => {
  const { projectsStore, historiesStore } = useAppContext()
  const [meetingToCancel, setMeetingToCancel] = useState<Meeting>()

  const cancelMeeting = async (meeting: Meeting, reason: string) => {
    await projectsStore.cancelMeeting(meeting, reason, false)
    setMeetingToCancel(undefined)
    await historiesStore.refresh(meeting.projectId)
  }

  return (
    <>
      <StyledUncontrolledPopover
        placement="auto"
        isOpen={popoverOpen}
        target={target}
        toggle={toggle}
        trigger="legacy"
      >
        <PopoverBody>
          <Line>
            <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <Button color={'link'} onClick={toggle}>
                <FontAwesomeIcon icon={'times'} color={'var(--main-gray)'} size={'lg'} />
              </Button>
            </div>
          </Line>
          <Line
            leftElement={
              <Chip style={{ backgroundColor: projectsStore.getProjectColor(meeting.projectId) }} />
            }
          >
            <Title>{MEETING_LABELS[meeting.type]}</Title>
          </Line>
          <Line
            leftElement={
              <FontAwesomeIcon
                icon={['far', 'calendar']}
                color={'var(--main-gray-2)'}
                size={'lg'}
              />
            }
          >
            <GreyText>{`${unzonedDayjs(meeting.date).format(dayFormat)} - ${unzonedDayjs(
              meeting.date
            )
              .add(meeting.duration, 'minute')
              .format(hourFormat)}`}</GreyText>
          </Line>
          <Line
            leftElement={<FontAwesomeIcon icon={'user'} color={'var(--main-gray-2)'} size={'lg'} />}
          >
            <GreyText>
              {meeting.advisors.map(a => `${a.firstName} ${a.lastName}`).join(', ')}
            </GreyText>
          </Line>
          {meeting.type === 'IN_PERSON' && meeting.address && (
            <Line
              leftElement={
                <FontAwesomeIcon icon={'map-marker-alt'} color={'var(--main-gray-2)'} size={'lg'} />
              }
            >
              <a
                className="ml-2"
                target="_blank"
                title="Voir sur Google Maps"
                rel="noopener noreferrer"
                href={`https://www.google.com/maps/search/?api=1&query=${toUrlEncoded(
                  meeting.address
                )}`}
              >
                <GreyText>{`${meeting.address.street} - ${meeting.address.zipCode} ${meeting.address.city}`}</GreyText>
              </a>
              <iframe
                frameBorder="0"
                height={200}
                width={300}
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyD7h_Ml6l3rmMLRSP_vW2NcThZhZIhCYUs
    &q=${toUrlEncoded(meeting.address)}&language=fr`}
                allowFullScreen={true}
                title={'googleIFrame'}
              />
            </Line>
          )}
          {meeting.type === 'PHONE' && (
            <Line
              leftElement={
                <FontAwesomeIcon icon={'phone'} color={'var(--main-gray-2)'} size={'lg'} />
              }
            >
              <GreyText>{meeting.tel}</GreyText>
            </Line>
          )}
          <Line
            leftElement={
              <FontAwesomeIcon icon={['far', 'comment']} color={'var(--main-gray-2)'} size={'lg'} />
            }
          >
            <GreyText>{meeting.reason}</GreyText>
          </Line>
          <Line>
            <div style={{ justifyContent: 'flex-end', display: 'flex', margin: '3%' }}>
              {meeting.type !== 'COMMITTEE' &&
                unzonedDayjs(meeting.date)
                  .add(meeting.duration, 'minute')
                  .isAfter(dayjs()) && (
                  <CancelSpan
                    color={'link'}
                    onClick={() => {
                      toggle()
                      setMeetingToCancel(meeting)
                    }}
                  >
                    Annuler ce RDV
                  </CancelSpan>
                )}
            </div>
          </Line>
        </PopoverBody>
      </StyledUncontrolledPopover>
      {meetingToCancel && (
        <CancelMeetingModal
          meeting={meetingToCancel}
          cancelMeeting={cancelMeeting}
          close={() => setMeetingToCancel(undefined)}
        />
      )}
    </>
  )
}

export function sortMap<T, U>(
  map: Map<T, U[]>,
  extractor: (u: U) => number
): Map<T, U[]> {
  const sorted = new Map<T, U[]>()
  map.forEach((value, key) =>
    sorted.set(key, value.sort((a, b) => extractor(a) - extractor(b)))
  )
  return sorted
}

export function mapValues<T, U, R>(
  map: Map<T, U[]>,
  mapper: (u: U) => R
): Map<T, R[]> {
  const projected = new Map<T, R[]>()
  map.forEach((value, key) => projected.set(key, value.map(mapper)))
  return projected
}

export function mapValue<T, U, R>(
  map: Map<T, U>,
  mapper: (u: U) => R
): Map<T, R> {
  const projected = new Map<T, R>()
  map.forEach((value, key) => projected.set(key, mapper(value)))
  return projected
}

import { computed, observable, reaction } from 'mobx'
import { FeatureFlagLight, FeatureKey } from '../model/config'
import { AssociationId, AuthStore } from './auth'
import { FeaturesService } from '../service/featuresService'
import { Association } from '../model/project'
import { browserVersion, isEdge } from 'react-device-detect'

export class FeaturesStore {
  @observable features?: FeatureFlagLight = undefined

  constructor(private featureService: FeaturesService, private authStore: AuthStore) {
    authStore.onLoggedIn(async () => {
      this.features = await featureService.getFeatures()
    })
  }

  @computed
  get featuresLoaded(): boolean {
    return this.features !== undefined
  }

  @computed
  get quizzActivation(): boolean {
    return this.checkFeatureActivation(FeatureKey.QUIZZ_FEATURE)
  }

  projectStatusActivation(project: { association?: Association }): boolean {
    return this.checkFeatureActivationByAssociation(FeatureKey.PROJECT_STATUS_FEATURE, project)
  }

  @computed
  get accountDeleteActivation(): boolean {
    return this.checkFeatureActivation(FeatureKey.ACCOUNT_DELETE_FEATURE)
  }

  messengerActivation(project: { association?: Association }): boolean {
    // Pour le moment, la messagerie ne fonctionne pas avec Edge
    if (isEdge && +browserVersion < 80) return false

    return this.checkFeatureActivationByAssociation(FeatureKey.MESSENGER_FEATURE, project)
  }

  onFeaturesLoaded = (callback: () => any) =>
    reaction(
      () => this.features,
      features => features && callback(),
      {
        fireImmediately: true,
      }
    )

  private checkFeatureActivation(key: FeatureKey): boolean {
    return this.features ? this.features[key] : false
  }

  private checkFeatureActivationByAssociation(
    key: FeatureKey,
    project: { association?: Association }
  ): boolean {
    if (!this.features || !project.association) return false

    return this.checkFeatureActivationByAssociationId(key, project.association.id)
  }

  checkFeatureActivationByAssociationId(key: FeatureKey, associationId?: AssociationId): boolean {
    if (!this.features || !associationId) return false

    const activationForAssociation = this.features[`${key}:${associationId}`]

    return (
      this.features[key] && (activationForAssociation === undefined || activationForAssociation)
    )
  }
}

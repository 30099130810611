import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { createBrowserHistory } from 'history'
import { AuthStore } from './store/auth'
import { ProjectsStore } from './components/projects/store/projects.store'
import { HistoriesStore } from './components/projects/store/histories.store'
import { MessengerStore } from './components/messenger/store/MessengerStore'
import { ResourcesStore } from './components/resources/store/resources.store'
import { NotificationStore } from './components/notifications/NotificationsStore'
import { DictionaryStore } from './store/dictionaryStore'
import { FeaturesStore } from './store/featuresStore'
import { ApiProxy } from './service/APIProxy'
import { MetadataService } from './service/metadata.service'
import { MimeTypesService } from './service/mimetypes.service'
import { StompService } from './service/StompService'
import { AccountService } from './service/account.service'
import { TypesService } from './service/typesService'
import { ResourceService } from './service/resourceService'
import { PdfService } from './service/pdf.service'
import { FeaturesService } from './service/featuresService'
import React, { useContext } from 'react'
import { ProfilerService } from './service/profiler.service'
import { AdvisorService } from './service/advisor.service'
import { QuizzService } from './service/quizzService'
import { PrediagStore } from './components/projects/store/prediag.store'
import { AnalyticsService } from './service/analytics.service'

export interface Stores {
  authStore: AuthStore
  routingStore: RouterStore
  projectsStore: ProjectsStore
  historiesStore: HistoriesStore
  messengerStore: MessengerStore
  resourcesStore: ResourcesStore
  notificationsStore: NotificationStore
  dictionaryStore: DictionaryStore
  featuresStore: FeaturesStore
  prediagStore: PrediagStore
}

export interface Services {
  apiProxy: ApiProxy
  metadataService: MetadataService
  mimeService: MimeTypesService
  stompService: StompService
  accountService: AccountService
  typesService: TypesService
  resourceService: ResourceService
  pdfService: PdfService
  profilerService: ProfilerService
  advisorService: AdvisorService
  quizzService: QuizzService
  analyticsService: AnalyticsService
}

export type ServiceAndStore = Services & Stores

export const createContext = (): ServiceAndStore => {
  const routingStore = new RouterStore()

  const browserHistory = createBrowserHistory()
  syncHistoryWithStore(browserHistory, routingStore)

  const authStore = new AuthStore(routingStore)
  const apiProxy = new ApiProxy(authStore)

  const analyticsService = new AnalyticsService(apiProxy, authStore)

  const metadataService = new MetadataService(apiProxy)
  const mimeService = new MimeTypesService()
  const accountService = new AccountService(authStore, apiProxy)
  const stompService = new StompService(authStore)
  const typesService = new TypesService(apiProxy)
  const featuresService = new FeaturesService(apiProxy)
  const resourceService = new ResourceService(apiProxy)
  const pdfService = new PdfService(apiProxy)
  const profilerService = new ProfilerService(apiProxy)
  const advisorService = new AdvisorService(apiProxy)

  const projectsStore = new ProjectsStore(authStore, apiProxy, profilerService)
  const historiesStore = new HistoriesStore(apiProxy, projectsStore)
  const resourcesStore = new ResourcesStore(authStore, resourceService)
  const dictionaryStore = new DictionaryStore(typesService, authStore)
  const featuresStore = new FeaturesStore(featuresService, authStore)
  const messengerStore = new MessengerStore(projectsStore, authStore, stompService, featuresStore)
  const prediagStore = new PrediagStore(authStore, apiProxy, projectsStore)
  const notificationsStore = new NotificationStore(
    authStore,
    stompService,
    historiesStore,
    projectsStore,
    prediagStore
  )

  const quizzService = new QuizzService(apiProxy, authStore, projectsStore)

  const stores: Stores = {
    authStore,
    routingStore,
    projectsStore,
    historiesStore,
    messengerStore,
    resourcesStore,
    notificationsStore,
    dictionaryStore,
    featuresStore,
    prediagStore,
  }

  const services: Services = {
    apiProxy,
    metadataService,
    mimeService,
    stompService,
    accountService,
    typesService,
    resourceService,
    pdfService,
    profilerService,
    advisorService,
    quizzService,
    analyticsService,
  }

  return { ...stores, ...services }
}

// @ts-ignore
const AppContext: React.Context<ServiceAndStore> = React.createContext({})

export default AppContext

export const useAppContext = () => useContext(AppContext)

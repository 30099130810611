import React, { FC } from 'react'
import { Dayjs } from 'dayjs'
import styled from 'styled-components'
import { Month } from './Month'
import { Meeting } from '../../model/project'

interface CalendarYearProps {
  date: Dayjs
  meetings: Meeting[]
}

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2%;
`

export const CalendarYear: FC<CalendarYearProps> = ({ date, meetings }) => {
  const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
  return (
    <Grid>
      {months.map(m => (
        <Month key={date.format('YYYY_MM')} date={date.month(m)} meetings={meetings} />
      ))}
    </Grid>
  )
}

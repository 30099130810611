import { action, computed, observable, reaction, runInAction } from 'mobx'
import {
  DetailedProject,
  DocumentConsultationRequest,
  FinancementOperation,
  Meeting,
  MeetingCancel,
  Project,
  ProjectId,
} from '../../../model/project'
import { ProjectDocument } from '../../../model/document'
import dayjs from 'dayjs'
import { AuthStore } from '../../../store/auth'
import { ApiProxy } from '../../../service/APIProxy'
import { ProfilerService } from '../../../service/profiler.service'

const PROJECT_COLORS = [
  {
    name: 'green',
    value: 'var(--main-green)',
  },
  {
    name: 'blue',
    value: 'var(--main-blue)',
  },
  {
    name: 'yellow',
    value: 'var(--main-yellow)',
  },
]

export class ProjectsStore {
  @observable projects: DetailedProject[] = []
  @observable projectsFetched: boolean = false
  @observable isPrediagRequired: boolean = false
  @observable hasActiveProject: boolean = false

  constructor(
    private authStore: AuthStore,
    private apiProxy: ApiProxy,
    private profilerService: ProfilerService
  ) {
    authStore.onLoggedIn(() => this.fetchProjects())
  }

  @action fetchProjects = async (force: boolean = false) => {
    if (this.projects.length && !force) {
      return this.projects
    }
    let projects: DetailedProject[]
    try {
      projects = await this.apiProxy.get<DetailedProject[]>('/project/complete')
    } catch (e) {
      // en attendant de trouver mieux, on refait l'appel une fois
      projects = await this.apiProxy.get<DetailedProject[]>('/project/complete')
    }
    runInAction(() => {
      this.projects = projects.sort((a, b) =>
        dayjs(b.createdDate).diff(dayjs(a.createdDate), 'day')
      )
      this.projectsFetched = true
    })
    this.profilerService.endCallOf('fetchProjects')
    this.projects.forEach(p => {
      if (p.displayPrediagAlert) {
        this.isPrediagRequired = true
      }
      if (p.active) {
        this.hasActiveProject = true
      }
    })
    return this.projects
  }

  // ------------- Meetings

  @action cancelMeeting = async (
    { id, projectId }: Meeting,
    cancelReason: string,
    onlyForCurrentUser: boolean
  ) => {
    await this.apiProxy.put<null, MeetingCancel>(
      '/project/meetings/cancelEntrepreneur',
      {
        id,
        cancelReason,
        onlyForCurrentUser,
      },
      false
    )
    runInAction(() => {
      const project: DetailedProject | undefined = this.projects.find(({ id }) => id === projectId)
      if (project) {
        if (project.meetings.map(m => m.id).indexOf(id) !== -1) {
          project.meetings = project.meetings.filter(m => m.id !== id)
        }
        if (project.newMeetings.map(m => m.id).indexOf(id) !== -1) {
          project.newMeetings = project.newMeetings.filter(m => m.id !== id)
        }
      }
    })
  }

  @computed get newMeetings(): Meeting[] {
    return this.projects
      .map(({ newMeetings }) => newMeetings || [])
      .reduce((acc, val) => acc.concat(val), [])
      .sort((m1, m2) => dayjs(m1.date).diff(dayjs(m2.date), 'minute'))
  }

  project(id: ProjectId): DetailedProject | undefined {
    return this.projects.find(p => p.id === id)
  }

  @computed get meetings(): Meeting[] {
    return this.projects
      .map(({ meetings }) => meetings || [])
      .reduce((acc, val) => acc.concat(val), [])
      .sort((m1, m2) => dayjs(m1.date).diff(dayjs(m2.date), 'minute'))
  }

  // ------------- Documents

  @computed get newDocuments(): ProjectDocument[] {
    return this.projects
      .map(({ documents }) => documents || [])
      .reduce((acc, val) => acc.concat(val), [])
      .filter(document => document.status === 'PENDING_UPLOAD' || document.status === 'INCOMPLETE')
  }

  @computed get anyDocuments(): boolean {
    return (
      this.projectsFetched &&
      !!this.projects
        .map(({ documents }) => documents || [])
        .reduce((acc, val) => acc.concat(val), []).length
    )
  }

  @action updateDocument(document: ProjectDocument) {
    const project = this.project(document.projectId)
    if (project) {
      project.documents
        .filter(({ id }) => id === document.id)
        .forEach(d => Object.assign(d, document))
    }
  }

  onProjectsLoaded = (onProjectsLoadedCb: (projects: DetailedProject[]) => any) =>
    reaction(
      () => this.projectsFetched,
      projectsFetched => projectsFetched && onProjectsLoadedCb(this.projects),
      {
        fireImmediately: true,
      }
    )

  getProjectColor(projectId: string) {
    return (
      PROJECT_COLORS[this.projects.map(p => p.id).indexOf(projectId)].value ||
      PROJECT_COLORS[0].value
    )
  }

  getProjectColorName(projectId: string) {
    return (PROJECT_COLORS[this.projects.map(p => p.id).indexOf(projectId)] || PROJECT_COLORS[0])
      .name
  }

  @action
  async refreshProject(projectId: ProjectId) {
    const resp = await this.apiProxy.get<Project>(`/project/${projectId}`)
    this.projects.filter(p => p.id === projectId).forEach(p => Object.assign(p, resp))
  }

  @action
  async refreshMeetings(projectId: ProjectId) {
    const resp = await this.apiProxy.get<Meeting[]>(`/project/${projectId}/meeting`)
    this.projects.filter(p => p.id === projectId).forEach(p => (p.meetings = resp))
  }

  @action
  async refreshDocuments(projectId: ProjectId) {
    const resp = await this.apiProxy.get<ProjectDocument[]>(`/project/${projectId}/documents`)
    this.projects.filter(p => p.id === projectId).forEach(p => (p.documents = resp))
  }

  @action addDocument(projectId: ProjectId, doc: ProjectDocument) {
    this.projects.filter(p => p.id === projectId).forEach(p => p.documents.push(doc))
  }

  @action
  async refreshFinancement(projectId: ProjectId) {
    const resp = await this.apiProxy.get<FinancementOperation[]>(
      `/project/${projectId}/financement`
    )
    this.projects.filter(p => p.id === projectId).forEach(p => Object.assign(p.financement, resp))
  }

  @action pushNewConsulationDocument = ({ id, url, projectId }: ProjectDocument) => {
    this.apiProxy.post<null, DocumentConsultationRequest>(
      `/documentConsultations`,
      {
        url,
        projectId,
        documentId: id,
      },
      false
    )
  }

  getIsPrediagRequiredForProject(projectId: string) {
    const proj = this.project(projectId)
    return proj === undefined ? false : proj.displayPrediagAlert
  }
}

import React, { Component, ReactNode } from 'react'
import './MenuItem.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { Collapse } from 'reactstrap'
import { RouterStore } from 'mobx-react-router'
import { ProjectsStore } from '../../../components/projects/store/projects.store'

interface SubMenuItems {
  label: string
  link: string
  projectId: string
  onClick?: () => any
}

export interface MenuItemProps {
  label: string | ReactNode
  link: string
  icon: React.ReactNode
  color: string
  subItems?: SubMenuItems[]
  renderCondition?: () => boolean
  onClick?: (toggleSubMenu: () => any) => any
}

@inject('routingStore', 'projectsStore')
@observer
class MenuItem extends Component<
  MenuItemProps & { routingStore?: RouterStore; projectsStore?: ProjectsStore }
> {
  @observable toggled: boolean = false

  render() {
    const {
      color,
      label,
      link,
      icon,
      subItems,
      routingStore,
      projectsStore,
      renderCondition,
      onClick,
    } = this.props

    const display = renderCondition === undefined || renderCondition()
    const myStyle = { color, '--item-color': color } as React.CSSProperties
    return display ? (
      <div>
        <NavLink
          to={!subItems ? link : {}}
          activeClassName={'active'}
          className="MenuItem"
          style={myStyle}
          onClick={e => {
            e.stopPropagation()
            onClick!(() => (this.toggled = !this.toggled))
          }}
          replace={!subItems && routingStore!.location.pathname === link}
        >
          <div>
            {icon}
            {subItems && !!subItems.length && (
              <button
                aria-label="expand"
                className={`MenuItem--Toggle ${this.toggled ? 'toggled' : ''}`}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  this.toggled = !this.toggled
                }}
                style={{ position: 'absolute', right: '7%', top: '18%' }}
              >
                <FontAwesomeIcon icon={'angle-right'} />
              </button>
            )}
          </div>
          <div className="mt-3 MenuItem-label">
            <span>{label}</span>
          </div>
        </NavLink>
        {subItems && (
          <Collapse isOpen={this.toggled} className={'MenuItem-SubItems'}>
            {subItems.map((si, i) => (
              <NavLink
                key={i}
                to={si.link}
                style={{ backgroundColor: color }}
                className={`MenuItem-SubItems-SubItem serif MenuItem-SubItems-SubItem-Project-color-${projectsStore!.getProjectColorName(
                  si.projectId
                )}`}
                onClick={si.onClick}
              >
                {si.label}
              </NavLink>
            ))}
          </Collapse>
        )}
        <div className="MenuItem-divider" />
      </div>
    ) : (
      <></>
    )
  }
}

export default MenuItem

import { UserModel } from '../../../store/auth'
import React, { useState } from 'react'
import { Card, Modal, ModalBody, ModalHeader } from 'reactstrap'
import './Personal.scss'
import { ChangePassword } from './ChangePassword'
import { H4 } from '../../shared/H/H'
import { observer } from 'mobx-react-lite'
import { useAppContext } from '../../../context'

export const Personal = observer<{ user: UserModel }>(({ user }) => {
  const { accountService, featuresStore } = useAppContext()

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const toggleDeleteModal = () => setOpenDeleteModal(!openDeleteModal)

  return (
    <div className="row PersonalInfo">
      {/*<div className="col-6 col-lg-4">*/}
      {/*<Card body>*/}
      {/*<h4><FontAwesomeIcon icon={'chevron-right'} color={'#00b195'}/> Ma photo</h4>*/}
      {/*</Card>*/}
      {/*</div>*/}
      <div className="col-12 col-lg-6 col-xl-5">
        <Card body className="card">
          <H4 arrowColor={'green'} text={'Mes informations'} />

          <div className="info-container">
            <div className="info-group">
              <span className="info-label">Nom</span>
              <span className="info-value">{user.lastName}</span>
            </div>
            <div className="divider" />
            <div className="info-group">
              <span className="info-label">Prénom</span>
              <span className="info-value">{user.firstName}</span>
            </div>
            <div className="divider" />
            <div className="info-group">
              <span className="info-label">Mail</span>
              <span className="info-value">{user.email}</span>
            </div>
            <div className="divider" />
          </div>

          {/*<div className={'btn-container'}>
            <button className="btn btn-fa" disabled>
              Faire une demande de modification
            </button>
          </div>*/}
        </Card>
      </div>
      <div className="col-12 col-lg-6 col-xl-5">
        <Card body className="card">
          <H4 arrowColor={'green'} text={'Modifier mon mot de passe'} />
          <ChangePassword />
        </Card>
      </div>

      {featuresStore.accountDeleteActivation && (
        <>
          <div className="col-12">
            <Card body>
              <button className="btn btn-dark" onClick={toggleDeleteModal}>
                Supprimer mon compte
              </button>
            </Card>
          </div>

          <Modal isOpen={openDeleteModal} toggle={toggleDeleteModal}>
            <ModalHeader>Suppression de votre compte</ModalHeader>
            <ModalBody>
              <p>Etes-vous sûr de vouloir supprimer votre compte ?</p>
              <p>Cette action est définitive !</p>
              <div className="clearfix">
                <button className="btn btn-secondary float-left" onClick={toggleDeleteModal}>
                  Annuler
                </button>
                <button
                  className="btn btn-danger float-right"
                  onClick={() => accountService.deleteAccount()}
                >
                  Supprimer mon compte
                </button>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </div>
  )
})

import { Project } from '../../../model/project'
import { Link } from 'react-router-dom'
import React from 'react'
import './Timeline.scss'
import { ReactComponent as Arrow } from '../../../assets/images/arrow.svg'
import { observer } from 'mobx-react-lite'
import Skeleton from 'react-loading-skeleton'
import { Operations } from './Operations'
import { useAppContext } from '../../../context'
import { TimelineProgress, TimelineStatic } from './TimelineProgress'

export const TimeLine = observer<{ project: Project; projectLink: boolean; id?: string }>(
  ({ project, projectLink, id }) => {
    const { featuresStore } = useAppContext()

    return (
      <div className="Timeline d-flex">
        <div style={{ flex: 1 }}>
          <div className="mb-n1 d-flex">
            <span>
              Projet : <span className="projectName serif">{project.name}</span>
            </span>
            {project.livrable && project.livrable!.pact && (
              <span className="ml-5">
                Pacte :{' '}
                <span className="projectName serif">
                  <a href={project.livrable!.pact.link} target="_blank" rel="noopener noreferrer">
                    {project.livrable!.pact.name}
                  </a>
                </span>
              </span>
            )}
          </div>

          {project.active ? (
            <>
              {featuresStore.projectStatusActivation(project) ? (
                <TimelineProgress project={project} id={id} />
              ) : (
                <TimelineStatic id={id} />
              )}

              {featuresStore.projectStatusActivation(project) && !!project.operations.length && (
                <Operations operations={project.operations} projectId={project.id} />
              )}
            </>
          ) : (
            <div style={{ marginBottom: '10px', marginTop: '10px' }}>
              <div style={{ marginBottom: '12px' }}>
                Des travaux d'amélioration nous empêchent actuellement d'afficher certaines
                informations de votre projet. L'Appli by FA sera très bientôt à nouveau entièrement
                disponible.
              </div>
              <div>Merci de votre patience !</div>
            </div>
          )}
        </div>

        {projectLink && (
          <Link to={`/p/${project.id}`} className={'see-project'}>
            <Arrow />
          </Link>
        )}
      </div>
    )
  }
)

const SkeletonChild = () => <Skeleton width={'100px'} height={'20px'} />

export const TimelineSkeleton = () => (
  <div className="Timeline d-flex skeleton">
    <div style={{ flex: 1 }}>
      <div className="mb-1 ml-4 d-flex">
        <Skeleton width={'150px'} />
        <div className="ml-5">
          <Skeleton width={'150px'} />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '0 2em',
        }}
      >
        <SkeletonChild />
        <SkeletonChild />
        <SkeletonChild />
        <SkeletonChild />
      </div>
    </div>
  </div>
)

import React, { FC, useState } from 'react'
import { Address, DetailedProject } from '../../../../model/project'
import { Card, Col, Row } from 'reactstrap'
import { DocumentStatus, ProjectDocument } from '../../../../model/document'
import { Document } from '../../../shared/document/Document'
import { DocumentUpload } from '../../../shared/document/DocumentUpload'
import { reaction } from 'mobx'
import './ProjectDetails.scss'
import { ReactComponent as Associations } from '../../../../assets/images/associations.svg'
import { ReactComponent as Speak } from '../../../../assets/images/speak.svg'
import { H4 } from '../../../shared/H/H'
import { Link } from 'react-router-dom'
import { useAppContext } from '../../../../context'
import { observer } from 'mobx-react-lite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAlert } from 'react-alert'
import { UploadModal } from '../../../shared/document/UploadModal'
import { Feature, useHealthCheck } from '../../../../store/healthcheckStore'
import { OnBoardingProject } from '../onboarding/OnBoardingProject'
import { BrowserView } from 'react-device-detect'

const ORDERED_DOC_STATES: ReadonlyArray<DocumentStatus> = Object.freeze([
  'PENDING_UPLOAD',
  'INCOMPLETE',
  'PENDING_VALIDATION',
  'VALID',
])

export const DOCUMENT_COMPARATOR = (d1: ProjectDocument, d2: ProjectDocument) => {
  const state1 = ORDERED_DOC_STATES.indexOf(d1.status)
  const state2 = ORDERED_DOC_STATES.indexOf(d2.status)

  if (state1 === state2) {
    return d1.description.localeCompare(d2.description)
  }
  return state1 - state2
}

const toUrlEncoded = (address: Address) =>
  escape([address.street, address.city, address.zipCode].join(','))

type ProjectDetailsProps = {
  project: DetailedProject
  onboardingReady: boolean
}

export const getLibelleConsigne = (consigne: string) => {
  switch (consigne) {
    case 'LIRE':
      return 'A lire'
    case 'COMPLETER_ENVOYER':
      return 'A compléter et envoyer'
    case 'COMPLETER_ENVOYER_SIGNER':
      return 'A compléter et envoyer signé'
    case 'CONSULTER_SIGNER':
      return 'A consulter et envoyer signé'
    case 'SUIVI':
      return 'Document de suivi à compléter et renvoyer'
    default:
      return ''
  }
}

const isWaitCompleted = (d: ProjectDocument) => ['PENDING_UPLOAD', 'INCOMPLETE'].includes(d.status)

const DocumentList: FC<{
  docs: ProjectDocument[]
  selector: (d: ProjectDocument) => boolean
}> = observer(({ docs, selector }) => {
  const sortedDocs = docs
    .slice()
    .sort(DOCUMENT_COMPARATOR)
    .filter(selector)

  const docWaitComplet = sortedDocs.filter(d => isWaitCompleted(d))

  const docCompleted = sortedDocs.filter(d => !isWaitCompleted(d))

  const { projectsStore } = useAppContext()

  return (
    <>
      <div className="doc-title-separator">
        Vous avez {docWaitComplet.length} document{docWaitComplet.length > 1 && 's'} à compléter
      </div>
      <RenderDocuments docs={docWaitComplet} uploadCb={d => projectsStore.updateDocument(d)} />

      <div className="divider mt-auto" />
      <RenderDocuments docs={docCompleted} uploadCb={d => projectsStore.updateDocument(d)} />
    </>
  )
})

const DocumentDynamicList: FC<{
  docs: ProjectDocument[]
  selector: (d: ProjectDocument) => boolean
}> = observer(({ docs, selector }) => {
  const sortedDocs = docs
    .slice()
    .sort(DOCUMENT_COMPARATOR)
    .filter(selector)

  const docAtRead = sortedDocs.filter(d => d.consigne === 'LIRE')
  const nbreDocAtRead = sortedDocs.filter(d => d.consigne === 'LIRE' && isWaitCompleted(d))
  const docAtCompleteAndUpload = sortedDocs.filter(d => d.consigne === 'COMPLETER_ENVOYER')
  const nbreDocAtCompleteAndUpload = sortedDocs.filter(
    d => d.consigne === 'COMPLETER_ENVOYER' && isWaitCompleted(d)
  )
  const docSuivi = sortedDocs.filter(d => d.consigne === 'SUIVI')
  const nbrDocSuivi = sortedDocs.filter(d => d.consigne === 'SUIVI' && isWaitCompleted(d))

  const { projectsStore } = useAppContext()

  return (
    <>
      <div className="doc-title-separator">
        Vous avez {nbreDocAtRead.length} document{nbreDocAtRead.length > 1 && 's'} à lire
      </div>
      <RenderDocuments docs={docAtRead} uploadCb={d => projectsStore.updateDocument(d)} />
      <div className="doc-title-separator">
        Vous avez {nbreDocAtCompleteAndUpload.length} document
        {nbreDocAtCompleteAndUpload.length > 1 && 's'} à compléter et renvoyer
      </div>
      <RenderDocuments
        docs={docAtCompleteAndUpload}
        uploadCb={d => projectsStore.updateDocument(d)}
      />
      <div className="doc-title-separator">
        Vous avez {nbrDocSuivi.length} document
        {nbreDocAtCompleteAndUpload.length > 1 && 's'} de suivi à compléter et renvoyer
      </div>
      <RenderDocuments docs={docSuivi} uploadCb={d => projectsStore.updateDocument(d)} />
    </>
  )
})

const RenderDocuments: FC<{
  docs: ProjectDocument[]
  uploadCb: (d: ProjectDocument) => void
}> = observer(({ docs, uploadCb }) => {
  const alert = useAlert()
  const { projectsStore } = useAppContext()
  return (
    <>
      {docs.map(d => (
        <div className="mb-2" key={d.id}>
          <Document
            document={d}
            methodLog={() => {
              projectsStore.pushNewConsulationDocument(d)
            }}
            documentUpload={(up: DocumentUpload) =>
              reaction(
                () => up.sendingState,
                (document, reaction) => {
                  switch (up.sendingState) {
                    case 'completed':
                      uploadCb(up.document)
                      alert.success('Document(s) envoyé(s)', { timeout: 5000 })
                      up.sendingState = 'not_started'
                      reaction.dispose()
                      break
                    case 'error':
                      alert.error("Une erreur est survenue lors de l'envoi de vos documents")
                      reaction.dispose()
                      break
                  }
                },
                {
                  fireImmediately: true,
                }
              )
            }
          />
        </div>
      ))}
    </>
  )
})

export const ProjectDetails: FC<ProjectDetailsProps> = ({ project, onboardingReady }) => {
  const { id, documents, structure, advisor, association, name } = project
  const { dictionaryStore, featuresStore, apiProxy, projectsStore } = useAppContext()
  const [modalOpened, toggleModal] = useState(false)
  const [errorOnUpload, setError] = useState(false)
  const [sending, setSending] = useState(false)
  const alert = useAlert()
  const [messengerIsUp, setMessengerIsUp] = useState<boolean | undefined>(undefined)
  const { accountService } = useAppContext()
  useHealthCheck(Feature.MESSAGE).then(featureIsUp => {
    setMessengerIsUp(featureIsUp)
  })

  const sendFiles = async (files: File[], docType?: string, description?: string) => {
    setSending(true)
    if (errorOnUpload) {
      setError(false)
    }

    const fd = new FormData()
    files.forEach(f => fd.append('file', f, f.name))
    fd.append('documentType', docType!)
    fd.append('documentDescription', description!)

    try {
      const doc = await apiProxy.formData<ProjectDocument>(`/project/${project.id}/documents/`, fd)
      projectsStore.addDocument(project.id, doc)
    } catch (e) {
      setError(true)
    } finally {
      setSending(false)
      toggleModal(false)
      errorOnUpload
        ? alert.error("Une erreur est survenue lors de l'envoi de vos documents")
        : alert.success('Document(s) envoyé(s)', { timeout: 5000 })
    }
  }

  function getDocumentsSummary(text: string, isAdminDoc: boolean) {
    return (
      <>
        <Card body>
          <div className="d-flex justify-content-between">
            <H4 arrowColor={'yellow'} text={text} />
            <div className="add-document" onClick={() => toggleModal(!modalOpened)}>
              <FontAwesomeIcon icon="plus-circle" size="2x" />
            </div>
          </div>

          <DocumentList
            docs={documents}
            selector={d =>
              isAdminDoc
                ? dictionaryStore!.administrativeDocuments.includes(d.type)
                : dictionaryStore!.projectDocuments.includes(d.type)
            }
          />
        </Card>
      </>
    )
  }

  function getDocumentsAdminSummary(text: string) {
    return (
      <>
        <Card body>
          <div className="d-flex justify-content-between">
            <H4 arrowColor={'yellow'} color={'red'} text={text} />
          </div>
          <DocumentDynamicList
            docs={documents}
            selector={d => dictionaryStore!.dynamicDocuments.includes(d.type)}
          />
        </Card>
      </>
    )
  }

  return (
    <div className="ProjectDetails">
      <BrowserView>
        {onboardingReady && <OnBoardingProject open={!accountService.accountOnBoarding.PROJECT} />}
      </BrowserView>
      <Row className="mb-3">
        <div className="col-lg-6 col-md-12 d-flex mt-2">
          <Card body className="flex-fill">
            <H4 arrowColor={'yellow'} text={'Ma structure'} />
            {structure ? (
              <>
                <div>{structure.name}</div>
                <div className="divider mt-auto" />
                <Row className="mt-auto pb-3 ">
                  <Col xs={4}>
                    <div className="label">Adresse</div>
                    <div className="d-flex flex-column">
                      {structure.address ? (
                        <>
                          {structure.address.street}
                          <span>
                            {structure.address.zipCode} {structure.address.city}
                          </span>
                        </>
                      ) : (
                        <div style={{ fontStyle: 'italic' }}>Non renseigné</div>
                      )}
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div className="label">Forme juridique</div>
                    {structure.legalStatus ? (
                      <div>{structure.legalStatus}</div>
                    ) : (
                      <span style={{ fontStyle: 'italic' }}>Non renseigné</span>
                    )}
                  </Col>
                  <Col xs={4}>
                    <div className="label">SIRET</div>
                    <div>
                      {structure.siret ? (
                        <span>{structure.siret}</span>
                      ) : (
                        <span style={{ fontStyle: 'italic' }}>Non renseigné</span>
                      )}
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <span>Votre projet n'est pas encore rattaché à une structure</span>
            )}
          </Card>
        </div>
        <div className="col-lg-3 col-md-6 mt-2 d-flex">
          <Card body outline className="flex-fill d-flex flex-column align-items-center">
            <H4 className={'align-self-start'} arrowColor={'yellow'} text={'Mon conseiller'} />

            <Speak style={{ margin: '1em 0' }} height={'3em'} width={'3em'} />
            {advisor && (
              <div className="card-important">
                {advisor.firstName} {advisor.lastName}
              </div>
            )}
            <div className="divider" />
            {advisor ? (
              <>
                <div>
                  <a
                    href={`mailto:${advisor.email}?subject=[${name}]%20Contact`}
                    className="text-muted"
                  >
                    {advisor.email}
                  </a>
                </div>
                <div className="text-muted">{advisor.phone}</div>
                {featuresStore!.messengerActivation({ association }) &&
                  messengerIsUp === true &&
                  project.active && (
                    <Link to={`/m/${id}`} className="btn btn-fa mt-auto">
                      Envoyer un message en direct
                    </Link>
                  )}
              </>
            ) : (
              <span style={{ fontStyle: 'italic' }}>
                Vous n'avez pas encore de conseiller référent
              </span>
            )}
          </Card>
        </div>
        <div className="col-lg-3 col-md-6 mt-2 d-flex">
          <Card body outline className="flex-fill d-flex flex-column align-items-center">
            <H4
              className={'align-self-start'}
              arrowColor={'yellow'}
              text={'Mon association territoriale'}
            />
            <Associations style={{ margin: '1em 0' }} height={'3em'} width={'3em'} />
            {association && <div className="card-important">{association.name}</div>}
            <div className="divider" />

            {association ? (
              <>
                {association.address && (
                  <div className="text-muted">
                    {association.address.street}, {association.address.zipCode}{' '}
                    {association.address.city}
                  </div>
                )}
                <div className="text-muted">{association.phone}</div>
                {association.address && (
                  <a
                    className="btn m-auto btn-fa d-flex align-self-start justify-content-center"
                    target="_blank"
                    title="Voir sur Google Maps"
                    rel="noopener noreferrer"
                    href={`https://www.google.com/maps/search/?api=1&query=${toUrlEncoded(
                      association.address
                    )}`}
                  >
                    <span>Voir sur une carte</span>
                  </a>
                )}
              </>
            ) : (
              <span style={{ fontStyle: 'italic' }}>
                Vous n'êtes pas encore rattaché à une association
              </span>
            )}
          </Card>
        </div>
      </Row>
      {project.active && (
        <>
          <Row>
            <Col lg={6} md={12} sm={12} className="mt-2" id={'DOCUMENTS'}>
              {getDocumentsSummary('Mes documents projet', false)}
            </Col>
            <Col lg={6} md={12} sm={12} className="mt-2" id={'DOCUMENTS_ADMIN'}>
              <Row className="mt-2">
                {getDocumentsSummary('Mes documents administratifs', true)}
              </Row>
              <Row className="mt-2">
                {getDocumentsAdminSummary('Documents nécessitant votre attention')}
              </Row>
            </Col>
          </Row>
          <UploadModal
            open={modalOpened}
            toggle={() => toggleModal(!modalOpened)}
            sendFiles={sendFiles}
            sending={sending}
          />
        </>
      )}
    </div>
  )
}

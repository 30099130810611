import environment from '../config/environment'
import { AuthStore } from '../store/auth'

type ParamValue = string | number | undefined

export class ApiProxy {
  private prefix = environment.API_URL

  constructor(private authStore: AuthStore) {}

  post = async <Out, In = any>(
    endpoint: string,
    data: In,
    hasResponseBody: boolean = true,
    auth: boolean = true
  ): Promise<Out> =>
    hasResponseBody
      ? this.withBody(endpoint, data, auth, 'POST')
      : this.withBodyNoOut(endpoint, data, auth, 'POST')

  put = async <Out, In = any>(
    endpoint: string,
    data: In,
    hasResponseBody: boolean = true,
    auth: boolean = true
  ): Promise<Out> =>
    hasResponseBody
      ? this.withBody(endpoint, data, auth, 'PUT')
      : this.withBodyNoOut(endpoint, data, auth, 'PUT')

  get = async <Out>(
    endpoint: string,
    auth: boolean = true,
    params?: { [key: string]: ParamValue }
  ): Promise<Out> => this.withoutBody(endpoint, auth, 'GET', params)

  delete = async <Out>(endpoint: string, auth: boolean = true): Promise<Out> =>
    this.withoutBody(endpoint, auth, 'DELETE')

  formData = <Out>(
    endpoint: string,
    fd: FormData,
    onProgress?: (progress: number) => any
  ): Promise<Out> => {
    return new Promise<Out>((res, rej) => {
      const xhr = new XMLHttpRequest()
      xhr.open('POST', `${this.prefix}${endpoint}`)
      const headers = [...this.makeHeaders(true)]
      headers.forEach(v => {
        if ('content-type' !== v[0]) xhr.setRequestHeader(v[0], v[1])
      })
      xhr.onload = () => {
        if (xhr.status >= 400) {
          rej(xhr.status)
        } else {
          res(JSON.parse(xhr.responseText))
        }
      }

      if (xhr.upload && onProgress) {
        xhr.upload.onprogress = ev => onProgress((ev.loaded / ev.total) * 100)
      }
      xhr.send(fd)
    })
  }

  private async withoutBody<Out>(
    endpoint: string,
    auth: boolean = true,
    method: string,
    params?: { [p: string]: ParamValue }
  ): Promise<Out> {
    if (auth && !this.authStore.isLoggedIn) {
      return Promise.reject('must be connected')
    }
    const headers = this.makeHeaders(auth)
    const response = await fetch(`${this.prefix}${endpoint}${this.makeParams(params)}`, {
      headers,
      method,
    })
    if (response.status === 204) {
      // @ts-ignore
      return Promise.resolve(undefined)
    }
    const json = await response.json()
    if (!response.ok) return Promise.reject(json)
    if (!environment.production) {
      console.log('[API]', endpoint, { out: json })
    }
    return json
  }

  private async withBody<In, Out>(
    endpoint: string,
    data: In,
    auth: boolean = true,
    method: string
  ): Promise<Out> {
    if (auth && this.authStore.isLoggedIn == null) {
      return Promise.reject('must be connected')
    }
    const headers = this.makeHeaders(auth)
    const response = await fetch(`${this.prefix}${endpoint}`, {
      headers,
      method,
      body: data ? JSON.stringify(data) : undefined,
    })
    const json = await response.json()
    if (!response.ok) return Promise.reject(json)
    if (!environment.production) {
      console.log('[API]', endpoint, { out: json })
    }
    return json
  }

  private async withBodyNoOut<In>(
    endpoint: string,
    data: In,
    auth: boolean = true,
    method: string
  ): Promise<any> {
    if (auth && this.authStore.isLoggedIn == null) {
      return Promise.reject('must be connected')
    }
    const headers = this.makeHeaders(auth)
    const response = await fetch(`${this.prefix}${endpoint}`, {
      headers,
      method,
      body: data ? JSON.stringify(data) : undefined,
    })
    if (!response.ok) return Promise.reject(response)
    if (!environment.production) {
      console.log('[API]', endpoint, { out: response })
    }
    return Promise.resolve(response)
  }

  private makeParams = (params?: { [p: string]: ParamValue }) =>
    params != null
      ? `?${Object.entries(params)
          .filter(([_, v]: [string, ParamValue]) => v != null)
          .map(([k, v]: [string, ParamValue]) => `${k}=${v}`)
          .join('&')}`
      : ''

  private makeHeaders(auth: boolean): Headers {
    const headers = new Headers()
    if (this.authStore.isLoggedIn && auth) {
      headers.set('Authorization', this.authStore.authHeader)
    }
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
    return headers
  }
}

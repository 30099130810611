import React, { FC, useState } from 'react'
import './LeftMenu.scss'
import MenuItem, { MenuItemProps } from './menuItem/MenuItem'
import { observer } from 'mobx-react-lite'

import { ReactComponent as Idea } from '../../assets/images/idea.svg'
import { ReactComponent as Resources } from '../../assets/images/resources.svg'
import { ReactComponent as Dashboard } from '../../assets/images/dashboard.svg'
import { ReactComponent as Balance } from '../../assets/images/balance.svg'
import { ReactComponent as PrediagIcon } from '../../assets/images/prediag-icon_1.svg'
import { Collapse } from 'reactstrap'
import { useMedia } from 'react-use'
import { useAppContext } from '../../context'
import { BrowserView, MobileView } from 'react-device-detect'
import { Feature, useHealthCheck } from '../../store/healthcheckStore'

const LeftMenu: FC<{ collapsed: boolean; showMenu: (collapsed: boolean) => any }> = observer(
  ({ collapsed, showMenu }) => {
    const { projectsStore, featuresStore } = useAppContext()
    const [resourceIsUp, setResourceIsUp] = useState<boolean | undefined>(undefined)

    useHealthCheck(Feature.RESSOURCES).then((resourceIsUp: boolean) => {
      setResourceIsUp(resourceIsUp)
    })

    const makeItems = (): MenuItemProps[] => [
      {
        icon: <Dashboard />,
        label: 'Mon tableau de bord',
        link: '/d',
        color: 'var(--main-green)',
      },
      {
        icon: <Idea />,
        label: projectsStore!.projects.length > 1 ? 'Mes Projets' : 'Mon projet',
        link: '/p',
        color: 'var(--main-yellow)',
        subItems: projectsStore!.projects.map(p => ({
          label: p.name,
          link: `/p/${p.id}`,
          projectId: p.id,
        })),
      },
      {
        icon: <PrediagIcon />,
        label: (
          <span>
            En activité :
            <br /> ma situation
          </span>
        ),
        link: '/prediag',
        color: 'var(--main-violet)',
        renderCondition: () => true,
        subItems: projectsStore!.projects.map(p => ({
          label: p.name,
          link: `/prediag?project=${p.id}`,
          onClick: () => {},
          projectId: p.id,
        })),
      },
      {
        icon: <Resources />,
        label: 'Ressources & Formations',
        link: '/r',
        color: 'var(--main-blue)',
        renderCondition: () => resourceIsUp !== false,
      },
      {
        icon: <Balance />,
        label: 'Tester mon projet',
        link: '/quiz',
        color: 'var(--main-red)',
        renderCondition: () => featuresStore!.quizzActivation,
      },
      {
        icon: <Resources />,
        label: 'Ressources & Formations (En maintenance)',
        link: '/d',
        color: 'lightgrey',
        renderCondition: () => resourceIsUp === false,
      },
    ]

    const isWideScreen = useMedia('(min-width: 576px)')

    const onMenuClick = (def: MenuItemProps) => {
      if (def.subItems) {
        return (toggleSubMenu: () => any) => {
          toggleSubMenu!()
        }
      }
      return () => showMenu(false)
    }

    return (
      <>
        <BrowserView>
          <Collapse isOpen={collapsed || isWideScreen}>
            <div className="LeftMenu" id="onboardingDashboardOne">
              {makeItems().map(def => (
                <MenuItem key={def.link + def.color} {...def} onClick={onMenuClick(def)} />
              ))}
            </div>
          </Collapse>
        </BrowserView>
        <MobileView>
          <Collapse isOpen={collapsed || isWideScreen}>
            <div className={'MenuCanvas'} onClick={() => showMenu(false)}>
              <div className="LeftMenu">
                {makeItems().map(def => (
                  <MenuItem key={def.link + def.color} {...def} onClick={onMenuClick(def)} />
                ))}
              </div>
            </div>
          </Collapse>
        </MobileView>
      </>
    )
  }
)

export default LeftMenu

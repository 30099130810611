import React, { FC, useContext } from 'react'
import { PrediagCategory, PrediagSubCategory } from './PrediagModel'
import styled from 'styled-components'
import { camelize } from '../../utils/camelCase'
import { PrediagContext } from './PrediagComponent'
import { PrediagQuestionComponent } from './PrediagQuestion'
import { FATextBold } from '../shared/CustomStyledComponents/Text'

export const CategoryTitle = styled.h3`
  color: var(--main-violet);
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
`

export const Indent = styled.div`
  margin-left: 40px;
`

const PrediagSubCategoryComponent: FC<{
  subCategory: PrediagSubCategory
  subCategoryIndex: number
  supposedToBeValid: boolean
  projectId: string
  prediagId: string
  description: string
}> = ({
  subCategory,
  subCategoryIndex,
  supposedToBeValid,
  projectId,
  prediagId,
  description = '',
}) => {
  const { filteredQuestions } = useContext(PrediagContext)
  return (
    <>
      <div style={{ marginBottom: '40px', marginTop: '40px' }}>
        {subCategory.title && (
          <FATextBold color={'--main-violet'}>
            {String.fromCharCode(subCategoryIndex + 97).toUpperCase()}/ {subCategory.title}
          </FATextBold>
        )}
      </div>

      {subCategory.description && (
        <div className={'my-3'} dangerouslySetInnerHTML={{ __html: subCategory.description }} />
      )}

      {subCategory.questions
        .filter(q => filteredQuestions.indexOf(q.id) === -1)
        .map((question, index) => (
          <PrediagQuestionComponent
            key={question.id}
            prediagQuestion={question}
            prediagQuestionIndex={index}
            supposedToBeValid={supposedToBeValid}
            projectId={projectId}
            prediagId={prediagId}
            description={description}
          />
        ))}
    </>
  )
}

export const PrediagCategoryComponent: FC<{
  prediagCategory: PrediagCategory
  categoryIndex: number
  supposedToBeValid: boolean
  projectId: string
  prediagId: string
}> = ({ prediagCategory, categoryIndex, supposedToBeValid, projectId, prediagId }) => {
  const { filteredQuestions } = useContext(PrediagContext)
  return (
    <div className={'my-3'}>
      <CategoryTitle>
        {categoryIndex + 1} / {camelize(prediagCategory.title)}
      </CategoryTitle>

      <Indent>
        {prediagCategory.sousCategories &&
          prediagCategory.sousCategories.map((subCategory, index) => (
            <PrediagSubCategoryComponent
              key={subCategory.id}
              subCategory={subCategory}
              subCategoryIndex={index}
              supposedToBeValid={supposedToBeValid}
              projectId={projectId}
              prediagId={prediagId}
              description={prediagCategory.description}
            />
          ))}

        {prediagCategory.questions &&
          prediagCategory.questions
            .filter(q => filteredQuestions.indexOf(q.id) === -1)
            .map((question, index) => (
              <PrediagQuestionComponent
                key={question.id}
                prediagQuestionIndex={index}
                prediagQuestion={question}
                supposedToBeValid={supposedToBeValid}
                projectId={projectId}
                prediagId={prediagId}
                description={prediagCategory.description}
              />
            ))}
      </Indent>
    </div>
  )
}

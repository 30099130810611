import React, { FC } from 'react'
import styled from 'styled-components'
import dayjs, { Dayjs } from 'dayjs'
import { retrieveAllDaysOfMonthFrom } from './CalendarMonth'
import { GreyText } from './FACalendar'
import { Meeting } from '../../model/project'

const weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)

const Table = styled.table`
  border: none;
  * {
    font-size: 0.95em;
  }
`

const Thead = styled.thead`
  border: none;
`

const Th = styled.th`
  background-color: white;
  border: none;
  font-weight: normal;
  padding: 6px;
`

const Td = styled.td`
  border: none;
  padding: 0;
  text-align: center;
  min-width: 32px;
  height: 32px;
`

const Chip = styled.div`
  color: white;
  background-color: var(--main-green);
  border-radius: 15px;
  width: 23px;
  height: 22px;
`

const WeekNumber = styled.td`
  background-color: var(--main-light-gray);
`

const MonthContainer = styled.div`
  flex: 0 0 21%;
  margin: 2%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

interface DayProps {
  day: Dayjs
  outOfBounds: boolean
  meetings?: Meeting[]
}

const Day: FC<DayProps> = ({ day, outOfBounds, meetings }) => {
  const dayMeetings = meetings ? meetings.filter(m => dayjs(m.date).isSame(day, 'day')) : []

  const isInThePast = day.isBefore(dayjs(), 'day')
  const isToday = day.isSame(dayjs(), 'day')

  const date = () => {
    if (dayMeetings.length > 0) {
      return (
        <>
          <Chip
            className={'d-flex flex-column justify-content-center'}
            style={{
              opacity: outOfBounds ? 0.5 : 1,
              backgroundColor: isInThePast
                ? 'var(--main-gray)'
                : isToday
                ? ''
                : 'var(--main-black-2)',
            }}
          >
            <div>{day.date()}</div>
          </Chip>
        </>
      )
    }
    return (
      <Chip style={{ backgroundColor: 'white', color: 'black', opacity: outOfBounds ? 0.5 : 1 }}>
        {day.date()}
      </Chip>
    )
  }

  return (
    <Td key={day.day()}>
      <div className={'d-flex justify-content-center'}>{date()}</div>
    </Td>
  )
}

interface WeekProps {
  days: DayProps[]
}

const Week: FC<WeekProps> = ({ days }) => {
  // @ts-ignore
  const weekNumber = days[0].day.week()
  return (
    <tr key={weekNumber}>
      <WeekNumber key={`${weekNumber}-${weekNumber}`}>{weekNumber}</WeekNumber>
      {days.map((d, i) => (
        <Day
          key={`${weekNumber}-${d.day.toString()}`}
          day={d.day}
          outOfBounds={d.outOfBounds}
          meetings={d.meetings}
        />
      ))}
    </tr>
  )
}

interface MonthProps {
  date: Dayjs
  meetings: Meeting[]
}

const completeWithFirstDaysOfNextMonth = (days: DayProps[]) => {
  const nextMonthDays = []
  let lastDay = days[days.length - 1].day
  while (days.length + nextMonthDays.length < 42) {
    lastDay = lastDay.add(1, 'day')
    nextMonthDays.push({ day: lastDay, outOfBounds: true })
  }
  return days.concat(nextMonthDays)
}

const buildWeeks = (allDays: DayProps[]) => {
  const result = []
  let index = 0
  let newWeek = []
  while (index < 42) {
    while (newWeek.length < 7) {
      newWeek.push(allDays[index])
      index++
    }
    result.push(newWeek)
    newWeek = []
  }
  return result
}

export const Month: FC<MonthProps> = ({ date, meetings }) => {
  const firstDayOfMonth = date.date(1)
  const dayOfWeek = (firstDayOfMonth.day() === 0 ? 7 : firstDayOfMonth.day()) - 1

  const lastDaysOfPreviousMonth: DayProps[] = Array(dayOfWeek)
    .fill(0)
    .map((_, i) => firstDayOfMonth.add(-(dayOfWeek - i), 'day'))
    .map(day => {
      return { day, outOfBounds: true }
    })

  const allDaysOfCurrentMonth: DayProps[] = retrieveAllDaysOfMonthFrom(
    firstDayOfMonth.month(),
    firstDayOfMonth
  ).map(day => {
    return { day, outOfBounds: false }
  })

  const allDays: DayProps[] = completeWithFirstDaysOfNextMonth(
    lastDaysOfPreviousMonth.concat(allDaysOfCurrentMonth)
  ).map(d => {
    return {
      ...d,
      meetings,
    }
  })

  const weeks = buildWeeks(allDays)

  return (
    <MonthContainer>
      <div>
        <GreyText>{date.format('MMMM')}</GreyText>
        <Table>
          <Thead>
            <tr>
              <Th />
              <Th key={'L'}>
                <GreyText>L</GreyText>
              </Th>
              <Th key={'M'}>
                <GreyText>M</GreyText>
              </Th>
              <Th key={'MM'}>
                <GreyText>M</GreyText>
              </Th>
              <Th key={'J'}>
                <GreyText>J</GreyText>
              </Th>
              <Th key={'V'}>
                <GreyText>V</GreyText>
              </Th>
              <Th key={'S'}>
                <GreyText>S</GreyText>
              </Th>
              <Th key={'D'}>
                <GreyText>D</GreyText>
              </Th>
            </tr>
          </Thead>
          <tbody>
            {weeks.map((w, i) => (
              <Week key={'yw_' + date.format('YYYY_') + i.toString()} days={w} />
            ))}
          </tbody>
        </Table>
      </div>
    </MonthContainer>
  )
}

import React, { Component, memo } from 'react'
import { DetailedProject } from '../../../model/project'
import { disposeOnUnmount, inject, observer } from 'mobx-react'
import { RouteComponentProps } from 'react-router'
import { ProjectsStore } from '../store/projects.store'
import { RouterStore } from 'mobx-react-router'
import { IReactionDisposer, observable, reaction } from 'mobx'
import { Card, Row } from 'reactstrap'
import { TimeLine, TimelineSkeleton } from '../timeline/Timeline'
import { TabDefinition, Tabs } from '../../shared/Tabs/Tabs'
import { ProjectDetails } from './parts/ProjectDetails'
import { Memento } from './parts/Memento'
import { HistoryC } from './parts/History'
import { H2 } from '../../shared/H/H'
import Titled from '../../../home/Titled'
import Skeleton from 'react-loading-skeleton'
import { Products } from './parts/Products'
import { FeaturesStore } from '../../../store/featuresStore'

type ProjectPageProps = {
  projectsStore?: ProjectsStore
  routingStore?: RouterStore
  featuresStore?: FeaturesStore
}

export type PROJECT_TABS_KIND =
  | 'PROJECT'
  | 'MEMENTO'
  | 'FINANCIAL_PLAN'
  | 'CASH_PLAN'
  | 'BILAN'
  | 'HISTORY'
  | 'PRODUCTS'

type ActualProjectPageProps = RouteComponentProps<{ id: string }> & ProjectPageProps

@observer
export class Project extends Component<ActualProjectPageProps> {
  @observable selectedProject?: DetailedProject
  @observable selectedTab: PROJECT_TABS_KIND = 'PROJECT'
  @observable onboardingReady: boolean = false

  constructor(props: ActualProjectPageProps) {
    super(props)
    props.projectsStore!.onProjectsLoaded(projects => {
      const projectId = props.match.params.id
      const project = projects.find(p => p.id === projectId)
      if (project) {
        this.selectedProject = project
        this.selectedTab = 'PROJECT'
      } else {
        props.routingStore!.push('/p')
      }
    })
  }

  @disposeOnUnmount
  private hashChangeReaction: IReactionDisposer = reaction(
    () => {
      const withoutHash = this.props.routingStore!.location.hash.substring(1).toLocaleUpperCase()
      // keycloak adds session info on first load... ()&state...
      return withoutHash.includes('&')
        ? withoutHash.substring(0, withoutHash.indexOf('&'))
        : withoutHash
    },
    hash => {
      this.onboardingReady = false
      switch (hash) {
        case 'PROJECT':
        case 'MEMENTO':
        case 'FINANCIAL_PLAN':
        case 'CASH_PLAN':
        case 'BILAN':
        case 'PRODUCTS':
        case 'HISTORY':
          this.selectedTab = hash
      }
    },
    { fireImmediately: true }
  )

  componentDidMount() {
    this.onboardingReady = true
  }

  componentDidUpdate(
    prevProps: Readonly<ActualProjectPageProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    const { match, projectsStore, routingStore } = this.props
    const projectId = match.params.id
    this.onboardingReady = true
    if (prevProps.match.params.id !== projectId) {
      const project = projectsStore!.project(projectId)
      if (project) {
        this.selectedProject = project
        this.selectedTab = 'PROJECT'
      } else {
        routingStore!.push('/p')
      }
    }
  }

  render() {
    const p = this.selectedProject
    return <>{p ? this.renderProject(p, this.props.featuresStore!) : <ProjectSkeleton />}</>
  }

  private renderProject(p: DetailedProject, featuresStore: FeaturesStore) {
    const hideMemento = !p.active
    const hideFinancement = !p.active
    const tabs: TabDefinition<PROJECT_TABS_KIND>[] = [
      {
        label: 'Le projet',
        id: 'PROJECT',
        target: 'PROJECT',
        content: <TimeLine project={p} projectLink={false} />,
      },
      {
        label: 'Mémento',
        id: 'MEMENTO',
        target: 'MEMENTO',
        hide: hideMemento,
      },
      {
        label: 'Mon plan de financement',
        id: 'FINANCIAL_PLAN',
        target: 'FINANCIAL_PLAN',
        hide: true,
      },
      {
        label: 'Plan de trésorerie',
        id: 'CASH_PLAN',
        target: 'CASH_PLAN',
        disabled: true,
        hide: true,
      },
      {
        label: 'Bilan',
        id: 'BILAN',
        target: 'BILAN',
        disabled: true,
        hide: true,
      },
      {
        label: 'Historique',
        id: 'HISTORY',
        target: 'HISTORY',
      },
      {
        label: 'Solutions de financement',
        id: 'PRODUCTS',
        target: 'PRODUCTS',
        hide: !featuresStore.projectStatusActivation(p) || hideFinancement,
        disabled: !featuresStore.projectStatusActivation(p),
      },
    ]

    return (
      <>
        <Titled title={(t: string) => `${p.name} | ${t}`} />
        <Row className="mb-3" noGutters>
          <Card body>
            <H2 className={'align-self-start'} arrowColor={'yellow'} text={p.name} />
            <div className="mr-3 mb-3 ml-3">
              <Tabs
                selected={this.selectedTab}
                tabs={tabs}
                onSelect={t => {
                  // this will be handled by the hashChangeReaction
                  this.props.routingStore!.replace(`#${t.toLocaleLowerCase().replace(/^#/, '')}`)
                }}
              />
            </div>
          </Card>
        </Row>
        {this.renderTabContent(this.selectedTab, p, this.onboardingReady)}
      </>
    )
  }

  private renderTabContent(
    selectedTab: PROJECT_TABS_KIND,
    project: DetailedProject,
    onboardingReady: boolean
  ) {
    switch (selectedTab) {
      case 'PROJECT':
        return <ProjectDetails project={project} onboardingReady={onboardingReady} />
      case 'MEMENTO':
        return <Memento project={project} />
      case 'FINANCIAL_PLAN':
        return <span>FINANCIAL_PLAN</span>
      case 'CASH_PLAN':
        return <span>CASH_PLAN</span>
      case 'BILAN':
        return <span>BILAN</span>
      case 'HISTORY':
        return <HistoryC projectId={project.id} />
      case 'PRODUCTS':
        return <Products operations={project.financement} advisor={project.advisor} />
    }
  }
}

const ProjectSkeleton = memo(() => (
  <>
    <Row className="mb-3" noGutters>
      <Card body>
        <div className="ml-4 mb-2">
          <Skeleton width={'300px'} height={'38px'} />
        </div>
        <div className="mr-3 mb-3 ml-3">
          <Skeleton width={'360px'} height={'45px'} />
        </div>
        <div className="mr-3 mb-3 ml-3">
          <TimelineSkeleton />
        </div>
      </Card>
    </Row>
  </>
))

export default inject('projectsStore', 'routingStore', 'featuresStore')(Project)

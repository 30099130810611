import React, { FC, useState } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  BlueBoldText,
  BoldText,
  ButtonPopover,
  Carre,
  CustomPopover,
  GreenBoldText,
  InnerLinePopover,
  LinePopover,
  RedBoldText,
  TextPopover,
  TitrePopOver,
  VioletBoldText,
  YellowBoldText,
} from '../../shared/CustomStyledComponents/Popover'
import { ReactComponent as Background } from '../../../assets/images/background.svg'
import { ReactComponent as Dashboard } from '../../../assets/images/dashboard.svg'
import { ReactComponent as Idea } from '../../../assets/images/idea.svg'
import { ReactComponent as PrediagIcon } from '../../../assets/images/prediag-icon_1.svg'
import { ReactComponent as Resources } from '../../../assets/images/resources.svg'
import { ReactComponent as Balance } from '../../../assets/images/balance.svg'
import { Button, PopoverBody } from 'reactstrap'
import { useAppContext } from '../../../context'

export const TextOnBoarding = styled.div`
  max-width: 700px;
  margin-top: 85px;
  height: 50px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #777777;
`

export const TextTitre = styled.div`
  max-width: 700px;
  margin-top: -165px;
  height: 105px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 42px;
  line-height: 52px;
  text-align: center;
  color: #ffffff;
`

export const BackgroundCustom = styled(Background)`
  background: rgba(33, 37, 41, 0.76);
  background-blend-mode: darken;
  height: auto;
  width: 700px;
  margin-top: -10px;
`

export const ButtonStart = styled(Button)`
  background-color: var(--main-green);
  border-radius: 8px;
  border: none;
  min-width: 500px;
  margin-left: 100px;
  margin-top: 15px;
  margin-right: 100px;
`

export const OnboardingDashboard: FC<{}> = observer(() => {
  const { accountService } = useAppContext()
  const [onBoardingOne, setonBoardingOne] = useState(false)
  const [onBoardingTwo, setonBoardingTwo] = useState(false)
  const [onBoardingThree, setonBoardingThree] = useState(false)
  const [onBoardingFour, setonBoardingFour] = useState(false)
  const [onBoardingFive, setonBoardingFive] = useState(false)
  const [onBoardingSix, setonBoardingSix] = useState(false)
  const [onBoardingSeven, setonBoardingSeven] = useState(false)
  const [onBoardingEnd, setonBoardingEnd] = useState(false)

  return (
    <>
      <Dialog
        open={!accountService.accountOnBoarding.DASHBOARD}
        maxWidth={'lg'}
        fullWidth={false}
        scroll={'paper'}
      >
        <DialogTitle style={{ margin: '15px', padding: '5px' }}>
          <TextPopover style={{ display: 'flex' }}>
            <button
              style={{ marginLeft: 'auto' }}
              className="fa-layers fa-fw fa-lg btn-naked"
              onClick={() => {
                accountService.updateOnBoarding({ param: 'DASHBOARD', checked: true })
              }}
            >
              <FontAwesomeIcon size="lg" icon="times" color={'var(--main-gray-2)'} />
            </button>
          </TextPopover>
        </DialogTitle>
        <DialogContent style={{ padding: '0' }}>
          <BackgroundCustom />
          <TextTitre>
            Bienvenue sur
            <TextTitre style={{ marginTop: '0' }}>L'APPLI by FRANCE ACTIVE</TextTitre>
          </TextTitre>
          <TextOnBoarding>
            Ceci est votre 1ère connexion à L’APPLI by FRANCE ACTIVE,
            <TextOnBoarding style={{ marginTop: '0' }}>
              nous vous proposons <b>une présentation globale de la plateforme.</b>
            </TextOnBoarding>
          </TextOnBoarding>
          <div style={{ maxWidth: '700px', marginBottom: '10px', marginTop: '10px' }}>
            <ButtonStart
              onClick={() => {
                accountService.updateOnBoarding({ param: 'DASHBOARD', checked: true })
                setonBoardingOne(true)
              }}
            >
              Commencer
            </ButtonStart>
          </div>
        </DialogContent>
      </Dialog>
      {onBoardingOne && (
        <OnBoardingDashBoard
          targetLast={''}
          target={'onboardingDashboardOne'}
          isOpen={onBoardingOne}
          toggle={() => setonBoardingOne(!onBoardingOne)}
          setPopoverNextOpen={setonBoardingTwo}
          number={1}
          placement={'right'}
        />
      )}
      {onBoardingTwo && (
        <OnBoardingDashBoard
          targetLast={'onboardingDashboardOne'}
          target={'onboardingDashboardTwo'}
          isOpen={onBoardingTwo}
          toggle={() => setonBoardingTwo(!onBoardingTwo)}
          setPopoverNextOpen={setonBoardingThree}
          number={2}
          placement={'left'}
        />
      )}
      {onBoardingThree && (
        <OnBoardingDashBoard
          targetLast={'onboardingDashboardOne'}
          target={'onboardingDashboardTwo'}
          isOpen={onBoardingThree}
          toggle={() => setonBoardingThree(!onBoardingThree)}
          setPopoverNextOpen={setonBoardingFour}
          number={3}
          placement={'left'}
        />
      )}
      {onBoardingFour && (
        <OnBoardingDashBoard
          targetLast={'onboardingDashboardTwo'}
          target={'onboardingDashboardFour'}
          isOpen={onBoardingFour}
          toggle={() => setonBoardingFour(!onBoardingFour)}
          setPopoverNextOpen={setonBoardingFive}
          number={4}
          placement={'left'}
        />
      )}
      {onBoardingFive && (
        <OnBoardingDashBoard
          targetLast={'onboardingDashboardFour'}
          target={'onboardingDashboardFive'}
          isOpen={onBoardingFive}
          toggle={() => setonBoardingFive(!onBoardingFive)}
          setPopoverNextOpen={setonBoardingSix}
          number={5}
          placement={'top'}
        />
      )}
      {onBoardingSix && (
        <OnBoardingDashBoard
          targetLast={'onboardingDashboardFive'}
          target={'onboardingDashboardSix'}
          isOpen={onBoardingSix}
          toggle={() => setonBoardingSix(!onBoardingSix)}
          setPopoverNextOpen={setonBoardingSeven}
          number={6}
          placement={'left'}
        />
      )}
      {onBoardingSeven && (
        <OnBoardingDashBoard
          targetLast={'onboardingDashboardSix'}
          target={'onboardingDashboardSeven'}
          isOpen={onBoardingSeven}
          toggle={() => setonBoardingSeven(!onBoardingSeven)}
          setPopoverNextOpen={setonBoardingEnd}
          number={7}
          placement={'bottom'}
        />
      )}
      {onBoardingEnd && (
        <OnboardingEnd open={onBoardingEnd} targetLast={'onboardingDashboardSeven'} />
      )}
    </>
  )
})

const OnBoardingDashBoard: FC<{
  targetLast: string
  target: string
  isOpen: boolean
  toggle: () => any
  setPopoverNextOpen: any
  number: number
  placement: string
}> = ({ targetLast, target, isOpen, toggle, setPopoverNextOpen, number, placement }) => {
  const { accountService } = useAppContext()

  return (
    <>
      {document.getElementById(targetLast)?.setAttribute('style', 'z-index: 0')}
      {document
        .getElementById(target)
        ?.setAttribute('style', 'z-index: 11; background-color: white')}

      <CustomPopover
        target={target}
        isOpen={isOpen}
        toggle={toggle}
        trigger="classic"
        placement={placement}
      >
        <PopoverBody>
          <TextPopover style={{ display: 'flex' }}>
            {number}/7
            <button
              style={{ marginLeft: 'auto' }}
              className="fa-layers fa-fw fa-lg btn-naked"
              onClick={() => {
                toggle()
                accountService.updateOnBoarding({ param: 'DASHBOARD', checked: true })
                document.getElementById(targetLast)?.setAttribute('style', 'z-index: 0')
                document.getElementById(target)?.setAttribute('style', 'z-index: 0')
              }}
            >
              <FontAwesomeIcon size="lg" icon="times" color={'var(--main-gray-2)'} />
            </button>
          </TextPopover>
          {number === 1 && <InnerOnBoardingOne />}
          {number === 2 && <InnerOnBoardingTwo />}
          {number === 3 && <InnerOnBoardingThree />}
          {number === 4 && <InnerOnBoardingFour />}
          {number === 5 && <InnerOnBoardingFive />}
          {number === 6 && <InnerOnBoardingSix />}
          {number === 7 && <InnerOnBoardingSeven />}
          <LinePopover>
            <div style={{ marginLeft: 'auto' }}>
              <ButtonPopover
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  toggle()
                  setPopoverNextOpen(true)
                }}
              >
                Suivant
              </ButtonPopover>
            </div>
          </LinePopover>
        </PopoverBody>
      </CustomPopover>
    </>
  )
}

const InnerOnBoardingOne: FC<{}> = () => {
  return (
    <>
      <TitrePopOver>Rubriques de la plateforme</TitrePopOver>
      <div style={{ marginTop: '30px' }}>
        <LinePopover>
          <Carre>
            <Dashboard color={'var(--main-green)'} />
          </Carre>
          <InnerLinePopover>
            <TextPopover>
              <GreenBoldText>Mon tableau de bord</GreenBoldText> : ayez une vision globale de votre
              projet, et retrouvez les éléments principaux à venir
            </TextPopover>
          </InnerLinePopover>
        </LinePopover>
        <LinePopover>
          <Carre>
            <Idea color={'var(--main-yellow)'} />
          </Carre>
          <InnerLinePopover>
            <TextPopover>
              <YellowBoldText>Mon projet</YellowBoldText> : retrouvez toutes les informations
              détaillées de votre projet
            </TextPopover>
          </InnerLinePopover>
        </LinePopover>
        <LinePopover>
          <Carre>
            <PrediagIcon
              color={'var(--main-violet)'}
              style={{ maxWidth: '55px', marginLeft: '-11px' }}
            />
          </Carre>
          <InnerLinePopover>
            <TextPopover>
              <VioletBoldText>En activité, ma situation</VioletBoldText> : faites le point sur la
              situation économique et financière de votre structure
            </TextPopover>
          </InnerLinePopover>
        </LinePopover>
        <LinePopover>
          <Carre>
            <Resources color={'var(--main-blue)'} />
          </Carre>
          <InnerLinePopover>
            <TextPopover>
              <BlueBoldText>Ressources & Formations</BlueBoldText> : consultez les vidéos, guides et
              articles
            </TextPopover>
          </InnerLinePopover>
        </LinePopover>
        <LinePopover>
          <Carre>
            <Balance color={'var(--main-red'} />
          </Carre>
          <InnerLinePopover>
            <TextPopover>
              <RedBoldText>Tester mon projet</RedBoldText> : découvrez votre profil grâce à des quiz
            </TextPopover>
          </InnerLinePopover>
        </LinePopover>
      </div>
    </>
  )
}

const InnerOnBoardingTwo: FC<{}> = () => {
  return (
    <>
      <TitrePopOver>Les étapes de votre projet</TitrePopOver>
      <div style={{ marginTop: '15px' }}>
        <LinePopover style={{ marginBottom: '10px' }}>
          <TextPopover>
            Le cycle de vie de votre projet est divisé en <GreenBoldText>4 étapes</GreenBoldText> :
          </TextPopover>
        </LinePopover>
        <div>
          <LinePopover>
            <InnerLinePopover>
              <TextPopover>
                <GreenBoldText>En accompagnement</GreenBoldText> : vous êtes accompagné pour trouver
                la meilleure solution qui répond à votre besoin
              </TextPopover>
            </InnerLinePopover>
          </LinePopover>
          <LinePopover>
            <InnerLinePopover>
              <TextPopover>
                <GreenBoldText>En décision</GreenBoldText> : le comité s'apprête à prendre une
                décision sur votre demande de financement
              </TextPopover>
            </InnerLinePopover>
          </LinePopover>
          <LinePopover>
            <InnerLinePopover>
              <TextPopover>
                <GreenBoldText>En contractualisation</GreenBoldText> : vous avez eu un accord en
                comité, vous réunissez tous les éléments nécessaires pour le mettre en place
              </TextPopover>
            </InnerLinePopover>
          </LinePopover>
          <LinePopover>
            <InnerLinePopover>
              <TextPopover>
                <GreenBoldText>Activé</GreenBoldText> : le(s) solution(s) de financement ont été
                mise(s) en place, à vous de jouer !
              </TextPopover>
            </InnerLinePopover>
          </LinePopover>
        </div>
      </div>
    </>
  )
}

const InnerOnBoardingThree: FC<{}> = () => {
  return (
    <>
      <TitrePopOver>Légende</TitrePopOver>
      <div>
        <LinePopover>
          <Carre style={{ backgroundColor: 'var(--main-light-gray)' }} />
          <InnerLinePopover>
            <TextPopover>
              <GreenBoldText>Blanc</GreenBoldText> : l'étape n'est pas commencée
            </TextPopover>
          </InnerLinePopover>
        </LinePopover>
        <LinePopover>
          <Carre style={{ backgroundColor: 'var(--main-yellow-2)' }} />
          <InnerLinePopover>
            <TextPopover>
              <GreenBoldText>Orange</GreenBoldText> : l'étape est en cours
            </TextPopover>
          </InnerLinePopover>
        </LinePopover>
        <LinePopover>
          <Carre style={{ backgroundColor: 'var(--main-green)' }} />
          <InnerLinePopover>
            <TextPopover>
              <GreenBoldText>Vert</GreenBoldText> : l'étape est terminée
            </TextPopover>
          </InnerLinePopover>
        </LinePopover>
      </div>
    </>
  )
}

const InnerOnBoardingFour: FC<{}> = () => {
  return (
    <>
      <TitrePopOver>Liste de vos rendez-vous à venir</TitrePopOver>
      <div>
        <LinePopover>
          <TextPopover>
            <u>Bon à savoir</u> : accédez à plus de détails en cliquant sur vos rendez-vous.
          </TextPopover>
        </LinePopover>
      </div>
    </>
  )
}

const InnerOnBoardingFive: FC<{}> = () => {
  return (
    <>
      <TitrePopOver>À la une</TitrePopOver>
      <div>
        <LinePopover>
          <TextPopover>Découvrez des ressources adaptées à votre projet.</TextPopover>
        </LinePopover>
      </div>
    </>
  )
}

const InnerOnBoardingSix: FC<{}> = () => {
  return (
    <>
      <TitrePopOver>Partagez les documents associés à votre projet</TitrePopOver>
      <div>
        <LinePopover>
          <TextPopover>
            <BoldText>
              Consultez et transmettez les éléments nécessaires à votre conseiller
            </BoldText>{' '}
            pour vous accompagner tout au long de votre projet.
          </TextPopover>
        </LinePopover>
      </div>
    </>
  )
}

const InnerOnBoardingSeven: FC<{}> = () => {
  return (
    <>
      <TitrePopOver>Accès rapide</TitrePopOver>
      <div>
        <LinePopover>
          <Carre>
            <FontAwesomeIcon size="3x" icon={['far', 'calendar-alt']} color={'var(--main-green)'} />
          </Carre>
          <TextPopover>
            <BoldText>Calendrier :</BoldText> consultez toutes les dates importantes liées à votre
            projet
          </TextPopover>
        </LinePopover>
        <LinePopover>
          <Carre>
            <FontAwesomeIcon size="3x" icon={'bell'} color={'var(--main-green)'} />
          </Carre>
          <TextPopover>
            <BoldText>Notifications :</BoldText> restez informé de tout élément nouveau
          </TextPopover>
        </LinePopover>
        <LinePopover>
          <Carre>
            <FontAwesomeIcon size="3x" icon={'envelope'} color={'var(--main-green)'} />
          </Carre>
          <TextPopover>
            <BoldText>Messagerie :</BoldText> échangez rapidement et simplement avec votre
            conseiller
          </TextPopover>
        </LinePopover>
        <LinePopover>
          <Carre>
            <FontAwesomeIcon size="3x" icon={'user-circle'} color={'var(--main-green)'} />
          </Carre>
          <TextPopover>
            <BoldText>Votre profil :</BoldText> gérez votre compte
          </TextPopover>
        </LinePopover>
      </div>
    </>
  )
}

const OnboardingEnd: FC<{ open: boolean; targetLast: string }> = observer(
  ({ open, targetLast }) => {
    const [showPopup, setShowPopup] = useState(open)
    const { accountService } = useAppContext()
    return (
      <>
        {document.getElementById(targetLast)?.setAttribute('style', 'z-index: 0')}
        <Dialog open={showPopup} maxWidth={'lg'} fullWidth={false} scroll={'paper'}>
          <DialogTitle style={{ margin: '15px', padding: '5px' }}>
            <TextPopover style={{ display: 'flex' }}>
              <button
                style={{ marginLeft: 'auto' }}
                className="fa-layers fa-fw fa-lg btn-naked"
                onClick={() => {
                  setShowPopup(false)
                  accountService.updateOnBoarding({ param: 'DASHBOARD', checked: true })
                }}
              >
                <FontAwesomeIcon size="lg" icon="times" color={'var(--main-gray-2)'} />
              </button>
            </TextPopover>
          </DialogTitle>
          <DialogContent style={{ padding: '0' }}>
            <BackgroundCustom />
            <TextTitre>Félicitations !</TextTitre>
            <TextOnBoarding>Excellente navigation sur notre plateforme !</TextOnBoarding>
            <div style={{ maxWidth: '700px', marginBottom: '10px', marginTop: '10px' }}>
              <ButtonStart
                onClick={() => {
                  setShowPopup(false)
                  accountService.updateOnBoarding({ param: 'DASHBOARD', checked: true })
                }}
              >
                Terminer
              </ButtonStart>
            </div>
          </DialogContent>
        </Dialog>
      </>
    )
  }
)

import React, { FC, useState } from 'react'
import { Notification } from './Notification/Notification'
import './Header.scss'
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavbarBrand,
  NavItem,
  Popover,
  PopoverBody,
  UncontrolledDropdown,
} from 'reactstrap'
import logo from '../assets/images/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Arrow } from '../assets/images/arrow.svg'
import { useAppContext } from '../context'
import { Feature, useHealthCheck } from '../store/healthcheckStore'
import { BrowserView, isMobile } from 'react-device-detect'
import {
  Carre,
  CustomPopoverMenu,
  InnerLinePopover,
  LinePopover,
  TextPopover,
  TitrePopOver,
} from '../components/shared/CustomStyledComponents/Popover'
import { ReactComponent as DashboardIcon } from '../assets/images/dashboard.svg'
import { ReactComponent as Idea } from '../assets/images/idea.svg'
import { ReactComponent as PrediagIcon } from '../assets/images/prediag-icon_1.svg'
import { ReactComponent as Resources } from '../assets/images/resources.svg'
import { ReactComponent as Balance } from '../assets/images/balance.svg'

interface HeaderProps {
  toggleLeftMenu: () => any
  onClick: () => void
}

const LoggedInHeaderContent: FC<{
  isNotificationsOpen: boolean
  toggleNotification: (toggled?: boolean) => any
  menuBurgerClick: (e: React.MouseEvent) => any
}> = ({ isNotificationsOpen, toggleNotification, menuBurgerClick }) => {
  const {
    authStore,
    messengerStore,
    notificationsStore,
    routingStore,
    projectsStore,
  } = useAppContext()
  const [messengerIsUp, setMessengerIsUp] = useState<boolean | undefined>(undefined)
  const [openMenuTutoriel, setopenMenuTutoriel] = useState(false)
  useHealthCheck(Feature.MESSAGE).then(featureStatus => {
    if (featureStatus && !projectsStore.hasActiveProject) {
      setMessengerIsUp(false)
    } else {
      setMessengerIsUp(featureStatus)
    }
  })

  const showNotifications = projectsStore.hasActiveProject

  return (
    <Nav className="NavbarContent" navbar>
      <div className="handle">
        <Arrow />
        <span>ccélérons&nbsp;votre&nbsp;projet</span>
      </div>
      <div className="d-flex align-items-center navbarItems" id={'onboardingDashboardSeven'}>
        <NavItem onClick={menuBurgerClick} className="burger">
          <BurgerIcon />
        </NavItem>
        <BrowserView>
          <NavItem title="Calendrier" style={{ marginRight: '40px' }}>
            <NavLink
              to={'/calendar'}
              replace={routingStore!.location.pathname.startsWith('/calendar')}
            >
              <span className="fa-layers fa-2x fa-fw">
                <FontAwesomeIcon icon={['far', 'calendar-alt']} color={'var(--main-green)'} />
              </span>
            </NavLink>
          </NavItem>
        </BrowserView>

        <NavItem title="Notifications" id={'notificationMenu'}>
          <button
            id={'Notifications'}
            onClick={event => (showNotifications ? {} : event.preventDefault())}
            className={`fa-layers fa-2x fa-fw btn-naked ${showNotifications ? 'up' : 'down'}`}
            disabled={!showNotifications}
          >
            <FontAwesomeIcon icon="bell" color={'var(--main-green)'} />
            {!!notificationsStore!.unreadCount && (
              <span className="fa-layers-counter fa-lg header-counter-badge">
                {notificationsStore!.unreadCount}
              </span>
            )}
          </button>
          {showNotifications && (
            <NotificationPopover
              isNotificationsOpen={isNotificationsOpen}
              toggleNotification={toggleNotification}
            />
          )}
        </NavItem>

        {messengerStore.messengerEnabled && (
          <NavLink
            onClick={event => (messengerIsUp ? {} : event.preventDefault())}
            className={(messengerIsUp && 'up') || 'down'}
            id={'messengerMenu'}
            to={'/m'}
            replace={routingStore!.location.pathname.startsWith('/m')}
          >
            <span className="fa-layers fa-2x fa-fw">
              <FontAwesomeIcon icon="envelope" color={'var(--main-green)'} />
              {!!messengerStore!.newMessagesCount && (
                <span className="fa-layers-counter fa-lg header-counter-badge">
                  {messengerStore!.newMessagesCount}
                </span>
              )}
            </span>
          </NavLink>
        )}

        <BrowserView>
          <NavItem title="Menu tutoriel" style={{ marginLeft: '40px' }}>
            <button
              className="fa-layers fa-2x fa-fw btn-naked"
              id={'menu_tutoriel'}
              onClick={() => setopenMenuTutoriel(!openMenuTutoriel)}
            >
              <FontAwesomeIcon icon={['fas', 'question-circle']} color={'var(--main-green)'} />
            </button>
          </NavItem>
          <MenuTutorielPopover
            openMenu={openMenuTutoriel}
            toggle={() => setopenMenuTutoriel(!openMenuTutoriel)}
          />
        </BrowserView>

        <UncontrolledDropdown nav inNavbar direction={isMobile ? 'left' : 'down'}>
          <DropdownToggle nav caret id={'userMenu'} className="d-flex align-items-center">
            <FontAwesomeIcon icon="user-circle" size="3x" />
            <div className="ml-2 d-flex flex-column justify-content-center serif">
              {authStore!.user && (
                <BrowserView>
                  <div>{authStore!.user!.firstName}</div>
                  <div>{authStore!.user!.lastName}</div>
                </BrowserView>
              )}
            </div>
          </DropdownToggle>
          <DropdownMenu style={{ position: 'absolute' }} id={'userMenuPopup'}>
            <DropdownItem>
              <NavLink
                className="d-flex align-items-center"
                to={'/profil'}
                replace={routingStore!.location.pathname.startsWith('/profil')}
              >
                Paramètres
              </NavLink>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem>
              <NavItem
                className="d-flex align-items-center"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  authStore!.logout()
                }}
              >
                Déconnexion
              </NavItem>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </Nav>
  )
}

const MenuTutorielPopover: FC<{
  openMenu: boolean
  toggle: () => any
}> = ({ openMenu, toggle }) => {
  const { accountService } = useAppContext()

  return (
    <CustomPopoverMenu target={'menu_tutoriel'} isOpen={openMenu} placement={'top'} toggle={toggle}>
      <TitrePopOver>Revoir un tutoriel</TitrePopOver>
      <NavLink
        to={'/d'}
        onClick={() => {
          toggle()
          accountService.updateOnBoarding({ param: 'DASHBOARD', checked: false })
        }}
      >
        <LinePopover>
          <Carre>
            <DashboardIcon color={'var(--main-green)'} style={{ maxWidth: '36px' }} />
          </Carre>
          <InnerLinePopover>
            <TextPopover>Mon tableau de bord</TextPopover>
          </InnerLinePopover>
        </LinePopover>
      </NavLink>
      <NavLink
        to={'/p'}
        onClick={() => {
          toggle()
          accountService.updateOnBoarding({ param: 'PROJECT', checked: false })
        }}
      >
        <LinePopover>
          <Carre>
            <Idea color={'var(--main-yellow)'} style={{ maxWidth: '36px' }} />
          </Carre>
          <InnerLinePopover>
            <TextPopover>Mon projet</TextPopover>
          </InnerLinePopover>
        </LinePopover>
      </NavLink>
      <NavLink
        to={'/prediag'}
        onClick={() => {
          toggle()
          accountService.updateOnBoarding({ param: 'PREDIAG', checked: false })
        }}
      >
        <LinePopover>
          <Carre>
            <PrediagIcon
              color={'var(--main-violet)'}
              style={{ maxWidth: '55px', marginLeft: '-11px', marginTop: '-10px' }}
            />
          </Carre>
          <InnerLinePopover>
            <TextPopover>En activité : ma situation</TextPopover>
          </InnerLinePopover>
        </LinePopover>
      </NavLink>
      <NavLink
        to={'/r'}
        onClick={() => {
          toggle()
          accountService.updateOnBoarding({ param: 'RESSOURCES', checked: false })
        }}
      >
        <LinePopover>
          <Carre>
            <Resources color={'var(--main-blue)'} style={{ maxWidth: '36px' }} />
          </Carre>
          <InnerLinePopover>
            <TextPopover>Ressources & Formations</TextPopover>
          </InnerLinePopover>
        </LinePopover>
      </NavLink>
      <NavLink
        to={'/quiz'}
        onClick={() => {
          toggle()
          accountService.updateOnBoarding({ param: 'QUIZZ', checked: false })
        }}
      >
        <LinePopover>
          <Carre>
            <Balance color={'var(--main-red)'} style={{ maxWidth: '36px' }} />
          </Carre>
          <InnerLinePopover>
            <TextPopover>Tester mon projet</TextPopover>
          </InnerLinePopover>
        </LinePopover>
      </NavLink>
    </CustomPopoverMenu>
  )
}

const NotificationPopover: FC<{
  isNotificationsOpen: boolean
  toggleNotification: (toggled?: boolean) => any
}> = ({ isNotificationsOpen, toggleNotification }) => {
  const { notificationsStore } = useAppContext()

  return (
    <Popover
      target={'Notifications'}
      isOpen={isNotificationsOpen}
      toggle={toggleNotification}
      trigger="legacy"
      placement={'bottom-end'}
    >
      <PopoverBody className="UserNotificationsPopover">
        {!!notificationsStore!.unreadNotifications.length ? (
          <>
            <div className="notifications">
              {notificationsStore!.unreadNotifications
                .sort((a, b) => b.created.localeCompare(a.created))
                .map(n => (
                  <Notification
                    key={n.id}
                    notification={n}
                    clicked={() =>
                      setTimeout(() => {
                        toggleNotification(false)
                        notificationsStore!.markRead(n.id)
                      })
                    }
                  />
                ))}
            </div>
            <button
              className="btn btn-fa empty-button"
              onClick={() =>
                setTimeout(() => {
                  toggleNotification(false)
                  notificationsStore!.markAllRead()
                })
              }
            >
              <SwipeIcon />
              <span>Marquer toutes les notifications comme lues</span>
            </button>
          </>
        ) : (
          <div className="no-notifications">
            <FontAwesomeIcon icon="bell-slash" color="lightgray" size="2x" />
            <span>Vous n'avez pas de nouvelle notification</span>
          </div>
        )}
      </PopoverBody>
    </Popover>
  )
}

const SwipeIcon = () => (
  <span className="fa-layers fa-fw fa-lg">
    <FontAwesomeIcon icon="minus" transform="up-6 right-3" />
    <FontAwesomeIcon icon="minus" />
    <FontAwesomeIcon icon="minus" transform="down-6 left-3" />
  </span>
)

const BurgerIcon = () => (
  <span className="fa-layers fa-fw fa-lg">
    <FontAwesomeIcon icon="minus" transform="up-6" />
    <FontAwesomeIcon icon="minus" />
    <FontAwesomeIcon icon="minus" transform="down-6" />
  </span>
)

const Header: FC<HeaderProps> = ({ onClick, toggleLeftMenu }) => {
  const [isNotificationsOpen, setNotificationOpen] = useState(false)
  const { authStore } = useAppContext()

  const toggleNotification = (toggled?: boolean) => {
    if (toggled !== undefined) {
      setNotificationOpen(toggled)
    } else {
      setNotificationOpen(!isNotificationsOpen)
    }
  }

  const menuBurgerClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    toggleLeftMenu()
  }

  return (
    <Nav expand="md" className={'expanded'} onClick={() => onClick()}>
      <NavbarBrand href={'/d'}>
        <img alt="France Active" src={logo} height="75px" />
      </NavbarBrand>
      {authStore!.isLoggedIn && (
        <LoggedInHeaderContent
          isNotificationsOpen={isNotificationsOpen}
          toggleNotification={toggleNotification}
          menuBurgerClick={menuBurgerClick}
        />
      )}
      {!authStore!.isLoggedIn && (
        <Nav className="d-flex ml-auto">
          <NavItem>
            <Button color="secondary" onClick={() => authStore!.login()}>
              Me connecter
            </Button>
          </NavItem>
        </Nav>
      )}
    </Nav>
  )
}

export default Header

import React, { FC } from 'react'
import './Documents.scss'
import { Card } from 'reactstrap'
import { Document } from '../../shared/document/Document'
import { reaction } from 'mobx'
import { DocumentUpload } from '../../shared/document/DocumentUpload'
import { H4 } from '../../shared/H/H'
import { ReactComponent as DocumentsOk } from '../../../assets/images/document.svg'
import { ProjectDocument } from '../../../model/document'
import { Link } from 'react-router-dom'
import { useAppContext } from '../../../context'
import { observer } from 'mobx-react-lite'
import Skeleton from 'react-loading-skeleton'
import { useAlert } from 'react-alert'

const NoDoc = () => (
  <div className="NoDocument">
    <DocumentsOk />
    <div className="NoDocument-title">Documents à jour</div>
    <div className="divider" />
    <span className="text-muted">Vous nous avez transmis tous les documents demandés</span>
  </div>
)

const Documents: FC = observer(() => {
  const { projectsStore } = useAppContext()
  const alert = useAlert()

  return (
    <div className="Documents">
      {projectsStore.anyDocuments &&
        projectsStore.projectsFetched &&
        projectsStore.hasActiveProject && (
          <Card body>
            <H4 arrowColor={'green'}>Documents à fournir</H4>
            {projectsStore.newDocuments.length ? (
              projectsStore.newDocuments.slice(0, 3).map(d => (
                <div className="mb-2" key={d.id}>
                  <Document
                    document={d}
                    documentUpload={(up: DocumentUpload) =>
                      reaction(
                        () => up.sendingState === 'completed',
                        () => {
                          switch (up.sendingState) {
                            case 'completed':
                              projectsStore.updateDocument(up.document)
                              alert.success('Document(s) envoyé(s)', { timeout: 5000 })
                              break
                            case 'error':
                              alert.error(
                                "Une erreur est survenue lors de l'envoi de vos documents"
                              )
                              break
                          }
                        },
                        {
                          fireImmediately: true,
                        }
                      )
                    }
                  />
                </div>
              ))
            ) : (
              <NoDoc />
            )}
            {displayRemainingDocs(projectsStore.newDocuments)}
            {!!projectsStore.projects.length && (
              <Link to="/p" className="btn btn-fa">
                Retrouver tous mes documents
              </Link>
            )}
          </Card>
        )}
      {!projectsStore.projectsFetched && (
        <Card style={{ height: '300px' }} className="p-3">
          <div className="d-flex w-100 mb-3">
            <Skeleton width={'35px'} height={'30px'} />
            <div className="ml-2 w-75">
              <Skeleton width={'100%'} height={'30px'} />
            </div>
          </div>
          {Array.from(Array(2).keys()).map(i => (
            <div className="mb-2 d-flex flex-column" key={i}>
              <Skeleton width={'75%'} height={'30px'} />
              <Skeleton width={'50px'} height={'50px'} />
            </div>
          ))}
        </Card>
      )}
    </div>
  )
})

const displayRemainingDocs = (projectDocuments: ProjectDocument[]) => {
  const count = projectDocuments.length - 3
  if (count > 0) {
    return (
      <p className="Documents-AdditionalText">
        Et {count} autre{count !== 1 ? 's' : ''} document
        {count !== 1 ? 's' : ''}
      </p>
    )
  }
}

export default Documents

import React, { FC, useState } from 'react'
import { Address, Advisor, DetailedProject, Meeting, MeetingType } from '../../../model/project'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { useAppContext } from '../../../context'
import styled from 'styled-components'
import { MeetingPopover } from '../../calendar/MeetingPopover'
import { unzonedDayjs } from '../../../utils/date'

const Chip = styled.div`
  color: white;
  background-color: var(--main-green);
  border-radius: 3px;
  height: 13px;
  width: 13px;
  align-self: center;
  margin: 5px 5px 0 0;
`

const MeetingInfo = styled.span`
  color: var(--main-gray);
  font-size: 0.8em;
  line-height: 12px;
`

const ClickableMeeting = styled.div`
  :hover {
    cursor: pointer;
  }
`

const toUrlEncoded = (address: Address) =>
  [address.street, address.city, address.zipCode].join(' ').replace(/\s/g, '+')

export const MEETING_LABELS: Record<MeetingType, string> = {
  IN_PERSON: 'RDV physique',
  PHONE: 'RDV téléphonique',
  COMMITTEE: 'Comité décisionnel',
}

type MeetingProps = {
  meeting: Meeting
  displayDay?: boolean
}

function getMeetingAdvisor(meeting: Meeting, projectAdvisor?: Advisor) {
  if (!meeting.advisors.length) {
    return null
  }
  const isPrincipalAdvisorInMeeting = !!(
    projectAdvisor && meeting.advisors.find(a => a.id === projectAdvisor.id)
  )
  return meeting.advisors.length > 1 && isPrincipalAdvisorInMeeting
    ? projectAdvisor
    : meeting.advisors[0]
}

export const MeetingC: FC<MeetingProps> = ({ meeting, children, displayDay = false }) => {
  const { projectsStore } = useAppContext()
  const project: DetailedProject | undefined = projectsStore.project(meeting.projectId)
  const date = unzonedDayjs(meeting.date)
  const projectAdvisor = project && project.advisor
  const meetingAdvisor = getMeetingAdvisor(meeting, projectAdvisor)
  const [popoverOpen, setPopoverOpen] = useState(false)

  const hourFormat = (withDay: boolean) => `${withDay ? 'dddd DD MMMM' : ''} HH[h]mm`

  const target = `target-card-${meeting.id}`

  const isInThePast = date.add(meeting.duration, 'minute').isBefore(dayjs())

  return (
    <>
      <ClickableMeeting
        id={target}
        key={target}
        className={'d-flex mt-2'}
        onClick={() => {}}
        style={isInThePast ? { opacity: 0.5 } : {}}
      >
        <Chip
          style={{
            alignSelf: 'flex-start',
            flexShrink: 0,
            backgroundColor: projectsStore.getProjectColor(meeting.projectId),
          }}
        />
        <div className={'d-flex justify-content-between flex-grow-1'}>
          <div className={'d-flex flex-column justify-content-between'}>
            <div>
              <b>{MEETING_LABELS[meeting.type]}</b>
            </div>
            <div>
              <MeetingInfo>
                {' '}
                {`${date.format(hourFormat(displayDay))} - ${date
                  .add(meeting.duration, 'minute')
                  .format(hourFormat(false))}`}
              </MeetingInfo>
            </div>
            <div
              className={classNames('d-flex', 'align-items-center', {
                'justify-content-between':
                  meeting.type === 'PHONE' || !!meeting.address || meeting.type === 'COMMITTEE',
                'justify-content-end': !meeting.address && meeting.type === 'IN_PERSON',
              })}
            >
              {meeting.type === 'IN_PERSON' && meeting.address && (
                <a
                  title={`${meeting.address.street} - ${meeting.address.zipCode}, ${meeting.address.city}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://www.google.com/maps/search/?api=1&query=${toUrlEncoded(
                    meeting.address
                  )}`}
                  style={{ lineHeight: '12px' }}
                >
                  <div>
                    <MeetingInfo>{`${meeting.address.street} - ${meeting.address.zipCode}, ${meeting.address.city}`}</MeetingInfo>
                  </div>
                </a>
              )}
              {meeting.type === 'PHONE' && (
                <MeetingInfo>Votre conseiller vous appellera</MeetingInfo>
              )}
              {meeting.type === 'COMMITTEE' && (
                <div>
                  <MeetingInfo>
                    {project && project.association && project.association.name}
                  </MeetingInfo>
                </div>
              )}
            </div>
          </div>
          <div
            style={{ textAlign: 'end' }}
            className={'d-flex flex-column justify-content-between'}
          >
            {meetingAdvisor && (
              <div style={{ lineHeight: '12px' }}>
                <MeetingInfo
                  style={{ textAlign: 'end' }}
                >{`${meetingAdvisor.firstName} ${meetingAdvisor.lastName}`}</MeetingInfo>
              </div>
            )}
            {children}
          </div>
        </div>
      </ClickableMeeting>
      <MeetingPopover
        target={target}
        popoverOpen={popoverOpen}
        toggle={() => setPopoverOpen(!popoverOpen)}
        meeting={meeting}
      />
    </>
  )
}

import React, { FC, useEffect, useState } from 'react'
import {
  Advisor,
  FinancementOperationStatus,
  ProjectFinancementStage,
  FinancementOperation,
  FinancementOperationsArchiveesStatusArray,
  FinancementOperationsSuiviesStatusArray,
} from '../../../../model/project'
import { FinancementOperationStatusDictionary } from '../../../../service/projectService'
import { Card, Col, Input } from 'reactstrap'
import { H4 } from '../../../shared/H/H'
import './Products.scss'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { useAppContext } from '../../../../context'
import { Link } from 'react-router-dom'

const CardTitle = styled.div`
  background-color: #78acd4;
  color: white;
  margin-bottom: 0;
  padding-top: 0.75em;
  padding-bottom: 1em;
  padding-left: 0.75em;
`

const OtherCardTitle = styled.div`
  background-color: #9098a9;
  color: white;
  margin-bottom: 0;
  padding-top: 0.75em;
  padding-bottom: 1em;
  padding-left: 0.75em;
`

const CardMainTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
`

const CardTitleSubtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
`
const CardSubTitle = styled.div`
  background-color: var(--main-light-gray);
  font-size: 14px;
  padding-top: 0.75em;
  padding-left: 0.75em;
  height: 5em;
  color: black;
`

// Quand une card par colonne, on ne veut pas fixer la hauteur
// notament pour les tablettes et smartphones
const CardBody = styled.div`
    height: 570px;
    font-size: 14px;
    margin-left: 1em;
    @media (max-width: 984px){
      height:auto;
      margin-bottom: 10px;
    )
`

const ProductDetail = styled.div`
  margin-top: 1em;
`

const ProductDetailLabel = styled.div`
  color: black;
  font-weight: 700;
  max-width: 266px;
`

const SortDetail = styled.div`
  display: flex;
  justify-content: space-between;
  color: black;
`

const OperationFilters = styled.div`
  color: var(--main-gray);
`

export type SortCriteria = 'creationDate' | 'committeeDate'

export type BlueCard = 'INIT' | 'DECISION' | 'IN_CONTRACT' | 'ACTIVATED'
export type GreyCard = 'ABANDONNE' | 'AJOURNE' | 'REFUSE'

export // Apply the sort and filter criteria
const applySortAndFilter = (
  sortedOperations: FinancementOperation[],
  sortingCriteria: SortCriteria[],
  statusFilterCriteria: ProjectFinancementStage[]
) => {
  let sortedResult = sortedOperations
  // Sort by creation date desc
  if (sortingCriteria.includes('creationDate')) {
    sortedResult = sortedResult.slice().sort((a, b) => {
      if (a.creationDate < b.creationDate) {
        return 1
      }
      if (a.creationDate > b.creationDate) {
        return -1
      }
      return 0
    })
  }

  // Sort by committee date desc (when date is null, it's going at the end of the list)
  if (sortingCriteria.includes('committeeDate')) {
    sortedResult = sortedResult.slice().sort((a, b) => {
      if (a.comitteeDate === null || a.comitteeDate === undefined) {
        if (b.comitteeDate !== null && b.comitteeDate !== undefined) {
          return 1
        }
        return 0
      }

      if (b.comitteeDate === null || b.comitteeDate === undefined) {
        return -1
      }

      if (a.comitteeDate < b.comitteeDate) {
        return 1
      }
      if (a.comitteeDate > b.comitteeDate) {
        return -1
      }
      return 0
    })
  }

  sortedResult = sortedResult.slice().filter(o => statusFilterCriteria.includes(o.timelineStatus))

  return sortedResult
}

export const Products: FC<{ operations: FinancementOperation[]; advisor: Advisor | undefined }> = ({
  operations,
  advisor,
}) => {
  const [operationsDisplayed, setOperationsDisplayed] = useState<FinancementOperation[]>([])
  const { messengerStore } = useAppContext()

  // Keep sorting and filtering criteria
  const [sortingCriteria, setSortingCriteria] = useState<SortCriteria[]>(['creationDate'])
  const [selectedFilterCriteria, setSelectedFilterCriteria] = useState<ProjectFinancementStage[]>([
    'INIT',
    'IN_CONTRACT',
    'ACTIVATED',
    'DECISION',
    'AJOURNE',
    'REFUSE',
    'ABANDONNE',
  ])

  // Manage change in sorting criteria
  const manageSortChange = (criteria: SortCriteria) => {
    const newSortingCriteria: SortCriteria[] = [...sortingCriteria]

    if (criteria === 'creationDate' && !newSortingCriteria.includes('creationDate')) {
      newSortingCriteria.splice(0, newSortingCriteria.length)
      newSortingCriteria.push('creationDate')
    }

    if (criteria === 'committeeDate' && !newSortingCriteria.includes('committeeDate')) {
      newSortingCriteria.splice(0, newSortingCriteria.length)
      newSortingCriteria.push('committeeDate')
    }

    setSortingCriteria(newSortingCriteria)
  }

  // Manage change in filter criteria
  const manageFilterChange = (filterValue: ProjectFinancementStage) => {
    const newFilterCriteria: ProjectFinancementStage[] = [...selectedFilterCriteria]

    if (selectedFilterCriteria.includes(filterValue)) {
      newFilterCriteria.splice(newFilterCriteria.indexOf(filterValue), 1)
    } else {
      newFilterCriteria.push(filterValue)
    }
    setSelectedFilterCriteria(newFilterCriteria)
  }

  useEffect(() => {
    setOperationsDisplayed(applySortAndFilter(operations, sortingCriteria, selectedFilterCriteria))
  }, [operations, sortingCriteria, selectedFilterCriteria])

  const operationsCount = {
    INIT: 0,
    IN_CONTRACT: 0,
    ACTIVATED: 0,
    DECISION: 0,
    AJOURNE: 0,
    ABANDONNE: 0,
    REFUSE: 0,
  }
  operations.forEach(value => {
    operationsCount[value.timelineStatus] = operationsCount[value.timelineStatus] + 1
  })

  const advisorLabel =
    advisor !== undefined ? advisor.firstName + ' ' + advisor.lastName : 'votre conseiller'
  return (
    <>
      <div className="Products">
        <Card body>
          <H4 arrowColor={'yellow'} text={'Solutions de financement'} />
          <div style={{ fontStyle: 'italic', marginBottom: '20px' }}>
            Pour plus d’explications sur le détail et l’avancement des solutions de financement
            demandées, contactez{' '}
            {messengerStore.messengerEnabled ? <Link to="/m">{advisorLabel}</Link> : advisorLabel}.
          </div>
          <div className="Deliverable row">
            <Col xs={12} sm={8} md={6} lg={3}>
              <div>
                <div style={{ marginBottom: '2em', color: 'black' }}>
                  <div
                    style={{ fontSize: '18px', marginBottom: '-10px', color: 'var(--main-gray)' }}
                  >
                    Trier par :
                  </div>
                  <hr />
                  <div>
                    <SortButton
                      label={'Date de création'}
                      selected={sortingCriteria.includes('creationDate')}
                      onClick={() => manageSortChange('creationDate')}
                    />
                    <SortButton
                      label={'Date de comité'}
                      selected={sortingCriteria.includes('committeeDate')}
                      onClick={() => manageSortChange('committeeDate')}
                    />
                  </div>
                </div>
                <OperationFilters>
                  <div style={{ fontSize: '18px', marginBottom: '-10px' }}>Filtrer par :</div>
                  <hr />
                  <div>
                    <div style={{ color: 'black' }}>
                      <b>Demandes suivies</b>
                    </div>
                    <ul style={{ listStyle: 'none' }}>
                      {FinancementOperationsSuiviesStatusArray.map(status => (
                        <FilterButton
                          key={`${status}:${selectedFilterCriteria.indexOf(status) !== -1}`}
                          operationStatus={status}
                          elementNbr={operationsCount[status]}
                          onClick={manageFilterChange}
                          filterCriteria={selectedFilterCriteria}
                        />
                      ))}
                    </ul>
                    <div style={{ color: 'black' }}>
                      <b>Demandes archivées</b>
                    </div>
                    <ul style={{ listStyle: 'none' }}>
                      {FinancementOperationsArchiveesStatusArray.map(status => (
                        <FilterButton
                          key={`${status}:${selectedFilterCriteria.indexOf(status) !== -1}`}
                          operationStatus={status}
                          elementNbr={operationsCount[status]}
                          onClick={manageFilterChange}
                          filterCriteria={selectedFilterCriteria}
                        />
                      ))}
                    </ul>
                  </div>
                </OperationFilters>
              </div>
            </Col>
            <OperationsDisplay
              operations={operationsDisplayed !== undefined ? operationsDisplayed : []}
            />
          </div>
        </Card>
      </div>
    </>
  )
}

const SortButton: FC<{ label: string; selected: boolean; onClick: () => any }> = ({
  label,
  selected,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        textDecoration: selected ? 'underline' : 'none',
        cursor: 'pointer',
      }}
    >
      <SortDetail>
        <div>
          <b>{label}</b>
        </div>
        <div
          style={{
            display: 'inline',
            color: selected ? 'black' : 'lightgray',
          }}
        >
          <FontAwesomeIcon icon="arrow-up" style={{ fontSize: '1.25em' }} />
        </div>
      </SortDetail>
    </div>
  )
}

const LiFilter = styled.li`
  input {
    cursor: pointer;
  }
  label {
    cursor: pointer;
  }
`

const FilterButton: FC<{
  operationStatus: FinancementOperationStatus
  elementNbr: number
  onClick: (status: FinancementOperationStatus) => any
  filterCriteria: FinancementOperationStatus[]
}> = ({ operationStatus, elementNbr, onClick, filterCriteria }) => {
  const checked = filterCriteria.indexOf(operationStatus) !== -1

  return (
    <LiFilter>
      <Input
        type="checkbox"
        id={`${operationStatus}Filter`}
        onClick={() => onClick(operationStatus)}
        defaultChecked={checked}
      />
      <label htmlFor={`${operationStatus}Filter`}>
        {FinancementOperationStatusDictionary[operationStatus]} ({elementNbr})
      </label>
    </LiFilter>
  )
}

const OperationsDisplay: FC<{ operations: FinancementOperation[] }> = ({ operations }) => {
  return (
    <Col xs={12} sm={8} md={8} lg={8}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {operations.length > 0 &&
          operations.map(o => (
            <Card
              style={{
                border: '1px solid #c6c6c6',
                marginBottom: '25px',
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
                minWidth: '280px',
                marginRight: '10px',
              }}
              key={o.id}
            >
              <div style={{ color: 'var(--main-gray)' }}>
                {o.timelineStatus === 'INIT' ||
                o.timelineStatus === 'DECISION' ||
                o.timelineStatus === 'IN_CONTRACT' ||
                o.timelineStatus === 'ACTIVATED' ? (
                  <CardTitle style={{ height: '100px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <CardTitleSubtitle>{o.type.funding}</CardTitleSubtitle>
                    </div>
                    <div>
                      <CardMainTitle>{o.type.description} </CardMainTitle>
                    </div>
                  </CardTitle>
                ) : (
                  <OtherCardTitle style={{ height: '100px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <CardTitleSubtitle>{o.type.funding}</CardTitleSubtitle>
                    </div>
                    <div>
                      <CardMainTitle>{o.type.description} </CardMainTitle>
                    </div>
                  </OtherCardTitle>
                )}

                <CardSubTitle>
                  <div>
                    <b>
                      Statut :{' '}
                      <span
                        style={{
                          color:
                            o.timelineStatus === 'INIT' ||
                            o.timelineStatus === 'DECISION' ||
                            o.timelineStatus === 'IN_CONTRACT' ||
                            o.timelineStatus === 'ACTIVATED'
                              ? '#78ACD4'
                              : '#9098a9',
                        }}
                      >
                        {FinancementOperationStatusDictionary[o.timelineStatus]}
                      </span>
                    </b>
                  </div>
                  {o.comitteeDate && (
                    <div>
                      <b>Date de comité :</b>{' '}
                      <span style={{ color: 'var(--main-gray)' }}>
                        {dayjs(o.comitteeDate).format('DD/MM/YY')}
                      </span>
                    </div>
                  )}
                </CardSubTitle>
                <CardBody>
                  {(o.timelineStatus === 'INIT' || o.timelineStatus === 'DECISION') && (
                    <ProductDetail>
                      <ProductDetailLabel>
                        Les solutions de financement sont données à titre indicatif et ne peuvent
                        être engageantes qu’après une validation du comité de financement.
                      </ProductDetailLabel>
                    </ProductDetail>
                  )}
                  <ProductDetail>
                    <ProductDetailLabel>
                      Montant demandé{' '}
                      {(o.timelineStatus === 'IN_CONTRACT' || o.timelineStatus === 'ACTIVATED') && (
                        <span style={{ color: '#78ACD4' }}> / accordé</span>
                      )}{' '}
                      :
                    </ProductDetailLabel>
                    <div>
                      {o.askedAmount}&euro;
                      {(o.timelineStatus === 'IN_CONTRACT' || o.timelineStatus === 'ACTIVATED') && (
                        <span style={{ color: '#78ACD4' }}> / {o.amount}&euro;</span>
                      )}
                    </div>
                  </ProductDetail>
                  {(o.type.funding === 'Prêt' || o.type.funding === 'Garantie') && (
                    <ProductDetail>
                      <ProductDetailLabel>
                        {o.type.funding === 'Prêt' ? 'Taux' : 'Quotité'} :
                      </ProductDetailLabel>
                      <div>{o.rate}%</div>
                    </ProductDetail>
                  )}
                  {o.askedLength > 0 && (
                    <ProductDetail>
                      <ProductDetailLabel>
                        Durée demandée{' '}
                        {(o.timelineStatus === 'IN_CONTRACT' ||
                          o.timelineStatus === 'ACTIVATED') && (
                          <span style={{ color: '#78ACD4' }}> / accordée </span>
                        )}
                        :
                      </ProductDetailLabel>
                      <div>
                        {o.askedLength} mois
                        {(o.timelineStatus === 'IN_CONTRACT' ||
                          o.timelineStatus === 'ACTIVATED') && (
                          <span style={{ color: '#78ACD4' }}>
                            {' '}
                            / {o.lengthWithDelay - o.delay} mois
                          </span>
                        )}
                      </div>
                    </ProductDetail>
                  )}
                  {o.type.funding === 'Prêt' && (
                    <ProductDetail>
                      <ProductDetailLabel>Différé :</ProductDetailLabel>
                      <div>{o.delay} mois</div>
                    </ProductDetail>
                  )}

                  {o.type.funding === 'Prêt' && o.lengthWithDelay > 0 && (
                    <ProductDetail>
                      <ProductDetailLabel>Durée :</ProductDetailLabel>
                      <div>{o.lengthWithDelay} mois</div>
                    </ProductDetail>
                  )}

                  {o.decaissementDate && (
                    <ProductDetail>
                      <ProductDetailLabel>Date de décaissement :</ProductDetailLabel>
                      <div> {dayjs(o.decaissementDate).format('DD/MM/YY')}</div>
                    </ProductDetail>
                  )}

                  {o.loan && (
                    <ProductDetail>
                      <div>
                        <b style={{ color: 'black' }}>
                          Lié{o.type.funding === 'Garantie' && 'e'} à l’octroi d’un prêt bancaire :
                        </b>
                        <ul>
                          <li>Montant demandé : {o.loan.askedAmount}&euro;</li>
                          <li>Durée demandée : {o.loan.askedLength} mois</li>
                          <li>Taux : {o.loan.rate}%</li>
                          <li>Différé : {o.loan.delay} mois</li>
                        </ul>
                      </div>
                    </ProductDetail>
                  )}
                </CardBody>
              </div>
            </Card>
          ))}
      </div>
    </Col>
  )
}

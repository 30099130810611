import React, { FC } from 'react'
import { Card } from 'reactstrap'

import '../AvancementProjet.scss'
import { observer } from 'mobx-react-lite'
import { H4 } from '../../../shared/H/H'
import { useAppContext } from '../../../../context'

const AvancementProjetMobile: FC<{}> = observer(() => {
  const { projectsStore, routingStore } = useAppContext()
  return (
    <div className="AvancementProjet">
      <Card body>
        <H4 arrowColor={'green'}>
          {projectsStore.projects.length > 1 ? 'Mes projets' : 'Mon projet'}
        </H4>
        <div className="Timelines">
          {projectsStore.projects.map(project => (
            <div
              key={project.id}
              className="Timeline d-flex"
              onClick={() => routingStore.history.push(`/p/${project.id}`)}
            >
              <div style={{ flex: 1 }}>
                <div>
                  Projet : <span className="projectName serif">{project.name}</span>
                </div>
                {project.livrable && project.livrable!.pact && (
                  <div>
                    Pacte :{' '}
                    <span className="projectName serif">
                      <a
                        href={project.livrable!.pact.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {project.livrable!.pact.name}
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>
          ))}

          {projectsStore.projectsFetched && !projectsStore.projects.length && (
            <div>Vous n'avez pas de projet en cours</div>
          )}
        </div>
      </Card>
    </div>
  )
})

export default AvancementProjetMobile

import React, { FunctionComponent } from 'react'
import { Redirect, Route } from 'react-router'

import { observer } from 'mobx-react'
import Titled from './Titled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppContext } from '../context'

export interface RouteDefinition {
  path: string
  component: any
  title?: string
  routes?: RouteDefinition[]
  secured?: boolean
  renderCondition?: () => boolean
  waitCondition?: () => boolean
  exact?: boolean
}

export const RouteWithSubRoutes: FunctionComponent<RouteDefinition> = observer(
  (rtDef: RouteDefinition) => {
    const { authStore } = useAppContext()
    const display = !rtDef.renderCondition || rtDef.renderCondition()
    const wait = rtDef.waitCondition && rtDef.waitCondition()

    return (
      <Route
        exact={rtDef.exact}
        path={rtDef.path}
        render={props =>
          wait ? (
            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
              <FontAwesomeIcon icon="spinner" size={'5x'} spin={true} />
            </div>
          ) : (
            <>
              <Titled title={(t: string) => `${rtDef.title} | ${t}`} />
              {(rtDef.secured && !authStore.isLoggedIn) || (!display && <Redirect to={'/'} />)}
              <rtDef.component {...props} routes={rtDef.routes} />
            </>
          )
        }
      />
    )
  }
)

import styled from 'styled-components'
import { Card } from 'reactstrap'

export const FATitleCard = styled(Card)`
  margin-bottom: 20px;
  color: var(--main-black-2);
`

export const FACard = styled(Card)`
  padding: 20px;
`

import React, { FC, useEffect, useState } from 'react'
import { DisplayableResource } from '../../../../model/resource'
import { Card, CardBody, CardImg, CardTitle } from 'reactstrap'
import { Mime, mimeTypeService } from '../../../../service/mimetypes.service'

import docImg from '../../../../assets/images/application-doc.svg'
import archiveImg from '../../../../assets/images/application-archive.svg'
import pdfImg from '../../../../assets/images/application-pdf.svg'
import presentationImg from '../../../../assets/images/application-presentation.svg'
import spreadSheetImg from '../../../../assets/images/application-spreadsheet.svg'
import genericImg from '../../../../assets/images/file-generic.svg'
import audioImg from '../../../../assets/images/media-audio.svg'
import imageImg from '../../../../assets/images/media-image.svg'
import videoImg from '../../../../assets/images/media-video.svg'

export interface ResourceDocumentProps {
  resource: DisplayableResource
}

const mimeFromCall = async (link: string) => {
  const response = await fetch(link, {
    method: 'HEAD',
    cache: 'no-cache',
  })
  return response.headers.get('Content-Type')
}

const imageFor = (mimeType: Mime) => {
  switch (mimeType) {
    case 'doc':
      return docImg
    case 'spreadsheet':
      return spreadSheetImg
    case 'presentation':
      return presentationImg
    case 'pdf':
      return pdfImg
    case 'archive':
      return archiveImg
    case 'image':
      return imageImg
    case 'audio':
      return audioImg
    case 'video':
      return videoImg
    case 'other':
      return genericImg
  }
}

export const ResourceDocument: FC<ResourceDocumentProps> = ({ resource }) => {
  const mime = resource.metadata['DOCUMENT_MIME_TYPE'] || ''
  const [mimeType, setMime] = useState<Mime>(mimeTypeService.getKnownTypeForMimeType(mime))

  useEffect(() => {
    const updateMime = async () => {
      const mime = await mimeFromCall(resource.link)
      setMime(mimeTypeService.getKnownTypeForMimeType(mime || ''))
    }
    if (mime === '') {
      updateMime()
    }
  }, [mime, resource])

  return (
    <Card className="ResourceLink">
      <a href={resource.link} target="_blank" rel="noopener noreferrer">
        <CardImg top className="ResourceLink-Image doc" src={imageFor(mimeType)} />

        <CardBody className="d-flex">
          <CardTitle className="ResourceLink-Title">{resource.name}</CardTitle>
        </CardBody>
      </a>
    </Card>
  )
}

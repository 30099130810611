import React from 'react'
import { Card } from 'reactstrap'
import { H2, H4 } from '../../components/shared/H/H'

const Dpd = () => (
  <Card body>
    <H2 arrowColor={'green'}>Contacter le délégué à la protection des données (DPD)</H2>
    <div style={{ margin: '1rem', textAlign: 'justify' }}>
      <p>
        Si vous souhaitez obtenir des informations sur les traitements de données personnelles et
        exercer vos droits d’utilisateur, vous pouvez contacter le délégué à la protection des
        données (DPD) par :
      </p>

      <H4 arrowColor={'yellow'} className={'mt-5'}>
        Courriel
      </H4>
      <p>
        À l'adresse suivante : <a href="mailto:dpo@franceactive.org">dpo@franceactive.org</a>
      </p>

      <H4 arrowColor={'yellow'} className={'mt-5'}>
        Par courrier
      </H4>
      <p>À l'adresse suivante :</p>
      <p>
        France Active À l'attention du délégué à la protection des données (DPD) Tour Cityscope 3
        rue Franklin 93100 Montreuil
      </p>

      <H4 arrowColor={'yellow'} className={'mt-5'}>
        Par téléphone
      </H4>
      <p>Au numéro suivant : 01 53 24 26 26</p>
      <p>Le traitement de votre demande s’effectuera dans les meilleurs délais.</p>
    </div>
  </Card>
)

export default Dpd

import React, { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { useAppContext } from '../../../../context'
import {
  ButtonPopover,
  CustomPopover,
  LinePopover,
  TextPopover,
  TitrePopOver,
} from '../../../shared/CustomStyledComponents/Popover'
import { PopoverBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const OnBoardingProject: FC<{ open: boolean }> = observer(({ open }) => {
  const [onBoardingState, setOnboardingState] = useState<number>(open ? 1 : 0)

  return (
    <>
      {onBoardingState === 1 && (
        <OnBoardingDashBoard
          targetLast={''}
          target={'PROJECT'}
          isOpen={onBoardingState === 1}
          toggle={closed => (closed ? setOnboardingState(0) : setOnboardingState(2))}
          number={onBoardingState}
          placement={'top'}
        />
      )}
      {onBoardingState === 2 && (
        <OnBoardingDashBoard
          targetLast={'PROJECT'}
          target={'MEMENTO'}
          isOpen={onBoardingState === 2}
          toggle={closed => (closed ? setOnboardingState(0) : setOnboardingState(3))}
          number={onBoardingState}
          placement={'top'}
        />
      )}
      {onBoardingState === 3 && (
        <OnBoardingDashBoard
          targetLast={'MEMENTO'}
          target={'HISTORY'}
          isOpen={onBoardingState === 3}
          toggle={closed => (closed ? setOnboardingState(0) : setOnboardingState(4))}
          number={onBoardingState}
          placement={'top'}
        />
      )}
      {onBoardingState === 4 && (
        <OnBoardingDashBoard
          targetLast={'HISTORY'}
          target={'PRODUCTS'}
          isOpen={onBoardingState === 4}
          toggle={closed => (closed ? setOnboardingState(0) : setOnboardingState(5))}
          number={onBoardingState}
          placement={'top'}
        />
      )}
      {onBoardingState === 5 && (
        <OnboardingEnd
          targetLast={'PRODUCTS'}
          target={'DOCUMENTS'}
          isOpen={onBoardingState === 5}
          toggle={() => setOnboardingState(0)}
          number={onBoardingState}
          placement={'bottom'}
        />
      )}
    </>
  )
})

const OnBoardingDashBoard: FC<{
  targetLast: string
  target: string
  isOpen: boolean
  toggle: (closed: boolean) => any
  number: number
  placement: string
}> = ({ targetLast, target, isOpen, toggle, number, placement }) => {
  const { accountService } = useAppContext()

  return (
    <>
      {document.getElementById(targetLast)?.setAttribute('style', 'z-index: 0')}
      {target === 'PROJECT'
        ? document
            .getElementById(target)
            ?.setAttribute('style', 'z-index: 11; background-color: var(--main-green)')
        : document
            .getElementById(target)
            ?.setAttribute('style', 'z-index: 11; background-color: white')}

      <CustomPopover
        target={target}
        isOpen={isOpen}
        toggle={toggle}
        trigger="classic"
        placement={placement}
      >
        <PopoverBody>
          <TextPopover style={{ display: 'flex' }}>
            {number}/5
            <button
              style={{ marginLeft: 'auto' }}
              className="fa-layers fa-fw fa-lg btn-naked"
              onClick={() => {
                toggle(true)
                accountService.updateOnBoarding({ param: 'PROJECT', checked: true })
                document.getElementById(targetLast)?.setAttribute('style', 'z-index: 0')
                document.getElementById(target)?.setAttribute('style', 'z-index: 0')
              }}
            >
              <FontAwesomeIcon size="lg" icon="times" color={'var(--main-gray-2)'} />
            </button>
          </TextPopover>
          {number === 1 && <InnerOnBoardingOne />}
          {number === 2 && <InnerOnBoardingTwo />}
          {number === 3 && <InnerOnBoardingThree />}
          {number === 4 && <InnerOnBoardingFour />}
          <LinePopover>
            <div style={{ marginLeft: 'auto' }}>
              <ButtonPopover
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  toggle(false)
                }}
              >
                Suivant
              </ButtonPopover>
            </div>
          </LinePopover>
        </PopoverBody>
      </CustomPopover>
    </>
  )
}

const InnerOnBoardingOne: FC<{}> = () => {
  return (
    <>
      <TitrePopOver>Fiche d'identité de votre projet</TitrePopOver>
      <div>
        <LinePopover>
          <TextPopover>
            Retrouvez :<br />
            <span style={{ marginLeft: '20px' }}>- l'état d'avancement de votre projet</span>
            <br />
            <span style={{ marginLeft: '20px' }}>- les informations sur votre structure</span>
            <br />
            <span style={{ marginLeft: '20px' }}>
              - les coordonnées de votre conseiller et de France{' '}
              <span style={{ marginLeft: '30px' }}>Active</span>
            </span>
            <br />
            <span style={{ marginLeft: '20px' }}>- vos documents</span>
          </TextPopover>
        </LinePopover>
      </div>
    </>
  )
}

const InnerOnBoardingTwo: FC<{}> = () => {
  return (
    <>
      <TitrePopOver>Mémento de votre projet</TitrePopOver>
      <div>
        <LinePopover>
          <TextPopover>
            Retrouvez l'analyse objective et complète de votre projet menée par votre conseiller
          </TextPopover>
        </LinePopover>
      </div>
    </>
  )
}

const InnerOnBoardingThree: FC<{}> = () => {
  return (
    <>
      <TitrePopOver>
        Historique de votre activité sur
        <br />
        L'APPLI by FRANCE ACTIVE
      </TitrePopOver>
      <div>
        <LinePopover>
          <TextPopover>
            Retrouvez ici toutes vos notifications depuis la création de votre compte
          </TextPopover>
        </LinePopover>
      </div>
    </>
  )
}

const InnerOnBoardingFour: FC<{}> = () => {
  return (
    <>
      <TitrePopOver>Financement de votre projet</TitrePopOver>
      <div>
        <LinePopover>
          <TextPopover>
            Retrouvez ici le détail de chaque solution de financement de votre projet
          </TextPopover>
        </LinePopover>
      </div>
    </>
  )
}

const OnboardingEnd: FC<{
  targetLast: string
  target: string
  isOpen: boolean
  toggle: () => any
  number: number
  placement: string
}> = ({ targetLast, target, isOpen, toggle, number, placement }) => {
  const { accountService } = useAppContext()
  return (
    <>
      {document.getElementById(targetLast)?.setAttribute('style', 'z-index: 0')}
      {document
        .getElementById('DOCUMENTS')
        ?.setAttribute('style', 'z-index: 11; background-color: white')}
      {document
        .getElementById('DOCUMENTS_ADMIN')
        ?.setAttribute('style', 'z-index: 11; background-color: white')}
      <CustomPopover
        target={target}
        isOpen={isOpen}
        toggle={toggle}
        trigger="classic"
        placement={placement}
      >
        <PopoverBody>
          <TextPopover style={{ display: 'flex' }}>
            {number}/5
            <button
              style={{ marginLeft: 'auto' }}
              className="fa-layers fa-fw fa-lg btn-naked"
              onClick={() => {
                toggle()
                accountService.updateOnBoarding({ param: 'PROJECT', checked: true })
                document.getElementById(targetLast)?.setAttribute('style', 'z-index: 0')
                document.getElementById(target)?.setAttribute('style', 'z-index: 0')
              }}
            >
              <FontAwesomeIcon size="lg" icon="times" color={'var(--main-gray-2)'} />
            </button>
          </TextPopover>
          <TitrePopOver>Documents liés à votre projet</TitrePopOver>
          <div>
            <LinePopover>
              <TextPopover>
                Ajoutez directement des documents à transmettre à votre conseiller en toute
                simplicité
              </TextPopover>
            </LinePopover>
          </div>
          <LinePopover>
            <div style={{ marginLeft: 'auto' }}>
              <ButtonPopover
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  toggle()
                  accountService.updateOnBoarding({ param: 'PROJECT', checked: true })
                  document.getElementById(targetLast)?.setAttribute('style', 'z-index: 0')
                  document.getElementById(target)?.setAttribute('style', 'z-index: 0')
                }}
              >
                Terminer
              </ButtonPopover>
            </div>
          </LinePopover>
        </PopoverBody>
      </CustomPopover>
    </>
  )
}

import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC } from 'react'
import { Color } from './Colors'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const FAIconButtonStyled = styled.div`
  :hover {
    background-color: var(--main-gray-3);
    cursor: pointer;
  }
  text-align: center;
  width: 44px;
  height: 44px;
  padding: 10px;
  border-radius: 50%;
`

export const FAIconButton: FC<{ onClick: () => any; icon: IconProp; color: Color }> = ({
  color,
  icon,
  onClick,
}) => {
  return (
    <FAIconButtonStyled onClick={onClick}>
      <FontAwesomeIcon icon={icon} color={`var(${color})`} size={'lg'} />
    </FAIconButtonStyled>
  )
}

const RoundIconButton = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid var(--main-gray-2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
    background-color: var(--main-gray-3);
  }
`

type ChevronTypes = 'angle-double-right' | 'angle-double-left' | 'chevron-right' | 'chevron-left'

export const ButtonRoundChevron: FC<{ type: ChevronTypes; onClick: () => any }> = ({
  onClick,
  type,
}) => {
  return (
    <RoundIconButton className={'mr-1'} onClick={onClick}>
      <FontAwesomeIcon icon={type} size={'xs'} color={'var(--main-gray)'} />
    </RoundIconButton>
  )
}

const StyledPrimaryButton = styled.button`
  color: white;
  border-radius: 4px;
  width: fit-content;
  padding: 10px 30px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  :hover {
    background-color: var(--main-black-2) !important;
    color: white;
  }
`

export const PrimaryButton: FC<{
  color: Color
  onClick: () => any
  disabled?: boolean
  className?: string
  fillParent?: boolean
}> = ({ color, onClick, disabled = false, children, className = '', fillParent = false }) => {
  return (
    <StyledPrimaryButton
      className={`btn ${className}`}
      style={{ backgroundColor: `var(${color})`, width: fillParent ? '100%' : '' }}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </StyledPrimaryButton>
  )
}

export const SecondaryButton: FC<{
  color: Color
  onClick: () => any
  disabled?: boolean
  className?: string
}> = ({ color, onClick, disabled = false, children, className = '' }) => {
  const StyledSecondaryButton = styled(StyledPrimaryButton as any)`
    color: var(--main-gray);
    background-color: var(--main-gray-3);
    :hover {
      background-color: var(${color}) !important;
      color: white;
    }
  `
  return (
    <StyledSecondaryButton className={`btn ${className}`} onClick={onClick} disabled={disabled}>
      {children}
    </StyledSecondaryButton>
  )
}

export function uniq<T>(array: T[]): T[] {
  return uniqBy(array, it => it)
}

export function uniqBy<T, R>(
  array: T[],
  extractor: ((t: T) => R) | null = null
): T[] {
  if (!extractor) {
    return uniq(array)
  }

  return array.reduce(
    (acc: any, val: T) => {
      const prop: R = extractor(val)
      const { res, unique } = acc
      if (unique.has(prop)) {
        return acc
      }
      return { res: [...res, val], unique: new Set([...unique, prop]) }
    },
    { res: [], unique: new Set<R>() }
  ).res
}

import { action, observable } from 'mobx'
import { ProjectDocument } from '../../../model/document'
import { ApiProxy } from '../../../service/APIProxy'

export type SendingState = 'not_started' | 'sending' | 'completed' | 'error'

export class DocumentUpload {
  @observable progress: number = 0
  @observable sendingState: SendingState = 'not_started'
  @observable document: ProjectDocument

  constructor(doc: ProjectDocument, private apiProxy: ApiProxy) {
    this.document = doc
  }

  @action send = async (files: File[]) => {
    const fd = new FormData()
    files.forEach(f => fd.append('file', f, f.name))
    this.sendingState = 'sending'
    try {
      this.document = await this.apiProxy.formData<ProjectDocument>(
        `/project/${this.document.projectId}/documents/${this.document.id}/`,
        fd,
        p => (this.progress = p)
      )
      this.sendingState = 'completed'
    } catch (e) {
      this.sendingState = 'error'
    }
  }
}

import React, { FC, memo, useState } from 'react'
import './Meetings.scss'
import { Button, Card, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Meeting } from '../../../model/project'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MeetingC } from './MeetingC'
import { H4 } from '../../shared/H/H'
import { ReactComponent as MeetingIcon } from '../../../assets/images/meeting_2.svg'
import { MaterialLikeInput } from '../../shared/MaterialLikeInput/MaterialLikeInput'
import Skeleton from 'react-loading-skeleton'
import { useAppContext } from '../../../context'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { unzonedDayjs } from '../../../utils/date'

const LOCAL_DATE_FORMAT = 'dddd D MMMM'

const GreyText = styled.span`
  color: var(--main-gray);
`

const Scrollable = styled.div`
  max-height: 1036px;
  overflow-y: scroll;
  margin-right: -20px;
`

const CancelLink = styled(GreyText as any)`
  font-size: 12px;
  text-decoration: underline;
  white-space: nowrap;
  :hover {
    cursor: pointer;
  }
`

const Divider = styled.hr`
  margin: 8px -20px !important;
`

const Meetings: FC<{ title: string; meetings: Meeting[]; linkToCalendar?: boolean }> = observer(
  ({ title, meetings, linkToCalendar = false }) => {
    const { projectsStore, historiesStore } = useAppContext()
    const [meetingToCancel, setMeetingToCancel] = useState<Meeting>()

    const cancelMeeting = async (meeting: Meeting, reason: string) => {
      await projectsStore.cancelMeeting(meeting, reason, false)
      setMeetingToCancel(undefined)
      await historiesStore.refresh(meeting.projectId)
    }

    function renderMeetingsOfMonth(monthMeetings: Meeting[]) {
      const meetingsByDay = monthMeetings.reduce(
        (result: Record<string, Meeting[]>, m: Meeting) => {
          const displayDate = unzonedDayjs(m.date).format(LOCAL_DATE_FORMAT)
          result[displayDate] = result[displayDate] || []
          result[displayDate].push(m)
          return result
        },
        Object.create(null)
      )

      return (
        <>
          {Object.keys(meetingsByDay).map((date: string) => (
            <>
              <div key={date} className={'m-2 mt-3'}>
                <div className={'Meeting'}>
                  <div
                    className={'d-flex justify-content-between'}
                    style={
                      dayjs(date, LOCAL_DATE_FORMAT)
                        .year(dayjs().year())
                        .isBefore(dayjs(), 'day')
                        ? { opacity: 0.5 }
                        : {}
                    }
                  >
                    <GreyText style={{ fontSize: '14px', fontStyle: 'italic' }}>{date}</GreyText>
                  </div>
                  {meetingsByDay[date].map(m => (
                    <MeetingC meeting={m} key={m.id}>
                      {m.type !== 'COMMITTEE' &&
                        unzonedDayjs(m.date)
                          .add(m.duration, 'minute')
                          .isAfter(dayjs()) &&
                        projectsStore.project(m.projectId)?.active && (
                          <CancelLink
                            style={{ zIndex: 10 }}
                            onClick={(e: {
                              nativeEvent: { stopImmediatePropagation: () => void }
                            }) => {
                              e.nativeEvent.stopImmediatePropagation()
                              setMeetingToCancel(m)
                            }}
                          >
                            Annuler le RDV
                          </CancelLink>
                        )}
                    </MeetingC>
                  ))}
                </div>
              </div>
            </>
          ))}
        </>
      )
    }

    const renderMeetings = (meetings: Meeting[]) => {
      const meetingsByMonthAndYear = meetings.reduce((result, m) => {
        const key = dayjs(m.date).format('YYYY-MM')
        result[key] = result[key] || []
        result[key].push(m)
        return result
      }, Object.create(null))

      return (
        <Scrollable>
          {Object.keys(meetingsByMonthAndYear).map(monthAndYear => (
            <>
              <div className={'d-flex'}>
                <div className="divider mt-2" style={{ margin: '1.5em 3%' }} />
                <div>
                  <GreyText style={{ whiteSpace: 'nowrap' }}>
                    {dayjs(monthAndYear, 'YYYY-MM')
                      .format('MMMM YYYY')
                      .toUpperCase()}
                  </GreyText>
                </div>
                <div className="divider mt-2" style={{ margin: '1.5em 3%' }} />
              </div>
              {renderMeetingsOfMonth(meetingsByMonthAndYear[monthAndYear])}
            </>
          ))}
        </Scrollable>
      )
    }

    return (
      <Card body={true} className="Meetings">
        <H4 arrowColor={'green'}>{title}</H4>
        <Divider />
        {!projectsStore!.projectsFetched && <Skeletons />}
        {projectsStore!.projectsFetched && !meetings.length && <NoMeeting />}
        {renderMeetings(meetings)}
        {meetingToCancel && (
          <CancelMeetingModal
            meeting={meetingToCancel}
            cancelMeeting={cancelMeeting}
            close={() => setMeetingToCancel(undefined)}
          />
        )}
        {linkToCalendar && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Link to={`/calendar`}>
              <GreyText style={{ textDecoration: 'underline' }}>
                Retrouver tous mes rendez-vous
              </GreyText>
            </Link>
          </div>
        )}
      </Card>
    )
  }
)

type CancelMeetingModalProps = {
  close: () => void
  cancelMeeting: (m: Meeting, reason: string) => void
  meeting: Meeting
}
export const CancelMeetingModal: FC<CancelMeetingModalProps> = ({
  meeting,
  cancelMeeting,
  close,
}) => {
  const [reason, setReason] = useState('')
  const closeButton = (
    <div style={{ cursor: 'pointer' }} onClick={close}>
      <FontAwesomeIcon icon={['far', 'times-circle']} size={'lg'} />
    </div>
  )
  return (
    <Modal isOpen={true} toggle={close}>
      <ModalHeader close={closeButton} toggle={close} />
      <ModalBody>
        <p>Êtes vous sûr de vouloir annuler ce rendez-vous ?</p>
        <MeetingC meeting={meeting} displayDay={true} />
        <div className="mt-3">
          <MaterialLikeInput
            autoFocus={true}
            placeholder={"Raison de l'annulation"}
            onChange={e => setReason(e.target.value)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={close}>
          Ne pas annuler
        </Button>
        <Button color="secondary" onClick={() => cancelMeeting(meeting, reason)}>
          Annuler le rendez-vous et prévenir mon conseiller
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const NoMeeting = () => (
  <div className="NoMeeting">
    <MeetingIcon />
    <span className="NoMeeting-title">Aucun Rendez-vous</span>
    <div className="divider" />
    <span className="text-muted">Vous n'avez aucun rendez-vous de prévu</span>
  </div>
)

const Skeletons = memo(() => (
  <div className="d-flex w-100 mb-2 flex-column">
    <div className="w-100 justify-content-between d-flex mb-1">
      <div className="w-33">
        <Skeleton width={'100%'} height={'20px'} />
      </div>
      <div className="w-25">
        <Skeleton width={'100%'} height={'20px'} />
      </div>
    </div>
    <div className="w-25 mb-1">
      <Skeleton width={'100%'} height={'10px'} />
    </div>
    <div className="w-100 justify-content-between d-flex mb-1">
      <div className="w-33">
        <Skeleton width={'100%'} height={'10px'} />
      </div>
      <div className="w-33">
        <Skeleton width={'100%'} height={'10px'} />
      </div>
    </div>
    <div className="divider mt-2" />
  </div>
))

export default Meetings

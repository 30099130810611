import { useAppContext } from '../context'
import { useAlert } from 'react-alert'
import { FeatureKey } from '../model/config'

const localStatusStore: Health[] = []
let apiCallDone = false

interface Health {
  name: Feature
  health: Status
}

type Status = { status: HealthStatus }

enum HealthStatus {
  DOWN = 'DOWN',
  OUT_OF_SERVICE = 'OUT_OF_SERVICE',
  STOP = 'STOP',
  DEGRADED = 'DEGRADED',
  UP = 'UP',
  UNKNOWN = 'UNKNOWN',
}

export enum Feature {
  MESSAGE = 'message',
  RESSOURCES = 'resource',
  MEMENTO = 'pdf',
}

const SERVICES = [
  { name: Feature.MESSAGE, message: 'La messagerie est actuellement en maintenance' },
  { name: Feature.RESSOURCES, message: 'Les ressources sont actuellement en maintenance' },
  { name: Feature.MEMENTO, message: "L'export du mémento est actuellement en maintenance" },
]

export const useHealthCheck = (name: Feature) => {
  const { authStore, apiProxy, featuresStore } = useAppContext()
  const alert = useAlert()

  const checkHealthFor: () => Promise<Health[]> = () => {
    apiCallDone = true
    return apiProxy.get<Health[]>(`/services-health/`)
  }

  const storeStatus = (health: Health) => {
    if (localStatusStore.length < Object.keys(Feature).length) {
      if (!storeContains(health)) {
        localStatusStore.push(health)
      }

      if (health.health.status !== HealthStatus.UP && isFeatureEnabled(health.name)) {
        alert.error(SERVICES.find(service => service.name === health.name)!.message)
      }
    }
  }

  const isFeatureEnabled = (feature: Feature) => {
    let featureEnabledByAssociation = true

    if (authStore.user !== undefined && authStore.user.associationId !== undefined) {
      if (feature === Feature.MESSAGE) {
        featureEnabledByAssociation = featuresStore.checkFeatureActivationByAssociationId(
          FeatureKey.MESSENGER_FEATURE,
          authStore.user.associationId
        )
      }
    }
    return featureEnabledByAssociation
  }

  const storeContains = (health: Health) => {
    localStatusStore.forEach(healthStored => {
      if (healthStored.name === health.name) {
        return true
      }
    })
    return false
  }

  const checkFeatureStatus = (resolve: (up: boolean) => any) => {
    if (!apiCallDone) {
      checkHealthFor()
        .then(servicesHealth => {
          servicesHealth.forEach(health => storeStatus(health))
          const featureHealth = localStatusStore.find(h => h.name === name)!
          resolve(featureHealth.health.status === HealthStatus.UP)
        })
        .catch(() => {
          storeStatus({ name, health: { status: HealthStatus.DOWN } })
          resolve(false)
        })
    }
  }

  return new Promise<boolean>(resolve => {
    const currentStatus = localStatusStore.find(s => s.name === name)
    if (currentStatus) {
      resolve(currentStatus.health.status === HealthStatus.UP)
    } else {
      if (authStore !== undefined) {
        authStore.onLoggedIn(() => {
          checkFeatureStatus(resolve)
        })
      }
    }
  })
}
